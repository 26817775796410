import { ArrowPathIcon, CameraIcon, XCircleIcon, CheckIcon, PlusCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useRef, useState } from "react";
import { useLocalizedStrings } from "../Localization";

const left = "relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10";
const right = "relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10";
const middle = "relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10";

export default function Camera(props: {
    hidden?: boolean,
    enabled?: boolean,
    onSnapshot?(image: string): Promise<void>,
    onStateChange?(state: 'enabled' | 'disabled'): void,
    className?: string,
    cameraClassName?: string
}) {
    const cameraFeed = useRef<HTMLVideoElement>(null);
    const snapshot = useRef<HTMLCanvasElement>(null);
    const preview = useRef<HTMLImageElement>(null);
    const [pictureTaken, setPictureTaken] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const L = useLocalizedStrings();

    function updateCanvasSize() {
        if (!snapshot.current) return;

        if (snapshot.current?.width !== cameraFeed.current?.videoWidth || snapshot.current?.height !== cameraFeed.current?.videoHeight) {
            snapshot.current!.width = cameraFeed.current!.videoWidth;
            snapshot.current!.height = cameraFeed.current!.videoHeight;
        }
    }

    async function startCamera() {
        const media = await navigator.mediaDevices.getUserMedia({ video: true });
        cameraFeed.current!.srcObject = media;
        cameraFeed.current!.play();
        setPictureTaken(false);
        setShowCamera(true);
        props.onStateChange?.('enabled');
    }

    function takePicture() {
        const ctx = snapshot.current!.getContext('2d');
        const video = cameraFeed.current!;
        ctx?.drawImage(video, 0, 0, snapshot.current!.width, snapshot.current!.height);
        const image = snapshot.current!.toDataURL('image/png');
        preview.current!.src = image;
        setPictureTaken(true);
    }

    async function save() {
        await props.onSnapshot?.(preview.current!.src);
        cancel();
    }

    function cancel() {
        setShowCamera(false);
        setPictureTaken(false);
        props.onStateChange?.('disabled');
        (cameraFeed.current?.srcObject as MediaStream)?.getTracks()?.forEach(t => t.stop());
    }

    useEffect(() => {
        if (props.enabled) {
            startCamera();
        } else {
            cancel();
        }
    }, [props.enabled, snapshot, cameraFeed]);


    return (<div
        className={props.className}
        hidden={props.hidden}
    >
        <canvas ref={snapshot} hidden />
        <img ref={preview} hidden={!pictureTaken} className="pb-1.5" />
        <video
            ref={cameraFeed}
            hidden={!showCamera || pictureTaken}
            onLoadedMetadata={updateCanvasSize} />
        <button
            type="button"
            className={props.cameraClassName}
            hidden={showCamera}
            onClick={startCamera}>
            <PlusCircleIcon className={"h-6 w-6"} aria-hidden="true" />
            <span className={"text-sm font-medium text-gray-800"}>{L.selfie.camera}</span>
        </button>
        <button
            type="button"
            className={left}
            onClick={() => setPictureTaken(false)}
            hidden={!pictureTaken}>
            <ArrowPathIcon width={30} height={30} />
            <span>{L.selfie.start_over}</span>
        </button>
        <button
            type="button"
            className={left}
            hidden={!showCamera || pictureTaken}
            onClick={takePicture}>
            <CameraIcon width={30} height={30} />
            <span>{L.selfie.take_photo}</span>
        </button>
        <button
            type="button"
            className={middle}
            hidden={!showCamera || !pictureTaken}
            onClick={save}>
            <CheckIcon width={30} height={30} />
            <span>{L.selfie.done}</span>
        </button>
        <button
            type="button"
            className={right}
            hidden={!showCamera}
            onClick={cancel}>
            <XCircleIcon width={30} height={30} />
            <span>{L.apply.cancel}</span>
        </button>
    </div>);
}
