import { TrackedPrimitive, TrackedRecord, createFromRawValue } from "@aidkitorg/typesheets/lib/collab";
import { Text } from "@aidkitorg/types/src/survey";
import { search } from "aidkit/lib/program/search";

export const MIGRATIONS: Record<string, (qType: TrackedRecord) => void> = {
    'DashboardV1': (dashboard: TrackedRecord): void => {
        if (!dashboard.value.kind) {
            dashboard.set('kind', createFromRawValue(dashboard.state, 'Dashboard'));
        }
        if (!dashboard.value.version || dashboard.value.version < 1) {
            dashboard.set('version', createFromRawValue(dashboard.state, 1));
        }
    },
    'LoginWidgetV2': (loginWidget: TrackedRecord): void => {
        if (!loginWidget.value.buttonText) {
            loginWidget.set('buttonText', createFromRawValue(loginWidget.state, { en: 'Check your status', es: 'Revisa tu estatus' }));
        }
        if (!loginWidget.value.version || loginWidget.value.version.value < 2) {
            loginWidget.set('version', createFromRawValue(loginWidget.state, 2));
        }
    },
    'NotificationGroupV1': (notificationGroup: TrackedRecord) => {
        if (!notificationGroup.value.version || notificationGroup.value.version < 1) {
            notificationGroup.set('version', createFromRawValue(notificationGroup.state, 1));
        }
        if (!notificationGroup.value.kind) {
            notificationGroup.set('kind', createFromRawValue(notificationGroup.state, 'Notification'));
        }
        if (notificationGroup.value.timezone) {
            if (notificationGroup.value.scheduleSettings) {
                notificationGroup.value.scheduleSettings.set('timezone', createFromRawValue(notificationGroup.value.scheduleSettings.state, notificationGroup.value.timezone));
            } else {
                notificationGroup.set('scheduleSettings', createFromRawValue(notificationGroup.state, { timezone: notificationGroup.value.timezone.value }));
            }
            notificationGroup.remove('timezone', true);
        }
    },
    'PlaidBankLegacyV0': (plaidBank: TrackedRecord) => {
        if (!plaidBank.value.content) {
            plaidBank.set('content', createFromRawValue(plaidBank.state, 'Please log in'));
        }
        if (!plaidBank.value.version || plaidBank.value.version < 1) {
            plaidBank.set('version', createFromRawValue(plaidBank.state, 1));
        }
    },
    'SearchSelectV1': (searchSelect: TrackedRecord) => {
        if (!searchSelect.value.searchType) {
            const originalOptionsFileUrlValue: Record<string, TrackedRecord> = searchSelect.value.optionsFileUrl.value;
            // Map the original object to a new object, replacing each TrackedPrimitive with its value
            const optionsFileUrl = Object.fromEntries(
                Object.entries(originalOptionsFileUrlValue).map(
                  ([key, entry]: [string, TrackedRecord]) => [key, entry.value]
                )
              ) as unknown as Text;
            searchSelect.set('searchType', createFromRawValue(searchSelect.state, { kind: 'ClientSide', optionsFileUrl }));
            searchSelect.remove('optionsFileUrl', true);
        }
        if (!searchSelect.value.version || searchSelect.value.version < 1) {
            searchSelect.set('version', createFromRawValue(searchSelect.state, 1));
        }
    }
}