import React from 'react';
import { toast } from 'react-toastify';
import { useLocalizedStrings } from '../Localization';

export function ClearVirtualFileSystemButton() {

    const L = useLocalizedStrings();

    const test = L.offline.confirm_delete_files_test;

    async function blowItAway() {
        const result = prompt(L.offline.confirm_delete_files.replace("$n", test));
        if (result?.toLowerCase() === test.toLowerCase()) {
            const dir = await navigator.storage.getDirectory();
            toast.warning(L.offline.notify_delete_all_data);
            await dir.remove();
            toast.info(L.offline.notify_data_deleted);
        } else {
            toast.info(L.offline.notify_data_delete_cancelled);
        }
    }

    return (<a onClick={blowItAway} className="cursor-pointer text-blue-500">{L.offline.clear_files}</a>);
}
