import { useContext, useEffect, useRef, useState } from 'react';
import { Persona, Subsurvey } from '@aidkitorg/types/lib/survey';
import { classNames, snakeToEnglish } from '../Util';
import { usePost, useRoboNav } from '../API';
import { CheckCircleIcon, ExclamationTriangleIcon as ExclamationIcon, XCircleIcon } from "@heroicons/react/24/solid"
import { ArrowTopRightOnSquareIcon, Cog8ToothIcon as CogIcon, PlayIcon, PlusCircleIcon, StopIcon } from "@heroicons/react/24/outline"
import InterfaceContext from '../Context';
import FlyoutMenu from './FlyoutMenu';
import { Dropdown } from './Dropdown';
import { limitConcurrentRequests } from '@aidkitorg/types/lib/util';
import type { SurveyCompletionResult } from '@aidkitorg/robonav/lib';
import { useAsyncEffect } from '../Hooks/AsyncEffect';
import moment from 'moment';

const Statuses = [
    'queued',
    'running',
    'passed',
    'failed',
    'errored',
    'cancelled'
] as const;

type Status = typeof Statuses[number];

const statusTips: Record<Status, string> = {
    'queued': 'Waiting to begin',
    'passed': 'The last answered question was for the final expected target field of the survey',
    'running': 'Currently executing the run',
    'failed': 'The run did not have the expected outcome, but no errors occurred. this usually indicates a problem with the survey',
    'errored': 'Something went wrong with robonav, and the run was unable to complete. these runs should be considered invalid',
    'cancelled': 'These runs were cancelled by you before they could either start or finish, via the stop button'
};

function StatusIcon({ status }: { status: Status }) {
    const sizeClasses = "h-7 w-7";
    function showOn(...statuses: Status[]) {
        return !statuses.includes(status) ? 'hidden' : null;
    }
    return <div aria-details={statusTips[status]} aria-label={status} title={statusTips[status]}>
        <PlusCircleIcon className={classNames(sizeClasses, "text-indigo-700", showOn('queued'))} />
        <CogIcon className={classNames(sizeClasses, "animate-spin", showOn('running'))} />
        <CheckCircleIcon className={classNames(sizeClasses, "bg-green-400 text-white rounded-full", showOn('passed'))} />
        <XCircleIcon className={classNames(sizeClasses, "bg-red-600 text-white rounded-full", showOn('failed'))} />
        <ExclamationIcon className={classNames(sizeClasses, "text-yellow-400", showOn('errored', 'cancelled'))} />
    </div>;
}

type SurveyTesterProps = {
    survey: Subsurvey,
    personas: Persona[],
    runLimit: number,
    runConcurrency: number,
    after?: number
};

function RunReview({ result: { videoId, persona, lastQuestion } }: { result: SurveyCompletionResult }) {
    return <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">Last Question</h1>
                <p className="mt-2 text-sm text-gray-700">{lastQuestion?.question!}</p>
            </div>
        </div>
        {videoId && <VideoReview id={videoId} />}
        <span hidden={persona && !!Object.keys(persona).length} className="font-semibold">
            No Persona Details Available
        </span>
        <div hidden={!persona || !Object.keys(persona).length} className="mt-8 flow-root">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Persona</h1>
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Key</th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Value</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {Object.entries(persona ?? {}).filter(([, value]) => value).map(([key, value]) =>
                                    <tr>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{snakeToEnglish(key)}</td>
                                        <td className="whitespace-nowrap text-pretty px-3 py-4 text-sm text-gray-500">{value}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function VideoReview(props: { id: string }) {
    const getVideo = useRoboNav('/review/video', { textOnly: true });
    const videoPlayer = useRef<HTMLVideoElement | null>(null);
    const [videoLoaded, setVideoLoaded] = useState<boolean>();

    useEffect(() => {
        (async () => {
            if (!videoPlayer.current) return;

            const video: any = await getVideo({ id: props.id });
            videoPlayer.current.src = video;
            // seeks to the end of the video, so the last frame is shown in preview.
            videoPlayer.current.addEventListener('durationchange', () => {
                videoPlayer.current!.currentTime = videoPlayer.current!.duration;
            }, { once: true })
            setVideoLoaded(true);
        })();
    }, [props.id, videoPlayer])

    return <video hidden={!videoLoaded} muted controls ref={videoPlayer} />
}

function determineRunOutcome({ error, completed }: SurveyCompletionResult): Status {
    switch (true) {
        case error?.includes('Timeout'):
            return 'cancelled';
        case completed:
            return 'passed';
        default: return 'failed'
    }
}

function SurveyTester({ survey, personas, runLimit, runConcurrency, after }: SurveyTesterProps) {
    const runBot = useRoboNav('/bot/queue_run');
    const listRuns = useRoboNav('/bot/list_survey_results');
    const getRun = useRoboNav('/bot/get_survey_result');

    const { lang } = useContext(InterfaceContext);
    const [selectedRunResult, setSelectedRunResult] = useState<SurveyCompletionResult>();
    const [mode, setMode] = useState<'personas_only' | 'with_personas' | 'no_personas'>('no_personas');
    const [runs, setRuns] = useState<string[]>([]);
    const [runResults, setRunResults] = useState<Map<string, SurveyCompletionResult>>(new Map());
    const [running, setRunning] = useState<boolean>();

    useAsyncEffect(async (signal) => {

        const previousRunKeys = await listRuns({ afterDate: after, surveyName: survey.path }, undefined, signal);

        const previousRuns = await limitConcurrentRequests(
            previousRunKeys?.runKeys?.map(key => () => getRun({ key: key! }, undefined, signal).then(r => [key, r] as [string, SurveyCompletionResult])) ?? [],
            runConcurrency
        );

        setRuns([]);

        setRunResults(new Map(
            previousRuns
                .filter(r => r.status === 'fulfilled')
                .map(r => (r as any).value as [string, SurveyCompletionResult])
        ));

    }, [survey, after]);

    async function updateRunResults() {
        type RunResult = Awaited<ReturnType<typeof getRun>>;

        const results = await limitConcurrentRequests(
            runs.map(id => () => getRun({ surveyName: survey.path, id })
                .then(r => [id, r] as [string, RunResult])
            ),
            3
        );

        const fulfilledRequests = results
            .filter(r => r.status === 'fulfilled')
            .map(r => (r as PromiseFulfilledResult<[string, RunResult]>).value);

        const notFoundYet = fulfilledRequests
            .filter(([, r]) => 'status' in r && (r as any).status === 'not_found')
            .map(([taskId]) => taskId);

        setRuns(notFoundYet);

        setRunResults(prev => fulfilledRequests
            .filter(([, r]) => !('status' in r))
            .reduce((p, [taskId, r]) => p.set(taskId, r as SurveyCompletionResult), new Map(prev))
        );
    }

    useEffect(() => {
        let timer: undefined | NodeJS.Timeout | number;
        const timerFn = () => setTimeout(async () => {
            if (runs.length === 0) {
                return;
            }
            console.log('updating run results...');
            await updateRunResults();
            timer = timerFn();
        }, 10000);

        timerFn();

        return () => clearTimeout(timer);
    }, [runs]);

    async function startRuns() {
        if (running) {
            return;
        }

        setRunning(true);

        const newRuns: (() => Promise<string>)[] = [];
        if (mode === 'with_personas' || mode === 'no_personas') {
            for (const _ of Array(runLimit).fill(0)) {
                newRuns.push(() => runBot(
                    { surveyName: survey.path },
                ).then(r => r.taskId!));
            }
        }
        if (mode === 'with_personas' || mode === 'personas_only') {
            for (const { name } of personas) {
                newRuns.push(() =>
                    runBot({ persona: name, surveyName: survey.path })
                        .then(r => r.status === 'ok' ? r : Promise.reject(r))
                        .then(r => r.taskId!)
                );
            }
        }

        const tasksInQueue = await limitConcurrentRequests(newRuns, runConcurrency);

        setRuns(tasksInQueue
            .filter(t => t.status === 'fulfilled')
            .map(t => (t as PromiseFulfilledResult<string>).value)
        );

        setRunning(false);
    }

    function cancel() {
        setRunning(false);
        setRuns([]);
    }

    function clear() {
        cancel();
        setRuns([]);
        setRunResults(new Map());
        setSelectedRunResult(undefined);
    }

    const statusGroupsMap = Array.from(runResults).reduce(
        (prev, [taskId, run]) => {
            const outcome = determineRunOutcome(run);
            if (!prev.has(outcome)) {
                prev.set(outcome, []);
            }
            prev.get(outcome)!.push(Object.assign({ taskId }, run));
            return prev;
        },
        new Map<Status, (SurveyCompletionResult & { taskId: string })[]>([
            ['running', runs.map(taskId => ({ taskId, completed: false }))]
        ])
    );

    const modes: Record<typeof mode, string> = {
        'with_personas': 'Random & Personas',
        'no_personas': 'Only Random',
        'personas_only': 'Only Personas'
    };

    return <div className="space-y-2">
        <div className="flex">
            <h2>{survey.title?.[lang] ?? survey.path}</h2>
            <a href={`/condensed_view/robonav/${survey.path}`} className="text-indigo-400">
                <ArrowTopRightOnSquareIcon className="h-6 w-6" />
            </a>
        </div>
        <span className="relative isolate inline-flex rounded-md -z-1 shadow-sm">
            <button
                hidden={running}
                onClick={startRuns}
                className="bg-indigo-400 text-black rounded-l py-2 px-4 hover:bg-blue-300">
                <PlayIcon className="w-5 h-5" />
            </button>
            <button
                hidden={!running}
                onClick={cancel}
                className="bg-red-500 text-black rounded-l py-2 px-4 hover:bg-red-300">
                <StopIcon className="w-5 h-5" />
            </button>
            <Dropdown
                usePopper={true}
                className="bg-white text-black rounded-none z-50"
                options={Object.entries(modes).map(([modeType, title]) => ({
                    label: <span>{title}</span>,
                    callback: () => { setMode(modeType as typeof mode) },
                }))}
                label={<span>{modes[mode]}</span>} />
            <button onMouseDown={clear} className="bg-yellow-400 rounded-r text-black py-2 px-3 hover:bg-yellow-200">Clear</button>
        </span>
        <ul role="list" className="align-middle space-y-4">
            {Array.from(statusGroupsMap).filter(([, runs]) => runs.length > 0).map(([status, runs]) =>
                <div key={status}>
                    {runs.some(r => r.videoId || r.lastQuestion || r.persona) ?
                        <FlyoutMenu
                            className={"" /* intentionally left blank */}
                            zIndex={50}
                            label={<div className="flex space-x-1">
                                <StatusIcon status={status} />
                                <span>{runs.length}</span>
                                <span>{status}</span>
                            </div>}
                            items={<ul className="block">
                                {runs.filter(r => r.videoId).map((r, i) => <li key={r.taskId}>
                                    <a className="hover:bg-blue-400 hover:cursor-pointer"
                                        onMouseDown={() => { setSelectedRunResult(r) }}>
                                        {r.persona?.legal_name || `#${i}: ${r.lastQuestion?.hashId} ${r.lastQuestion?.question === '--' ? r.lastQuestion.target_field : r.lastQuestion?.question}`}
                                    </a>
                                </li>)}
                            </ul>}
                        /> : <div className="flex space-x-1">
                            <StatusIcon status={status} />
                            <span>{runs.length}</span>
                            <span>{status}</span>
                        </div>}
                </div>
            )}
        </ul>
        {!!selectedRunResult && <RunReview result={selectedRunResult} />}
    </div>;
}

export default function RoboNavConsole({ surveys, personas }: { surveys: Subsurvey[], personas: Persona[] }) {
    const [selected, setSelected] = useState<Subsurvey>(surveys[0])
    const { lang } = useContext(InterfaceContext);
    const [runLimit, setRunLimit] = useState<number>(1);
    const [after, setAfter] = useState<number>(Date.now() - (1000 * 60));

    useEffect(() => {
        if (!selected) {
            setSelected(surveys[0]);
        }
    }, [surveys]);

    return <div>
        <div className="flex">
            <Dropdown
                className="bg-white text-black rounded-l rounded-r-none z-50"
                label={<span>With {runLimit} Random App{runLimit > 1 ? 's' : ''}</span>}
                usePopper={true}
                options={[1, 10, 100].map(o => ({
                    label: <span>{o}</span>,
                    callback() {
                        setRunLimit(o as number)
                    }
                }))}
            />
            <Dropdown
                usePopper={true}
                className="bg-white text-black rounded-l-none rounded-r z-50"
                label={selected ? ("Survey: " + (selected?.title?.[lang] || selected?.path)) : 'Pick a Survey'}
                options={surveys.filter(s => s.anonymous).map(s => ({ label: <span>{s.title?.[lang] || s.path}</span>, callback() { setSelected(s) } }))}
            />
            <Dropdown
                usePopper={true}
                className="bg-white text-black rounded-l-none rounded-r z-50"
                label={after === 0 ? 'Showing All Results' : "Showing Results From: " + moment(after).toNow()}
                options={[
                    { label: '5 minutes', callback() { setAfter(Date.now() - (1000 * 60 * 5)) } },
                    { label: '1 day', callback() { setAfter(Date.now() - (1000 * 60 * 60 * 24)) } },
                    { label: 'all', callback() { setAfter(0) } },
                    { label: '1 hour', callback() { setAfter(Date.now() - (1000 * 60 * 60)) } }
                ]}
            />
        </div>
        {selected ?
            <SurveyTester
                after={after}
                survey={selected}
                personas={personas}
                runLimit={runLimit}
                runConcurrency={10}
            />
            : null}
    </div>;
}
