import * as survey from "@aidkitorg/types/lib/survey";
import { CompileExpressionToSQL } from "@aidkitorg/types/lib/translation/expr_to_sql";
import { PermissionScope } from "@aidkitorg/types/lib/translation/permissions";
import { expandGenericTemplates } from "@aidkitorg/types/lib/translation/templates"
import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { usePost } from "./API";
import { UserInfoContext } from "./Context";
import { DashboardComponent } from "./DistroDashboard";
import { getDistroBrowserIncompatibility, SpacedSpinner } from "./Util";

function utf8_to_b64(str: string) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

function b64_to_utf8(str: string) {
    return decodeURIComponent(escape(window.atob(str)));
}

function tryDecodingHash(hash: string) {
    try {
        return JSON.parse(b64_to_utf8(hash));
    } catch (e) {
        return null;
    }
}

export function encodeQueueForExplore(q: survey.Queue, sectionFilter?: survey.BooleanExpr): string {
    // create 'And' expression to include sectionFilters if needed
    let andFilter = {
        kind: 'And',
        clauses: [
        q.condition.kind === 'Click' ? q.condition.expr : q.condition,
        ...(sectionFilter ? [sectionFilter] : []), // only add sectionFilter if it exists
        {
            'kind': 'Value Empty',
            'value': {
                'kind': 'Field',
                'field': q.assigneeField
            }
        }
    ]};

    // put the andFilter into an applicant table view of the queue - including queue order (if exists)
    const exploreQuery = {
        query: {
            kind: 'Applicant Table',
            filter: q.condition.kind === 'Click' ? {
                kind: 'Click',
                expr: andFilter,
                orderBy: q.condition.orderBy
            } : andFilter,
            columns: [
                { kind: 'Field', field: 'legal_name'},
                ...(q.columns || []),
            ]
        }
    };
    return utf8_to_b64(JSON.stringify(exploreQuery));
}

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));
export function Explore() {
    const getScope = usePost('/program/enumerate_scope');
    const doMagic = usePost('/program/admin/magic');
    const runQuery = usePost('/dashboard/view');
    const params = new URLSearchParams(window.location.search);
    const user = useContext(UserInfoContext);
    const [running, setRunning] = useState(false);
    const [results, setResults] = useState<any>(null);
    const [showQuery, setShowQuery] = useState(false);
    const [dash, setDash] = useState<any>(null);
    const [query, setQuery] = useState<survey.Explore>(tryDecodingHash(window.location.hash.slice(1)) || {
        query: {
            kind: 'Applicant Table',
            columns: [],
            filter: {
                kind: 'Never'
            }
        }
    });
    const distroRef = useRef<React.ComponentRef<typeof Distro>>(null);
    const abortControllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        if (params.get('run') === 'yes') run();
    }, [])

    async function run() {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        const dashboard = {
            kind: 'Dashboard',
            path: '',
            components: [
                query.query
            ]
        };
        setDash(dashboard);
        setRunning(true);
        try {                   
            setResults( 
                await Promise.race([
                    runQuery( { dashboard }, undefined, abortControllerRef.current.signal ) as any,
                    new Promise((_, reject) => setTimeout(
                        () => reject(new Error('Query timeout')), 
                        30000 // Default API gateway timeout
                    ))
                ])
            );
        } catch (e:any) {
            if (e.name !== 'AbortError') {
                toast.error(e.message);
            }
        } finally {
            setRunning(false);
        }
    }

    async function cancel() {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
            abortControllerRef.current = null;
        }
        setRunning(false);
    }

    (window as any).magic = async (data: any) => {
        return await doMagic(data);
    }

    useEffect(() => {
        (async () => {
            const scope = await getScope({});
            distroRef.current?.initialize(query, user, undefined, {
                'fields': (scope as PermissionScope).fieldScope || [],
            }, `We are using a tool to generate queries and charts to explore data in a database. These queries and charts are defined using JSON.

The database has the following fields: ${(scope as PermissionScope).fieldScope.join(', ')}

As an example, this is a bar chart that shows the race of veterans: {"kind":"Bar Chart","title":"Gender of people who like the color blue","summary":{"kind":"Count"},"groups":[{"kind":"Field","field":"race"}],"filter":{"kind":"And","clauses":[{"kind":"Equals","field":"veteran","value":"yes"}]}}

This is a table of all applicants with their name and birth date: {"kind":"Applicant Table","columns":[{"kind":"Field","field":"legal_name"},{"kind":"Field","field":"birth_date"}],"filter":{"kind":"Not","clause":{"kind":"Never"}}}

Note: that to check if a field equals a value, the correct form is {"kind": "Equals", field: [FIELD], value: [VALUE]}

`)
        })()
    }, [user]);

    const incompatible = getDistroBrowserIncompatibility();
    if (incompatible) return incompatible;

    return <div className="bg-gray-100 h-full p-4">
        <h2>Query</h2>
        <div className="mt-4 bg-white shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <Suspense>
                <Distro 
                    ref={distroRef} 
                    value= {query}
                    types='src/survey.ts' name='Explore' onChange={(v) => {
                        window.location.hash = '#' + utf8_to_b64(JSON.stringify(v));
                        setQuery(expandGenericTemplates(v)) 
                    }}
                    />
            </Suspense>
        </div>
        <div className="mt-2 flex inline-flex">
            <button
                onClick={run}
                type="button"
                disabled={running}
                className="shadow rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                {running && <SpacedSpinner />}
                Run
            </button>
            {running && <button
                onClick={cancel}
                type="button"
                className="shadow rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                Cancel
            </button>}
            <button
                onClick={() => setShowQuery(!showQuery)}
                type="button"
                className="hidden shadow mt-2 ml-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                {showQuery ? 'Hide' : 'Show'} Query
            </button>
        </div>
        <div className="mt-4">
        {showQuery && <pre>{query.query.kind === 'Applicant Table' && CompileExpressionToSQL({
            cond: query.query.filter.kind === 'Click' ? query.query.filter.expr : query.query.filter, 
            columns: query.query.columns,
            orderBy: query.query.filter.kind === 'Click' ? query.query.filter.orderBy : undefined
            })}
        </pre>}
        {results !== null
            && (typeof results === 'string' 
            ? <div>{results}</div>
            : (<>
            <h1>Results</h1>
            {(results.components || []).map((s: survey.DashboardComponent, i: number) => {
                return <DashboardComponent
                    key={s.kind + i}
                    section={s}
                    updateFilterConfig={(path: string, idx: number) => {}}
                    isExploreQuery={true}
                    dashboard={ dash }
                    filterConfig={{} as Record<string, number>}
                />
            })}
        </>))}
        </div>
    </div>
}
