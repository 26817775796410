"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.unmockDate = exports.mockDate = void 0;
var globalObj = Function('return this')();
globalObj._unmockedDate = globalObj.Date;
// DEAR FUTURE AIDKIT READER:
// Do NOT console.log inside any of the mocked constructs, that will create an infinite loop
// as console.log creates a date function. TIL :) 
function mockDate(date) {
    globalObj.Date = new Proxy(globalObj._unmockedDate, {
        construct: function (Target, args) {
            // props to https://stackoverflow.com/a/43160428/671457
            if (args.length === 0) {
                return new Target(date);
            }
            // eslint-disable-next-line
            return new (Target.bind.apply(Target, __spreadArray([void 0], __read(args), false)))();
        },
        get: function (Target, prop, receiver) {
            if (prop === 'now') {
                return function () { return new Target(date).getTime() * 1; };
            }
            return Reflect.get(Target, prop, receiver);
        }
    });
}
exports.mockDate = mockDate;
function unmockDate() {
    globalObj.Date = globalObj._unmockedDate;
}
exports.unmockDate = unmockDate;
