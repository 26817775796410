"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickJSModule = void 0;
var quickjs_emscripten_1 = require("quickjs-emscripten");
var QuickJSModule = /** @class */ (function () {
    function QuickJSModule() {
    }
    // This gives us the shared instance of the QuickJSContext that we use for all computations.
    QuickJSModule.getQuickJSInstance = function () {
        return __awaiter(this, void 0, void 0, function () {
            var QuickJS, e_1, vm, dateRegex, code, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!QuickJSModule.context) return [3 /*break*/, 6];
                        QuickJS = void 0;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 2, , 4]);
                        QuickJS = (0, quickjs_emscripten_1.getQuickJSSync)();
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        return [4 /*yield*/, (0, quickjs_emscripten_1.getQuickJS)()];
                    case 3:
                        // if we don't have a QuickJSWasm module ready to use, we will get error 
                        // 'QuickJS not initialized. Await getQuickJS() at least once.' back. 
                        QuickJS = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [4 /*yield*/, QuickJS.newContext()];
                    case 5:
                        vm = _a.sent();
                        dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
                        code = "\n        originalDate = Date;\n        newDate = function() {\n          if (arguments.length === 1 && typeof arguments[0] === 'string') {\n              const match = arguments[0].match(".concat(dateRegex.toString(), ");\n              if (match) {\n                  const [_, month, day, year] = match;\n                  return new originalDate(+year, +month - 1, +day);\n              }\n          }\n          return new originalDate(...arguments);\n        };\n\n        newDate.now = Date.now;\n        newDate.UTC = Date.UTC;\n        newDate.parse = Date.parse;\n    \n        newDate.prototype = Date.prototype;\n        Date = newDate;\n        ");
                        res = vm.evalCode(code);
                        if (res.error) {
                            res.error.dispose();
                        }
                        else {
                            res.value.dispose();
                        }
                        QuickJSModule.context = vm;
                        _a.label = 6;
                    case 6: return [2 /*return*/, QuickJSModule.context];
                }
            });
        });
    };
    // Helper to unpack a deeply nested object into a nested QuickJSHandle object.
    QuickJSModule.createVmObject = function (scope, vm, depObject) {
        var _this = this;
        if (!depObject) {
            return scope.manage(vm.newString(''));
        }
        if (typeof depObject === 'string') {
            return scope.manage(vm.newString(depObject));
        }
        else if (typeof depObject === 'number') {
            return scope.manage(vm.newNumber(depObject));
        }
        else if (typeof depObject === 'object' && Array.isArray(depObject)) {
            var arr_1 = scope.manage(vm.newArray());
            depObject.forEach(function (obj, i) {
                var unpackedObj = _this.createVmObject(scope, vm, obj);
                vm.setProp(arr_1, i, unpackedObj);
            });
            return arr_1;
        }
        else {
            // otherwise, recursively build a vm object to hold all the diff fields and values
            var vmDepObject_1 = scope.manage(vm.newObject());
            Object.keys(depObject).forEach(function (key) {
                if (depObject[key]) {
                    var unpackedObj = _this.createVmObject(scope, vm, depObject[key]);
                    vm.setProp(vmDepObject_1, key, unpackedObj);
                }
            });
            return vmDepObject_1;
        }
    };
    // this function computes a conditional that relies on information about the program state -
    // this can be passed in as info, screener, org, etc. 
    // this function returns the value of the computed formula, as well as the set of fields from 
    // the info object that were used in computing the formula (its direct dependencies).
    QuickJSModule.computeWithDeps = function (formula, programState) {
        return __awaiter(this, void 0, void 0, function () {
            var vm;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, QuickJSModule.getQuickJSInstance()];
                    case 1:
                        vm = _a.sent();
                        return [2 /*return*/, quickjs_emscripten_1.Scope.withScope(function (scope) {
                                // create a blank _info to make sure we always at least have an _info object
                                vm.setProp(vm.global, '_info', scope.manage(vm.newObject()));
                                // if we have dependencies passed in, add these deps to our vm
                                if (programState) {
                                    var keys = Object.keys(programState);
                                    keys.forEach(function (depKey) {
                                        var depObject = programState[depKey];
                                        var vmDepObject = _this.createVmObject(scope, vm, depObject);
                                        // for info, we use a Proxy in our eval to keep track of which fields 
                                        // in info are dependencies of the formula. So we call the info that we do pass in _info
                                        // in order recreate the true info as that Proxy.
                                        var propKey = (depKey === 'info') ? '_info' : depKey;
                                        vm.setProp(vm.global, propKey, vmDepObject);
                                    });
                                }
                                try {
                                    var code = "\n          (() => {\n            const deps = {};\n            const info = new Proxy(_info, {\n              get: (target, key) => {\n                deps[key] = true;\n                return _info[key];\n              }\n            });\n\n            const out = ".concat(formula, ";\n            return [Object.keys(deps), out, ''];\n          })()");
                                    var out = scope.manage(vm.unwrapResult(vm.evalCode(code)));
                                    var toReturn = vm.dump(out);
                                    toReturn[0] = new Set(toReturn[0]);
                                    return toReturn;
                                }
                                catch (e) {
                                    // now error down here
                                    console.log("Error evaluating with QuickJS: ".concat(e));
                                    return [new Set(), '', e instanceof Error ? "".concat(e.message) : e];
                                }
                            })];
                }
            });
        });
    };
    return QuickJSModule;
}());
exports.QuickJSModule = QuickJSModule;
