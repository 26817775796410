import { useState } from "react";
import { usePost } from "./API";

export default function USIOPage() {
    const [cardId, setCardId] = useState('');
    const [status, setStatus] = useState<Awaited<ReturnType<typeof getTransactions>> | undefined>(undefined);

    const getTransactions = usePost('/usio/transactions');
    const changeStatus = usePost('/usio/set_status');
    async function query() {
        setStatus(await getTransactions({ cardId }))
    }

    const makeActive = async () => {
        if (confirm('Are you sure you want to make this card active?')) {
            await changeStatus({ cardId, status: 'Active' });
            await query();
        }
    }

    const makeInactive = async () => {
        if (confirm('Are you sure you want to make this card inactive?')) {
            await changeStatus({ cardId, status: 'Inactive' });
            await query();
        }
    }

    const close = async () => {
        if (confirm('Are you sure you want to close this card?')) {
            await changeStatus({ cardId, status: 'Closed' });
            await query();
        }
    }

    const columns = ['amount1', 'pending', 'type', 'transDesc1', 'transDesc2', 'transDate']
    const balance = status?.balance ? '$' + status.balance.substring(0, status.balance.length - 2) + "." + status.balance.substring(status.balance.length - 2) : '';

    return <>
        <h1>Card Lookup</h1>
        <fieldset>
            <legend>CardId</legend>
            <input value={cardId} onChange={(e) => setCardId(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
        </fieldset>
        <button onClick={query}>Query</button>
        {status && 
            <div>
                <h3>Status</h3>
                {status.status}
                {status.status !== 'Active' &&
                    <button onClick={makeActive}>Make Active</button>}
                {status.status !== 'Inactive' &&
                    <button onClick={makeInactive}>Make Inactive</button>}
                {status.status !== 'Closed' &&
                    <button onClick={close}>Close</button>}
                <h3>Balance</h3>
                {balance}
                <h1>Transactions</h1>
                <table>
                    <thead>
                        <tr>
                            {columns.map(c => <th>{c}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {(status.transactions || []).map((t: any) => <tr>
                            {columns.map(c => <td>{t[c]}</td>)}
                        </tr>)}
                    </tbody>
                </table>
            </div>}
    </>
}