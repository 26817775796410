import { DashboardExplanation } from "@aidkitorg/types/lib/survey";
import { useContext } from "react";
import InterfaceContext from "../Context";
import { useMarkdown } from "../Util";

export function DashboardExplanationComponent(props: {explanation: DashboardExplanation}) {
    const { content, width, alignment } = props.explanation;
    const context = useContext(InterfaceContext);
    const marked = useMarkdown(content[context.lang]);
    return (
        <div className={`my-4 mr-4 float-left bg-white overflow-hidden shadow rounded-lg ${width === "full" ? "w-full" : ""} ${alignment === "left" ? "" : "text-center"}`}>
            <div className="px-4 py-4 sm:p-6">
                <dl>
                    <dd className="mt-1 font-medium">
                        {marked}
                    </dd>
                </dl>
            </div>
        </div>
    );
}