import { InfoDict, QuestionProps } from "./Props";
//import { usePlaidLink, PlaidLinkOptions, PlaidLinkOnSuccess, PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
import { usePost, useToken } from "../API";
import { useContext, useEffect, useState } from "react";
import { languageContent, safeParse, SpacedSpinner } from "../Util";
import * as v0 from "@aidkitorg/types/lib/survey";
import type { PlaidProducts, PlaidTransaction, PlaidTransactionStream } from "aidkit/lib/income/plaid";
import InterfaceContext from "../Context";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

function usePlaid(props: {
        product: PlaidProducts, 
        targetField: string,
        name: string,
        info: InfoDict
    }, 
    updateUserToken: (token: string) => Promise<void>,
    onSuccess: (params: {
        encryptedAccessToken: string, 
        metadata: any,
        itemID: string
    }) => Promise<void>, 
    onError: (params: {
        metadata: {
            link_session_id: string
        }
    }) => Promise<void>) {

    const [linkToken, setLinkToken] = useState(null as string | null);
    const [userToken, setUserToken] = useState(props.info?.plaid_user);
    const createLinkToken = usePost('/plaid/create_link_token');
    const setAccessToken = usePost('/plaid/set_access_token');

    let { product, targetField, name } = props;

    const generateToken = async () => {
        const data = await createLinkToken({ 
            products: [
                ...(product === 'transactions' ? ['auth' as PlaidProducts] : []),
                product
            ],
            targetField,
            name,
            existingUserToken: userToken
        });
        if ((data as any).token) {
            setLinkToken(data.token!);
            setUserToken(data.encryptedUserToken!);
            updateUserToken(data.encryptedUserToken!);
        }
    };
    
    useEffect(() => {
        setTimeout(() => {
            if (props.name) generateToken()
        }, 1000);
    }, [props.name]);

    // The usePlaidLink hook manages Plaid Link creation
    // It does not return a destroy function;
    // instead, on unmount it automatically destroys the Link instance
    /*
    const config: PlaidLinkOptions = {
        onSuccess: async (public_token, metadata) => {
            console.log(public_token,metadata);
            const { error, encryptedAccessToken, itemID } = await setAccessToken({ product, public_token, metadata });
            if (encryptedAccessToken && itemID) {
                await onSuccess({ 
                    encryptedAccessToken, metadata, itemID 
                });
            }
        },
        onExit: (err, metadata) => {
            console.warn("error in plaid config onExit:", err, "metadata:",metadata);
        },
        onEvent: async (eventName, metadata) => {
            console.log("Plaid event:", eventName, metadata);
            if (eventName === 'ERROR') {
                await onError({ metadata });
            }
        },
        token: linkToken
    };
    
    return usePlaidLink(config);
    */
    return { open: () => {}, error: { error: null }, ready: false };
}

export function PlaidBankAccount(props: QuestionProps) {

    const settings = safeParse(props.Metadata || '{}') as v0.PlaidBankAccount;

    const product = !(settings as any).mode || ((settings as any).mode === 'assets') 
        ? 'assets' 
        : (settings as any).mode;

    const { open, error, ready } = usePlaid({ 
        product: product as PlaidProducts,
        targetField: props["Target Field"]!,
        name: props.info.legal_name,
        info: props.info
    }, async (encryptedUserToken) => {
        props.setInfoKey("plaid_user", encryptedUserToken, true, false);
    }, async (res) => {
        props.setInfoKey(props["Target Field"]!, res.itemID + ':' + res.encryptedAccessToken, true, false);
    }, async (error) => {
        props.setInfoKey(props["Target Field"]!, "error", true, false);
        props.setInfoKey(props["Target Field"]! + "_error", JSON.stringify({ error }), true, false);
    });

    const context = useContext(InterfaceContext);
    const markdown = useModularMarkdown({
        content: props[languageContent(context.lang)] || '',
        info: props.info,
    });

    const getTransactions = usePost('/income/plaid/transactions');

    const [transactions, setTransactions] = useState([] as PlaidTransactionStream[]);
    const [startDate, setStartDate] = useState(null as null | Date);
    const [endDate, setEndDate] = useState(null as null | Date);

    const doGetTransactions = async () => {
        let res = await getTransactions({ accessToken: props.info[props["Target Field"]!]! })
        if (!(res as any).error) {
            setStartDate(new Date((res as any).start_date));
            setEndDate(new Date((res as any).end_date));
            setTransactions((res as any).inflowStreams || [])
        }
    }

    

    return <>
        <fieldset>
            <legend>{markdown}</legend>
            <p>
                {error ? error.error : null}
            </p>
        {props.info[props["Target Field"]!] === 'error' && <p>
            Error With your Account: {props.info[props["Target Field"]! + "_error"]}
            </p>}
        {props.info[props["Target Field"]!] ? 
            <>
                <p>Thank you for authenticating.</p>
                {props.Viewer === 'screener' && <>
                <button onClick={() => open()} disabled={!ready}>Reauthenticate</button>
                {!(settings as any).mode || ((settings as any).mode === 'transactions') && <button onClick={async () => {
                    await doGetTransactions();
                }}>Get Income Streams</button>}</>}
            </>
            :
            !ready ? <SpacedSpinner /> : 
                <button onClick={() => open()} disabled={!ready}>
                Link bank account
            </button>
        }
        {transactions && transactions.length > 0 && <>
            <p>Recurring Transaction Inflow Streams</p>
            <table className="table table-responsive max-w-50">
                <thead>
                    <tr>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions.map((t, i) => <tr key={i}>
                        <td className="text-xs">{JSON.stringify(t)}</td>
                    </tr>)}
                </tbody>
            </table>
        </>}
        </fieldset>
    </>
}


export function PlaidIncome(props: QuestionProps) {
    return <>Not Supported</>    
    /** 
    const { open, ready } = usePlaid('income_verification' as PlaidProducts, async (res) => {
        props.setInfoKey(props["Target Field"]!, res.itemID + ':' + res.encryptedAccessToken, true, false);
    });

    return <>
        <fieldset>
            <legend>Link your Payroll Provider</legend>
            <p>
                We need to link your payroll provider to verify your income.
            </p>
        {props.info[props["Target Field"]!] ? 
            <p>Thank you for authenticating.</p>    
            :
            !ready ? <SpacedSpinner /> : 
                <button onClick={() => open()} disabled={!ready}>
                Link payroll provider
            </button>
        }
        </fieldset>
    </> */
}