import { BooleanExpr, ValueExpr } from "./survey";
import { CompileExpressionToJS } from "./translation/expr_to_js";

export function wrappedFn<T>(fnStr: string, safeReturn: T, params?: {
    args?: any[]
  }) {
    let fn;
    let result: boolean | string;
    let error;
    try {
      fn = Function(fnStr)();
    } catch (e) {
      console.error(`wrappedFn parse error: ${fnStr}`);
      return safeReturn;
    }
    
    try {
      result = fn(...params?.args || []);
    } catch (e) {  
      // Console log must come AFTER unmock or we may get weird log timestamps.
      console.log('wrappedFn compute error:', e);
      result = '';
      error = e instanceof Error ? e.message : (e as string);
    }
  
    return { result, error };
}

export function evalDistro(expression: ValueExpr, info: Record<string, string | undefined>) {
    // Evaluate 
    const expr = CompileExpressionToJS(expression);
    const wrappedResult = wrappedFn(
        "return (function(info, org, screener, view_info) { const out = " + expr + "; return out });",
        null,
        { 
            args: [info, {}, {}, {}]
        }
    );


    if (wrappedResult === null || wrappedResult.error) {
        console.error("Error processing distro conditional:",expr,"returning true: ", wrappedResult);
        return true;
    }

    return wrappedResult.result;
}

export function evalDistroConditional(conditional: BooleanExpr, info: Record<string, string | undefined>) {
    // Evaluate 
    const expr = CompileExpressionToJS(conditional);
    const wrappedResult = wrappedFn(
        "return (function(info, org, screener, view_info) { const out = " + expr + "; return out });",
        null,
        { 
            args: [info, {}, {}, {}]
        }
    );

    if (wrappedResult === null || wrappedResult.error) {
        console.error("Error processing distro conditional:",expr,"returning true: ", wrappedResult);
        return true;
    }

    return !!wrappedResult.result;
}
