import { useState } from "react";
import { get_host, usePost } from "../API";
import { type LivenessSession } from "aidkit/lib/application/liveness.schema";
import { Attachment } from "../Questions/Attachment";
import { snakeToEnglish } from "../Util";
import { type TypedQueueResults } from "aidkit/lib/documents/realtimeMediaProcessing";
import { SimpleForm } from "../Components/SimpleForm";
import { Link } from "react-router-dom";

export function LivenessDebug(props: any) {

    const findSessionsByAppId = usePost("/admin/liveness/find_sessions_by_appid");
    const findSessionsByContact = usePost("/admin/liveness/find_sessions_by_email");
    const getQueueResults = usePost("/admin/liveness/get_queue_results_by_id");

    const [searchTerm, setSearchTerm] = useState('');
    const [sessions, setSessions] = useState<null | LivenessSession[] | "no_results" >(null);

    const [queueResults, setQueueResults] = useState<null | Record<string, { [mode: string & { __kind: "face" | "barcode" | "text"}]: TypedQueueResults }>>(null);

    return (
        <div className="p-4 m-2 rounded-md shadow-md bg-gray-50">
            <h1>Liveness Debugging</h1>
            <div className="w-1/2">
                <SimpleForm title={"Enter the email or App ID (UID or Dynamo UID) you want to find liveness sessions for"} description={""}
                    inputs={[{ name: "term", label: "Search Term", value: searchTerm, onChange: (e) => setSearchTerm(e.target.value) }]}
                    submit={{ label: "Find Sessions", onClick: async (e) => {
                        e.preventDefault();
                        if (searchTerm.includes('@')) {
                            const email = searchTerm.trim().toLowerCase();
                            const response = await findSessionsByContact({ email });
                            setSessions(response?.sessions?.length ? response.sessions : "no_results");
                        } else if ([11,22,36].includes(searchTerm.length)) {
                            // 11 is legacy uid, 22 is modern slug uid, 36 is dynamo app id
                            const response = await findSessionsByAppId({ appId: searchTerm });
                            setSessions(response?.sessions?.length ? response.sessions : "no_results");
                        } else {
                            alert("Invalid search term. Please enter an email address or app id.");
                        }
                    }}}
                    />
                {sessions && typeof sessions === 'string' && sessions === 'no_results' && <p className="ml-6">No liveness sessions were found matching that email.</p>}
            </div>
            {sessions && typeof sessions !== 'string' && <ul role="list" className="divide-y divide-gray-200">
                {sessions.map((s) => (
                    <li key={s.sessionData.livenessId} className="px-4 py-4 sm:px-0">
                        <h2 className="text-xl font-bold text-gray-900">
                            Applicant <Link target="_blank" to={`${s.appId.length == 22 ? 'a' : 'ua'}/${s.appId}`}>{s.appId}</Link></h2>
                        <h3 className="text-lg font-medium text-gray-900">{s.sessionData.livenessId} - Expires at: {new Date(s.expiresAt).toISOString()}</h3>
                        {/** For each attempt [front, back, selfie], show the media collected and metadata */}
                        {(['front','back','selfie'] as const).map((stage) => {
                            const attempts = s.sessionData[stage];
                            if (!Array.isArray(attempts)) return null;
                            return <div key={stage}>
                                {attempts && attempts.sort((a, b) => { return a.version - b.version }).map(attempt => (
                                    <div key={attempt.version} className="flex">
                                        <div className="mb-4 sm:mb-0 sm:mr-4 flex-shrink-0">
                                            <Attachment url={attempt.imageUrl} key={""} Viewer="screener" />
                                        </div>
                                        <div>
                                            <h4 className="text-lg font-bold">{snakeToEnglish(stage)} Attempt Version {attempt.version}</h4>
                                            <dd>IP Address: {attempt._ip}</dd>
                                            <dd>Created At: {attempt.createdAt}</dd>
                                            {attempt.queueId && <dd>Queue ID: {attempt.queueId} <button onClick={async () => {
                                                let queueId = attempt.queueId!;
                                                if (stage === 'front') {
                                                    const faceQueueResults = await getQueueResults({ action: "Detect Faces", queueId });
                                                    const textQueueResults = await getQueueResults({ action: "Extract Text", queueId });
                                                    setQueueResults((cur) => ({ ...cur, [queueId]: { 
                                                        face: faceQueueResults?.queueResults || { error: "No results" },
                                                        text: textQueueResults?.queueResults || { error: "No results" },
                                                    } }));
                                                } else if (stage === 'selfie') {
                                                    const faceQueueResults = await getQueueResults({ action: "Detect Faces", queueId });
                                                    setQueueResults((cur) => ({ ...cur, [queueId]: { 
                                                        face: faceQueueResults?.queueResults || { error: "No results" }
                                                    } }));
                                                } else if (stage === 'back') {
                                                    const barcodeQueueResults = await getQueueResults({ action: "Scan Barcode", queueId });
                                                    setQueueResults((cur) => ({ ...cur, [queueId]: { 
                                                        barcode: barcodeQueueResults?.queueResults || { error: "No results"} 
                                                    } }));
                                                }
                                            }}>Populate Queue Results</button></dd>}
                                            {queueResults && attempt.queueId && queueResults[attempt.queueId] && <div>
                                                <h5>Queue Results</h5>
                                                {Object.entries(queueResults[attempt.queueId]).map(([mode, results]) => (
                                                    <div key={mode} className="flex flex-col">
                                                        <h6>{mode}</h6>
                                                        {Object.entries(results).map(([key, value]: [string, any]) => (
                                                            <div key={key} className="flex">
                                                                <span className="font-bold">{key}</span>
                                                                <span>{typeof value === 'object' ? JSON.stringify(value) : value}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>}
                                            Browser Details:
                                            {Object.entries(attempt.browserDetails).map(([key, value]: [string, any]) => (
                                                <div key={key} className="flex">
                                                    <span className="font-bold">{key}</span>
                                                    <span>{typeof value === 'object' ? JSON.stringify(value) : value}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        })}
                    </li>
                ))}
            </ul>}
        </div>
    )
}