import React, { useContext } from "react";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { languageContent, safeParse } from "../Util";
import { QuestionProps } from "./Props";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";

export function ActionButton(props: QuestionProps) {
  const prev = (props.info[props["Target Field"]!] || '').match(/^(01|02|03|04|05|06|07|08|09|10|11|12)\/([0-3][0-9])\/((19|20)[0-9][0-9])$/);
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const content = props[languageContent(context.lang)];
  const metadata = safeParse(props["Metadata"]!);
  const formula = metadata.action;

  let func: any;
  try {
    // eslint-disable-next-line no-new-func
    func = formula && Function(
      "return (function(info, org, screener) { const out = " + formula + "; return out });"
    )();
  } catch (err: any) {
    console.log('Invalid Action formula. Error:', err);
  }

  function doAction() {
    if (metadata.confirmation) {
      const sure = window.confirm(metadata.confirmation[context.lang || 'en'] || 'Are you sure you wish to continue?');
      if (!sure) return;
    }
    try {
      const r: Record<string, any> = func(props.info, props.orgMetadata, props.screenerMetadata) || {};
      for (const key in r) {
          props.setInfoKey(key, (r[key] || '') + '', true, false);
      }
      if (metadata.toast) {
        toast.success(metadata.toast[context.lang || 'en'] || 'Success!');
      }
    } catch (e) {
      console.log("Error computing: ", e);
      alert("Error: " + e);
      Sentry.captureException(e, { 
        extra: {
          formula
        }
      });
    }
  }

  return <div>
    <button
            type="button"
            onClick={doAction}
            className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {content}
    </button>
  </div>
}