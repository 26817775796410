import { useContext, useRef, useState, useEffect } from "react";
import { usePost } from "../API";
import { CandidateChooser } from "../Components/CandidateChooser";
import { ValidatorMessage } from "../Components/ValidatorMessage";
import InterfaceContext, { AuthContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import { languageContent, safeParse, saveKeyToCookieAndRelocateToUrl, useMarkdown } from "../Util";
import { QuestionProps } from "./Props";
import * as v0 from "@aidkitorg/types/lib/survey";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import { useAsyncEffect } from '../Hooks/AsyncEffect';

export function ContactConfirmation(props: QuestionProps) {
    const L = useLocalizedStrings();
    const context = useContext(InterfaceContext);

    //const sendConfirmation = useAPIPost('/confirm_contact');
    const sendConfirmation = usePost("/confirm/phone");
    const checkCode = usePost("/confirm/phone_code");
    
    const token = useRef('')
    const resume = usePost("/subsurvey/resume", {
        token: () => {
          return token.current;
        }
    });

    const getApplicantCandidates = usePost("/applicant/get_candidates", { token: () => token.current });
    const [startNewToken, setStartNewToken] = useState('');
    const [showCandidates, setShowCandidates] = useState([] as Awaited<ReturnType<typeof getApplicantCandidates>>['candidates']);
    const [showCandidateChooser, setShowCandidateChooser] = useState(false);
    const [dialogDescription, setDialogDescription] = useState<undefined | v0.RichText>(undefined);
  
    const formattedContent = useModularMarkdown({
      content: props[languageContent(context.lang)] || '',
      info: props.info,
    });
    const formatted = (props["Additional Options"] || []).indexOf("Formatted") !== -1;
  
    // Authenticated - No resume and only authorized screener/subsurvey can confirm contact.
    const authOnly = (props["Additional Options"] || []).indexOf('Authenticated') !== -1;
  
    // Get challenges from local window if we are NOT dealing with an auth only confirmation.
    const [challenges, setChallengesState] = useState<Record<string, string> | null>(
      (!authOnly) && window.localStorage.getItem('challenges') ?  JSON.parse(window.localStorage['challenges']) : null);
    const [contact, setContact] = useState<string | null>(window.localStorage.getItem('challenge_contact') || null);
  
    const [code, setCode] = useState(''); 
    const [validationError, setValidationError] = useState(''); // used for bettering UX
    const checking = useRef(false);
    const [sendingCode, setSendingCode] = useState(false);
    const metadata = safeParse(props['Metadata'] || '{}') as Omit<v0.ContactConfirmation, 'content'> & { contact_field: string };
    const codeBox = useRef<HTMLInputElement | null>(null);
    const contact_key = metadata.contact_field || 'phone_number';
    const prefix = contact_key.includes('email') ? 'email:' : 'phone:';

    let pleaseEnterCode = L.questions.contact_confirmation.please_enter_code_from_sms;

    if ((props.info[contact_key] || '').indexOf('@') !== -1) {
      pleaseEnterCode = L.questions.contact_confirmation.please_enter_code_from_email;
    }

    const isEmail = (props.info[contact_key] || '').includes('@');
    const pleasePressButtonMd = useMarkdown(isEmail ? 
      metadata.customEmailConsentText 
        ? (metadata.customEmailConsentText[context.lang] || metadata.customEmailConsentText.en)
        : L.questions.contact_confirmation.please_press_button_email
      : metadata.customSMSConsentText 
        ? (metadata.customSMSConsentText[context.lang] || metadata.customSMSConsentText.en)
        : L.questions.contact_confirmation.please_press_button_sms);

    const authContext = useContext(AuthContext);

    const [confirmed, setConfirmed] = useState(false);
  
    const allowRedirect = props.Viewer !== 'screener' 
      && !window.location.pathname.startsWith('/config')
      && !window.location.pathname.startsWith('/p/');

    function setChallenges(challenges: Record<string, string> | null) {
      if (!props.uid) { 
        if (challenges) {
            window.localStorage.setItem('challenges', JSON.stringify(challenges));
            window.localStorage.setItem('challenge_contact', props.info[contact_key]);
        } else {
            window.localStorage.removeItem('challenges');
        }
      }
      setChallengesState(challenges);
      setContact(props.info[contact_key]);
    }
  
    // If the contact is different cancel the challenge
    useEffect(() => {
      if (props.info[contact_key] != contact) {
        setChallenges(null);
        setCode('');
      }
    }, [props.info]);
  
    // Keep confirmed state in sync with the contact state
    useEffect(() => {
      if (props.info[props["Target Field"]!]) {
        setConfirmed(true);
      } else {
        setConfirmed(false);
      }
    }, [props.info[props["Target Field"]!]]);
  
    const doResume = async (params: { 
      ingested: boolean,
      resumptionToken: string,
      confirmationToken: string,
      decision?: "new" | "resume"
    }) => {
      token.current = params.resumptionToken;
      if (params.decision === 'resume' && allowRedirect) {
        // only alert if we are resuming.
        alert(L.questions.contact_confirmation.previous_application_found);
      }
      if (params.ingested && allowRedirect) {
        const redirectsTo = metadata.ingestedResumesToSubsurvey || 'apply';
        const newURL = `${window.location.protocol}//${window.location.host}/p/${redirectsTo}?key=${token.current}`;
        // alert("Forwarding to " + newURL);
        saveKeyToCookieAndRelocateToUrl(newURL);
        return;
      } else {
          const previous = await resume({
              form_name: 'apply'
          });

          if (previous?.newToken) {
            token.current = previous?.newToken;
          }

          if (previous?.info) {
            props.LoadInfo?.(previous.info!, token.current);
            // If this is a first time app, we won't have the confirmation fields. Set those here.
            if (!previous.info[props["Target Field"]!]) {
              props.setInfoKey(props["Target Field"]!, prefix + params.confirmationToken, true, false);
              props.setInfoKey(contact_key + '_confirmed_info_', JSON.stringify({ field: props["Target Field"] }), true, false);
            }
          }
      }
      props.saveAuth?.(token.current);
    }

    const doCheckCode = async (code: string) => {
      setCode(code);
      if (code.length !== 6) {
        setValidationError(L.questions.contact_confirmation.code_is_not_6_digits);
        return;
      }
  
      if (checking.current) return;
      checking.current = true;
  
      try {
        const matched = await checkCode({
          answer: code,
          token: challenges!.phone,
          contact_key,
          uid: props.uid,
        })
        if (matched.error === 'challenge_expired') {
          setChallenges(null);
          setCode('');
          alert(L.questions.contact_confirmation.code_expired);
        } else if (matched.error === 'challenge_incorrect') {
          alert(L.questions.contact_confirmation.code_incorrect);
        } else if (matched.attestation) {
          setChallenges(null);
          setCode('');
  
          token.current = matched.attestation;
          const prefix = contact_key.includes('email') ? 'email:' : 'phone:';
  
          let shouldSetNewInfo = true;
          if ((props["Additional Options"] || []).indexOf("Don't Resume") === -1) {
            const res = await getApplicantCandidates({ 
              info: props.info,
              mode: 'confirmation'
            });
  
            const { candidates, canStartNewToken } = res;
            setDialogDescription(res.dialogDescription);

            const anyCandidateHasSubsurveys = candidates.some((c) => c.subsurveys?.length);

            if (allowRedirect && candidates.length && (candidates.length > 1 || canStartNewToken || anyCandidateHasSubsurveys)) {
              setStartNewToken(canStartNewToken || '');
              setShowCandidates(candidates);
              return;
            } else if (candidates.length === 1 && !canStartNewToken) {
              return await doResume({
                ingested: candidates[0].applicant.kind === 'ingested',
                resumptionToken: candidates[0].resumeToken,
                confirmationToken: matched.attestation,
                decision: res.decision
              });
            }
          }
  
          if (shouldSetNewInfo) {
            props.setInfoKey(props["Target Field"]!, prefix + matched.attestation, true, false);
            // The setTimeout is a hack to make sure info is updated correctly by waiting until the first setInfoKey finishes before setting the second
            // TODO: Fix the underlying issue preventing this from setting correctly
            setTimeout(() => {
              props.setInfoKey(contact_key + '_confirmed_info_', JSON.stringify({ field: props["Target Field"] }), true, false);
            }, 0);
          }

          // Only save this as an auth token if we don't already have an auth token.
          if (!authContext.token?.()) {
            props.saveAuth?.(matched.attestation);
          }
        } else {
          setChallenges(null);
          setCode('');
          props.saveAuth?.(null);
        }
      } finally {
        checking.current = false;
      }
    }
  
    const showConfirmation = (challenges || confirmed);

    // If we have ANY candidates, show the candidate chooser
    useEffect(() => {
      setShowCandidateChooser(showCandidates && showCandidates.length > 0);
    }, [showCandidates]);
  
    if (showCandidateChooser) {
      return <CandidateChooser 
        label={<>{dialogDescription?.[context.lang] || L.apply.we_found_some_existing_apps}
          {startNewToken ? <><br /><span>{L.apply.you_can_also_start_new}</span></> : null}
          </>}
        candidates={[
        ...showCandidates.map((c) => ({
          ...c, 
          buttonLabel: metadata?.customResumeButtonText?.[context.lang],
          onClick: async () => {
            setShowCandidates([] as any[]);
            await doResume({
              ingested: c.applicant.kind === 'ingested',
              resumptionToken: c.resumeToken,
              confirmationToken: token.current,
            });
          }
        })), 
        ...(startNewToken ? [{
          buttonLabel: L.apply.start_new_application,
          onClick: async () => {
            setShowCandidates([] as any[]);
            await doResume({
              ingested: false,
              resumptionToken: startNewToken + ":new",
              confirmationToken: token.current
            });
          }
        }] : [])]} 

        onClose={() => {
          setShowCandidates([] as any[]);
        }}
        />
    }
  
    return <fieldset>
        <legend>
            {formatted ? formattedContent : <b>{props[languageContent(context.lang)]}</b>}
        </legend>
        <div className="mt-2 flex-col">
            <label style={{display: "block"}} 
              aria-hidden={!showConfirmation ? "true" : "false"} htmlFor="contact-code">
              <span>{confirmed
                ? <span>{L.questions.contact_confirmation.contact_has_been_confirmed}</span>
                : (challenges ? <span>{pleaseEnterCode}</span> : <span>{pleasePressButtonMd}</span>)
              }</span>
              {!confirmed &&
                <>
                  <input
                    ref={codeBox}
                    autoComplete="one-time-code"
                    value={confirmed ? L.questions.contact_confirmation.contact_confirmed : code}
                    id="contact-code"
                    aria-hidden={!showConfirmation ? "true" : "false"}
                    disabled={!challenges && !confirmed}
                    onChange={(e) => { 
                      const value = (e.target.value || '').replace(/[^0-9]/g, '');
                      if (value.length === 6) {
                        setValidationError('');
                      }
                      setCode(value);
                    }}
                    className=
                        {(showConfirmation ? "" : "hidden ") + "max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400"}
                  />
                  {challenges &&
                    <>
                      {validationError && <ValidatorMessage message={validationError} />}
                      <button 
                          className="inline-flex items-center mr-2 mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          type="submit" onClick={(e) => { e.preventDefault(); doCheckCode(code); }}>{L.apply.confirm_code}</button>
                      <button 
                          className="inline-flex items-center mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={(e) => { setCode(''); setChallenges(null); }}>{L.apply.cancel}</button>
                    </>}
                </>}
            </label>
          {!challenges && !confirmed &&
            <button
                type="button"
                onClick={async (e) => {
                  if (sendingCode) return;
                  setSendingCode(true);
                  const challenges = await sendConfirmation({
                    phone: props.info[contact_key],
                    language: context.lang
                  })
                  if (challenges.token) {
                    setChallenges({'phone': challenges.token});
                    setSendingCode(false);
                    setTimeout(() => codeBox.current?.focus(), 100);
                  }
                }}
                className="inline-flex items-center -mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
              {metadata?.customSendButtonText?.[context.lang] 
                || metadata?.customSendButtonText?.en 
                || L.questions.contact_confirmation.send_confirmation_code} 
            </button>}
        </div>
    </fieldset>
  }
