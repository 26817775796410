import type { MapRegionDensity } from '@aidkitorg/types/lib/survey';
import type { FeatureCollection, Geometry } from 'geojson';
import { geoMercator, geoPath } from 'd3-geo';
import * as d3Selection from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import { useDebouncedCallback } from '../Hooks/Debounce';
import { useLayoutEffect, useRef } from 'react';
import { DashboardComponentProps } from '../DistroDashboard';
import { SpacedSpinner } from '../Util';
import { useLocalizedStrings } from '../Localization';

type MapRegionDensityProps = DashboardComponentProps['section'] & MapRegionDensity & {
    geoJSON: FeatureCollection<Geometry, {
        label: string;
        count: number;
        kind: string;
        center: [number, number];
    }>
};

type ChartRenderer = {
    render: (container: HTMLElement, geoJSON: MapRegionDensityProps['geoJSON'], noResultsHTML: string) => void;
};

const renderer : ChartRenderer = {    
    render(container, geoJSON, noResultsHTML){
        if(!geoJSON?.features?.length){
            container.innerHTML = noResultsHTML;
            return;
        }

        container.innerHTML = '';
        const svg = d3Selection.select(container).append('svg');
        const colorScale = d3Scale.scaleLinear(
            d3Array.extent(geoJSON.features.map(f => f.properties.count)) as [number, number],
            ['#bfdbfe', '#1e3a8a'] // tailwind blue-200 - blue-900
        ).clamp(true);
        
        const padding = 20;
        const { offsetWidth: chartWidth, offsetHeight: chartHeight } = container;

        const projection = geoMercator().fitSize([chartWidth-padding*2, chartHeight-padding*2], geoJSON);
        projection.translate(
            projection.translate().map(v => v + padding) as [number, number]
        );
        const pathGenerator = geoPath().projection(projection);
        const supportsPopover = 'popover' in container;
        
        svg.attr('width', chartWidth);
        svg.attr('height', chartHeight);
        svg.attr('viewBox', [0, 0, chartWidth, chartHeight]);

        const popover = d3Selection.select(container).append('div')
        .attr('popover', 'manual')
        .attr('class', 'p-2 m-0 z-10 fixed overflow-visible text-gray-600 text-xs rounded-lg border bg-gray-100 border-gray-800 shadow-sm after:visible after:absolute after:h-2 after:w-2 after:rotate-45 after:bg-inherit after:left-2/4 after:top-full after:-ml-1 after:-mt-1 after:content-[""]')
        .style('pointer-events', 'none');
        const popoverContent = popover.append('div').attr('class', 'popover-content');

        if (supportsPopover) {
            // @ts-ignore
            popover.node()!.hidePopover();
        }

        const pathsGroup = svg.append('g').attr('class', 'boundaries');
        pathsGroup.selectAll("path")
            .data(geoJSON.features)
            .join('path')
                .attr('d', pathGenerator)
                .attr('fill', d => colorScale(d.properties.count))
                .attr('stroke-width', 0.5)
                .attr('stroke-linejoin', 'round')
                .attr('stroke', '#333')
                .style('cursor', 'pointer')
                .on('mouseenter', function onMouseEnter(e, datum){
                    const { label, count, center } = datum.properties;
                    popoverContent.html(`
                    <div class="flex flex-col gap-1 items-start">
                        <div>Name: <span class="font-bold">${label}</span></div>
                        <div>Count: <span class="font-bold">${count}<span></div>
                    </div>`);
                    const regionCenter = projection(center);
                    popover.node()!.classList.remove('hidden');
                    popover.node()!.classList.add('visible');    
                
                    if (supportsPopover) {
                        // @ts-ignore
                        popover.node()!.showPopover();
                    } 
                    if (regionCenter && popover.node()) {
                        const [centerX, centerY] = regionCenter;
                        const { top, left } = container.getBoundingClientRect();           
                        popover.style('left', `${left + centerX - popover.node()!.offsetWidth/2}px`);
                        popover.style('top', `${top + centerY - popover.node()!.offsetHeight - 10}px`);
                    }                        
                })
                .on('mouseleave', function onMouseLeave(e, datum){
                    popover.node()!.classList.remove('visible');
                    popover.node()!.classList.add('hidden');
                    if (supportsPopover) {
                        // @ts-ignore
                        popover.node()!.hidePopover();
                    }            
                });
    }
}

export function MapRegionDensity(props: MapRegionDensityProps){
    const { width, height } = props;
    const containerRef = useRef(null);
    const L = useLocalizedStrings();
    const noResultsHTML = `<p>${L.dashboard.no_results}</p>`;
    const onWindowResize = useDebouncedCallback(() => {
        if (containerRef.current) {
            renderer.render(containerRef.current, props.geoJSON, noResultsHTML);
        }
    });
    useLayoutEffect(
        () => {
            if (containerRef.current) {
                renderer.render(containerRef.current, props.geoJSON, noResultsHTML);
            }
            window.addEventListener('resize', onWindowResize);
            return () => {
                window.removeEventListener('resize', onWindowResize);
            }
        },
        [containerRef.current, props.geoJSON]
    );

    return <div ref={containerRef} className="map-container w-full relative grow" style={{minWidth: `${width}px`, minHeight: `${height}px`}}>
        {/* render graph with D3 */}
    </div>
}