import { v1, v4 } from "uuid";
import * as v0 from "../survey"
import { AirtableSurvey, AirtableSurveyChoice, AirtableSurveyQuestion, AirtableTranslations, PopulatedQuestion, Section } from "../legacy/airtable"
import { CompileExpressionToSQL, CompileNudgeExprToSQL, findFields } from "./expr_to_sql";
import { CompileExpressionToJS } from "./expr_to_js";
import { pruneForUserTags } from "./permissions";
import { addGeneratedSubsurveys, expandGenericTemplates, jsonReplace } from "./templates";
import { Add, TRANSLATORS } from "./questions";

function compileConditional(conditional: v0.Code | v0.BooleanExpr | undefined) {
	if (!conditional) return undefined;
	if (typeof conditional === 'string') {
		return conditional;
	} else {
		const toReturn = CompileExpressionToJS(conditional as v0.BooleanExpr);
		return toReturn;
	}
}

// FNV-1a hash
export function hash(str?: string) {
    /*jshint bitwise:false */
    var i, l, hval = 0x811c9dc5;
    for (i = 0, l = (str || '').length; i < l; i++) {
        hval ^= (str || '').charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }
    return (hval >>> 0).toString(16).substring(-8);
}

export function expandDataExchanges(groups: v0.CrossProgramDataExchange[]): AirtableSurveyQuestion[] {
	const RentalSurvey: AirtableSurvey['Rental Survey'] = [];

	const fieldInfoDefsAdditions = groups.flatMap(g => 
		(g.import?.postProcessing?.map(p => p.targetField) ?? [])
		.concat((g.import?.fields.flatMap(findFields) ?? []))
	);

	function addRole(name: string, properties: string[]) {
		return hash(name + JSON.stringify(properties));
	}

	// this is purely to register these target fields in info_defs
	for(const field of fieldInfoDefsAdditions) {
		RentalSurvey.push({
			id: v4(),
			createdTime: '',
			fields: {
				Question: "--",
				"Field Type": "Single Line Text Entry",
				"Who Can Edit": [addRole('Screener', ['Screener'])],
				"Target Field": field,
				"Additional Options": ["Hidden","Optional"],
			}
		} as typeof RentalSurvey[number]);
	}

	return RentalSurvey;
}

export function expandPayments(groups: v0.Payment[]): AirtableSurveyQuestion[] {
	const RentalSurvey: AirtableSurvey['Rental Survey'] = [];

	for (const group of groups) {
    	const thisHash = hash(group.targetField + JSON.stringify(group.condition) + (group.ledger ? group.ledger : ''));
		if (thisHash !== group.enableKey || !group.targetField) {
			// This is super noisy in logs.
			// console.log('discarding payment group', group, thisHash)
			continue;
		}

		RentalSurvey.push({
			id: v4(),
			createdTime: '',
			fields: {
				Question: group.name + ' Payment',
				"Field Type": 'Payment',
				"Target Field": group.targetField,
				"English Content": '--',
				"Spanish Content": '--',
				"Metadata": JSON.stringify({ 
					amount: group.amount,
					type: group.type,
					ledger: group.ledger,
					card_id: group.cardIdField,
					sql: group.condition.kind === 'SQL' ? 
						group.condition.sql : 
						CompileExpressionToSQL({ cond: group.condition.expr, orderBy: group.condition.orderBy }),
					auto_associate: !!group.autoAssociate,
					higher_spending_limit: group.higherSpendingLimit,
					address: (group as any).mailingAddress,
					auto_retry: group.autoRetry
				}),
			}
		})
	}
	return RentalSurvey
}

export function expandNotifications(groups: v0.Notification[]): [AirtableSurveyQuestion[], AirtableTranslations[]] {
	groups = groups || [];
	const Translations: AirtableSurvey['Translations'] = [];
	const RentalSurvey: AirtableSurvey['Rental Survey'] = [];

	function addTranslation(content: v0.Content) {
		let text = Array.isArray(content) ? { 'en': JSON.stringify(content) } : content;
		const id = v4();
		Translations.push({
			id,
			createdTime: '',
			fields: text || { 'en': '--', 'es': '--' }
		})
		return id;
	}

	function addRole(name: string, properties: string[]) {
		return hash(name + JSON.stringify(properties));
	}

	function fieldNotSetMoreThanADayAgo(field: string): v0.BooleanExpr {
		return {
			kind: 'Or',
			clauses: [
				{
					kind: 'And',
					clauses: [{
						field,
						kind: 'Exists'
					}, {
						kind: 'Not',
						clause: {
							field,
							kind: 'Last Modified',
							ago: {
								amount: 1,
								unit: 'days'
							}
						}
					}]
				},
				{
					field,
					kind: 'DoesntExist'
				}
			]
		};
	}

	for (const group of groups) {
    const thisHash = hash(group.targetPrefix + JSON.stringify(group.initial_notification.enabled_when));
		
		if ((group.kind === 'InlineNotification' || thisHash !== group.enableKey)) {
			continue;
		}

		let contentIsArray = {
			'message': Array.isArray(group.initial_notification.message),
			'email_message': Array.isArray(group.initial_notification.email_message)
		}

		let timezone: v0.Timezone | undefined;
		if (v0.atLeastVersion<1>(group)) {
			timezone = group.scheduleSettings?.timezone;
		} else {
			timezone = group.timezone;
		}

		// A note for this function:
		// the pattern ...(foo ? { bar: ... } : {}) is a way to optionally add a parameter to an object at creation.
		// For example, if we have:
		// x = {
		//   y: 'yes',
		//   ...(foo ? { bar: 'baz' } : {})
		// }
		// then if foo is truthy, x looks like: { y: 'yes', bar: 'baz' }
		// else x looks like: { y: 'yes' }

		// For SMS
		if (group.contactMethod !== 'email' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
			id: v4(),
			createdTime: '',
			fields: {
				Question: group.name + ' Initial SMS',
				"Field Type": 'Notification',
				"Target Field": group.targetPrefix + '_sms',
				"Translation": [addTranslation(group.initial_notification.message)],
				"English Content": '--',
				"Spanish Content": '--',
				"Who Can Edit": [addRole('Screener', ['Screener'])],
				"Metadata": JSON.stringify({
					component: group,
					links: (group.initial_notification.subsurveys || []).reduce((o, s) => {
						o[s.variable] = s.name;
						return o;
					}, {} as Record<string, string | v0.TrackedLink>),
					contact_method: group.contactMethod,
					notify_applicant_phone: true,
					notify_applicant_phone_key: (group.recipient as v0.CustomContact)?.phoneField,
					notify_applicant_email_key: (group.recipient as v0.CustomContact)?.emailField,
					service_sid: group.smsService,
					channel: group.channel,
					timezone,
					...(contentIsArray['message'] 
						? { messageBlocks: group.initial_notification.message } 
						: {}),
					
					sql: group.initial_notification.enabled_when.kind === 'SQL' 
						? group.initial_notification.enabled_when.sql 
						: group.recipient === 'Unsubmitted Applicant' 
							? CompileNudgeExprToSQL({
								cond: group.initial_notification.enabled_when.expr,
								nudgeContact: 'phone_number',
								targetField: group.targetPrefix + '_sms',
							}) 
							: CompileExpressionToSQL({
								cond: {
									kind: 'And',
									clauses: [
										{
											field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
											kind: 'Exists'
										},
										fieldNotSetMoreThanADayAgo(group.targetPrefix + '_email'),
										fieldNotSetMoreThanADayAgo(group.targetPrefix + '_whatsapp'),
										group.initial_notification.enabled_when.expr
									]},
								orderBy: group.initial_notification.enabled_when.orderBy
							}),
					js: group.initial_notification.enabled_when.kind === 'Click' ? 
						CompileExpressionToJS(group.initial_notification.enabled_when.expr) :
						'',
					is_nudge: group.recipient === 'Unsubmitted Applicant'
				}),
			}
		})

		// For Email
		if (group.contactMethod !== 'sms' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
			id: v4(),
			createdTime: '',
			fields: {
				Question: group.name + ' Initial Email',
				"Field Type": 'Notification',
				"Target Field": group.targetPrefix + '_email',
				"Translation": [addTranslation((group.initial_notification.email_message || group.initial_notification.message))],
				"English Content": '--',
				"Spanish Content": '--',
				"Who Can Edit": [addRole('Screener', ['Screener'])],
				"Metadata": JSON.stringify({
					component: group,
					links: (group.initial_notification.subsurveys || []).reduce((o, s) => {
						o[s.variable] = s.name;
						return o;
					}, {} as Record<string, string | v0.TrackedLink>),
					contact_method: group.contactMethod,
					notify_applicant_email: true,
					notify_applicant_phone_key: (group.recipient as v0.CustomContact)?.phoneField,
					notify_applicant_email_key: (group.recipient as v0.CustomContact)?.emailField,
					subject: group.initial_notification.email_subject,
					service_sid: group.smsService,
					channel: group.channel,
					timezone,
					...(contentIsArray['email_message'] 
						? { messageBlocks: group.initial_notification.email_message } 
						: contentIsArray['message'] 
							? { messageBlocks: group.initial_notification.message } 
							: {}),
					sql: group.initial_notification.enabled_when.kind === 'SQL' 
						? group.initial_notification.enabled_when.sql 
						: group.recipient === 'Unsubmitted Applicant' 
						? CompileNudgeExprToSQL({
							cond: group.initial_notification.enabled_when.expr,
							nudgeContact: 'email',
							targetField: group.targetPrefix + '_email'
						}) 
						: CompileExpressionToSQL({
							cond: {
								kind: 'And',
								clauses: [
									{
										field: (group.recipient as v0.CustomContact)?.emailField || 'email',
										kind: 'Exists'
									},
									fieldNotSetMoreThanADayAgo(group.targetPrefix + '_sms'),
									fieldNotSetMoreThanADayAgo(group.targetPrefix + '_whatsapp'),
									group.initial_notification.enabled_when.expr
								]},
							orderBy: group.initial_notification.enabled_when.orderBy
						}),
					from: group.emailSender,
					js: group.initial_notification.enabled_when.kind === 'Click' ? 
						CompileExpressionToJS(group.initial_notification.enabled_when.expr) :
						'',
					is_nudge: group.recipient === 'Unsubmitted Applicant'
				}),
			}
		})

		// For WhatsApp
		if (group.contactMethod !== 'email' && group.contactMethod !== 'sms') RentalSurvey.push({
			id: v4(),
			createdTime: '',
			fields: {
				Question: group.name + ' Initial WhatsApp',
				"Field Type": 'Notification',
				"Target Field": group.targetPrefix + '_whatsapp',
				"Translation": [addTranslation(group.initial_notification.message)],
				"English Content": '--',
				"Spanish Content": '--',
				"Who Can Edit": [addRole('Screener', ['Screener'])],
				"Metadata": JSON.stringify({
					component: group,
					links: (group.initial_notification.subsurveys || []).reduce((o, s) => {
						o[s.variable] = s.name;
						return o;
					}, {} as Record<string, string | v0.TrackedLink>),
					contact_method: group.contactMethod,
					notify_applicant_whatsapp: true,
					notify_applicant_phone_key: (group.recipient as v0.CustomContact)?.phoneField,
					notify_applicant_email_key: (group.recipient as v0.CustomContact)?.emailField,
					// TODO: if we ever want a particular WhatsApp service SID for notifications only, 
					// we'll need to update this.
					service_sid: undefined,
					timezone,
					...(contentIsArray['message'] 
						? { messageBlocks: group.initial_notification.message } 
						: {}),
					sql: group.initial_notification.enabled_when.kind === 'SQL' 
						? group.initial_notification.enabled_when.sql 
						: group.recipient === 'Unsubmitted Applicant' 
							? CompileNudgeExprToSQL({
								cond: group.initial_notification.enabled_when.expr,
								nudgeContact: 'phone_number',
								targetField: group.targetPrefix + '_whatsapp',
							})
							: CompileExpressionToSQL({
								cond: {
									kind: 'And',
									clauses: [
										{
											field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
											kind: 'Exists'
										},
										fieldNotSetMoreThanADayAgo(group.targetPrefix + '_email'),
										fieldNotSetMoreThanADayAgo(group.targetPrefix + '_sms'),
										group.initial_notification.enabled_when.expr
									]}
								}),
					js: group.initial_notification.enabled_when.kind === 'Click' ? 
						CompileExpressionToJS(group.initial_notification.enabled_when.expr) :
						'',
					is_nudge: group.recipient === 'Unsubmitted Applicant'
				}),
			}
		})

		for (const followup of group.followups || []) {
			const thisHash = hash(group.targetPrefix + 
				followup.suffix + 
				JSON.stringify(followup.send_if));
			if (thisHash !== followup.enableKey) {
				continue;
			}

			let recipient = (followup.recipient || group.recipient) as v0.CustomContact;

			let followupIsArray = {
				'message': Array.isArray(followup.message),
				'email_message': Array.isArray(followup.email_message)
			}

			// For SMS
			if (group.contactMethod !== 'email' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
				id: v4(),
				createdTime: '',
				fields: {
					Question: group.name + ' Followup SMS ' + followup.suffix,
					"Field Type": 'Notification',
					"Target Field": group.targetPrefix + '_' + followup.suffix + '_sms',
					"Translation": [addTranslation(followup.message as v0.Text)],
					"English Content": '--',
					"Spanish Content": '--',
					"Who Can Edit": [addRole('Screener', ['Screener'])],
					"Metadata": JSON.stringify({
						component: group,
						links: (followup.subsurveys || []).reduce((o, s) => {
							o[s.variable] = s.name;
							return o;
						}, {} as Record<string, string | v0.TrackedLink>),
						contact_method: group.contactMethod,
						notify_applicant_phone: true,
						notify_applicant_phone_key: recipient?.phoneField,
						notify_applicant_email_key: recipient?.emailField,
						service_sid: group.smsService,
						channel: group.channel,
						timezone,
						...(followupIsArray['message'] ? { messageBlocks: followup.message } : {}),
						sql: followup.send_if.kind === 'SQL' 
							? followup.send_if.sql 
							: (group.recipient === 'Unsubmitted Applicant' 
								? CompileNudgeExprToSQL({
									cond: followup.send_if.expr,
									nudgeContact: 'phone_number',
									followupAfter: followup.after,
									followupTargetField: group.targetPrefix + '_' + followup.suffix + '_sms',
									targetField: group.targetPrefix + '_sms',
								}) 
								: CompileExpressionToSQL({
									cond: {
										kind: 'And',
										clauses: [
											{
												field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
												kind: 'Exists'
											},
											{
												field: group.targetPrefix + '_sms',
												kind: 'Exists'
											},
											{
												field: group.targetPrefix + '_sms',
												kind: 'Last Modified',
												ago: followup.after
											},
											fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_email'),
											followup.send_if.expr
										]},
									orderBy: followup.send_if.orderBy
								})),
						is_nudge: group.recipient === 'Unsubmitted Applicant'
					}),
				}
			})

			// For Email
			if (group.contactMethod !== 'sms' && group.contactMethod !== 'whatsapp') RentalSurvey.push({
				id: v4(),
				createdTime: '',
				fields: {
					Question: group.name + ' Followup Email ' + followup.suffix,
					"Field Type": 'Notification',
					"Target Field": group.targetPrefix + '_' + followup.suffix + '_email',
					"Translation": [addTranslation((followup.email_message || followup.message) as v0.Content)],
					"English Content": '--',
					"Spanish Content": '--',
					"Who Can Edit": [addRole('Screener', ['Screener'])],
					"Metadata": JSON.stringify({
						component: group,
						links: (followup.subsurveys || []).reduce((o, s) => {
							o[s.variable] = s.name;
							return o;
						}, {} as Record<string, string | v0.TrackedLink>),
						contact_method: group.contactMethod,
						notify_applicant_email: true,
						notify_applicant_phone_key: recipient?.phoneField,
						notify_applicant_email_key: recipient?.emailField,
						subject: followup.email_subject,
						service_sid: group.smsService,
						channel: group.channel,
						timezone,
						...(followupIsArray['email_message'] 
							? { messageBlocks: followup.email_message } 
							: followupIsArray['message'] ? { messageBlocks: followup.message } : {}),
						sql: followup.send_if.kind === 'SQL' 
							? followup.send_if.sql 
							: group.recipient === 'Unsubmitted Applicant' 
							? CompileNudgeExprToSQL({
								cond: followup.send_if.expr,
								nudgeContact: 'email',
								followupAfter: followup.after,
								followupTargetField: group.targetPrefix + '_' + followup.suffix + '_email',
								targetField: group.targetPrefix + '_email',
							}) 
							: CompileExpressionToSQL({
								cond: {
									kind: 'And',
									clauses: [
										{
											field: (group.recipient as v0.CustomContact)?.emailField || 'email',
											kind: 'Exists'
										},
										{
											field: group.targetPrefix + '_email',
											kind: 'Exists'
										},
										{
											field: group.targetPrefix + '_email',
											kind: 'Last Modified',
											ago: followup.after
										},
										fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_sms'),
										followup.send_if.expr
									]
								},
								orderBy: followup.send_if.orderBy
							}),
						from: group.emailSender,
						is_nudge: group.recipient === 'Unsubmitted Applicant'
					}),
				}
			})

			// For WhatsApp
			if (group.contactMethod !== 'email' && group.contactMethod !== 'sms') RentalSurvey.push({
				id: v4(),
				createdTime: '',
				fields: {
					Question: group.name + ' Followup WhatsApp ' + followup.suffix,
					"Field Type": 'Notification',
					"Target Field": group.targetPrefix + '_' + followup.suffix + '_whatsapp',
					"Translation": [addTranslation(followup.message as v0.Text)],
					"English Content": '--',
					"Spanish Content": '--',
					"Who Can Edit": [addRole('Screener', ['Screener'])],
					"Metadata": JSON.stringify({
						component: group,
						links: (followup.subsurveys || []).reduce((o, s) => {
							o[s.variable] = s.name;
							return o;
						}, {} as Record<string, string | v0.TrackedLink>),
						contact_method: group.contactMethod,
						notify_applicant_whatsapp: true,
						notify_applicant_phone_key: recipient?.phoneField,
						notify_applicant_email_key: recipient?.emailField,
						service_sid: undefined,
						timezone,
						...(followupIsArray['message'] ? { messageBlocks: followup.message } : {}),
						sql: followup.send_if.kind === 'SQL' 
							? followup.send_if.sql 
							: group.recipient === 'Unsubmitted Applicant' 
								? CompileNudgeExprToSQL({
									cond: followup.send_if.expr,
									nudgeContact: 'phone_number',
									followupAfter: followup.after,
									followupTargetField: group.targetPrefix + '_' + followup.suffix + '_whatsapp',
									targetField: group.targetPrefix + '_whatsapp',
								}) 
								: CompileExpressionToSQL({
									cond: {
										kind: 'And',
										clauses: [
											{
												field: (group.recipient as v0.CustomContact)?.phoneField || 'phone_number',
												kind: 'Exists'
											},
											{
												field: group.targetPrefix + '_whatsapp',
												kind: 'Exists'
											},
											{
												field: group.targetPrefix + '_whatsapp',
												kind: 'Last Modified',
												ago: followup.after
											},
											fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_email'),
											fieldNotSetMoreThanADayAgo(group.targetPrefix + '_' + followup.suffix + '_sms'),
											followup.send_if.expr
										]
									}
							})
					}),
				}
			})
		}
	}

	return [RentalSurvey, Translations]
}

export function deepCopy(obj: any): any {
	if (Array.isArray(obj)) {
		return obj.map((o: any) => deepCopy(o));
	} else if (typeof obj === 'object' && obj !== null) {
		return Object.keys(obj).reduce((o: any, k: string) => {
			o[k] = deepCopy(obj[k]);
			return o;
		}, {});
	} 	
	return obj;
}

export function expandTemplates (root: v0.Root): v0.Root {
	if (!root) return [];
	const expanded = expandGenericTemplates(root);
	return addGeneratedSubsurveys(expanded);
}

export function extractNotificationsAndPayments (root: v0.Survey, scope: v0.BooleanExpr[], options?: {
	email?: string,
	smsService?: string,
}): [v0.Notification[], v0.Payment[]] {
	let notifs: v0.Notification[] = [];
	let payments: v0.Payment[] = [];
	for (const section of root) {
		if (section.kind === 'Collection') {
			const [n, p] = extractNotificationsAndPayments(section.components, [...scope, section.options.limitedToApplicants].filter((a) => a) as v0.BooleanExpr[])
			notifs = notifs.concat(n);
			payments = payments.concat(p);
		}
		if (section.kind === 'Section') {
			section.components.forEach((component) => {
				if (component.kind === 'InlineNotification') {
					notifs.push(component as v0.InlineNotification);
				}
			});
		}
		// TODO: this can be simplified to only use kind once we can rely on it.
		if (section.kind === 'Notification'
			|| ((section as any).targetPrefix !== undefined && (section as any).contactMethod !== undefined)) {
			notifs.push(section as v0.NotificationGroup);
		}
		else if ((section as any).amount !== undefined) {
			payments.push(section as v0.Payment);
		}
		else if (section.kind === 'GiveCard Mailing') {
			payments.push({
				name: `${section.kind} (${section.targetField})`,
				type: 'givecard_mail',
				...section,
				kind: 'Payment',
				amount: '0',
			});
		}
	}
	return [notifs, payments];
}

export function condenseConfig(config?: v0.ProgramConfig): AirtableSurvey['Configuration'] {
	if (!config) return [];
	let result: AirtableSurvey['Configuration'] = [];
	
	// Map each config key to a result row

	let add = (key: string, value: string) => {
		result.push({
			id: v4(),
			createdTime: '',
			fields: {
				Key: key,
				Value: value
			}
		});
	}
	
	// Comms:
	add('experimental_comms', config.comms?.experimental ? 'true' : '');

	add('comms', JSON.stringify(config.comms || {}));
	if (config.comms?.alternateContacts) add('alternate_contact', JSON.stringify(config.comms.alternateContacts));

	// Application
	add('application', JSON.stringify(config.application || {}));

	// Fraud
	add('fraud', JSON.stringify(config.fraud || {}));

	// Experimental Feature Flags
	add('enable_clean_uploads', config.experimental?.enableCleanUploads ? 'true' : '');
	add('enable_roboscreener_validate_clean_uploads', config.experimental?.enableRoboscreenerValidateCleanUploads ? 'true' : '');
	add('lateral_unnesting_in_dashboards', config.experimental?.lateralUnnestingInDashboards ? 'true' : '');
	add('experimental_enableQuickJS', config.experimental?.enableQuickJS ? 'true' : '');
	add('enable_custom_query_filtering', config.experimental?.enableCustomQueryFiltering ? 'true' : '');

	// Capabilities
	add('import_key_id', config.capabilities?.importKeyId || '');
	add('encrypted_report_configs', JSON.stringify(config.capabilities?.encryptedReportConfigs || []));

	for (let row of config.legacyAirtable?.configuration || []) {
		if (row.key === 'alternate_contact' && config.comms?.alternateContacts) {
			continue; // skip, this was already added.
		}
		if (row.key) add(row.key, row.value);
	}

	return result;
}

export function v0ToLegacy (root: v0.Root, options?: {
	url?: string,
	existingConfig?: Record<string, string>,
	roles?: string[],
}): AirtableSurvey {
	root = expandTemplates(deepCopy(root));
	
	if (options?.roles) {
		if (Array.isArray(root)) {
			root = pruneForUserTags(root, options?.roles || []);
		} else {
			root.survey = pruneForUserTags(root.survey, options?.roles || []);
		}
	}

	const v0: v0.Survey = Array.isArray(root) ? root : root.survey;

	let RentalSurvey: AirtableSurvey['Rental Survey'] = [];
	const Choices: AirtableSurvey['Choices'] = [];
	const Configuration: AirtableSurvey['Configuration'] = [];
	let Translations: AirtableSurvey['Translations'] = [];
	const Roles: AirtableSurvey['Roles'] = [];

	let RentalSurveyToAppend: AirtableSurvey['Rental Survey'] = [];

	const slugsAtTheBottom: Record<string, boolean> = {};

	if (!Array.isArray(root)) {
		const [n, p] = extractNotificationsAndPayments(root.survey, []);

		const notifs = expandNotifications([...root.notifications || [], ...n]);
		RentalSurveyToAppend = notifs[0];
		Translations = notifs[1];

		const payments = expandPayments([...root.payments || [], ...p]);
		RentalSurveyToAppend = RentalSurveyToAppend.concat(payments);

		const dataExchanges = expandDataExchanges([...root.crossProgramDataExchange || []]);
		RentalSurveyToAppend = RentalSurveyToAppend.concat(dataExchanges);

		if (options?.url?.includes('-entireprogram')) {
			const config = condenseConfig(root.config);
			const existingConfig = options.existingConfig || {};

			// Overwrite any existing config with distro config
			for (const row of config) {
				if (row.fields.Key) {
					existingConfig[row.fields.Key] = row.fields.Value || '';
				}
			}
			Object.assign(options.existingConfig || {}, existingConfig);
		}
	}

	function addTranslation(content: v0.Content) {
		let text = Array.isArray(content) ? { en: JSON.stringify(content) } : content;
		const id = hash(JSON.stringify(text));
		Translations.push({
			id,
			createdTime: '',
			fields: text || { 'en': '--', 'es': '--' }
		})
		return id;
	}

	function addChoice(choice: v0.Select['choices'][number]) {
		const id = hash(JSON.stringify(choice));
		Choices.push({
			id,
			createdTime: '',
			fields: {
				Name: choice.value,
				Translation: [addTranslation(choice.label)],
				"English Content": '--',
				"Spanish Content": '--',
				'Exclusive Option': choice.exclusive,
				'Select All': choice.selectAll,
				'Other Field': choice.otherField
			}
		})
		return id;
	}

	function addRole(name: string, properties: string[]) {
		const id = hash(name + JSON.stringify(properties));
		Roles.push({
			id,
			createdTime: '',
			fields: {
				Name: name,
				'Additional Properties': properties
			}
		})
		return id;
	}

	function combineConditionals(component: any, condition?: string): string | undefined {
		if ((component as any).conditionalOn && condition) {
			return '(' + condition + ')' + ' && ' + compileConditional((component as any).conditionalOn);
		}
		if ((component as any).conditionalOn) {
			return compileConditional((component as any).conditionalOn);
		}
		if (condition) {
			return condition;
		}
	}

	function addConfiguration(params: Parameters<Add['Configuration']>[0]) {
		if (!options || !options.existingConfig) return;
		let { kind, key, update, value } = params;
		if (kind === 'update' && update) {
			options.existingConfig[key] = update(options.existingConfig[key]);
		} else if (kind === 'set') {
			options.existingConfig[key] = value || '';
		}
	}

	let pushSection = (section: v0.CollectionComponent | v0.ConditionalBlock, depth: number, condition?: string): string => {
		const id = v4();
		if (section.kind === 'Section') {
			RentalSurvey.push({
				id,
				createdTime: '',
				fields: {
					Question: section.title.en,
					"Field Type": 'Section',
					"Translation": [addTranslation(section.title)],
					"English Content": '--',
					"Spanish Content": '--',
					"Metadata": JSON.stringify({ 
						conditional: combineConditionals(section, condition),
						screenerMode: section.screenerMode || 'All At Once',
						depth,
						hideNextButton: section.hideNextButton, 
						hidden: !!section.hidden,
					}),
				}
			})
		}

		type HasComponents<T> = T extends { components: any } ? T : never;
		type TypeHasProp<T, Prop extends string> = T extends { [key in Prop]?: any } ? T : never;
		for (const component of ((section as HasComponents<v0.CollectionComponent | v0.ConditionalBlock>).components || [section])) {
			let Metadata = {} as any;
			Metadata.conditional = combineConditionals(component, condition);
			if (!component.kind) {
				// For Payment and some legacy types, kind is optional. just go next.
				continue;
			}
			if (component.kind === 'Conditional Block') {
				pushSection(component, depth, Metadata.conditional);

				if (component.otherwise) {
					pushSection({
						kind: 'Conditional Block',
						components: component.otherwise!,
						// Note: this is technically incorrect but conditionalOn is not used when
						// called recursively like this
						conditionalOn: '' as unknown as v0.Code,
					}, depth, '(' + (condition === undefined ? 'true' : condition) + ' && !(' + Metadata.conditional + '))')

				}
			} else {
				const kind = component.kind as keyof typeof TRANSLATORS;
				const Translator = TRANSLATORS[kind];

				if (!Translator || !Translator.addFunc) { 
					continue;
				}
				Translator.addFunc(component as any, {
					Question: (q) => {
						if (component.kind === 'Section') {
							// Calculate depth based on any sections above this one
							return pushSection(component, depth + 1, Metadata.conditional);
						}
            
						let metadata = JSON.parse(q.fields.Metadata || '{}') as {
							textToSpeech?: v0.CommonProperties['textToSpeech'],
							[key: string]: any;
						};

						if ((component as any).textToSpeech) {
							metadata.textToSpeech = (component as TypeHasProp<v0.Block, 'textToSpeech'>).textToSpeech!
						} 

						if ((component as any).customAudio) {
							metadata.customAudio = (component as TypeHasProp<v0.Block, 'customAudio'>).customAudio!
						}
		
						// TODO: Stack this with any section conditionals etc
						if (Metadata.conditional) {
							metadata.conditional = Metadata.conditional;
						}
						q.fields.Metadata = JSON.stringify(metadata);
						//console.log(metadata) - this is extremely noisy in RS
						if ('optional' in component && component.optional) {
							q.fields['Additional Options'] = [...(q.fields['Additional Options'] || []), 'Optional'];
						}
						if ('hidden' in component && component.hidden) {
							q.fields['Additional Options'] = [...(q.fields['Additional Options'] || []), 'Hidden'];
						}

						q['id'] = '';
						q.fields['id'] = '';
						if (q.fields['Field Type'] !== 'Subsurvey') q.fields['Question'] = '';
						const newId = hash(JSON.stringify(q));
						q['id'] = newId;
						q.fields['id'] = newId;
						if (q.fields['Field Type'] !== 'Subsurvey') q.fields['Question'] = newId;

						if (component.kind === 'Subsurvey') {

						}

						q.fields['Kind'] = component.kind;

						RentalSurvey.push(q)

						// Get any slugs and mark those to be added
						// Do this in a safe way so it doesn't crash the whole thing
						try {
							let slugs = TRANSLATORS[kind].getSlugs(component as any)?.slugs;
							for (const slug of slugs) {
								slugsAtTheBottom[slug.startsWith('_') ? (component as any).targetField + slug : slug] = true;
							}
						} catch (e) { console.warn("Error adding slugs to question:", q, e) }
					},
					Translation: addTranslation,
					Choice: addChoice,
					Role: addRole,
					Section: pushSection,
					Configuration: addConfiguration,
					Depth: depth
				})
			}
		}
		return id;
	}

	for (const section of v0) {
		pushSection(section, 0);
	}

	const slugSurvey: AirtableSurvey['Rental Survey'] = [];
	for (const key in slugsAtTheBottom) {
		slugSurvey.push({
			id: v4(),
			createdTime: '',
			fields: {
				Question: key,
				"Field Type": "Single Line Text Entry",
				"Additional Options": ["Hidden","Optional"],
				"Translation": [addTranslation({ en: key })],
				"English Content": '--',
				"Spanish Content": '--',
				"Target Field": key,
				"Who Can Edit": [addRole('Screener', ['Screener'])]
			}
		})
	}

	return {
		'Rental Survey': [...RentalSurvey, ...RentalSurveyToAppend, ...slugSurvey],
		Roles,
		Choices,
		Configuration,
		Translations
	}
}

export class Survey {
	airtableSurvey: AirtableSurvey;
	choicesById: Record<string, AirtableSurveyChoice['fields']> = {};
	questionsById: Record<string, AirtableSurveyQuestion['fields']> = {};
	translationsById: Record<string, AirtableTranslations['fields']> = {};

	private constructor() {
		this.airtableSurvey = {} as AirtableSurvey;
	}
	public static load(airtable: AirtableSurvey, params?: {
		includeAudit?: boolean
	}) {
		const survey = new Survey();

		// Do some computations that are commonly required for most other functions
		survey.airtableSurvey = airtable;

		let afterImport = [] as typeof survey.airtableSurvey['Rental Survey']
		for (const question of survey.airtableSurvey['Rental Survey']) {
			if (question.fields['Field Type'] === 'Import') {
				/*
				const metadata = question.fields['Metadata'] || '{}';
				const unpackedMetadata = JSON.parse(metadata);
				if (unpackedMetadata.url && !unpackedMetadata.url.includes('-audit')) {
						const toMerge = v0ToLegacy(JSON.parse(await getSurveyDefinition(unpackedMetadata.url)));
						for (const question of toMerge['Rental Survey']) {
								afterImport.push(question);
						}
						survey.airtableSurvey['Choices'] = [...survey.airtableSurvey['Choices'], 
								...toMerge['Choices']];
						survey.airtableSurvey['Translations'] = [...survey.airtableSurvey['Translations'], 
								...toMerge['Translations']];
				}
				*/
			} else {
				afterImport.push(question);
			}
		}
		survey.airtableSurvey['Rental Survey'] = afterImport;

		const expanded: typeof survey.airtableSurvey['Rental Survey'] = [];
		for (const question of survey.airtableSurvey['Rental Survey']) {
			expanded.push(question);
			if (question.fields['Field Type'] === 'Contract') {
				if ((question.fields['Additional Options'] || []).includes('No Signatures')) {
					continue;
				}

				const metadata = JSON.parse(question.fields.Metadata || '{}') as {
					'signers': Record<string, string>
				};

				const signers = Object.keys(metadata['signers'] || {});
				for (const signer of signers) {
					expanded.push({
						id: v4(),
						createdTime: '',
						fields: {
							'Question': question.fields.Question + ' - ' + signer,
							'Field Type': 'Contract Signer',
							'English Content': signer,
							'Target Field': question.fields['Target Field'] + '_' + signer,
							'Additional Options': question.fields['Additional Options'],
							'Conditional On': question.fields['Conditional On'],
							'Conditional On Value': question.fields['Conditional On Value'],
						}
					});
				}

				expanded.push({
					id: v4(),
					createdTime: '',
					fields: {
						'Question': question.fields.Question + ' - Stale',
						'Field Type': 'Contract Stale',
						'Target Field': question.fields['Target Field'] + '_stale',
						'Additional Options': question.fields['Additional Options'],
						'Conditional On': question.fields['Conditional On'],
						'Conditional On Value': question.fields['Conditional On Value'],
					}
				});
			}
		}
		survey.airtableSurvey['Rental Survey'] = expanded;
		survey.airtableSurvey['Choices'].forEach((c) => survey.choicesById[c.id] = c.fields)
		survey.airtableSurvey['Rental Survey'].forEach((c) => survey.questionsById[c.id] = c.fields);
		(survey.airtableSurvey['Translations'] || []).forEach((c) => survey.translationsById[c.id] = c.fields)
		return survey;
	}
	getSurvey(): { 
		sections: Section[],
		viewableFields: string[]
	} {
		// Utility for populating translation
		const getExtraTranslations = <T extends { Translation?: string[] }>(item: T, text?: boolean) => {
			let extraTranslations = {} as AirtableTranslations['fields'];
			if (item['Translation']) {
				extraTranslations = item['Translation'].map(id => this.translationsById[id])[0];
				if (extraTranslations.en) {
					extraTranslations[text ? 'English Text' : 'English Content'] = extraTranslations.en;
				}
				if (extraTranslations.es) {
					extraTranslations[text ? 'Spanish Text' : 'Spanish Content'] = extraTranslations.es;
				}
			}
			return extraTranslations;
		}

		const populatedQuestions: PopulatedQuestion[] = this.airtableSurvey['Rental Survey'].filter((q: any) => {
			return (q.fields.Question || q.fields.modern) && q.fields['Field Type'] !== 'Stage'
		}).map((q: any) => {
			let id = q.id;
			q = q.fields;
			const extraTranslations = getExtraTranslations(q);
			return {
				...q,
				'Options (if relevant)': (q['Options (if relevant)'] || []).map((id: any) => {
					const choice = this.choicesById[id];
					const extraTranslations = getExtraTranslations(choice, true);
					return {
						...choice,
						...extraTranslations
					};
				}),
				'Conditional On Value': q['Conditional On Value'] ? q['Conditional On Value'].map((v: any) => this.choicesById[v].Name) : undefined,
				...extraTranslations,
				id
			};
		}) as unknown as PopulatedQuestion[];

		// Group questions in a section into actual sections rather than 
		// just using them as markers.
		return populatedQuestions.reduce<{ 
			sections: Section[],
			viewableFields: string[]
		}>(
			(soFar, q) => {
				if (q['Field Type'] === 'Section') {
					soFar.sections.push({...q,
						Questions: []
					})
				} else {
					if (soFar.sections.length > 0) {
						soFar.sections[soFar.sections.length - 1].Questions.push(q)
					}
				}
				return soFar;
			},
			{ sections: [], viewableFields: [] }
		);
	}
}

export async function handleImports(survey: AirtableSurvey, programId: string, getSurveyDefinition: (url: string) => Promise<string>, userTags?: string[]) {
	// For some configuration things, we need the existing configuration to Add to it
	let airtableConfig = survey['Configuration'];

	// Map the key of each existingConfiguration row to a new dict so we can easily add to the right spot
	let existingConfig = airtableConfig.reduce((acc, cur) => {
		if (!cur.fields.Key) return acc;
		acc[cur.fields.Key] = cur.fields.Value || '';
		return acc;
	}, {} as Record<string,string>);

	// Copy all questions to a new array, replacing each import with a promise so we can run async
	let imported: string[] = [];
	const airtableQuestions: (AirtableSurveyQuestion | Promise<AirtableSurveyQuestion[]>)[] = survey["Rental Survey"].map((question: AirtableSurveyQuestion) => {
		if (question.fields["Field Type"] === "Import") {
			return new Promise(async (resolve, reject) => {
				const afterImport: AirtableSurveyQuestion[] = [];
				const metadata = question.fields["Metadata"] || "{}";
				const unpackedMetadata = JSON.parse(metadata);
				const kind = unpackedMetadata.kind || "inline";

				if ((userTags || []).includes('no_airtable') && !unpackedMetadata.url.includes("entireprogram")) {
					resolve(afterImport);
					return;
				}

				if (unpackedMetadata.url && !unpackedMetadata.url.includes("-audit")) {
					// When we use the generic Airtable base we need to replace [handle] with the program name
					unpackedMetadata.url = unpackedMetadata.url.replace('[handle]', programId);//programName);

					let toMerge: AirtableSurvey;
					try {
						const t0 = Date.now();
						let v0 = JSON.parse(await getSurveyDefinition(unpackedMetadata.url))
						const t1 = Date.now();
						console.log("[Handle Imports] Loading", unpackedMetadata.url, "took", t1 - t0, "ms");
						if (userTags) {
							const expanded = expandTemplates(Array.isArray(v0) ? v0 : v0.survey);
							if (Array.isArray(v0)) {
								v0 = expanded;
								v0 = pruneForUserTags(v0, userTags || []);
							} else {
								v0.survey = expanded;
								v0.survey = pruneForUserTags(v0.survey, userTags || []);
							}
						}
						const t2 = Date.now();
						console.log("[Handle Imports] Pruning and Expanding", unpackedMetadata.url, "took", t2 - t1, "ms");

						toMerge = v0ToLegacy(v0, {
							url: unpackedMetadata.url,
							existingConfig
						});
						const t3 = Date.now();
						console.log("[Handle Imports] v0ToLegacy", unpackedMetadata.url, "took", t3 - t2, "ms");
						
						for (const question of toMerge["Rental Survey"]) {
							afterImport.push(question);
						}
						survey["Choices"] = [...survey["Choices"], ...toMerge["Choices"]];
						survey["Roles"] = [...survey["Roles"], ...toMerge["Roles"]];
						survey["Translations"] = [
							...survey["Translations"],
							...toMerge["Translations"],
						];

						imported.push(unpackedMetadata.url);

						if (kind === "subsurvey") {
							const path = unpackedMetadata.path || "";
							afterImport.push({
								id: v4(),
								createdTime: "",
								fields: {
									Question: "Subsurvey Config - " + path,
									"Field Type": "Section",
									"English Content": "Subsurvey Config - " + path,
									"Spanish Content": "Subsurvey Config - " + path,
								},
							});
							// Keep the metadata that was specified for this Distro Import, and add autofill_sections to it.
							// TODO: Do we need to do this for kind === 'inline' as well?
							unpackedMetadata["autofill_sections"] = true;
							afterImport.push({
								id: v4(),
								createdTime: "",
								fields: {
									Question: path,
									"Field Type": "Subsurvey",
									"English Content": "--",
									"Spanish Content": "--",
									Subquestions: toMerge["Rental Survey"]
										.filter((q) => q.fields["Field Type"] === "Section")
										.map((q) => q.id),
									Metadata: JSON.stringify(unpackedMetadata),
								},
							});
						}
					} catch (e) {
						console.log("Import Failed", unpackedMetadata.url, e);
						reject(e);
					}
				}
				resolve(afterImport);
			});
		} else {
			return question;
		}
	});

	await Promise.all(airtableQuestions)
		.then((result) => {
			console.log("Imported: ", imported.join(', '));
			// Result contains AirtableSurveyQuestions and arrays of AirTableSurveyQuestions
			survey["Rental Survey"] = result.flat();
			survey.Configuration = Object.keys(existingConfig).map((key) => {
				return {
					id: v4(),
					createdTime: "",
					fields: {
						Key: key,
						Value: existingConfig[key],
					},
				};
			})
		})
		.catch((e) => {
			console.log('Promise.all failed', e);
		});
}

export const AirtableSurveyToSurveyDefinition = (airtableSurvey: AirtableSurvey) => {
	return Survey.load(airtableSurvey).getSurvey();
}
