import * as v0 from "@aidkitorg/types/lib/survey";
import { safeParse } from "../Util";

export function SimilarDocumentCheck(props: {
    component: v0.SimilarDocumentCheck,
    info: Record<string, string | undefined>,
    uid: string,
}) {
    const { component, info } = props;

    if (component.showStatus) {
        // An applicant has been processed (checked for doc similarity) if the following dependency hashes have been generated:
        // - The depshash for SimilarDocumentCheck config (indicating we've ran a similarity check at least once before)
        // - A vectorized hash for each doc field that is non-null exists (indicating the text exists in the vectordb)
        const hashes = [
            `${component.targetField}_depshash`,
            ...component.documentTextFields
                .filter((field) => info[field]?.length ?? 0 > 0)
                .map((field) => `${field}_vectorized_hash`)
        ];

        const processed = hashes.every((fieldName) => info[fieldName]);

        // If the applicant doesn't have any values in the configured documentTextFields, 
        // we won't process them because they don't have any related texts to analyze.
        const notApplicable = component.documentTextFields.every((field) => info[field] === undefined || info[field]?.length === 0 );

        const similarDocCheck = info[component.targetField]
        const similarDocCheckResults = similarDocCheck !== undefined && similarDocCheck.length > 0 && safeParse(similarDocCheck)
        const similarDocCheckResultCount = (similarDocCheckResults && similarDocCheckResults['related'] && similarDocCheckResults['related'].length) || 0;
        const similarDocCheckResultsLastChecked = info[component.targetField + "_timestamp"] ? new Date(info[component.targetField + "_timestamp"] as string).toLocaleString('default', { timeZoneName: 'short' }) : null;

        const status = processed
            ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Processed</span>
            : <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">Queued for Processing</span>;

        const checkStatus = similarDocCheckResultCount === 0
            ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">No Duplicates Found</span>
            : <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Duplicates Found</span>;

        if (notApplicable) {
            return (
                <section>
                    <h4>Similar Document Check</h4>
                    <ul>
                        <li>Check ID: <pre className="inline">{component.targetField}</pre></li>
                        <li>System Status: <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">Not Applicable</span></li>
                    </ul>
                    <p>
                        This applicant doesn't have any data for the documents configured in this check. We will process them once they have at least one matching document.
                    </p>
                </section>
            )
        }

        return (
            <section>
                <h4>Similar Document Check</h4>
                <ul>
                    <li>Check ID: <pre className="inline">{component.targetField}</pre></li>
                    <li>System Status: {status}</li>
                    {processed && <li>Result: {checkStatus}</li>}
                    {processed && similarDocCheckResultsLastChecked && <li>Last Check: {similarDocCheckResultsLastChecked}</li>}
                </ul>
                {processed && <p>
                        {similarDocCheckResultCount === 0 && <span>No similar documents have been found for this applicant.</span>}
                        {similarDocCheckResultCount === 1 && <span>1 similar document has been found for this applicant in other applications.</span>}
                        {similarDocCheckResultCount > 1 && <span>{similarDocCheckResultCount} similar documents have been found for this applicant in other applications.</span>}
                    </p>}
                {!processed && <p>This applicant's documents will be processed momentarily. Check back later for results.</p>}
            </section>
        );
    }

    return <></>;
};