import { useState } from "react";
import { SpacedSpinner } from "../Util";

export function SimpleForm(props: { 
    title?: string,
    description?: string
    inputs: { 
        name: string,
        label: string,
        value: React.InputHTMLAttributes<HTMLInputElement>['value'],
        onChange: React.InputHTMLAttributes<HTMLInputElement>['onChange'],
        type?: React.InputHTMLAttributes<HTMLInputElement>['type'],
        autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'],
        placeholder?: React.InputHTMLAttributes<HTMLInputElement>['placeholder'],
    }[],
    submit: {
        label: string,
        onClick: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>,
    }
}) {

    const [submitting, setSubmitting] = useState(false);

    // source https://tailwindui.com/components/application-ui/forms/form-layouts - 'Stacked'
    return <form className="w-full p-6">
        <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-6">
                <h2 className="text-base font-semibold leading-7 text-gray-900">{props.title}</h2>
                {props.description && <p className="mt-1 text-sm leading-6 text-gray-600">{props.description}</p>}
            </div>
            
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {props.inputs.map((input) => (
                <div className="sm:col-span-4" key={input.name}>
                    <label htmlFor={input.name} className="block text-sm font-medium leading-6 text-gray-900">
                        {input.label}
                    </label>
                    <div className="mt-2">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                                type={input.type || "text"}
                                name={input.name}
                                id={input.name}
                                value={input.value}
                                onChange={input.onChange}
                                autoComplete={input.autoComplete || "off"}
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder={input.placeholder || ''}
                            />
                        </div>
                    </div>
                </div>
                ))}
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={submitting}
                    onClick={(e) => { 
                        setSubmitting(true);
                        props.submit.onClick(e).then(() => {
                            setSubmitting(false);
                        });
                    }}
                >
                    <span>{submitting && <SpacedSpinner />}</span>
                    <span>{props.submit.label}</span>
                </button>
            </div>
        </div>
    </form>
}