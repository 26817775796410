import { registerRoute, Route } from 'workbox-routing';
import { RouteMatchCallback, RouteHandlerCallback, RouteHandlerCallbackOptions } from 'workbox-core';
import type { taggedHandler as coreH } from 'aidkit/lib/common/handler';
import type { taggedHandler as roboH } from '@aidkitorg/roboscreener/lib/api/util';
import { createLogger, DefaultColors, DefaultStyles, safeHttpRegex, sleep } from '../../utils';

const logger = createLogger('Offline API', DefaultStyles(DefaultColors));

export type Fn<T, U> = coreH<T, U> | roboH<T, U>;
export type extractParams<T> = T extends Fn<infer A, any> ? A : never;
export type extractReturn<T> = T extends Fn<any, infer A> ?
    A extends Promise<infer B> ? B : A
    : never;

export type Delayed<T extends (...args: any) => any> =
    T extends (...args: infer A) => infer R ? (
        (...args: { [I in keyof A]: Promise<A[I]> }) => R
    ) : never;

export class LazyRoute<Init extends (...args: any[]) => Promise<RouteHandlerCallback>> extends Route {
    private route: RouteHandlerCallback | undefined;

    constructor(match: RouteMatchCallback, handler: Init, ...params: Parameters<Delayed<Init>>) {
        super(match, async (req) => {
            if (!this.route) {
                const args = await Promise.all(params);
                this.route = await handler(...args);
            }
            return this.route(req);
        });
    }
}

export enum SyncStatus {
    Unavailable = 'unavailable',
    BrandNew = 'new',
    InSync = 'in-sync', // also represents Backstreet-Boys mode
    Processing = 'processing',
    Stale = 'stale',
    Ahead = 'ahead'
}

const SyncEventType = 'offline-sync';

export type SyncStateEvent = {
    type: typeof SyncEventType,
    category: 'applicants',
} & SyncState;

export type SyncState = { id?: string } & ({
    status: SyncStatus.Unavailable,
} | {
    status: SyncStatus.InSync,
    timeTaken?: number,
    failures?: any[]
} | {
    status: SyncStatus.Processing,
} | {
    status: SyncStatus.Stale,
    lastUpdate?: number,
} | {
    status: SyncStatus.Ahead,
    changes: number
} | {
    status: SyncStatus
});

export async function publishSyncState(state: SyncState) {
    await clients.matchAll().then(c => c.forEach(client => client.postMessage({
        ...state,
        type: SyncEventType,
        category: 'applicants',
        version: '🍦'
    })));
}

/**
 *
 * @summary A route with additional customizations applied.
 *
 * @description Now you may be thinking... WHY do we need more customizations? Workbox has routing!
 * Tragically, they only *really* provide routing logic for GET requests. 
 * For POST, or any other method, we're on our own.
 * This is a problem, because the vast majority of our APIs are POST requests.
 * 
 * Fortunately, all is not lost. We can use ~95% of the workbox routing logic,
 * and just add a few customizations to make it work for us.
 *
 *
 * @author luke@aidkit.org
 *
 * @example
 *
 * ```ts
 * // in a separate file somewhere...
 * // it is assumed that makeRoute() returns a taggedHandler<T, U> type.
 *
 * const directory = {
 *  '/api/endpoint': makeRoute(withToken(), withOtherDep())(endpointLogic)
 *  };
 *  
 * export type Directory = typeof directory;
 *
 * // in the main file...
 *
 * import type { Api } from './routes';
 *
 * const routes: Api<Directory> = {
 *    '/api/endpoint': {
 *      async route(params, req) => {
 *          const resp = await fetch(req.request);
 *          return resp.json();
 *    },
 *    onSuccess: async (params, req, resp) => {
 *      const data = await resp.json();
 *      // do something with data
 *      }
 *    }
 *  }
 *    ```
 *    
 */
export type ConfiguredRoute<A, K extends keyof A> = {
    /**
     * Use if the route to intercept uses something other than POST.
     *
     * defaults to POST
     */
    method?: Route['method'],

    /**
     * Use to act on routes even if they do not fail. An example usage could be
     * firing an event to a service worker to update the cache.
     */
    whenOnline?(params: extractParams<A[K]>, req: Request, resp: Response): Promise<Response | void>,

    /**
     * The route logic to use when offline
     */
    whenOffline: (params: extractParams<A[K]>, routeInfo: RouteHandlerCallbackOptions) => Promise<extractReturn<A[K]>>,

    responseHandler?(params: extractParams<A[K]>, req: Request, resp: Response): Promise<Response>,
};

/**
 * Converts an api-like type into a form that is compatible with offline routing.
 */
export type Api<A> = {
    [K in keyof A]: ConfiguredRoute<A, K>
};

/**
 * Registers all routes contained within a given API, to operate offline.
 *
 * @param routes the api routes to register
 * @memberof module:aidkit-offline-routing
 */
export function registerRoutes<A>(routes: Api<A>): Route[] {
    return Object.keys(routes).map(path => {
        const confRoute = routes[path as keyof Api<A>];
        return registerRoute(safeHttpRegex(path + '.*'), async (req) => {
            const origReq = req.request.clone();
            const params = await origReq.clone().json();
            let resp: Awaited<ReturnType<typeof confRoute.whenOffline>>;
            let response: Response;
            try {
                response = await fetch(req.request);

                const error = response.clone().json().catch(() => {}).then(j => j.error);
                if (!response.ok || response.status !== 204 && await error) {
                    throw new Error(`Request failed with status ${response.status}: ${await error ?? response.statusText}`);
                }
                if (confRoute.whenOnline) {
                    logger.debug('reacting to online mode');
                    const res = await confRoute.whenOnline(params, origReq.clone(), response.clone());
                    response = res ?? response;
                }
            } catch {
                logger.debug('using offline mode');
                resp = await confRoute.whenOffline(
                    params,
                    { ...req, request: origReq },
                );
                response = new Response(
                    typeof resp === 'string' || resp instanceof ArrayBuffer ? resp : JSON.stringify(resp),
                    {
                        headers: { 'Content-Type': 'application/json' }
                    });
            }

            if (confRoute.responseHandler) {
                logger.debug('using response handler');
                response = await confRoute.responseHandler(params, origReq.clone(), response.clone());
            }

            return response;

        }, confRoute.method ?? 'POST')
    });
}
