import * as esprima from 'esprima';

export function getFormulaParents(formula: string) {

    const masters = ['info','screener','org','view_info'];

    const parents: string[] = [];
    let varUpNext: string | undefined;

    // if (!formula) throw new Error("Formula for " + key + " is missing!");

    let tokens;
    try {
        tokens = esprima.tokenize(formula);
    } catch (e) {
        console.log("Error tokenizing formula:", formula);
        throw e;
    }
    for (const token of tokens) {
        const foundVariable = token.value;
        if (varUpNext && masters.indexOf(varUpNext) !== -1 && token.type === 'Punctuator' && foundVariable === '['){
            varUpNext = 'String'
        }
        if (varUpNext === 'String' && token.type === 'String'){
            const fixedVariable = foundVariable.replace(/[\'\"]/g,'');
            parents.push(fixedVariable);

            varUpNext = undefined;
        }
        if (token.type === 'Identifier') {
            if (varUpNext) {
                if (varUpNext === 'info') {
                   parents.push(foundVariable);
                }
                varUpNext = undefined;
            } else {
                if (masters.indexOf(foundVariable) !== -1) {
                    varUpNext = foundVariable;
                }
            }
        }
    }

    return parents;
}

/** Gets all possible variables regardless of parents, weeds out common JS namespace variable. */
export const getAllIdentifiers = (formula: string, varNames?: string[]) => {
    const tokens = esprima.tokenize(formula);
    // let ignoreKeys: string[] = varNames || []; 
    let ignoreKeys = [...(varNames || []), ...new Set([Array, Date, String, Number, Boolean].flatMap(
        c => [c.toString().match(/\w+/g)![1], ...Object.getOwnPropertyNames(c.prototype)]
    ))];
    return [...new Set(tokens.filter((t,i,array) => t.type === 'Identifier' && ignoreKeys.indexOf(t.value) === -1).map(t => t.value))];
}

// This should really go in an aidkit-common package but we don't have that.
export function filterDict<T>(dict: Record<string, T>, filter: (key: string, value: T) => boolean): Record<string, T> {
    let filtered: typeof dict = {};
    for (let key of Object.keys(dict)) {
        if (filter(key, dict[key])) {
            filtered[key] = dict[key];
        }
    }
    return filtered;
}

export type AppsWithDetailInfo = Record<string, Record<string, string>>;

/**
 * limits the number of concurrent requests to a set number of slots.
 * Whenever a slot opens, the next request is started.
 */
export async function limitConcurrentRequests<T = any>(promises: (() => Promise<T>)[], slots: number): Promise<PromiseSettledResult<T>[]> {
    let remaining = slots;
    const completed: PromiseSettledResult<T>[] = Array(promises.length);
    return await new Promise((resolve) => {
        function queueNext() {
            const next = promises.pop();
            if (next) {
                const spot = promises.length;
                next()
                    .then(
                        (value) => completed[spot] = ({ value: value as T, status: 'fulfilled' }),
                        (reason) => completed[spot] = ({ reason, status: 'rejected' }))
                    .finally(() => queueNext());
            } else {
                remaining--;
            }

            if (remaining === 0) {
                resolve(completed);
            }
        }

        for (let i = 0; i < slots; i++) {
            queueNext();
        }
    })
}
