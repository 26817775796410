import React from "react"
import { useCookies } from "react-cookie"
import { useHistory } from "react-router"
import { usePost } from "./API"
import { useLocalizedStrings } from "./Localization"

export function AuthModal(props: { children: React.ReactNode, title: string }) {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-green-400">{props.title}</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {props.children}
        </div>
      </div>
    </div>
  )
}

type InputProperties = {
    label: string,
    type: string,
    [key: string]: any
}
export function Input(props: InputProperties): JSX.Element {
    return <label className="block mb-5">
        <span className="text-gray-700">{props.label}</span>
        <input {...props} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-300 focus:ring focus:ring-green-200 focus:ring-opacity-50" />
    </label>
}

type ButtonProperties = {
    label: string,
    [key: string]: any
}
export function Button(props: ButtonProperties): JSX.Element {
    return <button {...props} className="block w-full tracking-wider rounded-md pt-3 pb-2 text-green-700 shadow-sm border-gray-300 bg-green-300 hover:text-green-100 hover:bg-green-400 font-logo text-2xl">{props.label}</button>
}

export function ModernAuth() {
  const sendConfirmation = usePost("/confirm/phone");
  const login = usePost("/login");
  const [,setCookie,] = useCookies(["auth_token"]);
  const [stage, setStage] = React.useState<'enter_email' | 'enter_code'>('enter_email');
  const [email, setEmail] = React.useState<string>('');
  const [code, setCode] = React.useState<string>('');
  const [token, setToken] = React.useState<string>('');
  const history = useHistory();
  const L = useLocalizedStrings();
  const next = decodeURIComponent(location.search.slice("?next=".length));

  async function startAuth() {
    setStage('enter_code');
    const result = await sendConfirmation({ phone: email, language: 'en' });
    if (result.token) {
        setToken(result.token);
    } else {
        setStage('enter_email');
    }
  }

  async function completeAuth() {
    const result = await login({ token, answer: code });
    if (result?.token) {
        setCookie("auth_token", result.token, { 
            maxAge: 86400, path: "/"
        });
        history.push(next || "/");
    }
    setStage('enter_email');
  }

  return <AuthModal title="Please Sign In">
      {stage === 'enter_email' && <>
        <Input label="Email" type="email" value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
        <br />
        <Button label="Sign In" onClick={startAuth} />
      </>}
      {stage === 'enter_code' && <>
        <Input label="Token" type="token" value={code} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCode(e.target.value)} />
        <br />
        <Button label="Confirm Code" onClick={completeAuth} />
      </>}
        <p className="mt-4">{L.auth.we_use_cookies}</p>
    </AuthModal>

}
