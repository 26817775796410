import { Block, Collection, FieldEquals, Or, PaymentModule, Section, Subsurvey } from "../../survey";

export const generatePaymentSubsurveys = (config: PaymentModule): Collection => {
    return {
        "kind": "Collection",
        "name": {
            "en": "Subsurveys"
        },
        "options": {},
        "components": [
            ...[generatePaymentUpdateSubsurvey(config)],
            ...[generatePaymentFaqsSubsurvey(config)],
            ...(config.debit.kind === 'GiveCard') ? [generateGiveCardMailSubsurvey(config)] : [],
            ...(config.debit.kind === 'GiveCard') ? [generateGiveCardInfoSubsurvey(config)] : [],
            ...(config.debit.kind === 'Usio' && config.debit.virtual.enabled) ? [generateMyCardInfoSubsurvey(config)] : [],
        ]
    }
};

const generatePaymentUpdateSubsurvey = (config: PaymentModule): Subsurvey => ({
    "kind": "Subsurvey",
    "path": "payment-update",
    "sections": [
        {
            "kind": "Section",
            "title": {
                "en": "Payment Information Update ",
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# Payment Information Update \n\nYou are receiving this survey because you need to update your payment information. \n\n\n",
                    }
                },
                {
                    "kind": "Select",
                    "targetField": "applicant_payment_type",
                    "content": {
                        "en": "**Which payment method are you choosing?**",
                    },
                    "choices": [
                        ...(config.ach.enabled) ? [{
                            "value": "ach",
                            "label": {
                                "en": "Direct Deposit (ACH)"
                            }
                        }] : [],
                        ...(config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
                            "value": "usio_pickup",
                            "label": {
                                "en": "Pre-Paid Debit Card Pick Up: A pre-paid debit card that you pick up from [location]",
                            }
                        }] : [],
                        ...(config.debit.kind === 'Usio' && config.debit.virtual.enabled) ? [{
                            "value": "usio_virtual",
                            "label": {
                                "en": "Pre-Paid Virtual Card: Pre-paid debit card that will be sent to you by email and text",
                            }
                        }] : [],
                        ...(config.debit.kind === 'Usio' && config.debit.mail.enabled) ? [{
                            "value": "usio_mail",
                            "label": {
                                "en": "Pre-Paid Debit Card by Mail: Pre-paid debt card that will be mailed to your home address",
                            }
                        }] : [],
                        ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
                            "value": "givecard_mail",
                            "label": {
                                "en": "Pre-Paid Debit Card by Mail: Pre-paid debt card that will be mailed to your home address"
                            }
                        }] : [],
                        ...(config.debit.kind === 'GiveCard' && config.debit.pickup.enabled) ? [{
                            "value": "givecard_load",
                            "label": {
                                "en": "Pre-Paid Debit Card Pick Up: A pre-paid debit card that you pick up from [location]",
                            }
                        }] : [],
                    ]
                },
                ...(config.ach.enabled) ? [{
                    "kind": "Conditional Block",
                    "components": [
                        {
                            "kind": "Explanation",
                            "content": {
                                "en": "**Collecting Bank Info:**\n\nPlease enter your bank account information below. We take your privacy very seriously. We will only use this information to send you payments on behalf of the this program.\n\n![Bank Info Update](https://raw.githubusercontent.com/aidkitorg/aidkitorg.github.io/main/bank_info_reference.png)\n",
                            }
                        },
                        {
                            "kind": "BankRoutingNumber",
                            "content": {
                                "en": "**Please enter your Bank Account Routing Number** (Enter 121000358 for Testing)\n\n*The routing number is a 9-digit identification number used to identify your bank.*",
                            },
                            "targetField": "bank_routing_number"
                        },
                        {
                            "kind": "Number",
                            "content": {
                                "en": "**Please enter your Bank Account Number** \n\n*Note: We encrypt your bank account number as soon as you hit save.*\n\n*Your account number is the unique number assigned to your specific bank account. This is not the number that is on your debit card, and is best located on a check or by accessing a bank statement, your online bank account, or your bank directly..*",
                            },
                            "targetField": "bank_account_number",
                            "min": 0,
                            "encrypted": true
                        },
                        {
                            "kind": "TextEntry",
                            "content": {
                                "en": "** Personal name on the Bank Account (i.e. the name of person associated with the bank account, not the name of your bank).**\n\n\n**Note the bank account must be under your name.**\n**Do not put the brand name of your bank.**",
                            },
                            "targetField": "bank_account_name",
                            "format": "legalName"
                        },
                        {
                            "kind": "Select",
                            "content": {
                                "en": "** Is this a checking or savings account?**",
                            },
                            "targetField": "bank_account_type",
                            "choices": [
                                {
                                    "value": "checking",
                                    "label": {
                                        "en": "Checking",
                                    }
                                },
                                {
                                    "value": "savings",
                                    "label": {
                                        "en": "Savings",
                                    }
                                }
                            ]
                        }
                    ],
                    "conditionalOn": {
                        "field": "applicant_payment_type",
                        "kind": "Equals",
                        "value": "ach"
                    } as FieldEquals
                } as Block] : [],
                ...(config.debit.kind === 'Usio' && config.debit.mail.enabled) ? [{
                    "kind": "Conditional Block",
                    "components": [
                        {
                            "kind": "Address",
                            "targetField": "usio_mailing_address",
                            "content": {
                                "en": "**Please enter the mailing address where we should send the debit card.**",
                            }
                        },
                        {
                            "kind": "Validated",
                            "targetField": "validate_usio_address",
                            "content": {
                                "en": "The address must be in California.",
                            },
                            "formula": "if (info.applicant_payment_type === 'usio_mail' && info.usio_mailing_address && info.usio_mailing_address_state != 'CA') { throw '$content'; } return 'hide_ok';",
                            "advisory": false
                        },
                        {
                            "kind": "Validated",
                            "targetField": "validate_usio_address_po_box",
                            "content": {
                                "en": "The address must be a physical address.  It cannot be a P.O. Box",
                            },
                            "formula": "if (!info.usio_mailing_address_street) return 'hide_ok';\n\nconst poBoxVariations = ['po box', 'P.O. Box', 'PO Box', 'p.o. box', 'p.o.'];\nconst addressLower = info.usio_mailing_address_street.toLowerCase();\nconst containsPOBox = poBoxVariations.some(variation => addressLower.includes(variation.toLowerCase()));\n\nif (containsPOBox) {\n    throw '$content';  \n}\nreturn 'hide_ok';  ",
                            "advisory": false
                        }
                    ],
                    "conditionalOn": {
                        "kind": "Or",
                        "clauses": [
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "usio_mail"
                            },
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "givecard_mail"
                            }
                        ]
                    } as Or
                } as Block] : [],
                ...(config.debit.kind === 'Usio') ? [{
                    "kind": "Select",
                    "targetField": "changed_usio_methods",
                    "content": {
                        "en": "Did you change between Debit Card Payment Methods?\n\ne.g. between any of the following: Debit Card Pickup, Mailed Debit Card, Virtual Card "
                    },
                    "choices": [
                        {
                            "value": "no",
                            "label": {
                                "en": "No"
                            }
                        },
                        {
                            "value": "yes",
                            "label": {
                                "en": "Yes"
                            }
                        }
                    ],
                    "conditionalOn": {
                        "kind": "Or",
                        "clauses": [
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "usio_pickup"
                            },
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "usio_mail"
                            },
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "usio_virtual"
                            }
                        ]
                    } as Or
                } as Block, {
                    "kind": "Action",
                    "content": {
                        "en": "Please click here to confirm the change in Debit Card Methods"
                    },
                    "action": "{\n  card_id: '',\n  payment_method_update: ''\n}",
                    "confirmation": {
                        "en": "Are you sure you switched from one Debit Card Payment Method to another?"
                    },
                    "toast": {
                        "en": "Thank you for confirming, please continue to submit."
                    },
                    "conditionalOn": {
                        "kind": "And",
                        "clauses": [
                            {
                                "kind": "Or",
                                "clauses": [
                                    {
                                        "field": "applicant_payment_type",
                                        "kind": "Equals",
                                        "value": "usio_pickup"
                                    },
                                    {
                                        "field": "applicant_payment_type",
                                        "kind": "Equals",
                                        "value": "usio_mail"
                                    },
                                    {
                                        "field": "applicant_payment_type",
                                        "kind": "Equals",
                                        "value": "usio_virtual"
                                    }
                                ]
                            },
                            {
                                "field": "changed_usio_methods",
                                "kind": "Equals",
                                "value": "yes"
                            }
                        ]
                    }
                } as Block] : [],
                {
                    "kind": "SubmitButton",
                    "targetField": "payment_method_update",
                    "hideRequestChangesButton": true
                },
                {
                    "kind": "Computed",
                    "targetField": "payment_selection_text_en",
                    "content": {
                        "en": "[hidden]",
                    },
                    "hidden": true,
                    "formula": {
                        "kind": "When",
                        "when": [
                            {
                                "cond": {
                                    "field": "applicant_payment_type",
                                    "kind": "Equals",
                                    "value": "usio_virtual"
                                },
                                "then": {
                                    "kind": "StringValue",
                                    "value": "virtual debit card"
                                }
                            },
                            {
                                "then": {
                                    "kind": "StringValue",
                                    "value": "debit card pick up"
                                },
                                "cond": {
                                    "kind": "Or",
                                    "clauses": [
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "usio_pickup"
                                        },
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "givecard_load"
                                        }
                                    ]
                                }
                            },
                            {
                                "then": {
                                    "kind": "StringValue",
                                    "value": "mailed debit card"
                                },
                                "cond": {
                                    "kind": "Or",
                                    "clauses": [
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "usio_mail"
                                        },
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "givecard_mail"
                                        }
                                    ]
                                }
                            }
                        ],
                        "otherwise": {
                            "kind": "StringValue",
                            "value": ""
                        }
                    },
                    "optional": true
                },
                {
                    "kind": "Computed",
                    "targetField": "payment_selection_text_es",
                    "content": {
                        "en": "[hidden]",
                    },
                    "hidden": true,
                    "formula": {
                        "kind": "When",
                        "when": [
                            {
                                "cond": {
                                    "field": "applicant_payment_type",
                                    "kind": "Equals",
                                    "value": "ach"
                                },
                                "then": {
                                    "kind": "StringValue",
                                    "value": "deposito directo"
                                }
                            },
                            {
                                "then": {
                                    "kind": "StringValue",
                                    "value": "tarjeta de debito"
                                },
                                "cond": {
                                    "kind": "Or",
                                    "clauses": [
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "usio_pickup"
                                        },
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "givecard_load"
                                        }
                                    ]
                                }
                            },
                            {
                                "then": {
                                    "kind": "StringValue",
                                    "value": "tarjeta de debito por correo"
                                },
                                "cond": {
                                    "kind": "Or",
                                    "clauses": [
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "usio_mail"
                                        },
                                        {
                                            "field": "applicant_payment_type",
                                            "kind": "Equals",
                                            "value": "givecard_mail"
                                        }
                                    ]
                                }
                            }
                        ],
                        "otherwise": {
                            "kind": "StringValue",
                            "value": ""
                        }
                    },
                    "optional": true
                }
            ]
        }
    ],
    "protectedSearch": {
        "condition": {
            "kind": "Click",
            "expr": {
                "field": "submitted",
                "kind": "Exists"
            }
        },
        "roles": [
            "admin"
        ],
        "sendLink": {
            "en": "Hello $actual_name, Please update your payment information using the following link: $link \n\n* If you no longer wish to receive your payment via the payment you selected when you applied, you may use the above link to update your payment method (e.g. switch from virtual card to debit card or vice versa).",
        }
    },
    "redirectToSubsurvey": "payment-faqs",
    "customSubmittedModal": {
        "title": {
            "en": "Your payment method has been updated!",
        }
    },
    "defaultExpiration": {
        "days": 3,
        "weeks": 0,
        "months": 0
    }
});

const generatePaymentFaqsSubsurvey = (config: PaymentModule): Subsurvey => ({
    "kind": "Subsurvey",
    "path": "payment-faqs",
    "sections": [
        {
            "kind": "Section",
            "title": {
                "en": "Payment FAQs",
                "es": "Preguntas frecuentes sobre pagos",
                "hmn": " Cov ntaub ntawv them nyiaj thiab nyiam"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Payment FAQs",
                        "es": "## Preguntas frecuentes sobre pagos",
                    }
                },
                {
                    "kind": "Select",
                    "targetField": "what_payment_method_did_you_pick",
                    "content": {
                        "en": "What payment method did you select when completing the application? ",
                        "es": "**¿Qué método de pago seleccionó al completar la solicitud?**",
                    },
                    "choices": [
                        ...(config.ach.enabled) ? [{
                            "value": "ach",
                            "label": {
                                "en": "Direct Deposit",
                                "es": "Depósito directo"
                            }
                        }] : [],
                        ...(config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
                            "value": "usio_pickup",
                            "label": {
                                "en": "Pre-Paid Debit Card Pick Up",
                                "es": "Recoger Tarjeta de Débito Prepagada"
                            }
                        }] : [],
                        ...(config.debit.kind === 'Usio' && config.debit.virtual.enabled) ? [{
                            "value": "usio_virtual",
                            "label": {
                                "en": "Pre-Paid Virtual Card",
                                "es": "Tarjeta virtual prepaga"
                            }
                        }] : [],
                        ...(config.debit.kind === 'Usio' && config.debit.mail.enabled) ? [{
                            "value": "usio_mail",
                            "label": {
                                "en": "Pre-Paid Debit Card by Mail",
                                "es": "Tarjeta de débito prepaga por correo"
                            }
                        }] : [],
                        ...(config.debit.kind === 'GiveCard' && config.debit.mail.enabled) ? [{
                            "value": "givecard_mail",
                            "label": {
                                "en": "GiveCard Debit Card by Mail"
                            }
                        }] : [],
                        ...(config.debit.kind === 'GiveCard' && config.debit.pickup.enabled) ? [{
                            "value": "givecard_load",
                            "label": {
                                "en": "GiveCard Debit Card Pickup"
                            }
                        }] : [],
                    ]
                }
            ]
        },
        ...(config.ach.enabled) ? [{
            "kind": "Section",
            "title": {
                "en": "Frequently Asked Questions - Direct Deposit",
                "es": "Preguntas frecuentes: depósito directo"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# Frequently Asked Questions - Direct Deposit",
                        "es": "# Preguntas frecuentes: depósito directo",
                        "hmn": "# Cov Ntaub Ntawv Mas Yog Hauv Tsev Hais Tawv - Nyob Hauv Koj Hauv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### When will I receive my money? \n\nACH transfers generally post in 1-2 days depending on the receiving institution- e.g. your bank or credit union. You received an email or text message on the day AidKit sent your payment to your bank or credit union. ",
                        "es": "### ¿Cuándo recibiré mi dinero?\n\nPor lo general, las transferencias ACH se procesan en 1 o 2 días, dependiendo de la institución receptora, que es su banco o cooperativa de crédito. Usted recibió un correo electrónico o mensaje de texto el día en que AidKit envió su pago a su banco o cooperativa de crédito.",
                        "hmn": "### Yog koj yuav tau koj daim ntawv tshwm sim? \n\nNtau txhua txoj kev ACH ua rau hauv 1-2 hnub tias kuj muaj kawm ntawv txoj kev ua hauj lwm- nplooj ntawv lossis lub tejchaw. Koj yuav tau tau email lossis qhov text message rau hauv lub hnub AidKit ntxiv koj kev tshwm sim rau koj nplooj ntawv lossis lub tejchaw."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Why is only some of my money available? \n\nBanks and credit unions have funds availability policies. They may make large deposits available smaller amounts over time. Check with your bank or credit union to learn about their policy. ",
                        "es": "### ¿Por qué solo está disponible una parte de mi dinero?\n\nLos bancos y cooperativas de crédito tienen políticas de disponibilidad de fondos. Pueden hacer que grandes depósitos estén disponibles en cantidades más pequeñas con el tiempo. Consulte con su banco o cooperativa de crédito para conocer su política.",
                        "hmn": "### Yuav tsum tsis yuav koj daim ntawv tshwm sim txog tus kheej lub caij nyoog?\n\nNplooj ntawv lossis lub tejchaw tau tuaj cov lus nug tseem ceeb txog koj txoj kev tshwm sim. Txoj kev ua haujlwm muaj qhov chaw ua haujlwm uas hais tias qhov kev tshwm sim no tau tsawg nrog kev noj tshuaj thaum lub hnub. Nrhiav cov lus rau hauv koj nplooj ntawv lossis lub tejchaw mus tso cai lawm rau hauv cov lus nug."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### I think there was a problem with my transfer or I have another question. \n\nPlease send a text, call us, or email us from the phone or email address you used to apply. You can contact us at: $support_email or $support_phone.",
                        "es": "### Creo que hubo un problema con mi transferencia o tengo otra pregunta.\n\nEnvíe un mensaje de texto, llámenos o envíenos un correo electrónico desde el teléfono o la dirección de correo electrónico que utilizó para presentar la solicitud. Puede contactarnos en: $support_email o llame al $support_phone.",
                        "hmn": "### Kuv paub tias muaj ib qhov chaw no tshwm sim hauv kuv lub npej tus, los sis kuv muaj lus nug.\n\nThov mus sau ntawv, hu rau peb, lossis email peb los ntawm cov lus nug tus, uas koj siv rau kev tshwm sim. Koj tuaj mus sau ntawv peb rau: $support_email los sis hu peb rau: $support_phone."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Questions or need support?\n\nContact $support_email or $support_phone.",
                        "es": "### ¿Tiene preguntas o necesita ayuda?\n\nEnvíe un correo electrónico a $support_email o llame al $support_phone.",
                        "hmn": "### Sib txuas lus los sis xav paub\n\nSau ntawv rau $support_email los sis hu rau $support_phone."
                    },
                    "collapsible": true
                }
            ],
            "conditionalOn": {
                "field": "what_payment_method_did_you_pick",
                "kind": "Equals",
                "value": "ach"
            } as FieldEquals
        } as Section] : [],
        ...(config.debit.kind === 'Usio' && config.debit.pickup.enabled) ? [{
            "kind": "Section",
            "title": {
                "en": "Frequently Asked Questions - Pre-paid Debit Card Pick-up ",
                "es": "Preguntas frecuentes - Recoger Tarjeta de Débito Prepagada",
                "hmn": "Cov Ntaub Ntawv Mas Yog Hauv Tsev Hais Tawv - Pre-Paid Debit Card Pick-Up"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# Frequently Asked Questions: Pre-paid Debit Card Pick-up ",
                        "es": "# Preguntas frecuentes: Retiro con tarjeta de débito prepago",
                        "hmn": "# Cov Ntaub Ntawv Mas: Pre-paid Debit Card Pick-up"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## How do I get my card? \n\nPlease text, call, or email us at $support_email or $support_phone to schedule a time to pick up your card. ",
                        "es": "## ¿Cómo obtengo mi tarjeta?\n\nEnvíenos un mensaje de texto, llámenos o envíenos un correo electrónico a $support_email o al $support_phone para programar una hora para recoger su tarjeta.",
                        "hmn": "## Koj pab txhij koj lub thawv?\n\nThov sau ntawv, hu, lossis email rau peb rau kev ntau ntawv ua siab los ntawm $support_email lossis $support_phone rau kev xav txhij koj lub thawv."
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Using My Card",
                        "es": "## Uso de mi tarjeta",
                        "hmn": "## Siv Koj Lub Thawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Are there fees or withdrawal limits associated with using the debit card?\n\nThere is no fee when you use the card for purchases - other fees vary. Please refer to your card agreement. ",
                        "es": "### ¿Existen tarifas o límites de retiro asociados con el uso de la tarjeta de débito?\n\nNo se cobra ningún cargo cuando utiliza la tarjeta para compras; otros cargos varían. Consulte el acuerdo de su tarjeta.",
                        "hmn": "### Yog cov txoj cai los sis cov ntaub ntawv yuav siv txoj kev uas txoj kev siv?\n\nTsis muaj txoj cai mus txog cov ntaub ntawv tias koj siv rau cov ntaub ntawv rau txoj kev pib - txoj cai lwm haum cov ntaub ntawv siv. Thov sau rau koj lub ntaub ntawv tso cai."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window).\n\n**Important note**: When using your card for online transactions or making purchases over the phone, the merchant will often validate your billing address (usually the ZIP code). Please make sure that you have the correct address information listed on your card account.",
                        "es": "### ¿Cómo uso los fondos para compras en línea y por teléfono?\n\nPuede usar su tarjeta de débito como usaría una tarjeta de crédito para compras en línea o por teléfono. Por lo general, deberá proporcionar el número de tarjeta, la fecha de vencimiento y el número de CV (código de seguridad de 3 dígitos), al que puede acceder iniciando sesión en [su cuenta](https://app.akimbocard.com/app/#/nowincentive/login) (abre en una ventana nueva).\n\n**Nota importante**: cuando utilice su tarjeta para transacciones en línea o realice compras por teléfono, el comerciante a menudo validará su dirección de facturación (generalmente el código postal). Asegúrese de tener la información de la dirección correcta en la cuenta de su tarjeta.",
                        "hmn": "### Koj pab txhij tsim cov ntaub ntawv rau kev pib online thiab ntawm tus xaiv qhov txiaj ntsig?\n\nKoj tuaj siv koj ntaub ntawv xws li koj yuav siv ib ntaub ntawv lub credit rau kev pib online los sis ntawm tus xaiv qhov txiaj ntsig. Koj yuav tsum tuaj siv cov lus txoj cai, daim ntawv tshwm sim, thiab lub CV number (3-digit security code), uas koj yuav tau sau ntawv los ntawm [koj lub npej tus](https://app.akimbocard.com/app/#/nowincentive/login) (nyob hauv lub nplooj). \n\n**Kev koomtes note**: Yog koj tuaj siv koj ntaub ntawv rau kev pib online los sis siv rau kev pib ntawm tus xaiv qhov txiaj ntsig, tus huab cua yuav qhia koj lub npej tus (qhov txiaj ntsig tseem ceeb). Thov sau ntawv txoj kev txiav txim siab rau peb thaum koj muaj kev txiav txim siab ntawm koj ntaub ntawv lub npej tus."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Can I add money to my Akimbo Now card? \n\nNot directly. There is no way to add funds to the card. However, when you add the card to your mobile wallet you can add additional funds via Apple Pay, Google Pay, or Samsung Pay. ",
                        "es": "### ¿Puedo agregar dinero a mi tarjeta Akimbo Now?\n\nNo directamente. No hay manera de agregar fondos a la tarjeta. Sin embargo, cuando agrega la tarjeta a su billetera móvil, puede agregar fondos adicionales a través de Apple Pay, Google Pay o Samsung Pay. ",
                        "hmn": "### Kuv tuaj yuav nqa lub tshuab rau kuv Akimbo Now card?\n\nTsis muaj ib qhov tshwm sim tom qab. Tsis muaj lwm cov ntsiab lus los nqa tshuab rau lub ntaub ntawv. Tab sis, thaum koj nqa lub ntaub ntawv rau koj mobile wallet, koj tuaj yuav nqa tshuab them yuav tsum nqa tshuab tshwj xeeb nrog Apple Pay, Google Pay, los sis Samsung Pay."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Managing My Card",
                        "es": "## Administrar Mi Tarjeta",
                        "hmn": "## Ntxig Kev Tshwm Sim Koj Ntaub Ntawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I access my Akimbo Now account?\n\nYou can log in with your email and password at https://app.akimbocard.com/app/#/nowincentive/login (opens in a new window).",
                        "es": "### ¿Cómo accedo a mi cuenta de Akimbo Now?\n\nPuedes iniciar sesión con tu correo electrónico y contraseña en https://app.akimbocard.com/app/#/nowincentive/login (se abre en una ventana nueva).",
                        "hmn": "### Koj pab saib koj lub npej tus Akimbo Now?\n\nKoj tuaj saib koj npej tus thiab password ntawm koj los ntawm https://app.akimbocard.com/app/#/nowincentive/login (nyob hauv lub nplooj)."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How can I check the balance of my card?\n\nYou can check the balance by logging into [your Akimbo Now account](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (opens in a new window) with your email and password, or by calling the automated phone service at <a href=\"tel:8559254626\">1-855-925-4626</a>.",
                        "es": "### ¿Cómo puedo consultar el saldo de mi tarjeta?\n\nPuede consultar el saldo iniciando sesión en [su cuenta de Akimbo Now](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (se abre en una ventana nueva) con su correo electrónico y contraseña, o llamando al servicio telefónico automatizado al <a href=\"tel:8559254626\">1-855-925- 4626</a>.",
                        "hmn": "### Koj pab saib lub txoj cai ntawm koj ntaub ntawv xwb?\n\nKoj tuaj saib lub txoj cai ntawm saib koj nplooj ntawv Akimbo Now ntawm koj lub npej thiab password, los sis tuaj hu rau kev pab saib ntawv ntawm lub sijhawm ntawm tus xov tos ntawm <a href=\"tel:8559254626\">1-855-925-4626</a>."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I change my PIN? \n\nLog in to your Akimbo Now account and click on \"CHANGE PIN\".\n\n![Akimbo Now change pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nYou can also change your PIN by calling <a href=\"tel:8554492273\">1-855-449-2273</a> Monday through Friday between the hours of 7am and 7pm CT.",
                        "es": "### ¿Cómo cambio mi PIN?\n\nInicie sesión en su cuenta de Akimbo Now y haga clic en \"CHANGE PIN\".\n\n![Akimbo Now cambia pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nTambién puede cambiar su PIN llamando al <a href=\"tel:8554492273\">1-855-449-2273</a> de lunes a viernes entre las 7 am y las 7 pm CT.",
                        "hmn": "### Koj pab hloov koj lub tshuab?\n\nSaib koj nplooj ntawv Akimbo Now thiab nyem rau \"CHANGE PIN.\"\n\n![Akimbo Now hloov tshuab](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nKoj ho yuav hloov koj tshuab los saib <a href=\"tel:8554492273\">1-855-449-2273</a> hnov Tsaus ntuj hais tias koj tau tau los ntawm 7am thiab 7pm CT."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Will my funds expire?\n\nAfter three years of inactivity you may need to take action to protect your funds. See the card agreement for more information. You can access your card agreement by logging in to your Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)",
                        "es": "### ¿Caducarán mis fondos?\n\nDespués de tres años de inactividad, es posible que deba tomar medidas para proteger sus fondos. Consulte el acuerdo de la tarjeta para obtener más información. Puede acceder a su contrato de tarjeta iniciando sesión en su cuenta Akimbo Now.\n\n![Ubicación del acuerdo de la tarjeta Akimbo Now](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)",
                        "hmn": "### Koj lub tshuab yuav txoj cai los ntawm lub ntiaj teb?\n\nTus ntawd sau 3 xyoo txhais lus, koj tuaj yuav tsum txoj cai ntawm ntau cov kev pab pawg rau kev txoj cai lub tshuab. Saib koj card agreement rau kev thov ntau dua. Koj tuaj tau saib koj card agreement los ntawm saib koj nplooj ntawv Akimbo Now. \n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### What should I do if my card is lost or stolen?\n\nAidKit works with a company called USIO to provide virtual cards. USIO is the card issuer that provides the Akimbo Now card. If your card is lost/stolen, call USIO customer support at <a href=\"tel:8559254626\">1-855-925-4626</a> to cancel the card.\n\nYou will need to provide them with your name, phone number, and the card number (if available) so they can process a replacement. ",
                        "es": "### ¿Qué debo hacer si pierdo o me roban mi tarjeta?\n\nAidKit trabaja con una empresa llamada USIO para proporcionar tarjetas virtuales. USIO es el emisor de la tarjeta que proporciona la tarjeta Akimbo Now. Si pierde o le roban su tarjeta, llame al servicio de atención al cliente de USIO al <a href=\"tel:8559254626\">1-855-925-4626</a> para cancelar la tarjeta.\n\nDeberá proporcionarles su nombre, número de teléfono y el número de tarjeta (si está disponible) para que puedan procesar un reemplazo. ",
                        "hmn": "### Yog kuv ntaub ntawv no nyob thaum kho los sis tsis muaj, kuv twb xav tsis ua\n\nAidKit twb tuaj xav txhawb cov khoom siv los ntawm ib tug tsev neeg uas pom ntaub ntawv virtual. USIO yog ib qhov issuer uas siv cov ntaub ntawv Akimbo Now. Yog koj ntaub ntawv nyob kho / kho, hu rau USIO customer support ntawm <a href=\"tel:8559254626\">1-855-925-4626</a> rau tsis txawj koj lub tshuab.\n\nKoj yuav tsum tuaj rau lawv cov ntaub ntawv twb tau txiav txim, daim ntawv tshwm sim, thiab cov tshuab twb siv (yaam uas twb tau txiav) thiab lawv yuav txiav cov tshuab saib koj."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How can I keep my money safe?\n\n- Keep the PIN code saved somewhere safe (you will need the PIN for purchases)\n- Report loss or theft right away by calling customer support for USIO, the card issuer,  at <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Don’t share your card number with others",
                        "es": "### ¿Cómo puedo mantener mi dinero seguro?\n\n- Guarde el código PIN en un lugar seguro (necesitará el PIN para las compras)\n- Informe la pérdida o el robo de inmediato llamando al servicio de atención al cliente de USIO, el emisor de la tarjeta, al <a href=\"tel:8559254626\">1-855-925-4626</a>\n- No comparta su número de tarjeta con otros",
                        "hmn": "### Koj yuav paub kuv tshuab txog koj lub ntaub ntawv?\n\n- Siv cov tshuab tshuab thiab hloov cov tshuab txog ib qhov twg uas mam li txwv (koj yuav tau txiav cov tshuab rau kev pib)\n- Txwv loss los sis kho tsev rau hu rau kev kho loss los ntawm USIO, ib qhov issuer ntawm cov ntaub ntawv, ntawm <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Tsis txawj koj lub ntaub ntawv daim ntawv tshwm sim rau lwm tus kheej yog (cov ntaub ntawv twb tau txiav txim siab rau kev pib)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Is my card insured? \n\nNo. Akimbo Now Cards are not FDIC or NCUA insured. ",
                        "es": "### ¿Está asegurada mi tarjeta?\n\nNo. Las tarjetas Akimbo Now no están aseguradas por la FDIC ni por la NCUA. ",
                        "hmn": "### Kuv ntaub ntawv yog tsis muaj pob thawj los?\n\nTsia. Ntaub ntawv Akimbo Now tsis muaj pov thawj tsa tshaj li no muaj."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Other questions",
                        "es": "## Otras preguntas",
                        "hmn": "## Lus nug"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Questions or need support?\n\nContact $support_email or $support_phone.",
                        "es": "### ¿Tiene preguntas o necesita ayuda?\n\nEnvíe un correo electrónico a $support_email o llame al $support_phone",
                        "hmn": "### Cov lus nug los sis xav paub\n\nSau ntawv rau $support_email los sis hu rau $support_phone."
                    },
                    "collapsible": true
                }
            ],
            "conditionalOn": {
                "field": "what_payment_method_did_you_pick",
                "kind": "Equals",
                "value": "usio_pickup"
            } as FieldEquals
        } as Section] : [],
        ...(config.debit.kind == 'Usio' && config.debit.virtual.enabled) ? [{
            "kind": "Section",
            "title": {
                "en": "Frequently Asked Questions - Virtual Debit Card",
                "es": "Preguntas Frecuentes de la Tarjeta de Débito Virtual",
                "hmn": "Cov Ntaub Ntawv Mas Yog Hauv Tsev Hais Tawv - Virtual Debit Card"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# Frequently Asked Questions - Virtual Debit Card",
                        "es": "# Preguntas Frecuentes de la Tarjeta de Débito Virtual",
                        "hmn": "# Cov Ntaub Ntawv Mas Uas Lawv Siv Rau Cov Virtual Debit Card"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## General Questions / Activating My Card",
                        "es": "## Preguntas generales / Activar mi tarjeta",
                        "hmn": "## Cov Lus Nug / Pib Lub Ntaub Ntawv Koj Ntaub Ntawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### What is a virtual debit card?\n\nA virtual (or online) debit card is a debit card that you can use without providing a physical card. The Akimbo Now Card is the type of card AidKit provides.\n\nOnce the card is activated, you can use it for online purchases and with a digital/mobile wallet. Digital/mobile wallets include Apple Pay, Google Pay, and Samsung Pay. There are instructions on linking your virtual debit card to a digital/mobile wallet below.",
                        "es": "### ¿Qué es una tarjeta de débito virtual?\n\nUna tarjeta de débito virtual (o en línea) es una tarjeta de débito que puede usar sin proporcionar una tarjeta física. La tarjeta Akimbo Now es el tipo de tarjeta que proporciona AidKit.\n\nUna vez que la tarjeta esté activada, puede usarla para compras en línea y con una billetera digital/móvil. Las billeteras digitales/móviles incluyen Apple Pay, Google Pay y Samsung Pay. Hay instrucciones sobre cómo vincular su tarjeta de débito virtual a una billetera digital/móvil a continuación.",
                        "hmn": "### Qhov no yog qhov virtual debit card?\n\nQhov virtual (los sis online) debit card yog ib tug debit card uas koj tuaj siv los tsawg xyuas lub tshuab txog tus neeg twg. Lub ntaub ntawv Akimbo Now yog qhov loj tshuab uas AidKit muab rau koj.\n\nTus ntaub ntawv no tawg uas koj tau hu rau kev pib online thiab nrog lub digital/mobile wallet. Lub digital/mobile wallet muaj Apple Pay, Google Pay, thiab Samsung Pay. Muaj cov ntaub ntawv txheej txheem rau koj lub virtual debit card hauv lub digital/mobile wallet thiaj li muaj ntxiv txog."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Do I need to register my debit card?\n\nYes. Registration is necessary to activate your virtual debit card and receive funds. Registration occurs when you create an account and activate your card in the portal. Registration may provide you with additional consumer rights.",
                        "es": "### ¿Necesito registrar mi tarjeta de débito?\n\nSí. Es necesario registrarse para activar su tarjeta de débito virtual y recibir fondos. El registro se produce cuando crea una cuenta y activa su tarjeta en el portal. El registro puede proporcionarle derechos de consumidor adicionales.",
                        "hmn": "### Koj yuav tsum nqa koj ntaub ntawv debit?\n\nCov nqa tau muaj tsim lawm tias koj yuav pib koj lub ntaub ntawv debit thiab tau koj kev txhawj xeeb. Cov nqa tau tuaj tawm thaum koj yog tsim ib lub npej tus thiab txhawb koj lub tshuab nyob rau hauv lub portal. Cov nqa tau muaj txoj cai yuav tsum koj cov neeg siv tshuab hauv qab lawm."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## How do I get my virtual debit card?\n\nYou will receive an email or text message that contains a link to create an account with Akimbo Now, activate your virtual card, and get access to your card number. \n\nYou can also get this link by clicking \"Get my card setup link\" at the bottom of this page.\n\nClicking on this link (or copying and pasting it into a web browser) will take you to a website for Akimbo Now that looks like this:\n\n![Akimbo Now collect your card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/collect_your_card.jpg)\n\n**To create an account with Akimbo Now and collect your card:**\n\n(1) Enter your name, email, and phone number (email or phone may already be filled in).\n(2) Create a password and a PIN.\n    * The password is used to log into your online account to access your card information and make changes to your account.\n    * A PIN is a four digit code you enter to confirm a debit purchase or ATM withdrawal. \n    * Choose a password and PIN that you will remember easily and consider saving them in a safe place.\n\n(3) Press finish in the bottom right of your screen.\n\n![Akimbo Now press finish](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/press_finish.jpg)\n\n(4) You will then see your member home page. From this page you can manage alerts, activate your card, and add your card to a digital wallet.\n(5) Be sure to click on “**ACTIVATE CARD**.” Your card must be activated for use.\n\n![Akimbo Now activate card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)",
                        "es": "### ¿Cómo obtengo mi tarjeta de débito virtual?\n\nRecibirá un correo electrónico o mensaje de texto que contiene un enlace para crear una cuenta con Akimbo Now, activar su tarjeta virtual y obtener acceso a su número de tarjeta.\n\nTambién puede obtener este enlace haciendo clic en \"Obtener el enlace de configuración de mi tarjeta\" en la parte inferior de esta página.\n\nAl hacer clic en este enlace (o copiarlo y pegarlo en un navegador web) lo llevará a un sitio web de Akimbo Now que se ve así:\n\n![Akimbo Ahora recoja su tarjeta](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/collect_your_card.jpg)\n\n**Para crear una cuenta en Akimbo Now y retirar tu tarjeta:**\n\n1. Ingrese su nombre, correo electrónico y número de teléfono (es posible que el correo electrónico o el teléfono ya estén completos).\n2. Cree una contraseña y un PIN.\n     * La contraseña se utiliza para iniciar sesión en su cuenta en línea para acceder a la información de su tarjeta y realizar cambios en su cuenta.\n     * Un PIN es un código de cuatro dígitos que ingresa para confirmar una compra con débito o un retiro en cajero automático.\n     * Elija una contraseña y un PIN que pueda recordar fácilmente y considere guardarlos en un lugar seguro.\n\n3. Presione finalizar en la parte inferior derecha de su pantalla.\n\n![Akimbo Ahora presiona finalizar](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/press_finish.jpg)\n\n4. Luego verá la página de inicio de su miembro. Desde esta página puede administrar alertas, activar su tarjeta y agregar su tarjeta a una billetera digital.\n5. Asegúrese de hacer clic en \"**ACTIVAR TARJETA**\". Su tarjeta debe estar activada para su uso.\n\n![Activar tarjeta Akimbo Now](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)",
                        "hmn": "## Koj pab tshav ntawm koj virtual debit card?\n\nKoj yuav tau ib email lossis qhov text message uas muaj kev hloov link rau saib koj lub npej tus Akimbo Now, hloov koj virtual card, thiab muaj saib koj lub tshuab tshwj xeeb.\n\nKoj tuaj tau ntau cov link no thiab muaj qhov link \"Ntshav koj lub link txheej tshuaj rau kuv tshuab\" ua kom ntawv no.\n\nSaib ntau cov link no (los sis saib ntau ntawv nrog ib tug web browser) yuav muab koj rau lub website ntawm Akimbo Now uas pom tias no xws li no:\n\n![Akimbo Now nkag siab koj tshuab](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/collect_your_card.jpg)\n\n**Rau hloov ib lub npej tus ntawv rau Akimbo Now thiab nkag siab koj lub tshuab:**\n\n(1) Nqa koj lub npej tus, email, thiab phone number (email los sis phone yuav tsum muab rau tshaj tawm).\n(2) Hloov ib password thiab ib PIN.\n    * Ib password yog siv rau hloov rau koj lub online account rau saib koj lub tshuab kev tshwm sim thiab hloov kev ntsuas rau koj lub npej tus.\n    * Ib PIN yog ib daim ntawv tshwm sim uas koj yuav siv rau txiav txim ntawm ib debit kev pib lossis ATM withdrawal.\n    * Xws li ib password thiab ib PIN uas koj yuav nco txog thiab caw koj ntawd ua tsaug ntau thiab muab siv txog ib qhov tshuab twg.\n   \n(3) Ntev finish rau lub tsev nrov ntawm koj lub pej.\n\n![Akimbo Now ntev finish](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/press_finish.jpg)\n\n(4) Koj yuav muab koj lub member home page. Nyob rau ntawm pej no koj yuav tsum muab tsim cov kev pab pawg, hloov koj lub tshuab, thiab nqa koj lub tshuab rau ib digital wallet.\n(5) Koj tsis paub tseeb \"HLOOV LUB TSHUAB.\" Koj lub tshuab yuav tsum hloov qhov siv rau kev pib. \n\n![Akimbo Now hloov lub tshuab](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### I created an Akimbo Now account over a day ago and my money is still not there.\n\nActivate your card. \n(1) Click on **VIEW YOUR VIRTUAL NUMBER**. \n![virtual](https://aidkit-public.s3.us-east-2.amazonaws.com/sifh/virtual%20card%20number.png)\n(2) Scroll down and click on **ACTIVATE CARD**. Enter your card information from Step 1. \n\nYour card must be activated before your payment will be loaded. Your money should be available to use within 1 day of activating it.\n\n![Akimbo Now activate card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)",
                        "es": "### Creé una cuenta de Akimbo Now hace más de un día y mi dinero todavía no está allí.\n\nAsegúrate de hacer clic en **ACTIVAR TARJETA**. Su tarjeta debe estar activada antes de que se cargue su pago. Su dinero debería estar disponible para su uso dentro de 1 día después de activarlo.\n\n![Activar tarjeta Akimbo Now](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)",
                        "hmn": "### Kuv tau hloov kuv npej tus Akimbo Now lub ntaub ntawv ob leeg thiab kuv ntaub tshwm sim muaj teeb meem.\n\nHloov koj lub tshuab.\n(1) Nqa rau **VIEW KOJ VIRTUAL NUMBER**.\n![virtual](https://aidkit-public.s3.us-east-2.amazonaws.com/sifh/virtual%20card%20number.png)\n(2) Hu kom rau los saib **HLOOV LUB TSHUAB**. Caw koj lub tshuab kev pib los ntawm Step 1.\n\nKoj lub tshuab yuav tsum hloov mus rau koj kev txiav txim thiab koj ntaub ntawv yuav muab siv rau hauv 1 hnub thaum koj hloov koj tshuab. \n\n![Akimbo Now activate card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### I can't find the email or text message that has my card setup link.\n\nClick on the **Get my card setup link** button below and verify the email or phone number we have on file for you. You will be redirected to a page that shows your link, if we have one for you.",
                        "es": "### No puedo encontrar el correo electrónico o mensaje de texto que tiene el enlace de configuración de mi tarjeta.\n\nHaga clic en el botón **Obtener el enlace para configuara mi tarjeta** a continuación y verifique el correo electrónico o el número de teléfono que tenemos registrado para usted. Se le redirigirá a una página que muestra su enlace, si lo tenemos para usted.",
                        "hmn": "### Koj tsis muaj ib email lossis qhov text message uas muaj koj card setup link.\n\nNyem rau qhov **Ntshav koj lub link txheej tshuaj rau kuv tshuab** tom tso npe rau thiab sau koj email los sis phone number uas peb muaj rau koj. Koj yuav muab txog rau ib qhov page uas pom koj link, yog tias peb muaj ib qhov tshuab txheej tshuaj rau koj."
                    },
                    "collapsible": true
                },
                {
                    "kind": "LoginWidget",
                    "page": "my-card-info",
                    "buttonText": {
                        "en": "Get my card setup link",
                        "es": "Obtener el enlace para configurar mi tarjeta",
                        "hmn": "Thov kom kuv lub ntaub ntawv lub link txheej tshuaj rau kuv."
                    },
                    "version": 2
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# Using My Card",
                        "es": "## Uso de mi tarjeta",
                        "hmn": "# Siv Kuv Lub Ntaub Ntawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Where can I use my debit card?\n\nYou can use your debit card to make purchases online, over the phone, and at any store that accepts debit cards using virtual/mobile wallets (also referred to as tap to pay, cardless, or contactless payment).\n\nYou can get cash back with no fees at merchants that allow it (including Walmart, some CVS, and some grocery stores - check with individual stores about their policies).\n\nIn the AkimboNow portal you also have the option to request an ACH be sent to a bank account, request a check be mailed to you or someone else, and/or to request a physical debit card be mailed to you if you have a mailing address. ",
                        "es": "### ¿Dónde puedo usar mi tarjeta de débito?\n\nPuede usar su tarjeta de débito para hacer compras en línea, por teléfono y en cualquier tienda que acepte tarjetas de débito usando billeteras virtuales/móviles (también conocidas como pagos con toque, sin tarjeta o sin contacto).\n\nPuede obtener un reembolso en efectivo sin cargos en los comerciantes que lo permiten (incluyendo Walmart, algunos CVS y algunas tiendas de comestibles; consulte con las tiendas individuales sobre sus políticas).\n\nTambién puede retirar efectivo en cajeros automáticos que tienen tecnología sin tarjeta/sin contacto o haciendo un retiro en mostrador en algunos bancos, aunque generalmente habrá una tarifa (consulte _¿Hay tarifas asociadas con el uso de la tarjeta de débito?_ a continuación para obtener más detalles).",
                        "hmn": "### Koj tuaj yuav siv koj debit card qhov tsim nyog?\n\nKoj tuaj yuav siv koj debit card rau kev pib online, ntawm lub tsev hais tawv, thiab rau txhua tshuab uas muaj ntau tus kheej nyob rau hauv virtual/mobile wallets (tseem ceeb rau hauv tap to pay, cardless, los sis contactless payment).\n\nKoj yuav tau ntev rau tsev nyob rau hauv txhua tus kheej uas muaj ib qhov kev pab pawg (xws li Walmart, ib tug cov CVS, thiab ib tug cov kuaj hmoob - saib los saib xyuas ntau tshuaj rau cov tsev uas xws li lawv yuav muaj cov neeg ntsuam xyuas ntawv txog lawv cov kev pab pawg).\n\nNyob rau hauv AkimboNow portal koj ceev xwm muaj chaw kom txog ntaub ntawv ACH xws li tau mus rau lub sijhawm bank account, tau mus rau ib tug check tau ntau rau koj los sis nrog ib tug ntaub ntawv debit mus tau mus rau koj yog koj muaj ib tug npej tus tau ntawv. "
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Are there fees or withdrawal limits associated with using the debit card?\n\nThere is no fee when you use the card for purchases - other fees vary. Please refer to your card agreement. ",
                        "es": "### ¿Existen tarifas o límites de retiro asociados con el uso de la tarjeta de débito?\n\nNo se cobra ningún cargo cuando utiliza la tarjeta para compras; otros cargos varían. Consulte el acuerdo de su tarjeta.",
                        "hmn": "### Yog cov txoj cai los sis cov ntaub ntawv yuav siv txoj kev siv yog tsis muaj pov thawj los?\n\nTsis muaj ib pov thawj los ntawm koj siv cov ntaub ntawv rau kev pib - txoj cai los muaj lwm haum cov ntaub ntawv yuav siv. Thov kom koj cov card agreement rau kev thov txoj cai."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I find my card number, expiration date, and CVC? \n\n(1) Click on \"View your card details.\" \n\n![Akimbo Now get card number Step 1](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_1_to_get_card_number.jpg)\n\n(2) Copy the information that appears on the screen.\n\n![Akimbo Now get card number Step 2](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_2_to_get_card_number.jpg)",
                        "es": "### ¿Cómo encuentro el número de mi tarjeta, la fecha de vencimiento y el CVC?\n\n1. Haga clic en \"View your card details\".\n\n![Akimbo Ahora obtenga el número de tarjeta Paso 1](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_1_to_get_card_number.jpg)\n\n2. Copie la información que aparece en la pantalla.\n\n![Akimbo Ahora obtenga el número de tarjeta Paso 2](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_2_to_get_card_number.jpg)",
                        "hmn": "### Koj pab saib koj ntaub ntawv tshwj xeeb, expiration date, thiab CVC xws li nws yuav siv\n\n(1) Nqa rau \"Saib koj ntaub ntawv tshwj xeeb.\"\n\n![Akimbo Now tshwm sim card number Sib 1](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_1_to_get_card_number.jpg)\n\n(2) Sib tau cov ntaub ntawv uas muaj rau lub sijhawm ntawm lub tsev nrov. \n\n![Akimbo Now tshwm sim card number Sib 2](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/step_2_to_get_card_number.jpg)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I use the funds with a virtual/mobile wallet?\n\n[Click here](https://akimbo.zendesk.com/hc/en-us/articles/360048843013-Adding-your-card-to-Apple-Pay-Google-Pay-Samsung-Pay-and-other-mobile-wallets) (opens in a new window) for instructions on how to use your debit card with Apple Pay, Google Pay, and Samsung Pay on your mobile phone.",
                        "es": "## ¿Cómo uso los fondos con una billetera virtual/móvil?\n\n[Haga clic aquí](https://akimbo.zendesk.com/hc/en-us/articles/360048843013-Adding-your-card-to-Apple-Pay-Google-Pay-Samsung-Pay-and-other-mobile-wallets) (abre en una ventana nueva) para obtener instrucciones sobre cómo usar su tarjeta de débito con Apple Pay, Google Pay y Samsung Pay en su teléfono móvil.",
                        "hmn": "### Koj pab txhij tsim lub txoj cai rau ntaub ntawv nrog ib tug virtual/mobile wallet?\n\n[Klik rau ntawm no](https://akimbo.zendesk.com/hc/en-us/articles/360048843013-Adding-your-card-to-Apple-Pay-Google-Pay-Samsung-Pay-and-other-mobile-wallets) (nyob hauv ib lub nplooj) rau ntau cov kev pab pawg ntawm kev txiav txim koj debit card rau Apple Pay, Google Pay, thiab Samsung Pay rau koj lub nplooj."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window).\n\n**Important note**: When using your virtual card for online transactions or making purchases over the phone, the merchant will often validate your billing address (usually the ZIP code). Please make sure that you have the correct address information listed on your card account.",
                        "es": "## ¿Cómo uso los fondos para compras en línea y por teléfono?\n\nPuede usar su tarjeta de débito como usaría una tarjeta de crédito para compras en línea o por teléfono. Por lo general, deberá proporcionar el número de tarjeta, la fecha de vencimiento y el número de CV (código de seguridad de 3 dígitos), al que puede acceder iniciando sesión en [su cuenta](https://app.akimbocard.com/app/#/nowincentive/login) (abre en una ventana nueva).\n\n**Nota importante**: cuando utilice su tarjeta virtual para transacciones en línea o realice compras por teléfono, el comerciante a menudo validará su dirección de facturación (generalmente el código postal). Asegúrese de tener la información de la dirección correcta en la cuenta de su tarjeta.",
                        "hmn": "### Koj pab txhij tsim cov ntaub ntawv rau kev pib online thiab ntawm tus xaiv qhov txiaj ntsig?\n\nKoj yuav tsum siv koj debit card xws li koj yuav siv ib ntaub ntawv lub credit rau kev pib online los sis ntawm tus xaiv qhov txiaj ntsig. Koj yuav tsum tuaj siv ib qhov lus txoj cai, daim ntawv tshwm sim, thiab lub CV number (3-digit security code), uas koj yuav tau sau ntawv los ntawm [koj lub npej tus](https://app.akimbocard.com/app/#/nowincentive/login) (nyob hauv lub nplooj). \n\n**Kev koomtes note**: Yog koj tuaj siv koj virtual card rau kev pib online los sis siv rau kev pib ntawm tus xaiv qhov txiaj ntsig, tus huab cua yuav qhia koj lub npej tus kev txiav txim siab (qhov txiaj ntsig tseem ceeb). Thov sau ntawv txoj kev txiav txim siab rau peb thaum koj muaj kev txiav txim siab ntawm koj ntaub ntawv lub npej tus."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Can I add money to my Akimbo Now card? \n\nNot directly. There is no way to add funds to the card. \n\nHowever, when you add the card to your mobile wallet you can add additional funds via Apple Pay, Google Pay, or Samsung Pay. ",
                        "es": "### ¿Puedo agregar dinero a mi tarjeta Akimbo Now?\n\nNo directamente. No hay manera de agregar fondos a la tarjeta.\n\nSin embargo, cuando agrega la tarjeta a su billetera móvil, puede agregar fondos adicionales a través de Apple Pay, Google Pay o Samsung Pay. ",
                        "hmn": "### Kuv tuaj yuav nqa lub tshuab rau kuv Akimbo Now card?\n\nTsis muaj ib qhov tshwm sim tom qab. Tsis muaj lwm cov ntsiab lus los nqa tshuab rau lub ntaub ntawv. Tab sis, thaum koj nqa lub ntaub ntawv rau koj mobile wallet, koj tuaj yuav nqa tshuab them yuav tsum nqa tshuab tshwj xeeb nrog Apple Pay, Google Pay, los sis Samsung Pay."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Managing My Card",
                        "es": "## Administrar mi tarjeta",
                        "hmn": "## Ntxig Kev Tshwm Sim Koj Ntaub Ntawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I access my Akimbo Now account?\n\nYou can log in with your email and password at \nhttps://app.akimbocard.com/app/#/nowincentive/login (opens in a new window).",
                        "es": "### ¿Cómo accedo a mi cuenta de Akimbo Now?\n\nPuedes iniciar sesión con tu correo electrónico y contraseña en\nhttps://app.akimbocard.com/app/#/nowincentive/login (se abre en una ventana nueva).",
                        "hmn": "### Koj pab saib koj lub npej tus Akimbo Now?\n\nKoj tuaj saib koj npej tus thiab password ntawm koj los ntawm https://app.akimbocard.com/app/#/nowincentive/login (nyob hauv lub nplooj)."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How can I check the balance of my card?\n\nYou can check the balance by logging into [your Akimbo Now account](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (opens in a new window) with your email and password, or by calling the automated phone service at <a href=\"tel:8559254626\">1-855-925-4626</a>.",
                        "es": "### ¿Cómo puedo consultar el saldo de mi tarjeta?\n\nPuede consultar el saldo iniciando sesión en [su cuenta de Akimbo Now](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (se abre en una ventana nueva) con su correo electrónico y contraseña, o llamando al servicio telefónico automatizado al <a href=\"tel:8559254626\">1-855-925- 4626</a>.",
                        "hmn": "### Koj yuav tsum saib cov tsim nyog ntawm kuv lub ntaub ntawv thiab cov npej tus?\n\nKoj tuaj yuav saib cov tsim nyog los ntawm saib [koj npej tus Akimbo Now lub ntaub ntawv](\nhttps://app.akimbocard.com/app/#/nowincentive/login) (nyob hauv lub nplooj) nrog koj npej tus thiab password, los sis tseem ceeb txog koj lub tshuab rau lub sijhawm ntawm <a href=\"tel:8559254626\">1-855-925-4626</a>."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I change my PIN? \n\nLog in to your Akimbo Now account and click on \"CHANGE PIN\".\n\n![Akimbo Now change pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nYou can also change your PIN by calling <a href=\"tel:8554492273\">1-855-449-2273</a> Monday through Friday between the hours of 7am and 7pm CT.",
                        "es": "### ¿Cómo cambio mi PIN?\n\nInicie sesión en su cuenta de Akimbo Now y haga clic en \"CHANGE PIN\".\n\n![Akimbo Now cambia pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nTambién puede cambiar su PIN llamando al <a href=\"tel:8554492273\">1-855-449-2273</a> de lunes a viernes entre las 7 am y las 7 pm CT.",
                        "hmn": "### Koj yuav pab ntxiv koj lub ntaub ntawv nyob ze hais txog?\n\nNqa rau koj Akimbo Now lub ntaub ntawv thiab klik rau \"TXIV LUB NTAUB NTAWV NROG TAU TXHAIS TXIM\".\n\n![Akimbo Now txiv lub ntaub ntawv](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nKoj ceev xwm tuaj yuav txiv koj lub ntaub ntawv nyob ze hais txog los siv hu rau <a href=\"tel:8554492273\">1-855-449-2273</a> hais txog Monday mus rau Friday ntawm lub hli ntuj ntawm cov hli 7am thiab 7pm CT."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Will my funds expire?\n\nAfter three years of inactivity you may need to take action to protect your funds. See the card agreement for more information. You can access your card agreement by logging in to your Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)",
                        "es": "### ¿Caducarán mis fondos?\n\nDespués de tres años de inactividad, es posible que deba tomar medidas para proteger sus fondos. Consulte el acuerdo de la tarjeta para obtener más información. Puede acceder a su contrato de tarjeta iniciando sesión en su cuenta Akimbo Now.\n\n![Ubicación del acuerdo de la tarjeta Akimbo Now](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)",
                        "hmn": "### Kuv ntaub ntawv yuav txhawj txim mus?\n\nThaum lub tsev yeej yuav 3 xyoo tseg mus txawj kev los ntawm koj, koj tuaj yuav xav tau ntxiv txog kev txim mus rau koj ntaub ntawv. Thov kom koj lub card agreement rau kev txhawj txim. Koj yuav tau ntsib koj lub card agreement los ntawm saib rau koj lub npej tus Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### What should I do if my card is lost or stolen?\n\nAidKit works with a company called USIO to provide virtual cards. USIO is the card issuer that provides the Akimbo Now card. If your card is lost/stolen, call USIO customer support at <a href=\"tel:8559254626\">1-855-925-4626</a> to cancel the card.\n\nYou will need to provide them with your name, phone number, and the card number (if available) so they can process a replacement. ",
                        "es": "### ¿Qué debo hacer si pierdo o me roban mi tarjeta?\n\nAidKit trabaja con una empresa llamada USIO para proporcionar tarjetas virtuales. USIO es el emisor de la tarjeta que proporciona la tarjeta Akimbo Now. Si pierde o le roban su tarjeta, llame al servicio de atención al cliente de USIO al <a href=\"tel:8559254626\">1-855-925-4626</a> para cancelar la tarjeta.\n\nDeberá proporcionarles su nombre, número de teléfono y el número de tarjeta (si está disponible) para que puedan procesar un reemplazo. ",
                        "hmn": "### Koj yuav ua rau txhua yam koj ntaub ntawv tau txais los ntawm cov txheej txheem lawm?\n\nAidKit muab kev txhawb rau ib qhov ntaub ntawv uas tsim los ntawm lub zog USIO. USIO yog ib qhov tshuab ntawv txheej txheem uas tsim los ntawm lub ntaub ntawv Akimbo Now. Yog koj ntaub ntawv tau txais los ntawm, hu rau USIO customer support ntawm <a href=\"tel:8559254626\">1-855-925-4626</a> rau txim nkoj lub ntaub ntawv.\n\nKoj yuav tsum tuaj lawm lawv ntawm koj lub npej tus, lub phone number, thiab lub ntaub ntawv tshwj xeeb (mas yuav tsum tau) kom lawv tau process ib qhov ntaub ntawv replacement."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How can I keep my money safe?\n\n- Keep the PIN code saved somewhere safe (you will need the PIN for purchases)\n- Report loss or theft right away by calling customer support for USIO, the card issuer,  at <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Don’t share your card number with others",
                        "es": "### ¿Cómo puedo mantener mi dinero seguro?\n\n- Guarde el código PIN en un lugar seguro (necesitará el PIN para las compras)\n- Informe la pérdida o el robo de inmediato llamando al servicio de atención al cliente de USIO, el emisor de la tarjeta, al <a href=\"tel:8559254626\">1-855-925-4626</a>\n- No comparta su número de tarjeta con otros",
                        "hmn": "### Koj yuav paub tseeb xwb txoj kev txiav txim siab koj ntaub ntawv?\n\n- Xws li hloov koj lub tshuab kev pab pawg thaum tseeb.\n- Tuaj lawm loss los sis theft tom ntej nrog USIO customer support, cov txheej txheem ntawm lub tshuab ntawv, rau <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Tsis muaj share koj lub ntaub ntawv kev pab pawg nrog lwm cov neeg lwm yam."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Is my card insured? \n\nNo. Akimbo Now Cards are not FDIC or NCUA insured. ",
                        "es": "### ¿Está asegurada mi tarjeta?\n\nNo. Las tarjetas Akimbo Now no están aseguradas por la FDIC ni por la NCUA. ",
                        "hmn": "### Koj ntaub ntawv yog lub tshuab ntawv xeeb?\n\nTsis. Akimbo Now Cards tsis muaj FDIC los sis NCUA tshuab ntawv xeeb."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Other questions",
                        "es": "## Otras preguntas",
                        "hmn": "## Lwm yam qhov ntsiab lus them kom koj pab thov."
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Questions or need support?\n\nContact $support_email or $support_phone",
                        "es": "### ¿Tiene preguntas o necesita ayuda?\n\nEnvíe un correo electrónico a $support_email o llame al $support_phone",
                        "hmn": "### Cov lus nug los sis xav kom cov lus\n\nSaib $support_email los ntawm $support_phone."
                    },
                    "collapsible": true
                }
            ],
            "conditionalOn": {
                "field": "what_payment_method_did_you_pick",
                "kind": "Equals",
                "value": "usio_virtual"
            } as FieldEquals
        } as Section, {
            "kind": "Section",
            "title": {
                "en": "Your card link",
                "es": "Enlace de su tarjeta",
                "hmn": "Koj lub ntaub ntawv lub tshuab link"
            },
            "components": [
                {
                    "kind": "Conditional Block",
                    "components": [
                        {
                            "kind": "Explanation",
                            "content": {
                                "en": `### We found your link!\n\nClick the following link to collect your virtual debit card: $card_id_setup_link (opens in a new window)\n\nFollow the instructions under _How do I get my virtual debit card?_ on the [FAQ page](https://${config.program}.aidkit.org/p/virtual-card-faq#how-do-i-get-my-virtual-debit-card) (opens in a new window). \n\nDon't forget to **activate** your card after you have created an account.`,
                                "es": `#### ¡Encontramos su enlace!\n\nHaga clic en el siguiente enlace para configurar una cuenta de Akimbo Now y activar su tarjeta de débito virtual: $card_id_setup_link (se abre en una nueva ventana)\n\nSiga las instrucciones en _¿Cómo obtengo mi tarjeta de débito virtual?_ en la [página de preguntas frecuentes](https://${config.program}.aidkit.org/p/virtual-card-faq#how-do-i-get-my-virtual-debit-card) (se abre en una ventana nueva).\n\nNo olvide **activar** su tarjeta después de haber creado una cuenta.`,
                                "hmn": `### Peb tseem yuav pom koj lub link!\n\nNqa rau lub link ntsiag tos los ntaub ntawv koj virtual debit card: $card_id_setup_link (nyob hauv lub nplooj tshiab)\n\nCaw koj cov kev pab pawg xws li _Koj tsis muaj xav koj virtual debit card?_ rau saib hauv [FAQ page](https://${config.program}.aidkit.org/p/virtual-card-faq#how-do-i-get-my-virtual-debit-card) (nyob hauv lub nplooj tshiab).\n\nTsis muaj xav mus **txhij** koj ntaub ntawv thaum koj yuav loj tau ib lub npej tus.`
                            }
                        },
                        {
                            "kind": "Explanation",
                            "content": {
                                "en": "##### If you already signed up for an account using the above link:\n\nVisit the [Akimbo Card page](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window) and log in with your email and password to check your balance.\n\nIf you have recently activated the card, it can take up to 24 hours for your payment to be loaded to the card.\n\nMake sure you have clicked on the **Activate Card** button to complete the card collection process. Your incentive payment will not be loaded onto the card until you activate it.\n\n![Akimbo Now activate card](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)",
                                "es": "##### Si ya se registró para obtener una cuenta usando el enlace anterior:\n\nVisite la [página de la tarjeta Akimbo](https://app.akimbocard.com/app/#/nowincentive/login) (se abre en una ventana nueva) e inicie sesión con su correo electrónico y contraseña para consultar su saldo.\n\nSi activó la tarjeta recientemente, su pago puede tardar hasta 24 horas en cargarse en la tarjeta.\n\nAsegúrese de haber hecho clic en el botón **Activar tarjeta** para completar el proceso de recolección de la tarjeta. Su pago de incentivo no se cargará en la tarjeta hasta que la active.\n\n![Activar tarjeta Akimbo Now](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)",
                                "hmn": "##### Yog koj tau hais tias koj tswv yim ua rau ib lub npej tus siv qhov link no:\n\nSaib [Akimbo Card page](https://app.akimbocard.com/app/#/nowincentive/login) (nyob hauv lub nplooj tshiab) thiab log in nrog koj email thiab password rau saib koj tsim nyog.\n\nYog koj tseem ceeb qhov ntaub ntawv, nws tuaj yuav tsum tsav tsheb txog 24 teev rau koj kev pab.\n\nThaum koj tau txhij rau cov ntaub ntawv, thov kom koj yuav nyob tau rau **Txhij Ntaub Ntawv** tab tom ntej rau lub npej tus ntaub ntawv. Koj kev pab yuav tsis muaj txoj cai tuaj rau koj ntaub ntawv txhij rau koj tawm. \n\n![Akimbo Now txhij ntaub ntawv](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/activate_card.jpg)"
                            },
                            "collapsible": false
                        }
                    ],
                    "otherwise": [
                        {
                            "kind": "Conditional Block",
                            "components": [
                                {
                                    "kind": "Explanation",
                                    "content": {
                                        "en": "#### Sorry, we can't find a card associated with that phone number or email address.\n\nPlease contact us for help at $support_email or $support_phone. ",
                                        "es": "#### Lo sentimos, no podemos encontrar una tarjeta asociada con ese número de teléfono o dirección de correo electrónico.\n\nPuede comunicarse con nosotros para obtener ayuda en  $support_email or $support_phone. ",
                                        "hmn": "#### Sib ntsib, peb tsis thov ncaj ncees ib ntaub ntawv nrog lub phone number lossis email address no.\n\nThov sau ntawv rau peb rau kev pab ntawm $support_email los sis $support_phone."
                                    }
                                }
                            ],
                            "otherwise": [],
                            "conditionalOn": {
                                "kind": "And",
                                "clauses": [
                                    {
                                        "field": "applicant_payment_type",
                                        "kind": "Equals",
                                        "value": "usio_virtual"
                                    },
                                    {
                                        "field": "card_id_setup_link",
                                        "kind": "DoesntExist"
                                    }
                                ]
                            }
                        }
                    ],
                    "conditionalOn": {
                        "kind": "And",
                        "clauses": [
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "usio_virtual"
                            },
                            {
                                "field": "card_id_setup_link",
                                "kind": "Exists"
                            }
                        ]
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": `#### Return to the Virtual Card FAQ page\n\nhttps://${config.program}.aidkit.org/p/payment_faqs\n(opens in a new window)`,
                        "es": `#### Volver a la página de preguntas frecuentes sobre la tarjeta virtual\n\nhttps://${config.program}.aidkit.org/p/payment_faqs\n(se abre en una ventana nueva)`,
                        "hmn": `#### Quaj nrhiav rau ib qho virtual card FAQ page\n\nhttps://${config.program}.aidkit.org/p/payment_faqs\n(nyob hauv lub nplooj tshiab)`
                    }
                }
            ],
            "hidden": true,
            "conditionalOn": {
                "field": "what_payment_method_did_you_pick",
                "kind": "Equals",
                "value": "usio_virtual"
            }
        } as Section] : [],
        ...(config.debit.kind === 'Usio' && config.debit.mail.enabled) ? [{
            "kind": "Section",
            "title": {
                "en": "Pre-paid Debit Card by Mail",
                "es": "Tarjeta de Débito Prepago por Correo",
                "hmn": "Pre-paid Debit Card los ntawm Lub Sijhawm"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# Pre-paid Debit Card by Mail",
                        "es": "# Tarjeta de Débito Prepago por Correo",
                        "hmn": "# Pre-paid Debit Card los ntawm Lub Sijhawm"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## When will my card arrive? \n\nCards take between 2-3 weeks to arrive. If you changed your mailing address, please let us know as soon as possible. ",
                        "es": "## ¿Cuándo llegará mi tarjeta?\n\nLas tarjetas tardan entre 2 y 3 semanas en llegar. Si cambió su dirección postal, infórmenos lo antes posible.",
                        "hmn": "## Yog tias peb lub ntaub ntawv yuav muaj pom?\n\nLub ntaub ntawv yuav pom ntawd yog mus saib li 2-3 hli. Yog koj tsim hloov tawm lub ntaub ntawv ntawm koj lub sijhawm, thov sau ntawv rau peb tom ntej ntau lawm."
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Using my card",
                        "es": "## Uso de mi tarjeta",
                        "hmn": "## Siv koj lub ntaub ntawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Are there fees or withdrawal limits associated with using the debit card?\n\nThere is no fee when you use the card for purchases - other fees vary. Please refer to your card agreement. ",
                        "es": "### ¿Existen tarifas o límites de retiro asociados con el uso de la tarjeta de débito?\n\nNo se cobra ningún cargo cuando utiliza la tarjeta para compras; otros cargos varían. Consulte el acuerdo de su tarjeta.",
                        "hmn": "### Yog sib koj lub ntaub ntawv, yog muaj feem los sis feem txiav txim siab?\n\nTsis muaj feem thaum koj sib koj ntaub ntawv rau kev txiav txim siab - feem lwm qhov no zoo li cas. Thov kom sau ntawv rau koj lub card agreement."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I use the funds for online and phone purchases?\n\nYou can use your debit card like you would use a credit card for online or phone purchases. You will typically need to provide the card number, expiration date, and CV number (3-digit security code), which you can access by logging into [your account](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window).\n\n**Important note**: When using your card for online transactions or making purchases over the phone, the merchant will often validate your billing address (usually the ZIP code). Please make sure that you have the correct address information listed on your card account.",
                        "es": "### ¿Cómo uso los fondos para compras en línea y por teléfono?\n\nPuede usar su tarjeta de débito como usaría una tarjeta de crédito para compras en línea o por teléfono. Por lo general, deberá proporcionar el número de tarjeta, la fecha de vencimiento y el número de CV (código de seguridad de 3 dígitos), al que puede acceder iniciando sesión en [su cuenta](https://app.akimbocard.com/app/#/nowincentive/login) (abre en una ventana nueva).\n\n**Nota importante**: cuando utilice su tarjeta para transacciones en línea o realice compras por teléfono, el comerciante a menudo validará su dirección de facturación (generalmente el código postal). Asegúrese de tener la información de la dirección correcta en la cuenta de su tarjeta.",
                        "hmn": "### Koj yuav pab txhij tsim cov ntaub ntawv rau kev txiav txim siab ntawm kev pib online thiab ntawm kev pib ntawm tus xaiv qhov txiaj ntsig?\n\nKoj tuaj yuav pab koj lub ntaub ntawv xws li koj yuav pab ib lub ntaub ntawv kev txiav txim siab ntawm kev pib online los sis kev pib ntawm tus xaiv qhov txiaj ntsig. Koj yuav tsum tuaj siv qhov lus txoj cai, daim ntawv tshwm sim, thiab lub CV number (3-digit security code), uas koj yuav tau access rau ntawm [koj lub account](https://app.akimbocard.com/app/#/nowincentive/login) (nyob hauv lub nplooj). \n\n**Kev koomtes note**: Yog koj siv koj lub ntaub ntawv rau kev txiav txim siab ntawm kev pib online los sis kev pib ntawm tus xaiv qhov txiaj ntsig, tus huab cua yuav qhia koj lub npej tus kev txiav txim siab (qhov txiaj ntsig tseem ceeb). Thov sau ntawv txoj kev txiav txim siab ntawm koj ntaub ntawv lub npej tus uas tias ua hauj lwm lub npej tus kev txiav txim siab."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Can I add money to my Akimbo Now card? \n\nNot directly. There is no way to add funds to the card. However, when you add the card to your mobile wallet you can add additional funds via Apple Pay, Google Pay, or Samsung Pay. ",
                        "es": "### ¿Puedo agregar dinero a mi tarjeta Akimbo Now?\n\nNo directamente. No hay manera de agregar fondos a la tarjeta. Sin embargo, cuando agrega la tarjeta a su billetera móvil, puede agregar fondos adicionales a través de Apple Pay, Google Pay o Samsung Pay. ",
                        "hmn": "### Kuv yuav nqa lub tshuab rau kuv lub Akimbo Now card?\n\nTsis muaj tawm kom. Tsis muaj ib qhov tshuab los nqa tshuab rau lub ntaub ntawv. Tab sis, thaum koj nqa lub ntaub ntawv rau koj mobile wallet, koj tuaj yuav nqa tshuab them yuav tsum nqa tshuab ntxhais rau Apple Pay, Google Pay, los sis Samsung Pay."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Managing my card",
                        "es": "## Administrar mi tarjeta",
                        "hmn": "## Txhawb koj ntaub ntawv"
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I access my Akimbo Now account?\n\nYou can log in with your email and password at \nhttps://app.akimbocard.com/app/#/nowincentive/login (opens in a new window).",
                        "es": "### ¿Cómo accedo a mi cuenta de Akimbo Now?\n\nPuedes iniciar sesión con tu correo electrónico y contraseña en\nhttps://app.akimbocard.com/app/#/nowincentive/login (se abre en una ventana nueva).",
                        "hmn": "### Koj pab txhawb txim koj Akimbo Now lub ntaub ntawv?\n\nKoj tuaj saib txim nrog koj email thiab password ntawm \nhttps://app.akimbocard.com/app/#/nowincentive/login (nyob hauv lub nplooj tshiab)."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How can I check the balance of my card?\n\nYou can check the balance by logging into [your Akimbo Now account](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (opens in a new window) with your email and password, or by calling the automated phone service at <a href=\"tel:8559254626\">1-855-925-4626</a>.",
                        "es": "### ¿Cómo puedo consultar el saldo de mi tarjeta?\n\nPuede consultar el saldo iniciando sesión en [su cuenta de Akimbo Now](\nhttps://app.akimbocard.com/app/#/nowincentive/login)  (se abre en una ventana nueva) con su correo electrónico y contraseña, o llamando al servicio telefónico automatizado al <a href=\"tel:8559254626\">1-855-925- 4626</a>.",
                        "hmn": "### Koj yuav paub cov txheej txheem ntawm kuv lub ntaub ntawv ces kuj pom lawv?\n\nKoj yuav paub cov txheej txheem ntawm txoj cai log in ntawm [koj lub account Akimbo Now](https://app.akimbocard.com/app/#/nowincentive/login) (nyob hauv lub nplooj tshiab) nrog koj email thiab password, los sis hu rau tus automated phone service ntawm <a href=\"tel:8559254626\">1-855-925-4626</a>."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How do I change my PIN? \n\nLog in to your Akimbo Now account and click on \"CHANGE PIN\".\n\n![Akimbo Now change pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nYou can also change your PIN by calling <a href=\"tel:8554492273\">1-855-449-2273</a> Monday through Friday between the hours of 7am and 7pm CT.",
                        "es": "### ¿Cómo cambio mi PIN?\n\nInicie sesión en su cuenta de Akimbo Now y haga clic en \"CHANGE PIN\".\n\n![Akimbo Now cambia pin](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nTambién puede cambiar su PIN llamando al <a href=\"tel:8554492273\">1-855-449-2273</a> de lunes a viernes entre las 7 am y las 7 pm CT.",
                        "hmn": "### Koj thov xav hloov koj lub ntaub ntawv?\n\nHloov txim rau koj Akimbo Now lub ntaub ntawv thiab nco txog \"CHANGE PIN\".\n\n![Akimbo Now hloov ntaub ntawv](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/change_pin.jpg)\n\nKoj tuaj hloov koj lub ntaub ntawv los ntawm hu rau <a href=\"tel:8554492273\">1-855-449-2273</a> hnov hauv lub Monday txog lub Friday ntawm cov hli ntuj ntawm 7am thiab 7pm CT."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Will my funds expire?\n\nAfter three years of inactivity you may need to take action to protect your funds. See the card agreement for more information. You can access your card agreement by logging in to your Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)",
                        "es": "### ¿Caducarán mis fondos?\n\nDespués de tres años de inactividad, es posible que deba tomar medidas para proteger sus fondos. Consulte el acuerdo de la tarjeta para obtener más información. Puede acceder a su contrato de tarjeta iniciando sesión en su cuenta Akimbo Now.\n\n![Ubicación del acuerdo de la tarjeta Akimbo Now](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)",
                        "hmn": "### Kuv ntaub ntawv yuav txhawj txim mus?\n\nThaum lub tsev yeej yuav 3 xyoo tseg mus txawj kev los ntawm koj, koj tuaj yuav xav tau ntxiv txog kev txim mus rau koj ntaub ntawv. Thov kom koj lub card agreement rau kev txhawj txim. Koj yuav tau ntsib koj lub card agreement los ntawm saib rau koj lub npej tus Akimbo Now Account.\n\n![Akimbo Now card agreement location](https://aidkit-public.s3.us-east-2.amazonaws.com/chicagorcp/card_agreement.jpg)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### What should I do if my card is lost or stolen?\n\nAidKit works with a company called USIO to provide virtual cards. USIO is the card issuer that provides the Akimbo Now card. If your card is lost/stolen, call USIO customer support at <a href=\"tel:8559254626\">1-855-925-4626</a> to cancel the card.\n\nYou will need to provide them with your name, phone number, and the card number (if available) so they can process a replacement. ",
                        "es": "### ¿Qué debo hacer si pierdo o me roban mi tarjeta?\n\nAidKit trabaja con una empresa llamada USIO para proporcionar tarjetas virtuales. USIO es el emisor de la tarjeta que proporciona la tarjeta Akimbo Now. Si pierde o le roban su tarjeta, llame al servicio de atención al cliente de USIO al <a href=\"tel:8559254626\">1-855-925-4626</a> para cancelar la tarjeta.\n\nDeberá proporcionarles su nombre, número de teléfono y el número de tarjeta (si está disponible) para que puedan procesar un reemplazo. ",
                        "hmn": "### Koj yuav ua rau txhua yam koj ntaub ntawv tau txais los ntawm cov txheej txheem lawm?\n\nAidKit muab kev txhawb rau ib qhov ntaub ntawv uas tsim los ntawm lub zog USIO. USIO yog ib qhov tshuab ntawv txheej txheem uas tsim los ntawm lub ntaub ntawv Akimbo Now. Yog koj ntaub ntawv tau txais los ntawm, hu rau USIO customer support ntawm <a href=\"tel:8559254626\">1-855-925-4626</a> rau txim nkoj lub ntaub ntawv.\n\nKoj yuav tsum tuaj lawm lawv ntawm koj lub npej tus, lub phone number, thiab lub ntaub ntawv tshwj xeeb (mas yuav tsum tau) kom lawv tau process ib qhov ntaub ntawv replacement."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### How can I keep my money safe?\n\n- Keep the PIN code saved somewhere safe (you will need the PIN for purchases)\n- Report loss or theft right away by calling customer support for USIO, the card issuer,  at <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Don’t share your card number with others",
                        "es": "### ¿Cómo puedo mantener mi dinero seguro?\n\n- Guarde el código PIN en un lugar seguro (necesitará el PIN para las compras)\n- Informe la pérdida o el robo de inmediato llamando al servicio de atención al cliente de USIO, el emisor de la tarjeta, al <a href=\"tel:8559254626\">1-855-925-4626</a>\n- No comparta su número de tarjeta con otros",
                        "hmn": "### Koj yuav paub tseeb xwb txoj kev txiav txim siab koj ntaub ntawv?\n\n- Xws li hloov koj lub tshuab kev pab pawg thaum tseeb.\n- Tuaj lawm loss los sis theft tom ntej nrog USIO customer support, cov txheej txheem ntawm lub tshuab ntawv, rau <a href=\"tel:8559254626\">1-855-925-4626</a>\n- Tsis muaj share koj lub ntaub ntawv kev pab pawg nrog lwm cov neeg lwm yam."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Is my card insured? \n\nNo. Akimbo Now Cards are not FDIC or NCUA insured. ",
                        "es": "### ¿Está asegurada mi tarjeta?\n\nNo. Las tarjetas Akimbo Now no están aseguradas por la FDIC ni por la NCUA. ",
                        "hmn": "### Koj ntaub ntawv yog lub tshuab ntawv xeeb?\n\nTsis. Akimbo Now Cards tsis muaj FDIC los sis NCUA tshuab ntawv xeeb."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "## Other questions",
                        "es": "## Otras preguntas",
                        "hmn": "## Lwm yam qhov ntsiab lus them kom koj pab thov."
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "### Questions or need support?\n\nContact $support_email or $support_phone",
                        "es": "### ¿Tiene preguntas o necesita ayuda?\n\nEnvíe un correo electrónico a $support_email o llame al $support_phone",
                        "hmn": "### Cov lus nug los sis xav kom cov lus\n\nSaib $support_email los ntawm $support_phone."
                    },
                    "collapsible": true
                }
            ],
            "conditionalOn": {
                "field": "what_payment_method_did_you_pick",
                "kind": "Equals",
                "value": "usio_mail"
            } as FieldEquals
        } as Section] : [],
        ...(config.debit.kind == 'GiveCard') ? [{
            "kind": "Section",
            "title": {
                "en": "GiveCard Information Sheet"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# GiveCard Information Sheet\n\n#### What is a GiveCard?\nGiveCard is a Mastercard prepaid debit card that works like any other debit card. GiveCards are reloadable by your program as well."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### General Card Information\n- You are able to use your GiveCard immediately, with no setup required. \n- GiveCards are already activated. \n- GiveCards can be used anywhere that accepts Mastercard debit cards. This means you can either use it physically in stores, or use it online (more details on this are listed below.)\n- We recommend keeping the paper your card comes attached to - this has some information on it that could be useful for identifying your card in the event that it gets lost or stolen.\n- If you believe that your card has been lost or stolen, please contact GiveCard Support immediately - more details on this are in the ‘lost/stolen’ section below.\n- You must set a PIN before you use the card at the ATM.\n- You will have access to a network of 80,000 fee-free ATMS. You can view a map of these ATMS at this link: https://www.moneypass.com/atm-locator.html\n- When using your card at a cash register, sometimes the machine will ask you for a PIN. If you have not set a PIN for your card, press the green ‘OK’ button on the machine to continue and you will be able to skip the PIN entry.\n\nIf the payment system you are using does not allow prepaid debit cards, here are alternative payment options which you can connect your GiveCard to:\n- Paypal\n- Venmo\n- Moneygram/Western Union money order (in-person)\n- Withdraw cash from ATMs (if applicable)"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Checking Balance\nGo to [givecard.io/support](https://www.givecardplatform.com/cardholders) to access the GiveCard cardholder site. The website that you see printed on the back of your card will also direct you here. On this site, you can:\n- Check your balance\n- Set your optional card PIN number\n- Find ATMs that are in your area \n- View the spending history of your card by creating an account and logging in"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Support\nIf you are experiencing an issue related to your program, please get in contact with BRF team. \n\nIf you are experiencing issues with your card, please contact GiveCard support by: \n- emailing support@givecard.io, or\n- calling 908-529-0955.\n- Our support team hours are 7am- 4pm PST. We will pick up your call or return your message immediately during those hours.\n- Outside of 7am- 4pm PST, our support team will get back to you as soon as they can, however this may not always be immediate."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Lost or Stolen Cards\nIf you believe your card is lost or stolen, contact GiveCard Support by calling 908-529-0955 immediately and lock your card through the [cardholder portal](https://www.givecardplatform.com/cardholders). The GiveCard team will freeze your card to protect whatever funds were on it (if not done already), and then will work with your program manager to reissue you a new card. In the event that your card has been reported as stolen and you notice unauthorized transactions have occurred on it, GiveCard Support will work with you to file a transaction dispute report."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Card Limits\nTransaction Limits:\n- Maximum per transaction: $5,000\n- Maximum per day: $5,000\n- Maximum per month: $5,000\n\nATM Restrictions:\n- Maximum per transaction: $1,000\n- Maximum per day: $1,000\n- Maximum per month: $2,500"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Program billing information (if needed for online purchases)\nBelow you will find the billing information for your program. This information should go in the billing information section when you make an online purchase:\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AidKit  \n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;383 Corona Street, Unit #814  \n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Denver, CO 80218"
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Other Restrictions\nMastercard imposes certain restrictions on prepaid cards. The following merchant category code restrictions may apply: \n- 6211\tSecurity Brokers/Dealers\n- 7273\tDating/Escort Services\n- 7297\tMassage Parlors\n- 7995\tBetting/ Casino Gambling\n- 7800\tGovernment Owned Lotteries\n- 7801\tGovernment Licensed Online Casinos (Online Gambling)\n- 7802\tGovernment Licensed Horse/Dog Racing\n\nPlease remember that this program is designed to provide you with no-strings-attached financial support, so you can always take money out at the ATM and use it for whatever you decide to spend your money on. As a reminder, you must set a PIN before you use the card at the ATM."
                    },
                    "collapsible": true
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Program Contact\nPlease contact the $program_name team in case of any non card-related issues, including questions surrounding when cards will be loaded next. Please reach out at: $support_email or $support_phone."
                    },
                    "collapsible": true
                }
            ],
            "conditionalOn": {
                "kind": "Or",
                "clauses": [
                    {
                        "field": "what_payment_method_did_you_pick",
                        "kind": "Equals",
                        "value": "givecard_mail"
                    },
                    {
                        "field": "what_payment_method_did_you_pick",
                        "kind": "Equals",
                        "value": "givecard_load"
                    }
                ]
            } as Or
        } as Section] : [],
    ],
    "anonymous": true
});

const generateGiveCardMailSubsurvey = (config: PaymentModule): Subsurvey => ({
    "kind": "Subsurvey",
    "path": "gc-mail",
    "sections": [
        {
            "kind": "Section",
            "title": {
                "en": "Mailed Debit Card Association",
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "In order to receive payments to your card, please input the 10 character ID on the back of your card beneath \"AidKit\", and then the last 4 digits of the card number.",
                    }
                },
                {
                    "kind": "Card Management",
                    "targetField": "",
                    "cardType": "GiveCard"
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": `All set! \n\nHave questions about the payment? Visit the Payments FAQ page: https://${config.program}.aidkit.org/p/payments-faq`,
                    }
                }
            ]
        }
    ]
})

const generateGiveCardInfoSubsurvey = (config: PaymentModule): Subsurvey => ({
    "kind": "Subsurvey",
    "path": "gc-info",
    "sections": [
        {
            "kind": "Section",
            "title": {
                "en": "GiveCard Information Sheet",
                "es": "Información sobre tarjetas GiveCard"
            },
            "components": [
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "# GiveCard Information Sheet",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### What is a GiveCard?\nGiveCard is a Mastercard prepaid debit card that works like any other debit card. GiveCards are reloadable by your program as well.",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### General Card Information\n- You are able to use your GiveCard immediately, with no setup required. \n- GiveCards are already activated. \n- GiveCards can be used anywhere that accepts Mastercard debit cards. This means you can either use it physically in stores, or use it online (more details on this are listed below.)\n- We recommend keeping the paper your card comes attached to - this has some information on it that could be useful for identifying your card in the event that it gets lost or stolen.\n- If you believe that your card has been lost or stolen, please contact GiveCard Support immediately - more details on this are in the ‘lost/stolen’ section below.\n- You must set a PIN before you use the card at the ATM.\n- You will have access to a network of 80,000 fee-free ATMS. You can view a map of these ATMS at this link: https://www.moneypass.com/atm-locator.html\n- When using your card at a cash register, sometimes the machine will ask you for a PIN. If you have not set a PIN for your card, press the green ‘OK’ button on the machine to continue and you will be able to skip the PIN entry.\n\nIf the payment system you are using does not allow prepaid debit cards, here are alternative payment options which you can connect your GiveCard to:\n- Paypal\n- Venmo\n- Moneygram/Western Union money order (in-person)\n- Withdraw cash from ATMs (if applicable)",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Checking Balance\nGo to [givecard.io/support](https://www.givecardplatform.com/cardholders) to access the GiveCard cardholder site. The website that you see printed on the back of your card will also direct you here. On this site, you can:\n- Check your balance\n- Set your optional card PIN number\n- Find ATMs that are in your area \n- View the spending history of your card by creating an account and logging in",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Support\nIf you are experiencing an issue related to your program, please get in contact with BRF team. \n\nIf you are experiencing issues with your card, please contact GiveCard support by: \n- emailing support@givecard.io, or\n- calling 908-529-0955.\n- Our support team hours are 7am- 4pm PST. We will pick up your call or return your message immediately during those hours.\n- Outside of 7am- 4pm PST, our support team will get back to you as soon as they can, however this may not always be immediate.",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Lost or Stolen Cards\nIf you believe your card is lost or stolen, contact GiveCard Support by calling 908-529-0955 immediately and lock your card through the [cardholder portal](https://www.givecardplatform.com/cardholders). The GiveCard team will freeze your card to protect whatever funds were on it (if not done already), and then will work with your program manager to reissue you a new card. In the event that your card has been reported as stolen and you notice unauthorized transactions have occurred on it, GiveCard Support will work with you to file a transaction dispute report.",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Card Limits\nTransaction Limits:\n- Maximum per transaction: $5,000\n- Maximum per day: $5,000\n- Maximum per month: $5,000\n\nATM Restrictions:\n- Maximum per transaction: $1,000\n- Maximum per day: $1,000\n- Maximum per month: $2,500",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Program billing information (if needed for online purchases)\nBelow you will find the billing information for your program. This information should go in the billing information section when you make an online purchase:\n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AidKit  \n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;383 Corona Street, Unit #814  \n&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Denver, CO 80218",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Other Restrictions\nMastercard imposes certain restrictions on prepaid cards. The following merchant category code restrictions may apply: \n- 6211\tSecurity Brokers/Dealers\n- 7273\tDating/Escort Services\n- 7297\tMassage Parlors\n- 7995\tBetting/ Casino Gambling\n- 7800\tGovernment Owned Lotteries\n- 7801\tGovernment Licensed Online Casinos (Online Gambling)\n- 7802\tGovernment Licensed Horse/Dog Racing\n\nPlease remember that this program is designed to provide you with no-strings-attached financial support, so you can always take money out at the ATM and use it for whatever you decide to spend your money on. As a reminder, you must set a PIN before you use the card at the ATM.",
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Program Contact\nPlease contact the $program_name team in case of any non card-related issues, including questions surrounding when cards will be loaded next. Please reach out at: $program_email or $program_phone.",
                    }
                }
            ]
        }
    ],
    "anonymous": true,
    "accessConditional": {
        "kind": "And",
        "clauses": [
            {
                "field": "applicant_payment_type",
                "kind": "Equals",
                "value": "givecard_mail"
            }
        ]
    }
});

const generateMyCardInfoSubsurvey = (config: PaymentModule): Subsurvey => ({
    "kind": "Subsurvey",
    "path": "my-card-info",
    "sections": [
        {
            "kind": "Section",
            "title": {
                "en": "Your card link",
                "es": "Enlace de su tarjeta",
            },
            "components": [
                {
                    "kind": "Conditional Block",
                    "components": [
                        {
                            "kind": "Explanation",
                            "content": {
                                "en": "### We found your link!\n\nClick the following link to collect your virtual debit card: $card_id_setup_link (opens in a new window)\n\nDon't forget to **activate** your card after you have created an account.",
                            }
                        },
                        {
                            "kind": "Explanation",
                            "content": {
                                "en": "##### If you already signed up for an account using the above link:\n\nVisit the [Akimbo Card page](https://app.akimbocard.com/app/#/nowincentive/login) (opens in a new window) and log in with your email and password to check your balance.\n\nIf you have recently activated the card, it can take up to 24 hours for your payment to be loaded to the card.\n\nMake sure you have clicked on the **Activate Card** button to complete the card collection process. Your incentive payment will not be loaded onto the card until you activate it.",
                            },
                            "collapsible": false
                        }
                    ],
                    "otherwise": [
                        {
                            "kind": "Conditional Block",
                            "components": [
                                {
                                    "kind": "Explanation",
                                    "content": {
                                        "en": "#### Sorry, we can't find a card associated with that phone number or email address.\n\nPlease contact us for help at $support_email.",
                                    }
                                }
                            ],
                            "otherwise": [],
                            "conditionalOn": {
                                "kind": "And",
                                "clauses": [
                                    {
                                        "field": "applicant_payment_type",
                                        "kind": "Equals",
                                        "value": "usio_virtual"
                                    },
                                    {
                                        "field": "card_id_setup_link",
                                        "kind": "DoesntExist"
                                    }
                                ]
                            }
                        }
                    ],
                    "conditionalOn": {
                        "kind": "And",
                        "clauses": [
                            {
                                "field": "applicant_payment_type",
                                "kind": "Equals",
                                "value": "usio_virtual"
                            },
                            {
                                "field": "card_id_setup_link",
                                "kind": "Exists"
                            }
                        ]
                    }
                },
                {
                    "kind": "Explanation",
                    "content": {
                        "en": "#### Return to the Virtual Card FAQ page\n\n[Insert Payment FAQs URL]\n(opens in a new window)",
                    }
                }
            ]
        }
    ]
});