import React, { useContext, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";
import { getApplicantLink, languageContent, safeParse } from "../Util";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { get_deployment, useAPIPost } from "../API";
import { useEffect } from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

function Flag(props: QuestionProps) {
  const context = useContext(InterfaceContext);

  const related =
      (safeParse(props.info[props["Target Field"]!] || "{}") as any)[
        "related"
      ] || [];


  async function save(value: string) {
    await props.setInfoKey(
      props["Target Field"]! + "_comment",
      value,
      true,
      false
    );
  }

  if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) return <></>;

  if (related.length) {
    return <div className={"survey-question relative pb-8 overflow-clip"} 
      data-name={props['Question']}>
        {props.lastQuestion !== true && <span className="absolute top-10 left-8 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />}
        <div className="relative flex space-x-4">
          <div className={'pt-1.5'}>
            <span className={'h-6 w-6 rounded-full flex items-center justify-center ring-4 ring-white bg-white '}>
                <QuestionMarkCircleIcon className={"h-7 w-7 text-red-200"} />
            </span>
          </div>
          <div className={'min-w-0 flex-1 pt-1.5 flex justify-between space-x-4'}>
            <div>
              <Alert variant={(props["Additional Options"] || []).indexOf("Advisory") === -1 ? "danger" : "primary"}>
                {props[languageContent(context.lang)]}
                {(props["Additional Options"] || []).indexOf("Advisory") === -1 &&
                <>
                  <br />
                  <br />
                  <Form.Control
                    value={props.info[props["Target Field"]! + "_comment"] || ""}
                    onChange={(e) => save(e.target.value)}/>
                </>}
              </Alert>
            </div>
          </div>
      </div>
    </div>
  }
  return <></>;
}

function FlagReview(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const L = useLocalizedStrings();
  const thisDeployment = get_deployment();

  const related =
    (safeParse(props.info[props["Target Field"]!] || "{}") as any)[
      "related"
    ] || [];

  const comment = props.info[props["Target Field"]! + "_comment"] || "";

  const last = (a: any[]) => a.length === 1 ? a[0] : a[1];

  const toLookup = related.filter(
    (deploymentUid: string) => deploymentUid.split(':')[0] === thisDeployment
  ).map((deploymentUid: string) => last(deploymentUid.split(':'))).join(',');

  const [names, setNames] = useState({} as Record<string, string>);
  const sendResourcePage = useAPIPost("/applicants/get_names");

  const [prevLookup, setPrevLookup] = useState(null as any);
  const settingNames = useRef(false);

  useEffect(() => {
    let cancel = false;
    if (!toLookup || toLookup.length < 1) return;
  
    if (prevLookup === toLookup) return;
    setPrevLookup(toLookup);

    (async () => {
      if (cancel || settingNames.current) return;

      settingNames.current = true;
      const result = await sendResourcePage({ uids: toLookup.split(',' )});
      settingNames.current = false;
      if (result && !cancel) setNames(result);
    })();

    return () => { cancel = true; }
  }, [toLookup, sendResourcePage]);

  if (related.length) {
    return (
      <Alert variant={(props["Additional Options"] || []).indexOf("Advisory") === -1 ? "danger" : "primary"}>
        {props[languageContent(context.lang)]}
        <br />
        <br />
        <ol>
          {related &&
            related.map((deploymentUid: string, i: number) => {
              let [deployment, uid] = deploymentUid.split(':');
              if(!deploymentUid.includes(':')){
                deployment = thisDeployment;
                uid = deploymentUid;
              }

              return (
                <li key={'related-' + deployment + ":" + uid}>
                  {getApplicantLink(deployment, thisDeployment, uid, names && names[uid] ? names[uid] : `${L.questions.flags.applicant_number}${i + 1}`)}
                </li>
              );

            })}
        </ol>
        <br />
        {comment && <>{L.questions.flags.the_screener_said} {comment}</>}
      </Alert>
    );
  }
  return <></>;
}

export { Flag, FlagReview };
