import { useEffect, useState } from "react";
import { useLocalizedStrings } from "../Localization";

export function DateInput(props: { key: string, label: string, setValue: (value: string) => void }) { 

    const L = useLocalizedStrings();
    const [month, setMonth] = useState("--" as string);
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");

    const dayValid = ['01','03','05','07','08','10','12'].includes(month) ? 
    !!day.match(/^(0[1-9]|[12]\d|3[01])$/) :  // Days 01-31 for months with 31 days
    month === '02' ? 
    parseInt(year || '0') % 4 === 0 ? 
    !!day.match(/^(0[1-9]|1\d|2[0-9])$/) :  // Days 01-29 for February in a leap year
    !!day.match(/^(0[1-9]|1\d|2[0-8])$/) :  // Days 01-28 for February in a non-leap year
    !!day.match(/^(0[1-9]|[12]\d|30)$/);  // Days 01-30 for months with 30 days

    const yearValid = !!year.match(/^(19|20|99)[0-9][0-9]$/);

    const allValid = dayValid && yearValid && month !== '--';
    const key = props.key;
    
    useEffect(() => {
        if (allValid) {
            props.setValue(month + "/" + day + "/" + year);
        } else {
            props.setValue("");
        }
    }, [allValid, day, month, year]);

    return <fieldset>
    <legend>
      <b>{props.label}<br /></b>
    </legend>
    <label htmlFor={"month_" + props.key}>{L.months.month}:&nbsp;</label>
    <select
        id={"month_" + props.key}
        name={"date_" + props.key}
        className={"appearance-none mt-1 mr-2 shadow-sm inline-block w-36 pl-3 bg-white pr-10 py-2 text-black text-base border-2 border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" +
            (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
                                    " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}
        value={month}
        onChange={(e) => setMonth(e.target.value)}
    >
        <option value="--" disabled selected hidden>{L.months.month}</option>
        <option value="01">{L.months.jan}</option>
        <option value="02">{L.months.feb}</option>
        <option value="03">{L.months.mar}</option>
        <option value="04">{L.months.apr}</option>
        <option value="05">{L.months.may}</option>
        <option value="06">{L.months.jun}</option>
        <option value="07">{L.months.jul}</option>
        <option value="08">{L.months.aug}</option>
        <option value="09">{L.months.sep}</option>
        <option value="10">{L.months.oct}</option>
        <option value="11">{L.months.nov}</option>
        <option value="12">{L.months.dec}</option>
    </select>
  <label htmlFor={"day_" + key}>{L.admin.invoices.day}:&nbsp;</label>
    <input
        id={"day_" + key}
        className={"max-w-lg mr-2 inline-block ml-2 w-12 shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
            (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
                                    " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}
        placeholder="DD"
        pattern="([0-2]\d|3[0-1])"
        value={day}
        onChange={(e) => 
          setDay(e.target.value.slice(0, 2))
        }
        onBlur={(e) => {
          if (e.target.value.length === 1) {
            setDay('0' + e.target.value);
          }
        }}
    />
    <span className="whitespace-nowrap">
      <label htmlFor={"year_" + key}>{L.admin.invoices.year}:&nbsp;</label>&nbsp;<input
          id={"year_" + key}
          pattern="[1-2][0-9]{3}"
          className={"max-w-lg inline-block w-16 shadow-sm border-solid p-2 ml-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" + 
              (allValid ? " border-2 border-green-200 focus:ring-green-400 ring-green-400 focus:border-green-400" : 
                                      " border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400")}
          placeholder="YYYY"
          value={year}
          onChange={(e) => setYear(e.target.value.slice(0, 4))}
      />
    </span>
  </fieldset>
}