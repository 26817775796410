
import { Translations } from "@aidkitorg/i18n/lib";
import { PublicConfig } from "@aidkitorg/types/lib/config";
import * as survey from "@aidkitorg/types/lib/survey";
import { ArrowDownOnSquareIcon, ArrowPathIcon, ArrowsPointingOutIcon, CircleStackIcon } from "@heroicons/react/24/outline";
import { TimedQueryResult } from "aidkit/lib/common/handler";
import { createContext, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { usePost } from "./API";
import { Dropdown } from "./Components/Dropdown";
import { ThreeColumnPage } from "./Components/ThreeColumnPage";
import InterfaceContext, { PublicConfigurationContext } from "./Context";
import { BarChart } from "./DistroDashboard/BarChart";
import { DataDictionary } from "./DistroDashboard/DataDictionary";
import { DistroDashboardTable } from "./DistroDashboard/DistroDashboardTable";
import { DashboardExplanationComponent } from "./DistroDashboard/ExplanationComponent";
import { FullScreenModal } from "./DistroDashboard/FullScreenModal";
import { HeroNumber } from "./DistroDashboard/HeroNumber";
import { PieChart } from "./DistroDashboard/PieChart";
import { useLocalizedStrings, useLocalizer } from "./Localization";
import { copyToClipboard, RigidSpinner, SpacedSpinner } from "./Util";
import { MapRegionDensity } from "./DistroDashboard/MapRegionDensity";
import Queue from "./DistroDashboard/Queue";


export type TableComponentProps = {
    component: survey.ApplicantTable | survey.Table,
    downloadQualifier?: string,
    sqlButton: ReactNode,
    refreshButton: ReactNode,
    results?: any,
    L: Translations,
    dashboard: string | survey.Dashboard,
    localizedTitle?: string,
    linksToSection?: string | survey.SubsurveyRef,
    queryExecutionTimeMs?: number,
    filterConfig: Record<string, number>
};

export type DashboardComponentProps = {
    section: survey.DashboardSection['components'][number] & {
        sql?: string, results?: any, groupKeys?: any,
        summaryKey?: string, error?: string, dashboardRef?: string, stackKeys?: any, stack?: boolean
    },
    updateFilterConfig: (path: string, index: number) => void,
    downloadQualifier?: string,
    publicAccessKey?: string,
    activeFilter?: survey.BooleanExpr,
    isExploreQuery?: boolean,
    linksToSection?: string | survey.SubsurveyRef,
    filterConfig: Record<string, number>,
    dashboard: string | survey.Dashboard
};

function getCurrentUTCSuffix() {
    const currentDate = new Date();
    const year = currentDate.getUTCFullYear();
    const month = ("0" + (currentDate.getUTCMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getUTCDate()).slice(-2);
    const hours = ("0" + currentDate.getUTCHours()).slice(-2);
    const minutes = ("0" + currentDate.getUTCMinutes()).slice(-2);
    const seconds = ("0" + currentDate.getUTCSeconds()).slice(-2);
    const formattedDate = `${year}_${month}_${day}_${hours}_${minutes}_${seconds}Z`;
    return formattedDate;
}

export function TableComponent(props: TableComponentProps) {
    const [taskId, setTaskId] = useState<string | null>(null);
    const [downloadResponse, setDownloadResponse] = useState<{ status?: string; url?: string; error?: string; } | null>(null);
    const [downloadInProgress, setDownloadInProgress] = useState(false);

    const fetchAsyncDownload = usePost('/dashboard/fetch_async_download');
    const downloadCSV = usePost('/dashboard/download', { textOnly: true });
    const { component, results, sqlButton, refreshButton, L, localizedTitle } = props;

    const downloadAtPath = async (downloadPath: string, downloadName: string, asyncDownload?: boolean): Promise<string | null> => {
        if (asyncDownload) {
            const taskResponse = JSON.parse((await downloadCSV({ dashboard: props.dashboard, filterIndexes: props.filterConfig, downloadPath, asyncDownload: true })) as string);
            return taskResponse.taskId;
        } else {
            const data = await downloadCSV({ dashboard: props.dashboard, filterIndexes: props.filterConfig, downloadPath });
            const blob = new Blob([data as any], { type: 'text/csv' });
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = downloadName;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
            return null;
        }
    };

    const handleDownloadClick = async (asyncDownload: boolean) => {
        setDownloadInProgress(true);
        try {
            const downloadTaskId = await downloadAtPath(
                component.download!.downloadPath || '',
                component.download!.filename + (props.downloadQualifier || '') + '_' + getCurrentUTCSuffix() + '.csv',
                asyncDownload
            );
            if (downloadTaskId) { // we only get a task id if it's an async download
                setTaskId(downloadTaskId);
            } else {
                setDownloadInProgress(false);
            }
        } catch (error) {
            toast.error(error + '');
            setDownloadInProgress(false);
        }
    };

    const pollForDownload = useCallback(async () => {
        if (taskId) {
            const readyResponse = await fetchAsyncDownload({ taskId });
            if (readyResponse.url) {
                setDownloadResponse(readyResponse);
                setTaskId(null); // Stop polling
                setDownloadInProgress(false);
            } else if (readyResponse.status === 'FAILED') {
                setTaskId(null); // Stop polling
                setDownloadInProgress(false);
                throw new Error('async_download_failed');
            } else if (readyResponse.error) {
                setTaskId(null); // Stop polling
                setDownloadInProgress(false);
            }
        }
    }, [taskId, fetchAsyncDownload]);

    useEffect(() => {
        if (taskId) {
            const intervalId = setInterval(pollForDownload, 1000);
            return () => clearInterval(intervalId);
        }
    }, [taskId, pollForDownload]);

    useEffect(() => {
        if (downloadResponse?.url) {
            const link = document.createElement('a');
            link.href = downloadResponse.url!;
            link.setAttribute('download', component.download!.filename + (props.downloadQualifier || '') + '_' + getCurrentUTCSuffix() + '.csv'); // Set the filename for the download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [downloadResponse]);

    if (!results && !component.download?.downloadOnly) {
        return <div>{localizedTitle}{L.support.loading}</div>;
    }

    return <div className="mt-4 clear-both border-gray-300 border-solid border-l-4 border-r-0 border-y-0 px-4">
        {/* The actual table */}
        {!component.download?.downloadOnly && (
            <DistroDashboardTable records={results}
                component={component}
                extra={<>{sqlButton}{refreshButton}</>}
                title={localizedTitle || undefined}
                count={results.length}
                linksToSection={props.linksToSection}
                queryExecutionTimeMs={props.queryExecutionTimeMs}
                />
        )}
        {/* The download button card */}
        {component.download !== undefined && (
            <div className="my-4 mr-4 py-2 px-3 text-center bg-white overflow-hidden shadow rounded-xl w-fit">
                {component.download?.downloadOnly && (
                    <span className="font-semibold flex justify-left items-end">
                        {sqlButton}
                        <span className="truncate text-gray-600 ml-2">{localizedTitle}</span>
                    </span>
                )}
                <div className="clear-both">
                    <div className="bg-white inline-block p-1 rounded-xl">{component.download.filename + '.csv'}
                        {downloadInProgress
                            ? <RigidSpinner className="mx-2" />
                            : <button className="cursor-pointer ml-2 text-gray-500 hover:text-blue-500 bg-slate-50 hover:bg-blue-200 border-solid border-2 border-blue-200 hover:border-blue-400 rounded-xl p-2"
                                onClick={() => handleDownloadClick(component.download?.asyncDownload ? true : false)}>
                                {L.dashboard.download}
                                <span className="ml-2">
                                    <ArrowDownOnSquareIcon width="20" height="20" className="inline"/>
                                </span>
                            </button>
                        }
                    </div>
                </div>
            </div>
        )}
    </div>
}

export function DashboardComponent(props: DashboardComponentProps) {
    const context = useContext(InterfaceContext);
    const { activeComponent } = useContext(ActiveComponentContext);
    const [selectedIdx, setSelectedIdx] = useState(props.section.kind === 'Dashboard Section' ? props.section.activeFilter || 0 : 0);
    const L = useLocalizedStrings();
    const localize = useLocalizer();
    const [componentIsLoading, setComponentIsLoading] = useState(false);
    const publicConfig = useContext(PublicConfigurationContext);
    const loadDashboard = usePost('/dashboard/load');
    const [results, setResults] = useState<TimedQueryResult | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);

    function loadComponent(skipCache?: boolean) {
        // Don't try to load anything if we don't have the config or the experiment is off or the component doesn't have a token
        if (!publicConfig?.experimental?.instantLoadDashboards || !props.section?.dashboardRef) {
            return;
        }

        setComponentIsLoading(true);
        (async () => {
            const results = (await loadDashboard({ dashboardRef: props.section.dashboardRef, publicAccessKey: props.publicAccessKey, skipCache })) as TimedQueryResult;
            setComponentIsLoading(false);
            setResults(results);
        })();
    }

    useEffect(() => {
        loadComponent();
    }, [props.section.dashboardRef, publicConfig]);

    let sqlButton = <></>;
    if (props.section.sql) {
        sqlButton = <CircleStackIcon className="w-6 h-6 text-gray-400 hover:text-gray-500 cursor-pointer inline" onClick={() => {
            copyToClipboard(props.section.sql!, 'Copied SQL to clipboard');
        }} />
    }

    // /explore has its own intuitive way to refresh results (click "Run" button), so reduce
    // redundancy/complexity by not showing refresh button
    const refreshButton = props.isExploreQuery
        ? <></>
        : <ArrowPathIcon className={`w-6 h-6 mr-2 ml-[7px] text-gray-400 hover:text-gray-500 cursor-pointer inline ${componentIsLoading ? 'animate-spin' : ''}`} onClick={() => {
            loadComponent(true);
        }}/>

    const localizedTitle = localize(
        ('title' in props.section && props.section.title)
            ? props.section.title
            : (('visualization' in props.section && props.section.visualization.title)
                ? props.section.visualization.title
                : '')) || '';

    const queryExecutionTimeMs = results?.executionTimeMs
        ? <span className="text-gray-400 text-xs">{results.executionTimeMs}ms</span>
        : <></>;

    function DashboardSimpleCard(content: string) {
        // Set some reasonable heights/widths for the placeholder cards to minimize
        // screen jumping as components load.
        let height: string;
        let width = '';
        switch (props.section.kind) {
            case 'Bar Chart':
            case 'Pie Chart':
            case 'Map Region Density':
                height = (props.section.height ?? 300) + 132 + 'px';
                width = (props.section.width ?? 300) + 48 + 'px';
                break;
            case 'Applicant Table':
            case 'Queue':
                height = '150px';
                width = '100%';
                break;
            case 'Hero Count':
            case 'Hero Number':
                height = '150px';
                break;
            case 'Custom Query':
                switch (props.section.visualization.kind) {
                    case 'Custom Bar Chart':
                    case 'Custom Pie Chart':
                        height = (props.section.visualization.height ?? 300) + 132 + 'px';
                        width = (props.section.visualization.width ?? 300) + 48 + 'px';
                        break;
                    case 'Table':
                        height = '150px';
                        width = '100%';
                        break;
                    case 'Custom Number':
                        height = '150px';
                        break;
                }
                break;
            default:
                height = '150px';
                width = '150px';
        }

        const kind = props.section.kind === 'Custom Query'
            ? props.section.visualization.kind
            : props.section.kind;
        return (
            <div className="my-4 mr-4 float-left text-center bg-white overflow-hidden shadow rounded-lg relative" style={{height, width}}>
                <div className="px-4 py-4 sm:p-6">
                    <dl>
                        <dt className="font-semibold text-gray-600 truncate">
                            <span>
                                <span className="text-gray-400 mr-[7px]">({kind})</span>
                                <ComponentHeader
                                    sqlButton={sqlButton}
                                    refreshButton={refreshButton}
                                    localizedTitle={localizedTitle}
                                    queryExecutionTimeMs={queryExecutionTimeMs}
                                />
                            </span>
                        </dt>
                        <dd className="mt-1 font-medium text-gray-400">
                            {content}
                        </dd>
                    </dl>
                </div>
            </div>
        );
    }

    // It doesn't make sense to expect there to be results if this is an explore query, or a download-only table.
    const useNewLoadStrategy = !props.isExploreQuery && !!publicConfig?.experimental?.instantLoadDashboards;

    // If there was an error with this component's SQL execution, surface it in a card and don't worry about what kind of component it is
    if (results?.error || props.section?.error) {
        return DashboardSimpleCard((results?.error ? results.error : props.section.error) || 'error');
    }

    // DashboardComponents are recursive in the case of Dashboard Sections,
    // so we only want to display the "loading" card for non-sections.
    // Data Dictionaries don't require any immediate querying and so have no results.
    // Tables and Custom Queries are also not permitted on public dashboards,
    // so we bypass this to remove those components entirely in the switch(kind) below.
    if (useNewLoadStrategy
            && !['Dashboard Section', 'Data Dictionary', 'Dashboard Explanation'].includes(props.section.kind)
            && !(props.section.kind === 'Applicant Table' && (props.publicAccessKey || props.section.download?.downloadOnly))
            && !(props.section.kind === 'Custom Query' && (props.publicAccessKey || (props.section.visualization.kind === 'Table' && props.section.visualization.download?.downloadOnly)))
            && (!results || componentIsLoading)) {
        return DashboardSimpleCard(L.support.loading);
    }

    /**
     * null is returned if the element should not be shown, for example, on public dashboards
     */
    function InnerComponent(): JSX.Element | null {
        switch (props.section.kind) {
            case 'Dashboard Section':
                let suffix: string | undefined;
                let activeFilter: survey.BooleanExpr | undefined;
                if (props.section.filters?.length > 0) {
                    suffix = '_' + props.section.filters[selectedIdx].name.replace(' ', '_').toLowerCase();
                    props.section.filters[selectedIdx].filter;
                }

                return <div className="mt-4 clear-both border-gray-300 border-solid border-l-4 border-r-0 border-y-0 px-4 relative flow-root">
                    <h2>{localizedTitle}</h2>
                    {props.section.filters?.length > 0 && (
                        <div className="block">
                            {/* <Dropdown> uses inline-block for its outermost element, which might be necessary for other
                              * use-cases, but can cause the dropdown to end up in odd places in Dashboards. Hence the surrounding <div>
                              */ }
                            <Dropdown
                                description="Filter"
                                direction="right"
                                label={<>{props.section.filters[selectedIdx].name}</>}
                                options={
                                    props.section.filters.map((f: { name: any; }, i: number) => {
                                        return {
                                            label: f.name,
                                            callback: () => {
                                                if (i !== selectedIdx) {
                                                    setSelectedIdx(i);
                                                    props.updateFilterConfig((props.section as survey.DashboardSection).filterPath!, i)
                                                }
                                            }
                                        }
                                    })}
                            />
                        </div>
                        )}
                    {/* Display semi-opaque overlay while component is loading after changing filter */}
                    {(activeComponent === (props.section.filterPath ?? '') + selectedIdx) &&
                        <div className="absolute -inset-2 bg-gray-500 opacity-25 flex justify-center z-20">
                            <div className="pt-5 text-white text-6xl"><SpacedSpinner style={{ width: "3rem", height: "3rem" }} />{' ' + L.support.loading}</div>
                        </div>
                    }
                    {props.section.components?.map((c: any, i: number) => {
                        return <DashboardComponent
                            key={c.kind + i}
                            section={c}
                            updateFilterConfig={props.updateFilterConfig}
                            downloadQualifier={(props.downloadQualifier || '') + (suffix || '')}
                            publicAccessKey={props.publicAccessKey}
                            activeFilter={activeFilter}
                            filterConfig={props.filterConfig}
                            dashboard={props.dashboard}
                        />
                    })}
                </div>
            case 'Applicant Table':
                if (props.publicAccessKey) {
                    return null;
                }
                return <TableComponent
                    component={props.section}
                    results={useNewLoadStrategy ? results?.rows : props.section.results}
                    downloadQualifier={props.downloadQualifier}
                    sqlButton={sqlButton}
                    refreshButton={refreshButton}
                    L={L}
                    localizedTitle={localizedTitle}
                    linksToSection={props.linksToSection}
                    queryExecutionTimeMs={results?.executionTimeMs}
                    dashboard={props.dashboard}
                    filterConfig={props.filterConfig}
                />;
            case 'Custom Query':
                if (props.publicAccessKey) {
                    return null;
                }
                switch (props.section.visualization.kind) {
                    case 'Table':
                        return <TableComponent
                            component={props.section.visualization}
                            results={useNewLoadStrategy ? results?.rows : props.section.results}
                            downloadQualifier={props.downloadQualifier}
                            sqlButton={sqlButton}
                            refreshButton={refreshButton}
                            L={L}
                            localizedTitle={localizedTitle}
                            linksToSection={props.linksToSection}
                            queryExecutionTimeMs={results?.executionTimeMs}
                            dashboard={props.dashboard}
                            filterConfig={props.filterConfig}
                        />;
                    case 'Custom Number':
                        let valueToUse = props.section.results;
                        if (useNewLoadStrategy) {
                            if (results?.rowCount !== 1) {
                                // TODO: localize
                                return DashboardSimpleCard('Invalid count query. Did not return exactly one row');
                            }
                            const rowKeys = Object.keys(results.rows[0]);
                            if (rowKeys.length !== 1) {
                                // TODO: localize
                                return DashboardSimpleCard('Invalid count query. Did not return exactly one column');
                            }
                            valueToUse = results.rows[0][rowKeys[0]];
                        }
                        return <HeroNumber
                            value={valueToUse}
                            prefix={props.section.visualization.prefix}
                            suffix={props.section.visualization.suffix}
                        />;
                    case 'Custom Bar Chart':
                        return <BarChart
                            data={useNewLoadStrategy ? results?.rows : props.section.results}
                            description={props.section?.visualization?.description?.content?.[context.lang]}
                            descriptionPlacement={props.section.visualization?.description?.placement}
                            groupKeys={props.section.visualization.groupKeys}
                            summaryKey={props.section.visualization.summaryKey}
                            isCustom={true}
                            hideXAxisLabels={props.section.visualization.hideXAxisLabels}
                            height={props.section.visualization.height}
                            width={props.section.visualization.width}
                            stack={props.section.visualization.stack}
                            totalKey={props.section.visualization.totalKey}
                            hideTotal={props.section.visualization.hideTotal}
                            units={props.section.visualization.units}
                            isExpanded={isExpanded}
                        />
                    case 'Custom Pie Chart':
                        return <PieChart
                            description={props.section.visualization?.description?.content?.[context.lang]}
                            descriptionPlacement={props.section.visualization?.description?.placement}
                            data={useNewLoadStrategy ? results?.rows : props.section.results}
                            groupKeys={props.section.visualization.groupKeys}
                            summaryKey={props.section.visualization.summaryKey}
                            totalKey={props.section.visualization.totalKey}
                            hideTotal={props.section.visualization.hideTotal}
                            isCustom={true}
                            height={props.section.visualization.height}
                            width={props.section.visualization.width}
                            pieStyle={props.section.visualization.pieStyle || publicConfig?.interface?.defaultPieChartStyle}
                            isExpanded={isExpanded}
                        />
                }
            case 'Dashboard Explanation':
                return <DashboardExplanationComponent explanation={props.section} />
            case 'Data Dictionary':
                return <DataDictionary component={props.section} />;
            case 'Hero Count':
                return <HeroNumber
                    value={useNewLoadStrategy ? results?.rowCount : props.section.results}
                    valueToCompare={(props.section as any).originalResults}
                />;
            case 'Hero Number':
                return <HeroNumber
                    value={useNewLoadStrategy
                        ? results?.rows && results.rows.length > 0 ? results.rows[0][props.section.summaryKey ?? ''] : ''
                        : props.section.results}
                    prefix={props.section.prefix}
                    suffix={props.section.suffix}
                    useCommas={props.section.useCommas}
                    decimalPlaces={props.section.decimalPlaces}
                />
            case 'Bar Chart':
                let timeSeriesGroupIndex = props.section.groups.findIndex(group => group.kind === 'Bucket Date');
                return <BarChart
                    data={useNewLoadStrategy ? results?.rows : props.section.results}
                    description={props.section.description?.content?.[context.lang]}
                    descriptionPlacement={props.section.description?.placement}
                    groupKeys={props.section.groupKeys}
                    summaryKey={props.section.summaryKey!}
                    stackKeys={props.section.stackKeys}
                    stack={props.section.stack}
                    timeSeriesGroupIndex={timeSeriesGroupIndex}
                    timeSeriesBucket={(props.section.groups[timeSeriesGroupIndex] as any)?.bucket}
                    order={props.section.order}
                    hideTotal={props.section.hideTotal}
                    height={props.section.height}
                    width={props.section.width}
                    units={props.section.units}
                    isExpanded={isExpanded}
                />
            case 'Pie Chart':
                return <PieChart
                    description={props.section?.description?.content?.[context.lang]}
                    descriptionPlacement={props.section.description?.placement}
                    data={useNewLoadStrategy ? results?.rows : props.section.results}
                    groupKeys={props.section.groupKeys}
                    summaryKey={props.section.summaryKey!}
                    height={props.section.height}
                    width={props.section.width}
                    pieStyle={props.section.pieStyle || publicConfig?.interface?.defaultPieChartStyle}
                    isExpanded={isExpanded}
                />
            case 'Queue':
                return <Queue
                    component={props.section}
                    sqlButton={sqlButton}
                    refreshButton={refreshButton}
                    numberInQueue={(useNewLoadStrategy ? results?.rowCount : props.section.numberInQueue) ?? 0}
                    queryExecutionTimeMs={queryExecutionTimeMs}
                    publicAccessKey={props.publicAccessKey}
                    localizedTitle={localizedTitle}
                    activeFilter={props.activeFilter}
                />
            case 'Map Region Density':
                // the query can only return a single row, which will be a FeatureCollection of all the matched boundaries
                const { geojson } = results?.rows?.[0] || {};
                return <MapRegionDensity {...props.section} geoJSON={geojson} />
            default:
                return <p>{`${props.section.kind} ${L.dashboard.not_supported_yet}`}</p>
        }
    }

    const innerDashboardComponent = InnerComponent();

    const kind = props.section.kind === 'Custom Query'
        ? props.section.visualization.kind
        : props.section.kind;
    if (['Dashboard Section', 'Applicant Table', 'Table', 'Queue', 'Dashboard Explanation', 'Data Dictionary'].includes(kind)
            || innerDashboardComponent === null) {
        // Undefined means the element should not be shown, for example, on public dashboards
        return innerDashboardComponent || <></>;
    }
    const expandable = ['Pie Chart', 'Bar Chart', 'Custom Pie Chart', 'Custom Bar Chart', 'Map Region Density'].includes(kind);

    return (
        <div className="my-4 mr-4 py-2 px-3 float-left text-center bg-white overflow-hidden shadow rounded-xl">
            <ComponentHeader
                sqlButton={sqlButton}
                refreshButton={refreshButton}
                localizedTitle={localizedTitle}
                queryExecutionTimeMs={queryExecutionTimeMs}
                expandable={expandable ? { expanded: isExpanded, setIsExpanded } : undefined}
            />
            {innerDashboardComponent}
            <div className="flex justify-end pr-2" title="Query execution time">{queryExecutionTimeMs}</div>
            {expandable && (
                <FullScreenModal open={isExpanded} onClose={() => setIsExpanded(false)}>
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <dl className="w-full">
                            <ComponentHeader
                                sqlButton={sqlButton}
                                refreshButton={refreshButton}
                                localizedTitle={localizedTitle}
                                queryExecutionTimeMs={queryExecutionTimeMs}
                            />
                        </dl>
                        {innerDashboardComponent}
                        <div className="flex justify-end pr-2" title="Query execution time">{queryExecutionTimeMs}</div>
                    </div>
                </FullScreenModal>
            )}
        </div>
    );
}

export function ComponentHeader(props: {
    sqlButton?: ReactNode,
    refreshButton?: ReactNode,
    localizedTitle?: string,
    queryExecutionTimeMs?: ReactNode,
    expandable?: {
        expanded: boolean,
        setIsExpanded: (value: SetStateAction<boolean>) => void
    },
}) {
    return (
        <span className="font-semibold flex justify-between items-end m-2">
            <span>{props.sqlButton}{props.refreshButton}</span>
            <span className="truncate text-gray-600">{props.localizedTitle}</span>
            <span>{props.expandable && <ArrowsPointingOutIcon className="h-6 w-6 text-gray-400 hover:text-gray-500 hover:cursor-pointer" onClick={() => props.expandable!.setIsExpanded(true)} />}</span>
        </span>
    );
}

const ActiveComponentContext = createContext({ activeComponent: null as string | null });

export function DistroDashboard(props: { dashboard?: string, isEmbedded?: boolean }): JSX.Element {
    const params = new URLSearchParams(window.location.search);
    const publicAccessKey = params.get("key") ?? undefined;
    const config: PublicConfig = useContext(PublicConfigurationContext);
    const { path } = useParams() as Record<string, string>
    const runQuery = usePost('/dashboard/view');
    const getDashboard = usePost('/dashboard/getDashboards');
    const L = useLocalizedStrings();
    const localize = useLocalizer();
    const [results, setResults] = useState<survey.Dashboard | null>(null);
    const [filterConfig, setFilterConfig] = useState<Record<string, number>>({});
    const [loadedPath, setLoadedPath] = useState<string | null>(null);
    // This is passed to children to let them know whether to display the "Loading..." overlay
    const [activeComponent, setActiveComponent] = useState<string | null>(null);
    // This is used by the main dashboard to indicate if it is currently executing any queries
    const [isRunning, setIsRunning] = useState<boolean>(false);

    useEffect(() => {
        // Don't try to load anything until the config has been loaded so we know which method to use.
        // Object.keys check because the config can be {} before it is loaded
        if (!config || Object.keys(config).length === 0) {
            return;
        }

        setIsRunning(true);
        (async () => {
            // TODO: this reruns every query on the dashboard anytime the dashboard rerenders.
            // We should find a better way to do this
            const results = (config?.experimental?.instantLoadDashboards)
                ? await getDashboard({ dashboard: props.dashboard ?? path, filterIndexes: filterConfig, publicAccessKey })
                : await runQuery({ dashboard: props.dashboard ?? path, filterIndexes: filterConfig, key: publicAccessKey });

            setResults(results as any);
            setLoadedPath(path);
            setActiveComponent(null);
            setIsRunning(false);
        })();
    }, [filterConfig, path, config]);

    return (props.isEmbedded || publicAccessKey)
        ? <div className="h-full p-4 px-8">
            {results && results.components &&
                <>
                    <div>
                        <h1 className="inline">{localize(results.title)}</h1>
                        {isRunning && <SpacedSpinner className="ml-3 inline" style={{ width: "2rem", height: "2rem" }} />}
                    </div>
                    <ActiveComponentContext.Provider value={{activeComponent}}>
                        {results.components.map((s, i) => {
                            return <DashboardComponent
                                key={s.kind + i}
                                section={s}
                                linksToSection={(results as any).linksToSection}
                                updateFilterConfig={(path: string, idx: number) => {
                                    setActiveComponent(path + idx);
                                    setFilterConfig((prev) => ({...prev, [path]: idx}));
                                }}
                                filterConfig={filterConfig}
                                publicAccessKey={publicAccessKey}
                                dashboard={ path }
                            />
                        })}
                    </ActiveComponentContext.Provider>
                </>}
            </div>
        : <ThreeColumnPage main={
            <div className="bg-gray-100 p-4 px-8 mb-3">
                {path === loadedPath && results && results.components &&
                    <>
                        <div>
                            <h1 className="inline">{localize(results.title)}</h1>
                            {isRunning && <SpacedSpinner className="ml-3 inline" style={{ width: "2rem", height: "2rem" }} />}
                        </div>
                        <ActiveComponentContext.Provider value={{activeComponent}}>
                            {results.components.map((s, i) => {
                                return <DashboardComponent
                                    key={s.kind + i}
                                    section={s}
                                    linksToSection={(results as any).linksToSection}
                                    updateFilterConfig={(path: string, idx: number) => {
                                        setActiveComponent(path + idx);
                                        setFilterConfig((prev) => ({...prev, [path]: idx}));
                                    }}
                                    filterConfig={filterConfig}
                                    dashboard={ path }
                                />
                            })}
                        </ActiveComponentContext.Provider>
                    </>}
                {(!results || path !== loadedPath) && <><SpacedSpinner /> {L.dashboard.loading_message}</>}
            </div>}
        />;
}
