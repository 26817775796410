import { useEffect, useRef, useState } from "react";
import { useToken } from "./API";
import { useInterval } from "./Util";

export const PUBSUB = process.env.NODE_ENV === 'development' 
    ? "ws://localhost:7777"
    : "wss://aklxl0yqhl.execute-api.us-east-2.amazonaws.com/prod";

export type BaseRealtimeEvent<T extends string, D> = {
    realm: string;
    channel: string;
    event: T;
    data: D;
};

export function useChannel<T extends BaseRealtimeEvent<string, any>>(realm: string, name: string, callback: (realtimeEvent: T) => void) {
    let socket = useRef<WebSocket>();

    if (window.location.port === '3000' && realm === 'distro') {
        // shut up distro in dev websockets
        // return (data: any) => { }
    }

    const [,token] = useToken();
    
    const setup = () => {
        
        if (socket.current) {
            socket.current.close();
        }

        if (!name) return;
        
        let s = socket.current = new WebSocket(PUBSUB + "?" + new URLSearchParams({ realm, channel: name, token: (token as string).replace('auth=','') }).toString());
        s.onerror = (event) => {
            console.warn("Error connecting to pubsub", event);
        }
        s.onopen = (event) => {
            console.log("Connected to pubsub", event);
        }
        s.onmessage = (event) => {
            // console.log("Got message:", e)
            try {
                callback(JSON.parse(event.data));
            } catch (error) {
                console.error("Error parsing pubsub message", error, (event as any).data);
                throw new Error("Error in Realtime " + realm + ":" + name)
            }
        }

        s.onclose = (event) => {
            console.log("Pubsub connection closed", event);

            // try to reopen. As long as useChannel has been called, we want to keep the conn open.
            // Code 1000 and 1001 are expected close events (codes <1000 are unused), so no need to try to reopen if those occur.
            if (event.code > 1001 && !document.hidden) {
                setTimeout(() => {
                    if (socket.current?.readyState !== WebSocket.OPEN) setup();
                }, 100);
            }
        }

    }
    const send = (event: T) => {
        if (socket.current?.readyState === WebSocket.OPEN) {
            socket.current?.send(JSON.stringify(event));
        } else if (socket.current?.readyState === WebSocket.CLOSED 
                    || socket.current?.readyState === WebSocket.CLOSING) {
            setup();
            setTimeout(() => send(event), 100);
        } else if (socket.current?.readyState === WebSocket.CONNECTING) {
            setTimeout(() => send(event), 100);
        }
    }

    useEffect(setup, [name, token]);
    return send;
}

export var stringToColour = function(str: string) {
    if (!str) return 'hsl(0%,0%,0%)';
    
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = 'hsl(' + (hash % 360) + ', 60%, 70%)';
    return colour;
}

export const inits = (str: string) => {
    if (str) return str.split('/')[0].split(' ').filter((v: string) => v).map((w: string) => w[0]).join('');

    return '';
}

export function FacePile(props: { name: string, channel: string, unsavedChanges?: () => boolean, browserTab?: string }) {
    const [userList, setUserList] = useState({} as Record<string, { name: string, lastSeen: number, unsavedChanges?: boolean, browserTab?: string }>);
    const [warned, setWarned] = useState(false);

    const sendData = useChannel("facepile", props.channel, (data) => {
        // console.log("Data:", data);
        if (data.event === "heartbeat") {
            setUserList((lastSeen) => ({ ...lastSeen, [data.data.name + '/' + data.data.browserTab]: { lastSeen: Date.now(), ...data.data }}));
        }
    });

    useInterval(() => {
        let unsaved = props.unsavedChanges?.() || false;
        if (props.name && document.visibilityState === "visible") {
            sendData({
                realm: 'facepile',
                channel: props.channel,
                event: 'heartbeat',
                data: {
                    name: props.name,
                    unsavedChanges: unsaved,
                    browserTab: props.browserTab
                }
            }); 
        }
        const ul = userList;
        let changed = false;
        for (const key in ul) {
            if (Date.now() - ul[key]['lastSeen'] > 2000) {
                delete ul[key];
                changed = true;
            }
        }
        if (changed) {
            setUserList({ ...ul });
        }
        const otherPeopleEdits = Object.keys(ul).map((n) => ul[n]).filter(u => u.unsavedChanges && u.name !== props.name).length > 0;
        const otherTabEdits = Object.keys(ul).map((n) => ul[n]).filter(u => u.unsavedChanges && u.name === props.name && u.browserTab !== props.browserTab).length > 0;
        // console.log(otherPeopleEdits, otherTabEdits)
        if (unsaved && !warned && (otherPeopleEdits || otherTabEdits)) {
            if (otherPeopleEdits) {
                alert("Warning! You're making a change when someone else has outstanding edits!");
            }
            if (otherTabEdits) {
                alert("Warning! You're making a change when you have edits in a separate tab!");
            }
            setWarned(true);
        }
        if (!otherPeopleEdits && !otherTabEdits) {
            setWarned(false);
        }
    }, 1000);
    
    return <div className="inline-block">{Object.keys(userList).map((k) => {
        let extraStyle = {}
        let extraTitle = ''
        if (userList[k].unsavedChanges) {
            extraStyle = { border: '2px solid red' };
            extraTitle = ' - Unsaved Changes '
        }
        return <div key={k} title={`${k.split('/')[0]} ${extraTitle}(${userList[k].browserTab})`} className={"inline-block ml-2 p-1 radius-lg text-white rounded-full cursor-pointer "} style={{backgroundColor: stringToColour(k), ...extraStyle}}>{inits(k)}</div>
    })}</div>
}