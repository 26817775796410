import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAPI, useDownloader } from "./API";
import { useLocalizedStrings } from "./Localization";
import Form from "react-bootstrap/Form";

import QueriesPage from "./Queries";
import { ConfigurationContext } from "./Context";
import { safeParse } from "./Util";
import { Dropdown } from "./Components/Dropdown";

type Report = {
    name: string;
    query: string;
    metadata: string;
}

function ReportRow(props: { report: Report }) {

    const L = useLocalizedStrings();
    const report = props.report;
    const metadata = safeParse(report.metadata || '{}');
    const download = useDownloader();

    // Need to hold filter state for each filter on the report.
    const [filters, setFilters] = useState({} as Record<string, string>);
    const [filtersParam, setFiltersParam] = useState("");
    const [dropdownLabel, setDropdownLabel] = useState({} as Record<string, string>);

    async function updateFilter(filterKey: string, newValue: string, label: string) {
        setFilters((prevState: Record<string, string>) => 
            Object.assign({}, { ...prevState, [filterKey]: newValue }));
        setDropdownLabel((prevState: Record<string, string>) => 
            Object.assign({}, { ...prevState, [filterKey]: label }));
    }

    useEffect(() => {
        if (!metadata?.filters) return;
        for (const k in metadata.filters) {
            if (metadata.filters[k] && metadata.filters[k].length) {
                if (!filters[k] && metadata.filters[k][0][0]) {
                    updateFilter(k, metadata.filters[k][0][0], metadata.filters[k][0][1]);
                }
            }
        }

    }, [metadata?.filters, filters, updateFilter])

    useEffect(() => {
        if (Object.keys(filters).length) {
            setFiltersParam("?filters="+encodeURIComponent(JSON.stringify(filters)));
        } else {
            setFiltersParam("");
        }
    }, [filters, setFiltersParam]);

    return (
        <div className="my-2 flex space-x-2 h-10 align-middle" 
            key={report.name + "_tr"}>
            <div key={'report_name_' + report.name}>
                <b className="h-15 align-middle">&apos;{report.name}&apos;</b>&nbsp;
            </div>
            <div key={'report_filters_' + report.name}>
                {metadata && metadata.filters && 
                Object.keys(metadata.filters).map((filterKey: string, filterIdx: number) => {
                    return (
                        <div key={filterKey + "_div_" + filterIdx} className="drop-shadow-lg inline-block mr-2">
                            <Dropdown label={dropdownLabel[filterKey]} options={
                                metadata.filters[filterKey].map((optionSet: string[], optionSetIdx: number) => {
                                    const label = optionSet.length > 1 ? optionSet[1] : optionSet[0];
                                    return { label, callback: () => updateFilter(filterKey, optionSet[0], optionSet[1]) };
                                })} />
                        </div>
                    );
                })}
            </div>
            <div key={'report_actions_' + report.name}>
                {metadata && metadata.metabase ? (
                    <><a className="align-middle" target="_blank" rel="noopener noreferrer" 
                        href={report.query}>View on Metabase</a></>
                ) : <button className="h-full rounded-md bg-blue-500 drop-shadow text-gray-100" 
                    onClick={() => download("/report/" + encodeURIComponent(report.name) + 
                        "/download_report" + filtersParam)}
                    key={'download_' + report.name}>
                    {L.reports.download_report}
                </button>}
            </div>
        </div>
    );
}

function ReportsPage() {
    const L = useLocalizedStrings();
    const configuration = useContext(ConfigurationContext);
    const reports = useAPI("/reports/list_reports");
    const allFields = useAPI("/reports/get_fields");
    const [fields, setFields] = useState('');
    const download = useDownloader();

    async function checkAndDownload() {
        if (!allFields.fields) {
            alert("Haven't loaded fields, try again in a sec");
            return;
        }
        var fs = fields.split(',').map((e) => e.trim());
        const specials = ['uid', 'screener_uid', 'screener', 'source', 'org', 'org_uid'];
        let not_found = [] as string[];
        for (const f of fs) {
            if (f.includes('$total_')) continue;
            if (specials.indexOf(f) === -1 && allFields['fields'][f] === undefined) {
                if (f.includes('*')){
                    const matches = Object.keys(allFields['fields']).filter(field => field.includes(f.replace('*','')));
                    if(matches.length === 0){
                        not_found.push(f);
                    }
                }else{
                    not_found.push(f)
                }
            }
        }
        if (not_found.length > 0) {
            alert("Didn't find " + not_found.join(','));
            return;
        }
        download("/reports/download_export?fields=" + encodeURIComponent(fields))
    }

    return (
        <>
            <div className="p-5">
                {(configuration.roles?.includes('admin') || configuration.roles?.includes('reports')) && (
                <>
                <h1>{L.reports.title}</h1>
                {reports.reports && reports.reports.map((report: Report, idx: number) => {
                    return <ReportRow key={'report_row_' + idx} report={report} />
                })}
                <hr/>
                <h1>{L.reports.exports}</h1>
                <Form.Control as="textarea" value={fields} onChange={(e) => setFields(e.target.value)} placeholder="Comma separated list of fields, i.e. legal_name, phone_number"></Form.Control>
                <br /><Button
                    onClick={checkAndDownload}>
                        {L.reports.download_export}
                    </Button>
                <hr/>
                </>
                )}
                {(configuration.roles?.includes('admin') || configuration.roles?.includes('query')) && (
                    <QueriesPage />
                )}
            </div>
        </>
    );
}

export default ReportsPage; 