import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get_deployment, get_rs_host, useAPIPost } from './API';
import { ClickableButton } from './Components/Button';
import { SurveyFieldsList } from './Components/SurveyFieldsList';
import { useSurveyTopology } from './Hooks/SurveyTopology';
import { useLocalizedStrings } from './Localization';
import { SpacedSpinner } from './Util';

/** be sure to pass applicants as a state var otherwise 
 * an endless loop will occur in the useEffect 
*/
export function useRoboScreenerAPI(props: {
    manualOnly?: boolean,
    allApplicants?: boolean, 
    uid?: string, 
    uids?: string[],
    limit?: number,
    debugKeys?: Record<string, string>,
    computeOptions?: {
        forceComputeFields?: string[],
        customClockTimestamp?: string
    }
}) {
    
    const compute = useAPIPost(get_rs_host() + '/compute_and_save', { 
        includeTokenInData: true 
    });

    const getAllApplicants = useAPIPost('/applicants/all');

    const [queuedApplicants, setApplicants] = useState<any[]>([]);
    const [updates, setUpdates] = useState({} as Record<string, Record<string,string>>);
    const [computing, setComputing] = useState(false);
    const [affected, setAffected] = useState<any[]>([]);

    const doCompute = async (overwrite?: boolean, saveUpdates?: boolean, params?: {
        onlySaveAffected?: boolean
    }) => {
        const allUpdates: Record<string, Record<string, string>> = {};
        const localUpdates = localStorage.getItem('rs-dry-updates');

        if (localUpdates && localUpdates.length > 0) {
            Object.assign(allUpdates, JSON.parse(localUpdates));
        }

        setComputing(true);
       
        const applicants: { 
            uid: string, 
            stage?: string,
            screener?: string, 
            hidden?: boolean 
        }[] = [];

        if (!saveUpdates) {
            if (props.allApplicants) {               
                const applicantResponse = await getAllApplicants({});
                applicants.push(...applicantResponse.data);
            } else if (props.uid) {
                applicants.push({ uid: props.uid })
            } else if (props.uids && props.uids.length > 0) {
                applicants.push( ...props.uids.map(uid => ({ uid })) );
                if (!props.limit) props.limit = applicants.length;
            }
            setApplicants(applicants);
        } else {
            if (params?.onlySaveAffected) {
                for (const uid in updates) {
                    if (Object.keys(updates[uid] || {}).length > 0) {
                        applicants.push({ uid });
                    }
                }
            } else {
                applicants.push(...queuedApplicants);
            }
        }

        let index = -1;
        const promises: Promise<any>[] = [];
        for (const applicant of applicants) {
            
            if (applicant.hidden) continue;
            
            if (['Unreachable','Ineligible'].indexOf(applicant.stage || 'asdfsdf') !== -1) continue;

            index++;
            if (props.limit && index >= props.limit) break;
            if (allUpdates[applicant.uid] && !overwrite) continue;

            const computeParams = {
                deploymentKey: get_deployment(),
                uid: applicant.uid,
                debugKeys: (props.debugKeys || {}),
                dryRunOnly: saveUpdates ? false : true, // very important.
                changedKeys: {},
                computeOptions: {
                    ...(props.computeOptions?.forceComputeFields && { forceComputeFields: props.computeOptions.forceComputeFields }),
                    ...(props.computeOptions?.customClockTimestamp && { customClockTimestamp: props.computeOptions.customClockTimestamp }),
                }
            }

            // stagger updates
            const updates = await compute(computeParams);
            allUpdates[applicant.uid] = updates.value;
        }
        await Promise.all(promises);
        console.debug("Setting local storage to allUpdates for uids: ", Object.keys(allUpdates));
        localStorage.setItem('rs-dry-updates', JSON.stringify(allUpdates));
        setUpdates(allUpdates);
        setComputing(false);
    };

    // Compute once on page load
    useEffect(() => {
        // overwrite if a uid is provided, otherwise don't overwrite local storage.
        if (!props.manualOnly) {
            doCompute(props.uid ? true : false, false);
        }
    }, []);

    return { 
        applicantUpdates: updates, 
        computing,
        refreshRS: async () => { localStorage.removeItem('rs-dry-updates'); await doCompute(true, false) },
        saveUpdates: async (params?: {
            onlySaveAffected?: boolean
        }) => { 
            await doCompute(true, true, params); 
            // Only dry compute again if there is no face being forced
            // Recomputing dry with _face forces the DetectFaces library to delete the face from
            //   Rekognition indexes. So let's not do that!
            if ((props.computeOptions?.forceComputeFields || []).filter(f => f.includes('_face')).length === 0) 
                await doCompute(true, false) 
        },
    }
}

/** This page runs a dry RS run on all non-hidden applicants and
 *  lets the user view any deltas.
 */
export function RoboScreenerPage(props: any) {

    const L = useLocalizedStrings();

    const surveyTopology = useSurveyTopology({});
    
    const [showUpdatesWithField, setShowUpdatesWithField] = useState('');
    const [limit, setLimit] = useState(50 as number | undefined);
    const [uids, setUIDs] = useState('');
    const [debugKeys, setDebugKeys] = useState({} as Record<string, string>);
    const [forceComputeFields, setForceCompute] = useState(null as string[] | null);

    const { applicantUpdates, computing, refreshRS, saveUpdates } = useRoboScreenerAPI({ 
        uids: uids && uids.length > 0 ? uids.replace(/\n/g, " ").replace(/,/g, " ").split(" ").filter((l: string) => l.length > 0).map(t => t.trim()) : [], 
        allApplicants: !uids || uids.length === 0, 
        manualOnly: true,
        limit,
        debugKeys,
        computeOptions: {
            ...(forceComputeFields ? { forceComputeFields } : {}),
        }
    });

    return <div className="flex flex-col">
        {false && <SurveyFieldsList dmap={surveyTopology.dmap} onFieldClick={(key) => setShowUpdatesWithField(key) } 
            description="Click one of the following keys to show all RS results that will be updated with that key." />}
        <div className="mb-40" style={{ maxWidth: "800px", padding: "10px" }}>
            <h2>{L.admin.survey_design.applicant_updates}</h2>
            <div className="form-group">
                <label htmlFor="uids" className="my-2">Enter UIDs separated by commas, spaces, or lines</label>
            </div>
            <textarea id="uids" className="my-1 w-full" value={uids} 
                onChange={(e) => setUIDs(e.target.value) } />
            <div>
                <label htmlFor="account-number" className="block text-sm font-medium text-gray-700">
                    Limit
                </label>
                <div className="mt-2 mb-2 relative rounded-md shadow-sm">
                    <input
                        type="text"
                        value={limit}
                        onChange={(e) => setLimit(parseInt((e.target?.value || '').replace(/[^0-9]/g, '') || '0'))}
                        className="focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder="50"
                    />
                </div>
            </div>
            <div className="my-2">
                <label htmlFor="account-number" className="block text-sm font-medium text-gray-700">
                    Debug Keys (Separate with commas)
                </label>
                <div className="mt-2 mb-2 relative rounded-md shadow-sm">
                    <input
                        type="text"
                        value={Object.keys(debugKeys || {}).join(',')}
                        onChange={(e) => {
                            let value = e.target.value || '';
                            let parsed = value.split(',');
                            setDebugKeys(parsed.reduce((acc, cur) => { acc[cur] = cur; return acc; }, {} as Record<string, string>));
                        }}
                        className="focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <div className="my-2">
                <label htmlFor="account-number" className="block text-sm font-medium text-gray-700">
                    Force Compute Target Fields (Separate with commas)
                </label>
                <div className="mt-2 mb-2 relative rounded-md shadow-sm">
                    <input
                        type="text"
                        value={(forceComputeFields || []).join(',')}
                        onChange={(e) => {
                            let value = e.target.value || '';
                            let parsed = value.split(',');
                            setForceCompute(parsed.map(v => v.trim()));
                        }}
                        className="focus:ring-indigo-500 h-10 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
                    />
                </div>
            </div>
            <ClickableButton color="green" onClick={() => refreshRS()}>
                {computing && <SpacedSpinner />}{L.admin.survey_design.refresh_applicants} ({L.admin.survey_design.limit} {limit})
            </ClickableButton>&nbsp;
            {Object.keys(applicantUpdates || {}).length > 0 ? 
            <>
                <ClickableButton color="blue" onClick={() => saveUpdates()}>
                    {computing && <SpacedSpinner />}{L.admin.survey_design.save_this_batch} ({L.admin.survey_design.affects} {Object.keys(applicantUpdates || {}).length})
                </ClickableButton>&nbsp;
                <ClickableButton color="blue" onClick={() => saveUpdates({ onlySaveAffected: true })}>
                    {computing && <SpacedSpinner />}Save Affected ({L.admin.survey_design.affects} {Object.keys(applicantUpdates || {}).filter(k => Object.keys(applicantUpdates[k] || {}).length > 0).length})
                </ClickableButton>
            </> : <></>}
            <br/>
            {showUpdatesWithField && <>
                <small><em>{L.admin.survey_design.showing_applicants_with_update} </em>{showUpdatesWithField}</small>&nbsp;
                <ClickableButton color="gray" onClick={() => setShowUpdatesWithField('')}>{L.admin.survey_design.clear}</ClickableButton>
            </>}
            <br/>
            {(Object.keys(applicantUpdates || {}) || []).map((uid: string) => {
                if (!applicantUpdates[uid]) return <></>;
                if (showUpdatesWithField && applicantUpdates[uid][showUpdatesWithField] === undefined) return <></>;

                return <div className="my-1" key={`top-div-uid-${uid}`}>
                    <div className="flex-1 pt-1.5">
                        <Link to={`/applicant/${uid}`} target="_blank">{uid}</Link>
                    </div>
                    <div className="flex-1 pt-1.5 m-2 flex flex-wrap">
                        {(Object.keys(applicantUpdates[uid] || {}) || []).map((key: string) => {
                            if (showUpdatesWithField && key !== showUpdatesWithField) return <></>
                            return <div className="m-1" key={`rs-page-${uid}-${key}`}>
                                <ClickableButton color="gray" 
                                    onClick={() => setShowUpdatesWithField(key)}>
                                        {key}: {applicantUpdates[uid][key]}
                                </ClickableButton>
                            </div>
                        })}
                    </div>
                </div>
            })}
        </div>
    </div>

}
