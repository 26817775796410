import React, { useContext } from "react";
import { useState } from "react";
import { Dropdown, DropdownButton, FormControl, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAPIPost } from "../API";
import { ConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import { copyToClipboard } from "../Util";

export type LinkSenderProps = {
    label?: string;
    message: {en: string, es: string};
    toastMessage: string;
    defaultValue?: string;
    linkCreator: (langKey: string) => Promise<string> | string;
};

export function LinkSender(props: LinkSenderProps): any {

    const L = useLocalizedStrings();
    const config = useContext(ConfigurationContext);

    const [phone, setPhone] = useState(props.defaultValue ?? '');
    const sendMessage = useAPIPost("/send_message");

    async function copyLink(lang?: string) {
      const link = await props.linkCreator(lang || 'en');

      copyToClipboard(link, L.copied_to_clipboard);
    }
    
      async function sendLink(lang?: string) {
        const link = await props.linkCreator(lang || 'en');
        await sendMessage({
          ...(phone.indexOf('@') !== -1 ? { email: phone } : { phone }),
          has_link: true,
          message:
            (lang === "es" ? props.message.es : props.message.en) + link,
          ...((config.roles || '').indexOf('internal audit') !== -1 && { internal_audit: true })
        });
      
        toast(props.toastMessage);
      }

    return (
      <div>
        <InputGroup>
          {props.label && (<InputGroup.Prepend>
            <InputGroup.Text>{props.label}</InputGroup.Text>
          </InputGroup.Prepend>)}
          <FormControl
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Phone Number or Email"
          />
          <DropdownButton as={InputGroup.Append} title={L.applicant.send_link}>
            <Dropdown.Item onClick={() => sendLink('en')}>
              {L.questions.contract.send_link_in_english}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => sendLink('es')}>
              {L.questions.contract.send_link_in_spanish}
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton as={InputGroup.Append} title={L.applicant.copy_link}>
            <Dropdown.Item onClick={() => copyLink('en')}>
              {L.questions.contract.copy_link_in_english}
            </Dropdown.Item>
            <Dropdown.Item onClick={() => copyLink('es')}>
              {L.questions.contract.copy_link_in_spanish}
            </Dropdown.Item>
          </DropdownButton>
        </InputGroup>
      </div>
    );
}