interface Document {
    instructions: { 
        en: string, 
        es: string
    },

}

/**
 * Static Content for W9 Certification
 */
const w9: Document = {
    instructions: {
        en: `Under penalties of perjury, I certify that:
    
    1. The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me); and

    2. [](#irs_flag_start)I am not subject to backup withholding because (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue
    Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and[](#irs_flag_end)

    3. I am a U.S. citizen or other U.S. person (defined in https://www.irs.gov/pub/irs-pdf/fw9.pdf); and

    4. The FATCA code(s) entered on this form (if any) indicating that I am exempt from FATCA reporting is correct.

    **Certification instructions.** You must cross out item 2 above if you have been notified by the IRS that you are currently subject to backup withholding 
    because you have failed to report all interest and dividends on your tax return. For real estate transactions, item 2 does not apply. #checkbox For mortgage interest paid, 
    acquisition or abandonment of secured property, cancellation of debt, contributions to an individual retirement arrangement (IRA), and, generally, payments 
    other than interest and dividends, you are not required to sign the certification, but you must provide your correct TIN. See the instructions for Part II, later.`,
        es: `Bajo pena de perjurio, yo certifico que:
    
    1. El número que aparece en este formulario es mi número de identificación del contribuyente correcto (o estoy esperando que me emitan un número) y 
        
    2. [](#irs_flag_start)No estoy sujeto a la retención adicional de impuestos porque (a) estoy exento de la retención adicional o (b) no he sido notificado por el Servicio de
    Impuestos Internos (IRS, por sus siglas en inglés) que estoy sujeto a la retención adicional de impuestos como resultado de no declarar todos los
    intereses o dividendos o (c) el IRS me ha notificado que ya no estoy sujeto a la retención adicional y[](#irs_flag_end)

    3. Soy ciudadano de los EE. UU. u otra persona de los EE. UU. (definido en https://www.irs.gov/pub/irs-pdf/fw9sp.pdf) y

    4. El (Los) código(s) de la FATCA anotado(s) en este formulario (si alguno) indicando que estoy exento de declarar conforme a FATCA es el (son los)
    correcto(s).

    **Instrucciones para la certificación.** Tiene que tachar la partida 2 anteriormente si el IRS le ha notificado que usted en estos momentos está sujeto a la retención adicional porque no declaró todos los intereses y dividendos en su declaración de impuestos. Para las transacciones de bienes inmuebles, la partida 2 no corresponde. #checkbox Para los intereses hipotecarios pagados, la adquisición o abandono de bienes garantizados, la cancelación de deudas, las aportaciones a un arreglo individual de ahorro para la jubilación (IRA, por sus siglas en inglés) y, por lo general, los pagos que no sean intereses y dividendos, no se le requiere firmar la certificación pero tiene que proveer su TIN correcto. Vea las instrucciones para la Parte II, más adelante.`
    }
}

export default w9;