import { useState, useRef } from "react";
import { useLocalizedStrings } from "../Localization";
import { useLocation } from "react-router-dom";
import { ArrowPathIcon } from '@heroicons/react/24/outline';

export function TurnableImage(props: { Viewer: string, src: string; alt: string, fullScreen?: boolean }) {
    const L = useLocalizedStrings();
    const [rotation, setRotation] = useState(0);
    const [imageError, isImageError] = useState(false);
    const ref = useRef<HTMLImageElement | null>(null);

    const modernUI = window.location.pathname.includes("/a/");

    if (imageError) {
        return (
            <li>
                <a href={props.src}
                    onClick={async (e) => {
                        e.preventDefault();
                        window.open(props.src, "_blank");
                    }}
                    rel="noopener noreferrer" target="_blank">
                    View Document
                </a>
            </li>
        );
    }

    return (
        <div className="mt-2">
            {props.Viewer === 'screener' &&
                <button
                    className="inline-flex justify-center border border-gray-50 rounded-md bg-white-100 py-1 text-sm text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 p-2"
                    aria-label="Rotate Image"
                    onClick={(e) => {
                        setRotation((rotation + 90) % 360);
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }}><ArrowPathIcon className="h-5 w-5 mr-1" />{ L.questions.attachment.rotate_image }</button>
            }
            <div style={
                (rotation === 90 || rotation === 270) ? { height: ref?.current?.width ? ref.current.width : 'auto' } : {}
            }>
                <img
                    ref={ref}
                    alt={props.alt}
                    style={{
                        maxWidth: (props.fullScreen || modernUI) ? "100%" : props.Viewer === 'screener' ? "400px" : "300px",
                        transform: 'translateY(' + (!props.fullScreen && (rotation === 90 || rotation === 270) ? (((ref.current?.width || 0) - (ref.current?.height || 0)) / 2) : 0) + 'px) ' +
                            'translateX(' + (!props.fullScreen && (rotation === 90 || rotation === 270) ? (((ref.current?.height || 0) - (ref.current?.width || 0)) / 2) : 0) + 'px) ' +
                            'rotate(' + rotation + 'deg)',
                        ...(props.fullScreen ? {objectFit: 'contain', maxHeight: '100%', height: '100%', width: '100%'} : {})
                    }}
                    onError={() => isImageError(true)}
                    src={props.src}
                />
            </div>
        </div>
    );
}

export function FullScreenTurnableImagePage() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const src = params.get("src");

    if (!src) {
        return <p>Error: No image source provided.</p>;
    }

    return (
        <div className="w-screen h-screen">
            <TurnableImage Viewer={'screener'} src={src} alt="Full screen turnable image" fullScreen={true} />
        </div>
    );
}
