import { useState } from "react";
import { usePost } from "./API";
import { TailwindSwitch } from "./Components/Switch";
import { ClickableButton } from "./Components/Button";
import { mapStrToList } from "./Util";

export default function BatchSetPage() {
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [uids, setUids] = useState('');

    const batchSet = usePost('/admin/batch_set');

    // Copy Field -- copy one key to another for selected applicants
    const [oldKey, setOldKey] = useState('');
    const [newKey, setNewKey] = useState('');

    const [copyFieldUIDs, setCopyFieldUIDs] = useState('');

    const copyField = usePost('/admin/copy_field');

    const [hideUnhide, setHideUnhide] = useState('hide');
    const [hideUnhideUIDs, setHideUnhideUIDs] = useState('');
    const batchHideUnhide = usePost('/admin/batch_hide_unhide');

    const [bool_doCompute, setBool_doCompute] = useState(false);

    async function submit() {
        alert(JSON.stringify(await batchSet({ 
            key, 
            value, 
            userIds: mapStrToList(uids),
            compute: bool_doCompute
        })));
    }

    async function submit2() {
        alert(JSON.stringify(await copyField({
            oldKey,
            newKey,
            uids: mapStrToList(copyFieldUIDs)
        })))
    }

    async function submit3() {
        alert(JSON.stringify(await batchHideUnhide({
            uids: mapStrToList(hideUnhideUIDs),
            hide: hideUnhide === 'hide'
        })))
    }

    return <>
    <h1>Batch Operations</h1>
    <hr/>
    <h2>Batch Set</h2>
    <p>Note that for simplicity and performance this does not run RS. If you want to run RS, do so from the other admin interface</p>

    <fieldset>
        <legend>Key</legend>
        <input value={key} onChange={(e) => setKey(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
        <legend>Value</legend>
        <input value={value} onChange={(e) => setValue(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
        <legend>UIDs (comma, line, or space separated)</legend>
        <textarea value={uids} onChange={(e) => setUids(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
        <TailwindSwitch checked={bool_doCompute} onChange={() => setBool_doCompute(!bool_doCompute)} 
            label="Recompute after?" />
    </fieldset>
    <br/>
    <ClickableButton onClick={submit} color="gray">Update</ClickableButton>

    <hr/>
    <h2>Copy Field</h2>
    <p>This copies one field to another for all applicants using their existing value for that field.</p>
    <fieldset>
        <legend>Copy From Target Field (Old Key)</legend>
        <input value={oldKey} onChange={(e) => setOldKey(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
        <legend>Copy To Target Field (New Key)</legend>
        <input value={newKey} onChange={(e) => setNewKey(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <fieldset>
        <legend>UIDs (comma, line, or space separated)</legend>
        <textarea value={copyFieldUIDs} onChange={(e) => setCopyFieldUIDs(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <button onClick={submit2}>Update</button>

    <hr/>
    <h2>Batch Hide/Unhide</h2>
    <p>This will hide or unhide all the applicants you provide below. Good way to quarantine applicants. Be sure to unhide after hiding if they are intended to stick around.</p>

    <fieldset>
        <legend>Hide or Unhide?</legend>
        <label className="block"><input type="radio" name="hide" onClick={() => setHideUnhide('hide')} value={'hide'} /> Hide</label>
        <label><input type="radio" name="hide" onClick={() => setHideUnhide('unhide')} value={'unhide'} /> Unhide</label>
    </fieldset>
    <fieldset>
        <legend>UIDs (comma, line, or space separated)</legend>
        <textarea value={hideUnhideUIDs} onChange={(e) => setHideUnhideUIDs(e.target.value)} className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md" />
    </fieldset>
    <button onClick={submit3}>{hideUnhide === 'hide' ? 'Hide' : 'Unhide'} Applicants</button>
    </>
}