import { DashboardDescription } from "@aidkitorg/types/lib/survey";
import { ArrowsPointingOutIcon } from "@heroicons/react/24/outline";
import React, { ReactNode, useState, useEffect, Fragment } from "react";
import { Cell, Pie, PieChart as RechartsPieChart, Tooltip, TooltipProps } from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { snakeToEnglish } from "../Util";
import { stringToColor } from "./ChartTools";
import { FullScreenModal } from "./FullScreenModal";

const label = (data: any, key: string, isModal: boolean) => function(params: {
	cx: number,
	cy: number,
	midAngle: number,
	innerRadius: number,
	outerRadius: number,
	value: string,
	index: number,
  }) {
	const RADIAN = Math.PI / 180;
	// eslint-disable-next-line
	const radius = 25 + params.innerRadius + (params.outerRadius - params.innerRadius);
	// eslint-disable-next-line
	const x = params.cx + radius * Math.cos(-params.midAngle * RADIAN);
	// eslint-disable-next-line
	const y = params.cy + radius * Math.sin(-params.midAngle * RADIAN);

	return (
        <text
			x={x}
			y={y}
			fontSize={isModal ? 16 : 12}
			fill={stringToColor(data[params.index][key])}
			textAnchor={x > params.cx ? "start" : "end"}
			dominantBaseline="central"
		>
			{snakeToEnglish(data[params.index][key])
				.split(' ')
				.map((word, i) => (
					<tspan key={word + i} textAnchor="left" x={x} dy={i === 0 ? 0 : '1em'}>
						{word}
				</tspan>
				))}
		</text>
    );
};

const CustomTooltip = (total: number, groupKeys: string[]) => ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active) {
        return (
            <div className="bg-white shadow-xl rounded-lg p-2">
                {groupKeys.map((groupKey) => (
                    <Fragment key={groupKey}>
                        {snakeToEnglish(groupKey)}: <b>{`${snakeToEnglish(payload?.[0]?.payload?.payload?.[groupKey] as string)}`}</b><br />
                    </Fragment>
                ))}
                {`${snakeToEnglish(payload?.[0].dataKey as string)}: ${payload?.[0].value}`}<br />
                {`Total Percent: ${((payload?.[0].value as number || 0) / total * 100).toFixed(2)}%`}
            </div>
        );
    }
    return null;
};

export function PieChart(props: {
  isCustom?: boolean;
  data: Record<string, any>[],
  groupKeys: string[],
  summaryKey: string,
  totalKey?: string,
  hideTotal?: boolean,
  width?: number,
  height?: number,
  pieStyle?: "donut" | "pie",
  description?: string,
	descriptionPlacement?: DashboardDescription['placement']
	isExpanded: boolean,
}) {	
	const { width=350, height=300, pieStyle } = props;
	const [chartWidth, setChartWidth] = useState(width);
	const [chartHeight, setChartHeight] = useState(height);
	const descPlacement = props.descriptionPlacement ?? 'bottom';

	useEffect(() => {
		const updateDimensions = () => {
			if(props.isExpanded){
				setChartWidth(window.innerWidth * 0.9);
				setChartHeight(window.innerHeight * 0.8);	
			}
			else {
				setChartWidth(width);
				setChartHeight(height);	
			}
		}	
		window.addEventListener("resize", updateDimensions);
		updateDimensions();
		return () => window.removeEventListener("resize", updateDimensions);
	}, [props.isExpanded]);

	const smallestDimension = Math.min(chartWidth, chartHeight);

	// cast values numbers
	props.data.forEach(d => {
		d[props.summaryKey] = Number(parseFloat(d[props.summaryKey]).toFixed(2));
		if(props.totalKey){
			d[props.totalKey] = Number(parseFloat(d[props.totalKey]).toFixed(2));
		}
	});
	props.data.sort((a, b) => {
		for (let i = props.groupKeys.length - 1; i > 0; i--) {
			const key = props.groupKeys[i];
			if (a[key] < b[key]) {
				return -1;
			} else if (a[key] > b[key]) {
				return 1;
			}
		}
		return 0;
	});

	const totalCount = props.data.reduce(
		(acc, v) => acc += v.count_applicants ?? (props.totalKey ? v[props.totalKey] : v[props.summaryKey]) ?? 0, 
		0
	);

	const renderChart = (isModal: boolean, width: number, height: number) => (
		<RechartsPieChart width={width} height={height} className='m-auto'>
			{props.groupKeys.map((groupKey, index) => {
			const numGroupKeys = props.groupKeys.length;
			const radiusStep = (smallestDimension * 0.4 - smallestDimension * 0.2) / numGroupKeys;
			const innerRadius = smallestDimension * 0.2 + index * radiusStep;
			const outerRadius = innerRadius + radiusStep;

			return (
				<Pie
				key={groupKey}
				label={label(props.data, groupKey, isModal)}
				data={props.data}
				dataKey={props.summaryKey}
				nameKey={groupKey}
				cx="50%"
				cy="50%"
				innerRadius={pieStyle === 'pie' ? 0 : innerRadius}
				outerRadius={outerRadius - 10}
				fill="#000"
				>
				{props.data?.map(datum => (
					<Cell key={`${groupKey}-${datum[groupKey]}-${index}`} fill={stringToColor(datum[groupKey])} />
				))}
				</Pie>
			);
			})}
			<Tooltip content={React.createElement(CustomTooltip(totalCount, props.groupKeys))} />
		</RechartsPieChart>);
	
	if (props.isExpanded) {
		return (
			<div className="w-full h-full flex flex-col items-center justify-center">
				<dl className="w-full h-full">
					<dt hidden={!props.description || descPlacement !== 'top'}
						title={props.description}
						className="text-gray-400 text-center">
						{props.description}
					</dt>
					<dd className="mt-1 font-medium w-full h-full">
						{renderChart(true, chartWidth, chartHeight)}
					</dd>
				</dl>
				<div>{'Total: ' + totalCount}</div>
				<div hidden={!props.description || descPlacement !== 'bottom'}
					title={props.description}
					className="text-gray-400 text-center">
					{props.description}
				</div>
			</div>
		)
	}

	return (
		<div>
			<dl>
				<dt hidden={!props.description || descPlacement !== 'top'} 
					title={props.description} 
					className="text-gray-400 max-w-xs truncate text-center">
					{props.description}
				</dt>
				<dd className="mt-1 font-medium">
					{renderChart(false, chartWidth, chartHeight)}
				</dd>
			</dl>
			{!props.hideTotal && <div>{'Total: ' + totalCount}</div>}
			<div hidden={!props.description || descPlacement !== 'bottom'} 
				title={props.description} 
				className="text-gray-400 max-w-xs truncate text-center">
				{props.description}
			</div>
		</div>
    );
}
