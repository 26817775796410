import { BooleanExpr, Field, GenericTemplatedBlock, NamedExpression, NotificationSender, OrderBy, SummaryExpr, TableLookup, ValueExpr } from "../survey";
import { hash } from "./v0_to_legacy";

function getColumnFromLookup(lookup: TableLookup): string {
    switch (lookup.kind) {
        case 'User':
            switch(lookup.prop) {
                case 'name': return 'name';
                case 'roles': return 'permissions';
                case 'email': return 'email';
            }
        case 'Message':
            switch (lookup.prop) {
                case 'needs_attention': return 'needs_attention';
            }
        case 'Payment':
            switch (lookup.prop) {
                case 'status': return 'state';
                case 'type': return 'kind';
                case 'amount': return 'amount';
                case 'date created': return 'created';
                case 'transaction ref': return 'external_reference';
            }
    }
}

export function findFields(expr: Conditional | SummaryExpr): string[] {
    switch (expr.kind) {
        case 'Named Expression':
            return findFields(expr.expression);
        case 'And':
        case 'Or':
            return expr.clauses.flatMap(findFields);
        case 'Not': 
            return findFields(expr.clause)
        case 'Equals':
        case 'Not Equal':
        case 'DoesntExist':
        case 'Exists':
        case 'Last Modified':
        case 'Last Modified Date':
        case 'Field':
            return [expr.field];
        case 'Value Equals':
            return findFields(expr.value).concat(findFields(expr.equals));
        case 'Value Not Equal':
            return findFields(expr.value).concat(findFields(expr.notEqual));
        case 'Value Empty':
        case 'Value Not Empty':
            return findFields(expr.value);
        case 'Value Less Than':
            return findFields(expr.value).concat(findFields(expr.lessThan));
        case 'Value Greater Than':
            return findFields(expr.value).concat(findFields(expr.greaterThan));
        case 'Value Contains':
            return findFields(expr.value).concat(findFields(expr.contains));
        case 'When':
            return (expr.when || []).flatMap(e => {
                return findFields(e.cond).concat(findFields(e.then));
            }).concat(
            findFields(expr.otherwise));
        case 'Transform String':
        case 'As Date':
        case 'Shift Date':
        case 'Bucket Date':
            return findFields(expr.value);
        case 'Applicant Luck':
            return ['_luck'];
        case 'NumericValue':
        case 'StringValue':
        case 'BooleanValue':
        case 'Never':
        case 'After':
        case 'Before':
        case 'Current User':
        case 'Now':
        case 'Sync Status':
            return [];
        case 'Templated Block':
            throw TEMPLATED_BLOCK_NOT_SUPPORTED;
        case 'Count':
            return [];
        case 'Mean':
        case 'Median':
        case 'Sum':
        case 'Round':
            return findFields(expr.value);
        case 'Add':
        case 'Subtract':
        case 'Multiply':
        case 'Divide':
            let fields = findFields(expr.baseValue);
            if (Array.isArray(expr.secondValue)) {
                fields = fields.concat(expr.secondValue.flatMap(value => findFields(value)));
            } else {
                fields = fields.concat(findFields(expr.secondValue));
            }
            return fields;
        case 'Code Boolean Expr':
            return [];
        case 'Expand':
            return [expr.value];
        case 'Concat':
            return expr.value.flatMap(findFields);
        case 'Extract':
            if(typeof expr.value === 'string') {
                return [expr.value];
            }
            return findFields(expr.value);
        default:
            let _: never = expr;
    }

    return [];
}

export function nameForColumn(column: ValueExpr | SummaryExpr | NamedExpression): string | null {
    let name;
    switch (column.kind) {
        case 'Named Expression':
            return column.name?.['en'] || nameForColumn(column.expression);
        case 'Field':
            name = column.lookup ? column.field + '-' + column.lookup.kind + '-' + column.lookup.prop : column.field;
            break;
        case 'Count':
            name = 'count_applicants';
            break;
        case 'Mean':
            name = 'field' in column.value ? `mean_${column.value.field}` : 'mean';
            break;
        case 'Median':
            name = 'field' in column.value ? `median_${column.value.field}` : 'median';
            break;
        case 'Sum':
            name = 'field' in column.value ? `sum_${column.value.field}` : 'sum';
            break;
        case 'When':
            name = `when then ${column.when.map(when => nameForColumn(when.then)).join(' then ')} else ${nameForColumn(column.otherwise)}`;
            break;
        case 'Round':
            name = `${nameForColumn(column.value)}`;
            break;
        case 'Add':
            if (Array.isArray(column.secondValue)) {
                name = `${nameForColumn(column.baseValue)} + ${column.secondValue.map((value) => nameForColumn(value)).join(' + ')}`;
            } else {
                name = `${nameForColumn(column.baseValue)} + ${nameForColumn(column.secondValue)}`;
            }
            break;
        case 'Subtract':
            if (Array.isArray(column.secondValue)) {
                name = `${nameForColumn(column.baseValue)} - ${column.secondValue.map((value) => nameForColumn(value)).join(' - ')}`;
            } else {
                name = `${nameForColumn(column.baseValue)} - ${nameForColumn(column.secondValue)}`;
            }
            break;
        case 'Multiply':
            if (Array.isArray(column.secondValue)) {
                name = `${nameForColumn(column.baseValue)} * ${column.secondValue.map((value) => nameForColumn(value)).join(' * ')}`;
            } else {
                name = `${nameForColumn(column.baseValue)} * ${nameForColumn(column.secondValue)}`;
            }
            break;
        case 'Divide':
            if (Array.isArray(column.secondValue)) {
                name = `${nameForColumn(column.baseValue)} / ${column.secondValue.map((value) => nameForColumn(value)).join(' / ')}`;
            } else {
                name = `${nameForColumn(column.baseValue)} / ${nameForColumn(column.secondValue)}`;
            }
            break;
        case 'StringValue':
            name = column.value;
            break;
        case 'NumericValue':
        case 'BooleanValue':
            name = `${column.value}`;
            break;
        case 'Last Modified Date':
            name = `${column.field} (Last Modified)`;
            break;
        case 'As Date':
            name = `${nameForColumn(column.value)} (As Date)`;
            break;
        case 'Shift Date':
            name = `${nameForColumn(column.value)} (Shift Date)`;
            break;
        case 'Now':
            name = `Now`;
            break;
        case 'Bucket Date':
            name = `${column.bucket.charAt(0).toUpperCase()}${column.bucket.slice(1)} of ${nameForColumn(column.value)}`;
            break;
        case 'Sync Status':
            name = column.kind;
            break;
        case 'Applicant Luck':
            name = 'luck';
            break;
        case 'Expand':
            name = column.value;
            break;
        case 'Extract':
            name = typeof column.path === 'string' ? column.path : column.path.at(-1);
            break;
        case 'Concat':
            const subs = column.value.map(nameForColumn).filter(n => n) as string[];
            name = subs.join('+');
    }
    // Postgres truncates column names to the first 63 characters
    return name ? name.slice(0, 63) : null;
}

function castAsNumericAndDefaultToZero(value: string) {
    return `COALESCE(CAST(${value} AS NUMERIC), 0)`;
}

export type Conditional = BooleanExpr | ValueExpr | NamedExpression | GenericTemplatedBlock<BooleanExpr[]>;

const TEMPLATED_BLOCK_NOT_SUPPORTED = 'expressed has not been expanded';
const DATE_KINDS = ['Last Modified Date', 'As Date', 'Shift Date', 'Now', 'Bucket Date'];

export function CompileExpressionToSQL(params: {
        cond?: Conditional,
        columns?: (ValueExpr | SummaryExpr | NamedExpression | NotificationSender)[],
        context?: { currentUser?: string, lateralUnnestingInDashboards?: boolean },
        groups?: ValueExpr[],
        countEmpty?: boolean,
        orderBy?: OrderBy,
        allowCommasInGroups?: boolean,
        includeHidden?: boolean,
        includeNonLatest?: boolean
}) {
    const fields: string[] = [];
    const lookups: Field[] = [];
    let isSummary: boolean = false;
    function findFields(expr: Conditional | SummaryExpr) {
        switch (expr.kind) {
            case 'Named Expression':
                findFields(expr.expression);
                break;
            case 'And':
            case 'Or':
                expr.clauses.map(findFields);
                break;
            case 'Not': 
                findFields(expr.clause)
                break;
            case 'Equals':
            case 'Not Equal':
            case 'DoesntExist':
            case 'Exists':
            case 'Last Modified':
            case 'Last Modified Date':
                fields.push(expr.field);
                break;
            case 'Field':
                if (expr.lookup) {
                    lookups.push(expr);
                }
                // payment lookups don't care about the value of the field, so 
                // we don't need to look it up
                if (expr.lookup?.kind !== 'Payment') {
                    fields.push(expr.field);
                }
                break;
            case 'Value Equals':
                findFields(expr.value);
                findFields(expr.equals);
                break;
            case 'Value Not Equal':
                findFields(expr.value);
                findFields(expr.notEqual);
                break;
            case 'Value Empty':
            case 'Value Not Empty':
                findFields(expr.value);
                break;
            case 'Value Less Than':
                findFields(expr.value);
                findFields(expr.lessThan);
                break;
            case 'Value Greater Than':
                findFields(expr.value);
                findFields(expr.greaterThan);
                break;
            case 'Value Contains':
                findFields(expr.value);
                findFields(expr.contains);
                break;
            case 'When':
                (expr.when || []).map(e => {
                    findFields(e.cond);
                    findFields(e.then);
                })
                findFields(expr.otherwise);
                break;
            case 'Transform String':
            case 'As Date':
            case 'Shift Date':
            case 'Bucket Date':
                findFields(expr.value);
                break;
            case 'Applicant Luck':
                fields.push('_luck');
                break;
            case 'NumericValue':
            case 'StringValue':
            case 'BooleanValue':
            case 'Never':
            case 'After':
            case 'Before':
            case 'Current User':
            case 'Now':
            case 'Sync Status':
                break;
            case 'Templated Block':
                throw TEMPLATED_BLOCK_NOT_SUPPORTED;
            case 'Count':
                isSummary = true;
                break;
            case 'Mean':
            case 'Median':
            case 'Sum':
                isSummary = true;
                findFields(expr.value);
                break;
            case 'Round':
                findFields(expr.value);
                break;
            case 'Add':
            case 'Subtract':
            case 'Multiply':
            case 'Divide':
                findFields(expr.baseValue);
                if (Array.isArray(expr.secondValue)) {
                    expr.secondValue.forEach(value => findFields(value));
                } else {
                    findFields(expr.secondValue);
                }
                break;
            case 'Code Boolean Expr':
                break;
            case 'Expand':
                fields.push(expr.value);
                break;
            case 'Concat':
                expr.value.forEach(findFields);
                break;
            case 'Extract':
                if(typeof expr.value === 'string') {
                    fields.push(expr.value);
                } else {
                    findFields(expr.value);
                }
                break;
            default:
                let _: never = expr;
        }
    }
    if (params.cond) {
        findFields(params.cond);
    }
    if (params.orderBy && params.orderBy.value !== 'Random') {
        // This will ensure that we add the join against the lottery table, even if no columns from it are explicitly requested in the Click Query
        if (params.orderBy.value === 'Applicant Luck') {
            fields.push('_luck');
        } else {
            findFields(params.orderBy.value);
        }
    }

    const updatedColumns: (ValueExpr | NamedExpression | SummaryExpr)[] = [];
    let includedNotifCol = false;
    params.columns?.forEach((col) => {
        if (col.kind === 'NotificationSender') {
            updatedColumns.push({ kind: 'Field', field: (col as NotificationSender).field + '_sms' });
            updatedColumns.push({ kind: 'Field', field: (col as NotificationSender).field + '_email' });
            includedNotifCol = true;
        } else {
            updatedColumns.push(col);
        }
    })

    if (includedNotifCol) {
        updatedColumns.push({ kind: 'Field', field: 'phone_number' });
        updatedColumns.push({ kind: 'Field', field: 'email' });
    }

    updatedColumns?.map(findFields);
    params.groups?.map(findFields);

    let fieldMap: Record<string, string> = {};

    function generateConditions(expr: Conditional | SummaryExpr): string {
        switch (expr.kind) {
            case 'Named Expression':
                return generateConditions(expr.expression);
            case 'And':
                return '(' + expr.clauses.map(generateConditions).join(' AND\n ') + ')';
            case 'Or':
                return '(' + expr.clauses.map(generateConditions).join(' OR\n ') + ')';
            case 'Not': 
                return '(NOT ' + generateConditions(expr.clause) + ')';
            case 'Equals':
                return `${fieldMap[expr.field]} = '${expr.value}'`;
            case 'Not Equal':
                return `(${fieldMap[expr.field]} IS NULL OR ${fieldMap[expr.field]} != '${expr.value}')`;
            case 'Exists':
                return `(${fieldMap[expr.field]} IS NOT NULL AND ${fieldMap[expr.field]} != '')`;
            case 'DoesntExist':
                return `(${fieldMap[expr.field]} IS NULL OR ${fieldMap[expr.field]} = '')`;
            case 'Never':
                return 'FALSE'
            case 'After':
            case 'Before':
                const year = expr.date.year.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping:false});
                const month = expr.date.month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                const day = expr.date.day.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                const hour = expr.time.hour.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                const minute = expr.time.minute.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                if (expr.kind === 'After') {
                    return `(NOW() > '${year}-${month}-${day} ${hour}:${minute}:00')`
                } else {
                    return `(NOW() < '${year}-${month}-${day} ${hour}:${minute}:00')`
                }
            case 'Last Modified':
                return `(${fieldMap[expr.field].replace('.value', '.created_at')} + interval '${expr.ago.amount} ${expr.ago.unit}' < NOW())`;
            case 'Last Modified Date':
                return `${fieldMap[expr.field].replace('.value', '.created_at')}`;
            case 'Field':
                if (expr.lookup) {
                    return `${fieldMap[hash(JSON.stringify(expr.lookup) + `${fieldMap[expr.field]}`)]}`
                }
                return `${fieldMap[expr.field]}`;
            case 'Value Equals':
                return `(${generateConditions(expr.value)} = ${generateConditions(expr.equals)})`;
            case 'Value Not Equal':
                return `(${generateConditions(expr.value)} != ${generateConditions(expr.notEqual)})`;
            case 'Value Empty':
                return `(${generateConditions(expr.value)} IS NULL OR ${generateConditions(expr.value)}::text = '')`;
            case 'Value Not Empty':
                return `(${generateConditions(expr.value)} IS NOT NULL AND ${generateConditions(expr.value)}::text != '')`;
            case 'Value Less Than':
                if (expr.value.kind === 'Field' && expr.value.lookup?.prop === 'date created') {
                    return `(${generateConditions(expr.value)} < ${generateConditions(expr.lessThan)})`;
                }
                if (DATE_KINDS.includes(expr.value.kind)) {
                    return `(${generateConditions(expr.value)} < ${generateConditions(expr.lessThan)})`;
                }
                return `(CAST(${generateConditions(expr.value)} AS NUMERIC) < CAST(${generateConditions(expr.lessThan)} AS NUMERIC))`;
            case 'Value Greater Than':
                if (expr.value.kind === 'Field' && expr.value.lookup?.prop === 'date created') {
                    return `(${generateConditions(expr.value)} < ${generateConditions(expr.greaterThan)})`;
                }
                if (DATE_KINDS.includes(expr.value.kind)) {
                    return `(${generateConditions(expr.value)} > ${generateConditions(expr.greaterThan)})`;
                }
                return `(CAST(${generateConditions(expr.value)} AS NUMERIC) > CAST(${generateConditions(expr.greaterThan)} AS NUMERIC))`;
            case 'Value Contains':
                const LIKE = expr.caseInsensitive ? 'ILIKE':'LIKE';
                if (expr.mode === 'string')
                    return `(${generateConditions(expr.value)} ${LIKE} '%'||${generateConditions(expr.contains)}||'%')`;
                return `(${generateConditions(expr.contains)}=any(string_to_array(${generateConditions(expr.value)}, ',')))`;
            case 'When':
                return `(CASE ${(expr.when || [{
                    cond: { kind: 'Never' },
                    then: expr.otherwise
                }]).map(e => 
                            `\n\tWHEN ${generateConditions(e.cond)} THEN ${generateConditions(e.then)}`).join('\n')
                        } \n\tELSE ${generateConditions(expr.otherwise)} 
                    END)`;
            case 'StringValue':
                return `'${expr.value}'`;
            case 'Transform String':
                let s = generateConditions(expr.value);
                if (expr.transform === "lowercase") {
                    return `lower(${s})`;
                } else if (expr.transform === "uppercase") {
                    return `upper(${s})`;
                } else if (expr.transform === "titlecase") {
                    return `initcap(${s})`;
                } else if (expr.transform === "trim") {
                    return `trim(${s})`;
                }
                return s;
            case 'NumericValue':
                return `CAST('${expr.value}' as NUMERIC)`;
            case 'BooleanValue':
                return `CAST('${expr.value}' as BOOLEAN)`;
            case 'Sync Status':
                return expr.mini ? "'m'" : 'null';
            case 'Current User':
                if (expr.property === 'tags') {
                    return `(select permissions from "user" where uid = '${params.context?.currentUser}')`
                }
                return `'${params.context?.currentUser || 'Current User Not Available'}'`;
            case 'Applicant Luck':
                return '_lottery.luck';
            case 'Templated Block':
                throw TEMPLATED_BLOCK_NOT_SUPPORTED;
            case 'Count':
                return `COUNT(app.uid)`;
            case 'Round':
                return `TO_CHAR(ROUND(${castAsNumericAndDefaultToZero(generateConditions(expr.value))}, ${expr.places || 0}), 'FM999999990${expr.places ? '.' + '0'.repeat(expr.places) : ''}')`;
            case 'Mean':
                return `AVG(${castAsNumericAndDefaultToZero(generateConditions(expr.value))})`
            case 'Median':
                return `PERCENTILE_CONT(0.5) WITHIN GROUP (ORDER BY ${castAsNumericAndDefaultToZero(generateConditions(expr.value))})`
            case 'Sum':
                return `SUM(${castAsNumericAndDefaultToZero(generateConditions(expr.value))})`
            case 'Add':
                if (Array.isArray(expr.secondValue)) {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) + ${expr.secondValue.map((value) => `(${castAsNumericAndDefaultToZero(generateConditions(value))})`).join(' + ')}`;
                } else {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) + (${castAsNumericAndDefaultToZero(generateConditions(expr.secondValue))})`;
                }
            case 'Subtract':
                if (DATE_KINDS.includes(expr.baseValue.kind)) {
                    if (Array.isArray(expr.secondValue)) {
                        return `EXTRACT(EPOCH FROM ((${generateConditions(expr.baseValue)}) - ${expr.secondValue.map((value) => `(${generateConditions(value)})`).join(' - ')}))`;
                    } else {
                        return `EXTRACT(EPOCH FROM ((${generateConditions(expr.baseValue)}) - (${generateConditions(expr.secondValue)})))`;
                    }
                }
                if (Array.isArray(expr.secondValue)) {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) - ${expr.secondValue.map((value) => `(${castAsNumericAndDefaultToZero(generateConditions(value))})`).join(' - ')}`;
                } else {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) - (${castAsNumericAndDefaultToZero(generateConditions(expr.secondValue))})`;
                }
            case 'Multiply':
                if (Array.isArray(expr.secondValue)) {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) * ${expr.secondValue.map((value) => `(${castAsNumericAndDefaultToZero(generateConditions(value))})`).join(' * ')}`;
                } else {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) * (${castAsNumericAndDefaultToZero(generateConditions(expr.secondValue))})`;
                }
            case 'Divide':
                if (Array.isArray(expr.secondValue)) {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) / ${expr.secondValue.map((value) => `(${castAsNumericAndDefaultToZero(generateConditions(value))})`).join(' / ')}`;
                } else {
                    return `(${castAsNumericAndDefaultToZero(generateConditions(expr.baseValue))}) / (${castAsNumericAndDefaultToZero(generateConditions(expr.secondValue))})`;
                }
            case 'As Date':
                return `CASE ` +
                    `WHEN ${generateConditions(expr.value)} IS NULL OR ${generateConditions(expr.value)} = '' THEN null ` +
                    `WHEN ${generateConditions(expr.value)} ~ '^\\w{3}\\s\\w{3}\\s\\d{2}\\s\\d{4}' ` + // matches e.g. Mon Jan 01 2022
                        `THEN to_timestamp(substring(${generateConditions(expr.value)} from 1 for 24), 'DY Mon DD YYYY HH24:MI:SS') AT TIME ZONE (substring(${generateConditions(expr.value)} from 29 for 3)) ` +
                    `WHEN ${generateConditions(expr.value)} ~ '^\\d{4}-\\d{2}-\\d{2}T' ` + // matches e.g. 2023-08-31
                        `THEN to_timestamp(${generateConditions(expr.value)}, 'YYYY-MM-DD"T"HH24:MI:SS.US') ` +
                    `ELSE CAST(${generateConditions(expr.value)} AS TIMESTAMP) ` +
                `END`;
            case 'Shift Date':
                return `(${generateConditions(expr.value)} 
                    + interval '${expr.interval.years || 0} years'
                    + interval '${expr.interval.months || 0} months'
                    + interval '${expr.interval.weeks || 0} weeks'
                    + interval '${expr.interval.days || 0} days'
                    + interval '${expr.interval.hours || 0} hours'
                    + interval '${expr.interval.minutes || 0} minutes')`;
            case 'Now':
                return 'NOW()';
            case 'Bucket Date':
                return `date_trunc('${expr.bucket}', ${generateConditions(expr.value)})`;
            case 'Code Boolean Expr':
                return 'FALSE';
            case 'Expand':
                return `jsonb_array_elements_text(${fieldMap[expr.value]}::jsonb)`
            case 'Concat':
                return expr.value.map(generateConditions).map(c => `(${c})`).join("|| '" + (expr.delimiter ?? ' ') + "' || ");
            case 'Extract':
                const path = Array.isArray(expr.path) ? expr.path : [expr.path];
                const projection = path.reduce((p, f, i) => p + `${(i + 1) === path.length ? '->>' : '->'}'${f}'`, '')
                if(typeof expr.value === 'string') {
                    return `(${fieldMap[expr.value]}::json)${projection}`;
                } else {
                    return `(${generateConditions(expr.value)}::json)${projection}`
                }

            default:
                let _: never = expr;
        }
        return '';
    }

    let joins = '';
    for (var i = 0; i < fields.length; i++) {
        if (fields[i] === 'uid') {
            fieldMap['uid'] = 'app.uid';
            continue;
        }

        if (fields[i] === '_luck' && !fieldMap['_luck']) {
            fieldMap['_luck'] = '_lottery.luck';
            joins += '\nLEFT JOIN lottery _lottery ON _lottery.uid = app.uid';
            continue;
        }

        if (!fieldMap[fields[i]]) {
            const fieldName = fields[i].replace(/[^a-zA-Z0-9_]/g, '_'); // Sanitize the field name to contain only alphanumeric characters and underscores
            joins += `
    LEFT JOIN applicantinfo ${fieldName}_${i} ON ${fieldName}_${i}.applicant = app.uid
        AND ${fieldName}_${i}.key = '${fields[i]}' ${params.includeNonLatest ? '' : `AND ${fieldName}_${i}.latest = true`}`;
            fieldMap[fields[i]] = `${fieldName}_${i}.value`;
        }
    }

    // Lookups involve joining against a non-applicantinfo table.
    // To do this, we first join against the applicantinfo table,
    // then join against the lookup table.
    // We start the index at fields.length to avoid collisions with regular fields,
    // and so to index into the lookups array, we have to use i - fields.length
    for (var i = fields.length; i < fields.length + lookups.length; i++) {
        const lookup = lookups[i - fields.length].lookup!;
        // We use this map to decouple the prop names in the type definition from the column names in the DB
        const lookupTableColumn = getColumnFromLookup(lookup);
        const lookupAlias = `${lookup.kind}_${lookupTableColumn}_${i}`;

        // Lookups always have an accompanying field, and to reference it the lookup query,
        // we need to find it in the fieldMap via lookup[x].field.
        let foreignField = `${fieldMap[lookups[i - fields.length].field]}`;

        // The key in the fieldMap is a hash of the lookup + the foreign field, to ensure that even the same lookup 
        // has a different key if it's used in a different place in the query.
        const fieldMapKey = hash(JSON.stringify(lookup) + foreignField);

        // payment lookups look up by target field key, not value. So we don't want the fieldMap value, we just want the field when building the query.
        if (lookup.kind === 'Payment') {
            foreignField = `'${lookups[i - fields.length].field}'`;
        }
        if (!fieldMap[fieldMapKey]) {

            fieldMap[fieldMapKey] = `${lookupAlias}.${lookupTableColumn}`;

            switch(lookup.kind) {
                case 'Message':
                    if (lookup.prop === 'needs_attention') {
                        if (lookup.messageType === 'sms') {
                            joins += `\n    LEFT JOIN (SELECT source, COUNT(*) AS ${lookupTableColumn} FROM "message" WHERE ${lookupTableColumn} = true GROUP BY source) ${lookupAlias}
                                on SUBSTRING(${lookupAlias}.source FROM 3) = ${foreignField} AND ${foreignField} IS NOT NULL AND ${foreignField} != ''`;
                        } else { // email
                            joins += `\n    LEFT JOIN (SELECT source, COUNT(*) AS ${lookupTableColumn} FROM "message" WHERE ${lookupTableColumn} = true GROUP BY source) ${lookupAlias}
                                on ${lookupAlias}.source = ${foreignField} AND ${foreignField} IS NOT NULL AND ${foreignField} != ''`;
                        }
                    }
                    break;
                case 'User':
                    joins += `\n    LEFT JOIN "user" ${lookupAlias} ON ${lookupAlias}.uid = ${foreignField}`;
                    break;
                case 'Payment':
                    if (lookup.prop === 'transaction ref') {
                        joins += `\n LEFT JOIN (
                            SELECT DISTINCT ON (SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2), SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3))
                                SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2) AS uid,
                                transaction.${lookupTableColumn} AS ${lookupTableColumn}
                            FROM rc_public.action
                            LEFT JOIN rc_public.dwolla_float_ach_grant df ON df.action_id = action.id
                            LEFT JOIN rc_public.load_usio_card luc ON luc.action_id = action.id
                            LEFT JOIN rc_public.usio_mailed_grant_action um ON um.action_id = action.id

                            LEFT JOIN rc_public.usio_transaction on luc.reference = reverse(split_part(reverse(scraped_data->>'type'),' ',1))
                            LEFT JOIN rc_public.dwolla_transaction on df.issued_transaction_id::uuid = dwolla_transaction.transaction_id
                            LEFT JOIN rc_public.transaction on (transaction.id = usio_transaction.transaction_id or transaction.id = dwolla_transaction.transaction_id or transaction.dynamic_kind = 'givecard')

                            WHERE SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3) = ${foreignField}

                            ORDER BY SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2), SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3), transaction.created_date DESC
                        ) AS ${lookupAlias} ON ${lookupAlias}.uid = app.uid
                        `;
                    } else if (lookup.prop === 'amount') {
                        joins += `\n LEFT JOIN (
                            SELECT DISTINCT ON (SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2), SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3))
                                SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2) AS uid,
                                entry.${lookupTableColumn} / -100.00 AS ${lookupTableColumn}
                            FROM rc_public.entry 
                            JOIN rc_public.action ON entry.action_id = action.id
                            LEFT JOIN rc_public.dwolla_float_ach_grant df ON df.action_id = action.id
                            LEFT JOIN rc_public.load_usio_card luc ON luc.action_id = action.id
                            LEFT JOIN rc_public.usio_mailed_grant_action um ON um.action_id = action.id

                            WHERE SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3) = ${foreignField}

                            ORDER BY SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2), SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3), action.created ASC
                        ) AS ${lookupAlias} ON ${lookupAlias}.uid = app.uid
                        `;
                    } else {
                        joins += `\n LEFT JOIN (
                            SELECT DISTINCT ON (SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2), SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3))
                                SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2) AS uid,
                                action.${lookupTableColumn} AS ${lookupTableColumn}
                            FROM rc_public.entry 
                            JOIN rc_public.action ON entry.action_id = action.id
                            LEFT JOIN rc_public.dwolla_float_ach_grant df ON df.action_id = action.id
                            LEFT JOIN rc_public.load_usio_card luc ON luc.action_id = action.id
                            LEFT JOIN rc_public.usio_mailed_grant_action um ON um.action_id = action.id

                            WHERE SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3) = ${foreignField}

                            ORDER BY SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 2), SPLIT_PART(COALESCE(df.reference, luc.reference, um.reference, action.dynamic_reference), ':', 3), action.created DESC
                        ) AS ${lookupAlias} ON ${lookupAlias}.uid = app.uid
                        `;
                    }
                    break;
            }
        }
    }

    let allColumns: (ValueExpr | SummaryExpr | NamedExpression | 'app.uid')[] | undefined;
    if (isSummary) {
        allColumns = [...updatedColumns || [], ...params.groups || []];
    } else if (updatedColumns && updatedColumns.length) {
        allColumns = ['app.uid', ...updatedColumns];
    } else {
        allColumns = ['app.uid'];
    }

    // This prevents empty unnested rows from being counted, unless countEmpty is true.
    let unnestedWhereClauses = '';
    let query = `\nSELECT ${(allColumns && allColumns.length) && allColumns.map((c, i) => {
        if (c === 'app.uid') {
            return c;
        }
        c = c as ValueExpr | SummaryExpr;
        let v = generateConditions(c);
        if (params.context?.lateralUnnestingInDashboards === true) {
            // If this is a group by column, we want to wrap it to handle comma separated fields
            if (isSummary && !DATE_KINDS.includes(c.kind)) {
                if (i > (params.columns?.length || 0) - 1) {
                    if (params.allowCommasInGroups) {
                        v = params.countEmpty
                            ? `coalesce(${v}, 'empty')`
                            : v;
                    } else if ('field' in c) {
                        v = `unnested."${c.field}"`
                        joins += `\nLEFT JOIN LATERAL unnest(string_to_array(${fieldMap[c.field]}, ',')) AS unnested("${c.field}") ON TRUE`;
                        if (!params.countEmpty) {
                            unnestedWhereClauses += ` AND (unnested."${c.field}" IS NOT NULL AND unnested."${c.field}" != '')`;
                        }
                    } else {
                        const str = params.countEmpty
                            ? `coalesce(${v}, 'empty')`
                            : v;
                        v = `unnest(string_to_array(${str}, ','))`;
                    }
                }
            }
        } else {
            // If this is a group by column, we want to wrap it to handle comma separated
            // fields
            if (isSummary && !DATE_KINDS.includes(c.kind)) {
                // if the column is outside the updatedColumns list, we know it was added as a group. If we don't allow 
                // commas in our groups, then we attempt to unnest.
                if (i > updatedColumns.length - 1) {
                    const str = params.countEmpty
                        ? `coalesce(${v}, 'empty')`
                        : v;
                    v = params.allowCommasInGroups ? v : `unnest(string_to_array(${str}, ','))`;
                }
            }
        }

        const name = nameForColumn(c);
        if (name) {
            return `${v} AS "${name}"`;
        }
        return `${v} AS "Column ${i}"`;
    }).join(', ')}\nFROM applicant app\n${joins}`;

    query += '\n\nWHERE ' + (params.includeHidden ? 'TRUE' : '(hidden is null OR hidden = false)');
    query += params.cond ? ' AND ' + generateConditions(params.cond) : '';
    if (params.context?.lateralUnnestingInDashboards === true) {
        query += unnestedWhereClauses;
    }

    if (params.groups != undefined) {
        query += '\n\nGROUP BY ' + params.groups.map((g, i) => {
            if (DATE_KINDS.includes(g.kind) || params.allowCommasInGroups) {
                return `${generateConditions(g)}`;
            }
            if ('field' in g && params.context?.lateralUnnestingInDashboards === true) {
                return `unnested."${g.field}"`;
            }
            const str = params.countEmpty
                ? `coalesce(${generateConditions(g)}, 'empty')`
                : generateConditions(g);
            return (params.allowCommasInGroups ? str : `unnest(string_to_array(${str}, ','))`);
        }).join(', ');
    }

    if (params.orderBy) {
        if (params.orderBy.value === 'Random') {
            query += `\nORDER BY RANDOM() `;
        } else {
            const orderByExpanded = params.orderBy.value === 'Applicant Luck' ? '(_lottery.luck)::text' : generateConditions(params.orderBy.value);
            // The ORDER BY clause below orders numbers, then strings, then nulls, and sorts within those groups according
            // to the ordering specified in params. This avoids the problem of '22' getting ordered before '3'
            query += `\nORDER BY 
    CASE 
        WHEN (${orderByExpanded})::text ~ '^-?[0-9]+(\.[0-9]+)?$' THEN 'A'
        WHEN ${orderByExpanded} IS NOT NULL THEN 'B'
        ELSE 'C'
    END,
    CASE WHEN (${orderByExpanded})::text ~ '^-?[0-9]+(\.[0-9]+)?$' THEN ((${orderByExpanded})::text)::BIGINT END ${params.orderBy.order} NULLS LAST,
    ${orderByExpanded} ${params.orderBy.order} NULLS LAST
`
        }
    }

    return query;
}

export function CompileNudgeExprToSQL(params: {
    cond?: BooleanExpr,
    nudgeContact: string,
    targetField: string,
    followupTargetField?: string,
    followupAfter?: {
        amount: number;
        unit: "days" | "weeks" | "months";
    }
}) {
    const fields: string[] = [];
    function findFields(expr: Conditional | SummaryExpr) {
        switch (expr.kind) {
            case 'Named Expression':
                findFields(expr.expression);
                break;
            case 'And':
            case 'Or':
                expr.clauses.map(findFields);
                break;
            case 'Not': 
                findFields(expr.clause)
                break;
            case 'Equals':
            case 'Not Equal':
            case 'DoesntExist':
            case 'Exists':
            case 'Last Modified':
            case 'Last Modified Date':
            case 'Field':
                fields.push(expr.field);
                break;
            case 'Value Equals':
                findFields(expr.value);
                findFields(expr.equals);
                break;
            case 'Value Not Equal':
                findFields(expr.value);
                findFields(expr.notEqual);
                break;
            case 'Value Empty':
            case 'Value Not Empty':
                findFields(expr.value);
                break;
            case 'Value Less Than':
                findFields(expr.value);
                findFields(expr.lessThan);
                break;
            case 'Value Greater Than':
                findFields(expr.value);
                findFields(expr.greaterThan);
                break;
            case 'Value Contains':
                findFields(expr.value);
                findFields(expr.contains);
                break;
            case 'When':
                (expr.when || []).map(e => {
                    findFields(e.cond);
                    findFields(e.then);
                })
                findFields(expr.otherwise);
                break;
            case 'Transform String':
            case 'As Date':
            case 'Shift Date':
            case 'Bucket Date':
                findFields(expr.value);
                break;
            case 'Applicant Luck':
                fields.push('_luck');
                break;
            case 'NumericValue':
            case 'StringValue':
            case 'BooleanValue':
            case 'Never':
            case 'After':
            case 'Before':
            case 'Current User':
            case 'Now':
            case 'Sync Status':
                break;
            case 'Templated Block':
                throw TEMPLATED_BLOCK_NOT_SUPPORTED;
            case 'Count':
                break;
            case 'Mean':
            case 'Median':
            case 'Sum':
            case 'Round':
                findFields(expr.value);
                break;
            case 'Add':
            case 'Subtract':
            case 'Multiply':
            case 'Divide':
                findFields(expr.baseValue);
                if (Array.isArray(expr.secondValue)) {
                    expr.secondValue.forEach(value => findFields(value));
                } else {
                    findFields(expr.secondValue);
                }
                break;
            case 'Code Boolean Expr':
                break;
            case 'Expand':
                break;
            case 'Concat':
                expr.value.forEach(findFields);
                break;
            case 'Extract':
                if(typeof expr.value === 'string') {
                    fields.push(expr.value);
                } else {
                    findFields(expr.value);
                }
                break;
            default:
                let _: never = expr;
        }
    }
    if (params.cond) {
        findFields(params.cond);
    }

    fields.push(params.nudgeContact);

    function generateConditions(expr: Conditional | SummaryExpr, asObject?: boolean): string {
        switch (expr.kind) {
            case 'Named Expression':
                return generateConditions(expr.expression);
            case 'And':
                return '(' + expr.clauses.map((c) => generateConditions(c, false)).join(' AND\n ') + ')';
            case 'Or':
                return '(' + expr.clauses.map((c) => generateConditions(c, false)).join(' OR\n ') + ')';
            case 'Not': 
                return '(NOT ' + generateConditions(expr.clause) + ')';
            case 'Equals':
                return `info->>'${expr.field}' = '${expr.value}'`;
            case 'Not Equal':
                return `(info->'${expr.field}' IS NULL OR info->>'${expr.field}' != '${expr.value}')`;
            case 'Exists':
                return `(info->'${expr.field}' IS NOT NULL AND info->>'${expr.field}' != '')`;
            case 'DoesntExist':
                return `(info->'${expr.field}' IS NULL OR info->>'${expr.field}' = '')`;
            case 'Never':
                return 'FALSE'
            case 'After':
            case 'Before':
                const year = expr.date.year.toLocaleString('en-US', {minimumIntegerDigits: 4, useGrouping:false});
                const month = expr.date.month.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                const day = expr.date.day.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                const hour = expr.time.hour.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                const minute = expr.time.minute.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
                if (expr.kind === 'After') {
                    return `(NOW() > '${year}-${month}-${day} ${hour}:${minute}:00')`
                } else {
                    return `(NOW() < '${year}-${month}-${day} ${hour}:${minute}:00')`
                }
            case 'Field':
                if (asObject) {
                    return `info->'${expr.field}'`;
                }
                return `info->>'${expr.field}'`;
            case 'Value Equals':
                return `(${generateConditions(expr.value)} = ${generateConditions(expr.equals)})`;
            case 'Value Not Equal':
                return `(${generateConditions(expr.value)} != ${generateConditions(expr.notEqual)})`;
            case 'Value Empty':
                return `(${generateConditions(expr.value, true)} IS NULL OR ${generateConditions(expr.value)}::text = '')`;
            case 'Value Not Empty':
                return `(${generateConditions(expr.value, true)} IS NOT NULL AND ${generateConditions(expr.value)}::text != '')`;
            case 'Value Less Than':
                if (DATE_KINDS.includes(expr.value.kind)) {
                    return `(${generateConditions(expr.value, true)} < ${generateConditions(expr.lessThan, true)})`;
                }
                return `(CAST(${generateConditions(expr.value, true)} AS NUMERIC) < CAST(${generateConditions(expr.lessThan, true)} AS NUMERIC))`;
            case 'Value Greater Than':
                if (DATE_KINDS.includes(expr.value.kind)) {
                    return `(${generateConditions(expr.value, true)} > ${generateConditions(expr.greaterThan, true)})`;
                }
                return `(CAST(${generateConditions(expr.value, true)} AS NUMERIC) > CAST(${generateConditions(expr.greaterThan, true)} AS NUMERIC))`;
            case 'Value Contains':
                const LIKE = expr.caseInsensitive ? 'ILIKE':'LIKE';
                if (expr.mode === 'string')
                    return `(${generateConditions(expr.value)} ${LIKE} '%'||${generateConditions(expr.contains)}||'%')`;
                return `(${generateConditions(expr.contains)}=any(string_to_array(${generateConditions(expr.value)}, ',')))`;
            case 'When':
                return `(CASE ${(expr.when || [{
                    cond: { kind: 'Never' },
                    then: expr.otherwise
                }]).map(e => 
                            `\n\tWHEN ${generateConditions(e.cond)} THEN ${generateConditions(e.then)}`).join('\n')
                        } \n\tELSE ${generateConditions(expr.otherwise)} 
                    END)`;
            case 'StringValue':
                return `'${expr.value}'`;
            case 'Transform String':
                let s = generateConditions(expr.value);
                if (expr.transform === "lowercase") {
                    return `lower(${s})`;
                } else if (expr.transform === "uppercase") {
                    return `upper(${s})`;
                } else if (expr.transform === "titlecase") {
                    return `initcap(${s})`;
                } else if (expr.transform === "trim") {
                    return `trim(${s})`;
                }
                return s;
            case 'NumericValue':
                return `CAST(info->'${expr.value}' as NUMERIC)`;
            case 'BooleanValue':
                return `CAST(info->'${expr.value}' as BOOLEAN)`;
            case 'Sync Status':
                return expr.mini ? "'m'" : 'null';
            case 'Templated Block':
                throw TEMPLATED_BLOCK_NOT_SUPPORTED;
            case 'Count':
                return '';
            case 'Mean':
                return `AVG(${castAsNumericAndDefaultToZero(generateConditions(expr.value))})`
            case 'Median':
                return `PERCENTILE_CONT(0.5) WITHIN GROUP (ORDER BY ${castAsNumericAndDefaultToZero(generateConditions(expr.value))})`
            case 'Sum':
                return `SUM(${castAsNumericAndDefaultToZero(generateConditions(expr.value))})`
            case 'As Date':
                return `CASE ` +
                    `WHEN ${generateConditions(expr.value, true)} IS NULL OR ${generateConditions(expr.value)} = '' THEN null ` +
                    `WHEN ${generateConditions(expr.value)} ~ '^\\w{3}\\s\\w{3}\\s\\d{2}\\s\\d{4}' ` + // matches e.g. Mon Jan 01 2022
                        `THEN to_timestamp(substring(${generateConditions(expr.value)} from 1 for 24), 'DY Mon DD YYYY HH24:MI:SS') AT TIME ZONE (substring(${generateConditions(expr.value)} from 29 for 3)) ` +
                    `WHEN ${generateConditions(expr.value)} ~ '^\\d{4}-\\d{2}-\\d{2}T' ` + // matches e.g. 2023-08-31
                        `THEN to_timestamp(${generateConditions(expr.value)}, 'YYYY-MM-DD"T"HH24:MI:SS.US') ` +
                    `ELSE CAST(${generateConditions(expr.value)} AS TIMESTAMP) ` +
                `END`;
            case 'Shift Date':
                return `(${generateConditions(expr.value)} 
                    + interval '${expr.interval.years || 0} years'
                    + interval '${expr.interval.months || 0} months'
                    + interval '${expr.interval.weeks || 0} weeks'
                    + interval '${expr.interval.days || 0} days'
                    + interval '${expr.interval.hours || 0} hours'
                    + interval '${expr.interval.minutes || 0} minutes')`;
            case 'Now':
                return 'NOW()';
            case 'Bucket Date':
                return `date_trunc('${expr.bucket}', ${generateConditions(expr.value)})`;
            case 'Code Boolean Expr':
                return 'FALSE';
            default:
                return `${expr.kind} not supported for Nudges.`;
        }
    }

    let followupJoin = '';
    let followupCond = '';
    let mainTargetField = params.targetField;
    if (params.followupAfter && params.followupTargetField) {
        mainTargetField = params.followupTargetField;
        followupJoin = `LEFT JOIN sentnudges sn2 ON sn2.key = info->>'${params.nudgeContact}' || '_${params.targetField}'`;
        followupCond = `AND sn2.key IS NOT NULL
            AND sn2.value <> 'error'
            AND ((sn2.created_at + interval '${params.followupAfter.amount} ${params.followupAfter.unit}') < NOW())`;
    }

    // We use table "sentnudges" to keep track of nudges - for regular notifications, we use applicantinfo. 
    // but unsubmitted applicants do not exist in applicantinfo, so sentnudges serves that purpose.
    // We limit this query to 1000 since pendinginfo can get really huge for some programs.
    let query = `\n     
        SELECT info->>'${params.nudgeContact}' AS contact 
        FROM pendinginfo
        LEFT JOIN sentnudges sn1 ON sn1.key = info->>'${params.nudgeContact}' || '_${mainTargetField}'
        ${followupJoin}
        WHERE ${params.cond ? generateConditions(params.cond) : ''}
        ${followupCond}
        AND sn1.key IS NULL AND info->>'${params.nudgeContact}' <> ''
        LIMIT 1000
    `;

    return query;
}
