import { useState } from "react";
import { usePost } from "../API"
import { useLocalizedStrings } from "../Localization";

/**
 * This is for uploading barcodes and testing, use it wisely as we have
 * limited amount of backend barcode scans.
 */
export function ScanBarcodePage(props: any) {
    
    const doUpload = usePost("/dev/test/upload_and_scan_barcode");
    const L = useLocalizedStrings();
    const [file, setFile] = useState(null as null | File);
    return <>
        <h1>Scan Barcode</h1>
        <form className="w-50 p-2 m-0">
            <div className="custom-file">

            <input
                className="custom-file-input"
                id={"fileupload"}
                onChange={async (e) => {
                    if (e.target.files) {
                        setFile(e.target.files[0]);
                    }
                }}
                type="file"
                accept="*"
            />
            <label
                className={"custom-file-label"}
                htmlFor={"fileupload" }
                >
                    {L.questions.attachment.choose_files}
                </label>
            </div>
            <button type="button" disabled={!file} onClick={async (e) => {
                e.preventDefault();
                if (!file) return;
                let res = await doUpload({ image: Buffer.from(await file.arrayBuffer()) });
                alert(JSON.stringify(res));
            }}>Upload</button>
        </form>
    </>
}