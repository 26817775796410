import React, { useState, useEffect, useContext, Fragment, useRef, MutableRefObject, useMemo, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMarkdown, languageContent, safeParse, MiniAidKitLogo, AidKitLogo, safeParseValidatedFormula } from "./Util";
import { get_deployment, get_rs_host, useAPIPost, usePost } from "./API";
import InterfaceContext, { AuthContext, ConfigurationContext, ForbiddenCopy, PublicConfigurationContext, SupportedLanguage } from "./Context";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon, ArrowLongLeftIcon, ArrowLongRightIcon, CheckCircleIcon, CheckIcon, ExclamationTriangleIcon, Bars3Icon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { QuestionNode, QuestionTypes, SurveyQuestion, TerminalQuestionNode } from "./Applicant";
import { RoboScreener } from "./Components/Roboscreener";
import { langToWord, useLocalizedStrings } from "./Localization";
import { Dropdown } from "./Components/Dropdown";
import type Directory from 'aidkit/lib/directory';
import type { GenericMetadata } from "@aidkitorg/airtable/src/types";
import { evalConditional, evalDistroConditional } from "@aidkitorg/roboscreener/lib/compute/util";
import { LanguageDropdown } from "./Components/LanguageDropdown";
import { InfoDict, QuestionOption, ValidatorDict } from "./Questions/Props";
import { BooleanExpr, RichText } from "@aidkitorg/types/lib/survey";
import { useCookies } from "react-cookie";
import { PublicConfig } from "@aidkitorg/types/lib/config";
import * as v0 from "@aidkitorg/types/lib/survey";
import { useModularMarkdown } from "./Hooks/ModularMarkdown";

export function useLinkKey() {
  const cookieName = encodeURIComponent('key:' + window.location.pathname);
  const [cookies, setCookie,] = useCookies([cookieName]);

  // Look for key in URL param first
  const params = new URLSearchParams(window.location.search);
  const key = params.get("key");
  if (key) {
    if (cookies[cookieName]) {
      setCookie(cookieName, key, {
        maxAge: 3600
      });
    }
    return key;
  }

  return cookies[cookieName];
}

type Step = {
  title: string;
  completed?: boolean;
  progressed?: boolean;
  visitable?: boolean;
  visible: boolean;
  children?: JSX.Element;
}

type Steps = {
  branding?: {
    name?: string,
    logo?: string
  },
  steps: Step[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
  sequential: boolean;
  languages: SupportedLanguage[],
  noNavBar?: true,
  blockedEditBanner?: RichText | string,
}

function ContinueModal(props: { continue: () => void, restart: () => void }) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const L = useLocalizedStrings();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all z-50 sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {L.apply.application_in_progress_found}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {L.apply.would_you_like_to_continue}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 sm:ml-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => { props.continue(); setOpen(false)}}
                  ref={cancelButtonRef}
                >
                  {L.apply.continue_where_i_left_off}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => { props.restart(); setOpen(false)}}
                >
                  {L.apply.start_new_application}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function SubmittingModal(props: { content: JSX.Element }) {
  const [open, setOpen] = useState(true)
  const cancelButtonRef = useRef(null)
  const L = useLocalizedStrings();

  function close() {
    window.location.reload();
    return false;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={close}>
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >

            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all z-50">
              <div className="">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <ArrowPathIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {L.apply.submitting}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {L.apply.submitting_your_information}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function AllDoneComponent(props: { 
  content: JSX.Element,
  customSubmittedModal?: {
    title?: RichText,
    message?: RichText
  },
  info?: InfoDict,
}) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const configuration = useContext(ConfigurationContext);
  const message =
    props.customSubmittedModal?.message?.[context.lang]
    || props.customSubmittedModal?.message?.en
    || configuration.application_submit_message
    || L.apply.your_application_has_been_received;

  // If we have info, allow substitutions
  const marked = (props.info)
    ? useModularMarkdown({ content: message, info: props.info })
    : useMarkdown(message);

  return (
    <div className="max-w-4xl mx-auto applicant-led pb-36 pt-10 sm:pt-20">
      <div className="">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
          <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-4 text-center sm:mt-0 sm:text-left px-5">
          <h1 className="text-lg leading-6 font-medium text-gray-900">
            {props.customSubmittedModal?.title 
              ? <span>{(props.customSubmittedModal.title[context.lang as SupportedLanguage] || props.customSubmittedModal.title['en'])}</span> 
              : <span>{L.apply.all_done}</span>}
          </h1>
          <div className="mt-3">
            <fieldset>
              <legend className="text-gray-500">
                {marked}
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  )
}

function LogoutButton(props: {}) {
  const cookieName = encodeURIComponent('key:' + window.location.pathname)
  const [cookies, ,] = useCookies(["auth_token", cookieName]);                      
  const publicConfig = useContext(PublicConfigurationContext);

  const L = useLocalizedStrings();

  return <button type="button" 
    onClick={() => { 
        if (cookies[cookieName]) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        }
        let redirect = publicConfig.application?.login?.logoutRedirectPath;
        if (redirect?.includes('http')) {
          window.location.href = redirect;
        } else if (redirect) {
          window.location.pathname = '/p/' + redirect;
        } else {
          window.location.href = 'https://aidkit.org';
        }
    }} 
  className="rounded-md bg-indigo-50 px-2.5 py-1.5 my-1 h-8 border-0 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">
    {L.apply.close}
  </button>
              
}

function NavBar(props: Steps) {
 
  const L = useLocalizedStrings();
  const configuration = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);
  const publicConfig = useContext(PublicConfigurationContext);

  const key = useLinkKey();

  // Prioritize the Distro program config, then the airtable config, otherwise use defaults
  const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configuration.applicant_facing_logo;
  const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configuration.applicant_facing_logo_width || '150';
  const fewSteps = props.steps.filter((s) => s.visible).length >= 9 ? (s: string) => '' : (s: string) => s;

  const programName = publicConfig.name || configuration.program_name || 'AidKit Program';

  const blockedBanner = props.blockedEditBanner 
  ? typeof props.blockedEditBanner === 'object'
    ? props.blockedEditBanner[context.lang] || props.blockedEditBanner['en']
    : props.blockedEditBanner
  : undefined;
  const marked = useMarkdown(blockedBanner);

  return <>
    {!props.noNavBar && 
    <div className={"sticky top-0 " + ('md:relative') + " w-full z-50"}>
    <Disclosure>
      {(state: {open: boolean}) => (
        <>
          <header className="bg-white">
            <div className="flex max-w-7xl mx-auto py-2 px-2 sm:px-6 lg:px-8 border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-200 items-center justify-between gap-2 sm:gap-4">
              <div className={"flex-shrink sm:flex-1 justify-self-start"}>
                {props.branding?.logo && <img className="h-8 w-auto" src={props.branding.logo} alt={props.branding.name} />}
                {!props.branding?.logo && 
                  <>{applicantFacingLogo
                    ?
                      <img
                        className="w-auto max-w-[150px] max-h-[125px]"
                        alt={programName}
                        src={applicantFacingLogo}
                        width={applicantFacingLogoWidth}
                      />
                    : (
                      <>
                        <div className="hidden sm:block">
                          <AidKitLogo width={100} height={40} />
                        </div>
                        <div className="block sm:hidden">
                          <MiniAidKitLogo width={50} height={40} />
                        </div>
                      </>
                    )}</>}
              </div>
              <div className={"hidden sm:inline-block"}>
                  {props.sequential ? <>
                    <div className={"center-hack " + fewSteps("center-hack-left") + " mx-1 text-md sm:text-xs mt-1 text-gray-500 font-semibold tracking-wide uppercase "}>
                      {L.apply.step_of_steps.replace('$step',(props.steps.filter((s) => s.visible).indexOf(props.steps[props.currentStep]) + 1) + '').replace('$steps', (props.steps.filter((s) => s.visible).length) + '')}
                    </div>
                    <div className={"sm:block center-hack w-full " + fewSteps("center-hack-left") + " text-sm " + fewSteps("md:text-xl") + " text-black font-medium"}>
                      {props.steps[props.currentStep]?.title}
                    </div></> :
                    <>
                    <div className={"sm:block center-hack " + fewSteps("center-hack-left") + " text-xl mt-2 ml-4 text-black font-medium " + fewSteps('md:hidden')}>
                      {props.steps[props.currentStep]?.title}
                    </div>
                    </>
                  }
              </div>
              <div className="flex-shrink sm:flex-1 flex justify-content-end gap-1.5 sm:gap-2">
                  {key && <div className="hidden md:inline-block"><LogoutButton /></div>}
                  <LanguageDropdown {...props} />
                  {true && 
                    <>
                      <Disclosure.Button aria-label="Application Step Menu" className={"bg-white h-10 flex-none rounded-md border border-gray-300 " + fewSteps('md:hidden')}>
                        { state.open ?
                          <XMarkIcon className="block h-8 w-8" aria-hidden="true" />:
                          <Bars3Icon className="block h-8 w-8" aria-hidden="true" /> }
                      </Disclosure.Button>
                    </>}
              </div>
            </div>
            <div className={"border-t-0 border-l-0 border-r-0 border-b border-solid border-gray-200 py-2 sm:hidden"}>
                  {props.sequential ? <>
                    <div className={"center-hack " + fewSteps("center-hack-left") + " mx-1 text-md sm:text-xs mt-1 text-gray-500 font-semibold tracking-wide uppercase " + fewSteps('md:hidden')}>
                      {L.apply.step_of_steps.replace('$step',(props.steps.filter((s) => s.visible).indexOf(props.steps[props.currentStep]) + 1) + '').replace('$steps', (props.steps.filter((s) => s.visible).length) + '')}
                    </div>
                    <div className={"hidden sm:block center-hack w-full " + fewSteps("center-hack-left") + " text-sm " + fewSteps("md:text-xl") + " text-black font-medium"}>
                      {props.steps[props.currentStep]?.title}
                    </div></> :
                    <>
                    <div className={"hidden sm:block center-hack " + fewSteps("center-hack-left") + " text-xl mt-2 ml-4 text-black font-medium " + fewSteps('md:hidden')}>
                      {props.steps[props.currentStep]?.title}
                    </div>
                    </>
                  }
              </div>
          </header>
          <Disclosure.Panel className={"bg-white " + fewSteps('md:hidden')}>
          {(cb: { close: () => void }) => (
            <>{props.steps.filter((s) => s.visible).map((step, index) => {
              if (!step.visitable) return null;
              return <a
                href={step.visitable ? ("#step" + (index + 1)) : undefined}
                role="link"
                aria-disabled={!step.visitable ? 'true' : undefined}
                onClick={() => { 
                    if (step.visitable) { cb.close(); props.setCurrentStep(props.steps.indexOf(step)); }
                  }}
                className={"flex-1 group py-2 flex flex-col mb-1 mt-1 border-b-0 border-solid " + (step.progressed ? "border-indigo-600" : "border-gray-100") + " border-t-0 " + (context.textAlign === 'right' ? "hover:border-r-indigo-800 border-r-4 border-l-0 pr-2" : "hover:border-l-indigo-800 border-l-4 border-r-0 pl-2")}
              >
                {props.sequential && <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                  {L.apply.step_step.replace('$step',(index + 1) + '')}
                </span>}
                <span className={"text-black font-medium " + (props.sequential ? "text-sm": "text-lg")}>{step.title}</span>
              </a>;
            })}
            </>)}
          </Disclosure.Panel>
          <div className={"flex bg-white " + (state.open ? 'hidden ' + fewSteps('md:flex') : '')}>
            {props.steps.filter((s) => s.visible).map((step, index) => 
              <div
                key={`div-step-${index}`}
                className={"flex-1 flex group pl-2 " + fewSteps('md:py-2') + " flex flex-row ml-0.5 mr-0.5 border-b-0 border-r-0 border-solid " + ((props.sequential ? step.progressed : props.currentStep === index) ? "border-indigo-600" : "border-gray-100") + " hover:border-t-indigo-800 border-l-0 border-t-4"}
              >
                {props.sequential && <span className={"hidden " + fewSteps("md:flex") + " w-6 h-6 mt-1 mr-3 flex items-center justify-center rounded-full " + 
                  (step.completed ? 'bg-indigo-600' : 'text-indigo-600 w-7 h-7 text-xs font-semibold border-solid border-2 border-indigo-600')}>
                  {step.completed === true ? 
                    <CheckIcon className="w-4 h-4 text-white" aria-hidden="true" />
                    : (index + 1)}
                </span>}
                <a 
                  href="#"
                  onClick={() => { 
                    !props.blockedEditBanner && step.visitable && props.setCurrentStep(props.steps.indexOf(step)); 
                  }}
                  className={props.sequential ? "hidden " + fewSteps('md:block') : "m-auto hidden " + fewSteps('md:block')}>
                  {props.sequential && <span className={"hidden " + fewSteps('md:block') + " text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800"}>
                    {L.apply.step_step.replace('$step', (index + 1) + '')}
                  </span>}
                  <span className={"hidden " + fewSteps('md:block') + " text-black font-medium " + (props.sequential ? "text-sm" : "text-xl")}>{step.title}</span>
                </a>
              </div>
            )}
          </div>
        </>)}
    </Disclosure>
    </div>}
    <div className={props.noNavBar ? 'pt-4' : 
      (((context.banner && context.banner?.[context.lang]) || blockedBanner) ? 
        "pt-8 md:pt-4" : "pt-8")}>
    {(context.banner && context.banner?.[context.lang]) &&
      <div className={"rounded-md p-4 flex-1 flex flex-col " + context.banner?.['__style']}>
        {context.banner?.[context.lang]}
      </div>}
    {blockedBanner &&
      <div className="flex rounded-md bg-yellow-50 border-2 border-yellow-300 p-2 justify-around">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-7 w-7 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className="text-sm text-yellow-700 -mb-4">
              <p>{marked}</p>
            </div>
          </div>
        </div>
      </div>}
    {props.steps[props.currentStep]?.children}
    </div>
  </>
}

type CreateImmutable<Type> = {
  +readonly [P in keyof Type]: Type[P]
}

type MappedContentTypes = {
  [Language in SupportedLanguage]?: string
}

type Question = {
      'Field Type': string,
      Question: string,
      'English Content': string,
      'Spanish Content': string,
      'Target Field'?: string,
      "Options (if relevant)"?: QuestionOption[],
      'Conditional On'?: string,
      'Conditional On Value'?: string[],
      Metadata?: string,
      'Additional Options'?: string[],
      computeEnabled: (info: InfoDict) => boolean | Promise<boolean>,
      currentlyEnabled?: boolean,
      complete?: boolean,
      lastVisibleQuestion?: boolean
}

type Sections = {
  info?: InfoDict,
  branding?: {
    name?: string,
    logo?: string,
  },
  options?: Record<string, any>,
  sections?: Array<MappedContentTypes & {
    'English Content': string,
    'Spanish Content': string,
    complete?: boolean,
    currentlyEnabled?: boolean,
    computeVisible: (info: InfoDict) => boolean | Promise<boolean>,
    currentlyVisible?: boolean,
    hideNextButton?: boolean,

    'Conditional On'?: string,
    'Conditional On Value'?: string,

    'Metadata'?: {
      'dashboard'?: boolean
    }
    Questions: Question[]
  }>,
}

function Section(props: { 
    section: Required<Sections>['sections'][number],
    sequential: boolean,
    next?: () => void,
    nextName?: string,
    previous?: () => void,
    previousName?: string,
    submit?: () => Promise<void>,
    saveAuth: (auth: string | null) => void,
    loadInfo?: (info: InfoDict, token: string) => void, 
    refreshInfo?: () => Promise<void>,
    setInfoKey: (
      key: string,
      value: any,
      valid: boolean,
      disqualifies: boolean
    ) => Promise<void>,
    info?: any,
    uid?: string,
    Viewer: 'applicant' | 'screener',
    blockFurtherEdits: boolean,
    disableInputs?: boolean,
    hideNextButton?: boolean,
    hideBottomButtons?: boolean,
    sectionBottomButtons?: { logoutRedirectPath: string }
 }) {
  

  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);
  const config: PublicConfig = useContext(PublicConfigurationContext);
  const bottomButtons = props.sectionBottomButtons ?? config.interface?.sectionBottomButtons;
  const checkpoint = usePost("/subsurvey/checkpoint");

  const { localid } = useParams<{localid?: string}>();
  const applicantToken = window.sessionStorage.getItem('auth:' + localid);

  // Generate infoValid object by mapping the truthiness of info object properties
  const infoValid = useMemo(() => {
    const infoValid: Record<string, boolean> = {};
    for (const key in props.info) {
      infoValid[key] = !!props.info[key];
    }
    return infoValid;
  }, [props.info]);

  const [unstuckIds, setUnstuckIds] = useState<string[]>([]);
  const clearApplicantSticky = (id: string) => setUnstuckIds(prevIds => [...prevIds, id]);
  const shouldObserveSticky = useCallback((id: string) => !unstuckIds.includes(id), [unstuckIds]);

  if (props.blockFurtherEdits) {
    return <></>;
  }

  return <>
    {props.sequential && props.previous && <QuestionNode
      icon={ArrowLeftCircleIcon} 
      key={props.section["English Content"] + "-question-first"}
    >
      <button
        type="button"
        onClick={() => { props.previous?.(); window.scrollTo(0,0); }}
        className={
          config.experimental?.newNavigationButtonStyle ? 
            "rounded-lg border-black bg-gray-600 px-4 py-2 text-sm font-medium text-gray-50 hover:outline hover:outline-gray-500" :
            "inline-flex items-center -mt-2 px-4 py-2 border border-gray-300  text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        }
      >
        {L.apply.previous_with_name.replace('$previous', props.previousName || '')}
      </button>
    </QuestionNode>}
    {props.section.Questions.map((question, index) => {
      const { keepVisibleForReview }: { keepVisibleForReview?: boolean } = safeParse(question.Metadata || '{}');
      return question.currentlyEnabled ? (
        <div
          className={keepVisibleForReview && shouldObserveSticky(question.Question) ? 'sticky top-0 bg-white z-[9]' : ''}
          key={props.section["English Content"] + "-question-" + index}
        >
          {React.createElement(SurveyQuestion, {
            ...question,
            index,
            uid: props.uid,
            Viewer: props.Viewer || 'applicant',
            setInfoKey: props.setInfoKey,
            info: props.info,
            infoValid,
            Submit: props.submit,
            LoadInfo: props.loadInfo,
            saveAuth: props.saveAuth,
            refreshInfo: props.refreshInfo,
            pageNext: props.next,
            provisional: true,
            selfServe: true,
            blockQuestionEdits: props.disableInputs || props.blockFurtherEdits,
            lastQuestion: (!props.next || !props.sequential || props.hideNextButton) && index === props.section.Questions.length - 1 || question.lastVisibleQuestion,
            clearApplicantSticky,
          })}
        </div>
      ) : null;
    })}
    {props.sequential && props.next && props.section.complete && !bottomButtons && !props.hideNextButton &&
      <TerminalQuestionNode
        icon={ArrowRightCircleIcon} 
        key={props.section["English Content"] + "-question-last"}
      >
        <button
            type="button"
            onClick={() => { props.next && props.next(); window.scrollTo(0,0)}}
            className={
            config.experimental?.newNavigationButtonStyle ? 
              "rounded-lg border-black bg-gray-600 px-4 py-2 text-sm font-medium text-gray-50 hover:outline hover:outline-gray-500" :
              "inline-flex items-center -mt-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }
          >
            {L.apply.next_with_name.replace('$next', props.nextName || '')}
        </button>
      </TerminalQuestionNode>
    }
    { bottomButtons && !props.hideBottomButtons && (
      <div className={"survey-question relative pb-8 overflow-clip mt-24"}>
        <div className="flex flex-col space-y-4">
          <div className={'pt-1.5'}>
          <div className='flex justify-between space-x-2 mb-10 max-w-sm'>
            <button type="button"
              onClick={() => { props.previous?.(); window.scrollTo(0, 0); }}
              disabled={!props.previous}
              className={`inline-flex items-center justify-center w-1/2 px-4 py-2 border text-sm font-medium rounded-md ${props.previous ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-100 hover:outline hover:outline-gray-500' : 'border-gray-200 text-gray-400 bg-gray-100 cursor-not-allowed'}`}
            >
              <ArrowLongLeftIcon className="h-4 w-4 mr-2" />
              {L.applicant.back}
            </button>
            {!props.hideNextButton && <button type="button"
              onClick={() => { props.next && props.next(); window.scrollTo(0, 0) }}
              disabled={!props.next || !props.section.complete}
              className={`inline-flex items-center justify-center w-1/2 px-4 py-2 border text-sm font-medium rounded-md ${(props.next && props.section.complete) ? 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500' : 'border-gray-200 text-gray-400 bg-gray-100 cursor-not-allowed'}`}
            >
              {L.applicant.next}
              <ArrowLongRightIcon className="h-4 w-4 ml-2" />
            </button>}
          </div>

            { applicantToken && (
              <div className="flex flex-justify-center max-w-sm">
                < button type="button"
                  onClick={async () => {
                    if (confirm(L.apply.confirm_logout)) {
                      await checkpoint({
                        form_name: 'apply',
                        info: props.info || {},
                      });
                      window.sessionStorage.clear()
                      window.location.href = '/' + bottomButtons.logoutRedirectPath;
                    }
                  }}
                  className="px-4 py-2 w-100 border border-gray-300 text-sm font-medium rounded-md text-red-500 bg-white hover:bg-gray-100 hover:outline hover:outline-gray-500"
                >
                  { L.apply.save_and_log_out }
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    )}
    <div className="mb-40"></div>
  </>
}

function updateVisibilityForSelect(question: Question, info: InfoDict) {
  // if SelectFromData, get options from source field, else use "Options (if relevant)"
  let options: QuestionOption[] = []
  const metadata = safeParse(question['Metadata'] || '{}');
  const sourceValue = safeParse(info[metadata.source_field] || '""');
  if (metadata.source_field && Array.isArray(sourceValue)) {
      options = sourceValue.map((i: string) => ({'Name': i, "English Text": i} as QuestionOption));
  } else if ( question["Options (if relevant)"] ) {
      options = question["Options (if relevant)"];
  }

  // For single select, return true if the target field is set for a non-other question,
  // or if the target field is set to other and the other field is set.
  if (question['Field Type'] === 'Single Select') {
    for (const option of options) {
      if (option.Name === 'other' || option["Other Field"]) {
        if (info[question["Target Field"] || ''] === option.Name && !!info[question["Target Field"] + "_" + option.Name]) {
          return true;
        }
      } else if (info[question["Target Field"] || ''] === option.Name) {
        return true;
      }
    }
  // For multiple select, return true if all of the selected options are non-other questions,
  // or if all other fields are set.
  } else if (question['Field Type'] === 'Multiple Select') {
    const optionsString = info[question["Target Field"] || ''] || '';
    if (!optionsString) return false;

    const selectedOptions = optionsString.split(',').filter((x) => x).reduce((acc, cur) => { acc[cur] = true; return acc; }, {} as Record<string, boolean>);

    const opts = (options).filter((opt) => selectedOptions[opt.Name]);

    if (!opts.length) return false;

    for (const option of opts) {
      if ((option.Name === 'other' || option["Other Field"]) && !info[question["Target Field"] + "_" + option.Name]) {
          return false;
      }
    }
    return true
  } else {
    throw new Error('updateVisibilityForSelect called on non-select question');
  }
  return false;
}

async function UpdateVisibility(sections: Sections, info: InfoDict) {
  let completeSoFar = true;
  let previousComplete = true;
  for (const section of sections?.sections || []) {
    // console.debug("Updating visibility for section: ", section["English Content"]);
    section.currentlyEnabled = completeSoFar;
    section.currentlyVisible = await section.computeVisible(info);
    // console.debug("Visibility is: ", section.currentlyVisible);
    if (section.currentlyVisible) {
      const showQuestion: boolean[] = [];
      let complete = true;
      let lastQ = null;
      for (const question of section.Questions) {
        //console.log("Question computeEnabled for question " + question.Question + ": " + question.computeEnabled(info));
        if (await question.computeEnabled(info)) {
          lastQ = question;

          // Mark completeness for a question with sub-questions based on whether all sub-questions are complete
          complete = CheckQuestionComplete(question, info, complete);
          // console.log("Checking question: ", question, complete);
        } else {
          question.currentlyEnabled = false;
        }
      }
      if (complete && lastQ && lastQ !== section.Questions[section.Questions.length - 1]) {
        lastQ.lastVisibleQuestion = true;
      }
      completeSoFar = completeSoFar && complete;
      section.complete = completeSoFar;
    } 
    if (previousComplete) {
      section.currentlyEnabled = true;
    } else {
      section.currentlyEnabled = false;
    }
    previousComplete = completeSoFar;
  }
  //console.log("Visibility", sections)
}

type SubmitInfo = (submit_key?: string, options?: { info: InfoDict }) => Promise<any>;

export function CheckQuestionComplete(question: Question, info: InfoDict, complete: boolean) {
  // Assume things are complete unless found otherwise
  question.currentlyEnabled = complete;
  question.complete = true;
  question.lastVisibleQuestion = false;

  if (question['Field Type'] === 'Likert' || question['Field Type'] === 'Number With Unit') {
    const metadata = safeParse(question['Metadata'] || '{}') as { questions: v0.Likert['questions'], choices: v0.Likert['choices'] };
    if (Array.isArray(metadata?.questions)) {
      const questions = metadata.questions;
      if (
        (question["Additional Options"] || []).indexOf("Optional") !== -1 ||
        questions.every((question: any) => !!info[question.targetField])
      ) {
        question.complete = true;
      } else {
        complete = false;
        question.complete = false;
        question.lastVisibleQuestion = true;
      }
    } else {
      const answerData = question['Target Field'] && info[question['Target Field']] ? info[question['Target Field']] : info[metadata.questions.targetField];
      const answerField = metadata.questions.answerField;
      const possibleAnswers = metadata.choices.map(c => c.value);
      if (answerData) {
        const parsedData = safeParse(answerData);
        if (Array.isArray(parsedData)) {
          const qComplete = parsedData.every(v => {
            return answerField && possibleAnswers.includes(v[answerField])
          });
          question.complete = qComplete;
          complete = qComplete;
        }
      }
    }
  } else if (question['Field Type'] === 'Ranking' && question['Target Field']) {
    const metadata = safeParse(question['Metadata'] || '{}');
    // Verify that the required number of choices in the ranking have been selected
    if (!info[question['Target Field']] || safeParse(info[question['Target Field']]!, []).filter((val: string) => !!val).length < metadata.num_choices) {
      if (!(question['Additional Options'] || []).includes('Optional')) {
        question.complete = false;
        complete = false;
        question.lastVisibleQuestion = true;
      }
    }
  } else if (question['Field Type'] === 'Single Select' || question['Field Type'] === 'Multiple Select') {
    if (updateVisibilityForSelect(question, info)) {
      question.complete = true;
    } else {
      if ((question['Additional Options'] || []).indexOf('Optional') === -1) {
        question.complete = false;
        complete = false;
        question.lastVisibleQuestion = true;
      }
    }
  } else if (question['Field Type'] === 'Liveness Detection' && question['Target Field']) {
    // console.log("Checking liveness", question["Target Field"], info[question["Target Field"]]);
    if (!info[question['Target Field']] || info[question['Target Field']]?.includes('pending')) {
      if (!(question['Additional Options'] || []).includes('Optional')) {
        question.complete = false;
        complete = false;
        question.lastVisibleQuestion = true;
      }
    }
  } else if (question['Target Field']) {
    if (((question['Additional Options'] || []).indexOf('Optional') === -1) &&
      ((question['Additional Options'] || []).indexOf('Advisory') === -1) &&
      ((question['Additional Options'] || []).indexOf('Hidden') === -1) &&
      question['Field Type'] !== 'Show Field' &&
      question['Field Type'] !== 'Show Date' &&
      !info[question['Target Field']]) {
      if (complete) {
        question.lastVisibleQuestion = true;
        complete = false;
      }
      question.complete = false;
    }
  }
  return complete;
}

export function ModularQuestionPage(props: { 
    sections: Sections, 
    uid?: string, 
    info: InfoDict | null, 
    viewInfo?: InfoDict,
    setInfo: (info: InfoDict) => void, 
    saveInfo: (info: InfoDict) => Promise<void>,
    saveAuth: (auth: string | null) => void,
    submit?: SubmitInfo,
    loadInfo?: (info: InfoDict, token: string) => void,
    refreshInfo?: () => Promise<void>,
    shouldFastForward?: MutableRefObject<boolean>,
    sequential: boolean,
    noHistory?: true,
    noNavBar?: true,
    branding?: {
      name?: string,
      logo?: string,
    },
    recompute?: number
    Viewer?: 'applicant' | 'screener',
    blockFurtherEdits?: {
      block_when: BooleanExpr,
      blocked_message: RichText
    }
  }) {

  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);
  const [roboscreener, setRoboscreener] = useState(null as RoboScreener | null);
  const [refresh, setRefresh] = useState(0);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [disableEdits, setDisableEdits] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const key = params.get('key');

  useEffect(() => {
    // When the pathname changes because the user is navigating to a different section/subsurvey, 
    // reset the selected tab to be the first tab.
    setSelectedTab(0);
  }, [window.location.pathname]);

  useEffect(() => {
    if (props.sections.sections && surveyLoaded && props.noHistory !== true && props.noNavBar !== true) {
      // console.log("Pushing step state", selectedTab + 1);
      // TODO: do something more react-standard in the future
      window.history.pushState(
        null,
        "",
        "#step" + (selectedTab + 1).toString()
      );
    }
  }, [props.sections.sections, surveyLoaded, selectedTab]);

  useEffect(() => {
    const idx = props.sections.sections?.findIndex(section => section["English Content"] === decodeURIComponent(window.location.hash.slice(1)));
    if (idx !== undefined && idx >= 0 && idx !== selectedTab) {
      setSelectedTab(idx);
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (!props.sections.sections) return;
    const formulas: Record<string, string> = {};
    for (const section of props.sections.sections || []) {
      const metadata = section.Metadata && typeof section.Metadata === 'string' && safeParse(section.Metadata || "{}");
      if (metadata?.hideNextButton) {
        section.hideNextButton = metadata?.hideNextButton;
      }
      if(metadata?.hidden) {
        section.computeVisible = () => false; 
      }
      else if (metadata?.conditional) {
        section.computeVisible = async (info: InfoDict) => await evalConditional(metadata.conditional, info, {}, {}, {
          viewInfo: props.viewInfo
        });
      } else if (section['Conditional On']) {
        if (section['Conditional On Value']) {
          if (section['Conditional On Value'][0] === '!') {
            section.computeVisible = (info: InfoDict) => !info[section['Conditional On']!];
          } else {
            section.computeVisible = (info: InfoDict) => !!(info[section['Conditional On']!] 
              && (section['Conditional On Value'] || [] as string[]).indexOf(info[section['Conditional On']!] || '') !== -1);
          }
        } else {
          section.computeVisible = (info: InfoDict) => !!info[section['Conditional On']!];
        }
      } else {
        section.computeVisible = (info: InfoDict) => true;
      }

      for (const question of section.Questions) {
        if (question['Field Type'] === "Computed") {
          formulas[question['Target Field']!] = safeParse(question['Metadata'] || '{}').formula;
        }
        if (question['Field Type'] === "Validated") {
          formulas[question['Target Field']!] = '(() => {' + safeParseValidatedFormula(question['Metadata'] || '') + '})()';
        }
        if (question['Field Type'] === "Payment") {
          question.computeEnabled = (info: InfoDict) => !!info[question['Target Field']!];
        } else if (question['Field Type'] === 'Resume Widget') {
          question.computeEnabled = (info: InfoDict) => !key;
        } else {
          if (question['Conditional On']) {
            // console.log(question['Conditional On'], question['Conditional On Value']);
            if (question['Conditional On Value']) {
              if (question['Conditional On Value'][0] === '!') {
                question.computeEnabled = (info: InfoDict) => !info[question['Conditional On']!];
              } else {
                question.computeEnabled = (info: InfoDict) => {
                  return !!(info[question['Conditional On']!] && (question['Conditional On Value'] || [] as string[]).indexOf(info[question['Conditional On']!] || '') !== -1);
                }
              }
            } else {
              question.computeEnabled = (info: InfoDict) => !!info[question['Conditional On']!];
            }
          } else {
            // Check if conditional in metadata, ensuring metadata is an object first for legacy Validated type support.
            if (question['Metadata'] && safeParse(question['Metadata'], 'safeReturn') !== 'safeReturn') {
              const metadata = safeParse(question['Metadata'] || '{}') as GenericMetadata;
              if (metadata.conditional) {
                question.computeEnabled = async (info: InfoDict) => {
                  const result = await evalConditional(metadata.conditional!, info, {}, {}, {
                    viewInfo: props.viewInfo
                  });
                  return !!result;
                }
              } else {
                question.computeEnabled = (info: InfoDict) => true; 
              }
            } else {
              question.computeEnabled = (info: InfoDict) => true;
            } 
          }
        }
      }
    }
    if (!roboscreener) {
      const rs = new RoboScreener('', formulas, props.saveInfo,  undefined); //, Object.keys(status.applicants)[0])
      setRoboscreener(rs);
    } else {
      // Reset deps
      roboscreener.formulas = formulas;
      roboscreener.collectedDependencies = false;
      // Kick off a recompute in the usual spot
      props.setInfo({...props.info})
    }
  }, [props.sections])

  useEffect(() => {
    if (roboscreener && props.recompute) {
      roboscreener.collectedDependencies = false;
      props.setInfo({...props.info})
    }
  }, [roboscreener, props.recompute]);

  useEffect(() => {
    (async () => {
      if (roboscreener && props.info) {
        roboscreener.info = props.info!;
        roboscreener.computeUpdates();
        props.setInfo(roboscreener.info);

        let localSelectedTab = selectedTab;
        if (props.sections) {
          await UpdateVisibility(props.sections, roboscreener.info);
          if (props.shouldFastForward && props.shouldFastForward.current) {
            let idx = 0;
            for (const section of props.sections.sections || []) {
              if ((section.complete && 
                  props.sections.sections?.indexOf(section) !== props.sections.sections!.length - 1) || !section.currentlyVisible) {
                  // Check if any further section is visible
                  for (let futureSectionIdx = idx + 1; futureSectionIdx < props.sections.sections!.length; futureSectionIdx++) {
                    if (props.sections.sections![futureSectionIdx].currentlyVisible) {
                      idx += 1;
                      break;
                    }
                  }
              } else {
                break;
              }
            }
            setSelectedTab(idx);
            localSelectedTab = idx;
            props.shouldFastForward.current = false;
          }

          let firstVisible = localSelectedTab;
          while (props.sections.sections && props.sections.sections![firstVisible] && props.sections.sections![firstVisible].currentlyVisible === false) {
            firstVisible += 1;
          }
          if (firstVisible !== localSelectedTab) {
            setSelectedTab(firstVisible);
          } else if (!surveyLoaded && props.sections.sections && !props.shouldFastForward) {
            // select tab based on window hash
            setSurveyLoaded(true);
            for (let i = 0; i < props.sections.sections.length; i++) {
              if (
                'step' + (i + 1) === window.location.hash.slice(1)
                && props.sections.sections[i].currentlyVisible
                && props.sections.sections[i].currentlyEnabled
              ) {
                // console.log("Selecting step", i + 1)
                setSelectedTab(i);
              }
            }
          }

          setRefresh(refresh + 1);
        }
      }
      (window as any).debuginfo = () => {
        console.log(props.info);
      }
    })();
  }, [props.info, roboscreener]);

  if (props.branding?.name) {
    document.title = props.branding.name;
  }

  useEffect(() => {
    (async () => {
      if (props.Viewer !== 'screener' && props.blockFurtherEdits?.block_when) {
        setDisableEdits(await evalDistroConditional(props.blockFurtherEdits.block_when, props.info || {}, false));
      }
    })();
  }, [props.info, props.Viewer, props.blockFurtherEdits]);

  const md = (s: string) => s;

  const L = useLocalizedStrings();

  return <div className="max-w-4xl mx-auto applicant-led pb-36">
      <NavBar noNavBar={props.noNavBar} branding={props.branding} steps={
        (props.sections.sections || []).map((section, index) => {

          let prevIndex = index - 1;
          while(prevIndex >= 0) {
            if (props.sections.sections![prevIndex].currentlyVisible) {
              break;
            }
            prevIndex -= 1;
          }

          let nextIndex = index + 1;
          while(nextIndex < props.sections.sections!.length) {
            if (props.sections.sections![nextIndex]?.currentlyVisible) {
              break;
            }
            nextIndex += 1;
          }

          return ({
          title: section[languageContent(context.lang)] || section['English Content'],
          completed: section.complete,
          visitable: section.currentlyEnabled || !props.sequential,
          visible: section.currentlyVisible || false,
          progressed: index < selectedTab + 1,
          children: <div key={'section-' + index} className={md("md:pt-4")}><Section 
            hideBottomButtons={props.sections?.options?.sectionBottomButtons?.hideOnFirstSection && index === 0}
            sectionBottomButtons={props.sections?.options?.sectionBottomButtons}
            sequential={!props.sequential && !section.Metadata?.dashboard ? false : true}
            section={section}

            next={nextIndex <= (props.sections.sections || []).length - 1 ? () => setSelectedTab(nextIndex) : undefined}
            nextName={props.sections.sections && props.sections.sections[nextIndex] ? props.sections.sections[nextIndex][languageContent(context.lang)] : nextIndex + ''}
            previous={ prevIndex >= 0 ? () => setSelectedTab(prevIndex) : undefined}
            previousName={props.sections.sections && props.sections.sections[prevIndex] ? props.sections.sections[prevIndex][languageContent(context.lang)] : prevIndex + ''}
            saveAuth={props.saveAuth}

            {...{
              ...(props.submit ? {submit: props.submit} : {}),
              ...(props.loadInfo ? {loadInfo: props.loadInfo} : {}),
              ...(props.refreshInfo ? { refreshInfo: props.refreshInfo } : {})
            }}

            setInfoKey={async (key, value, valid, disqualifies) => {
              roboscreener?.setKey(key, value);
              await UpdateVisibility(props.sections, roboscreener?.info || {});
              // console.log("rs info", roboscreener?.info );
              props.setInfo(roboscreener?.info || {});

              if (!props.sequential && props.submit && roboscreener?.info) {
                props.submit(undefined, { info: roboscreener.info })
              } 
            }}
            uid={props.uid}
            info={props.info}
            Viewer={props.Viewer || 'applicant'}
            disableInputs={!!(props.viewInfo?.readonly)}
            blockFurtherEdits={disableEdits}
            hideNextButton={section.hideNextButton}
          /></div>
        })})
      } 
      sequential={true}
      currentStep={selectedTab} 
      setCurrentStep={setSelectedTab}
      blockedEditBanner={disableEdits 
        ? props.blockFurtherEdits?.blocked_message 
        : (props.viewInfo?.readonly)
          ? L.applicant.view_only
          : undefined}
      languages={(config.languages || 'en,es').split(',') as SupportedLanguage[]}
      />
    </div>;

}

type BlockEdits = {
  block_when: BooleanExpr,
  blocked_message: RichText
}

export function SubsectionPage() {
  const params = new URLSearchParams(window.location.search);
  const { path } = useParams() as Record<string, string>;
  const key = useLinkKey();
  const viewInfo = JSON.parse(params.get("view_info") || '{}') as Record<string, string | undefined>;
  const get_survey = usePost("/subsurvey/definition");

  const initialInfo = {} as Record<string, string>;

  const specialKeys = ['key','lang','view_info'];

  for (const paramKey of Array.from(params.keys())) {
    if (paramKey && !specialKeys.includes(paramKey)) {
      initialInfo[paramKey] = params.get(paramKey)!;
    }
  }

  const [info, setInfo] = useState<InfoDict>(initialInfo);
  const [resp, setResp] = useState({} as Sections);
  const saveInfoRS = useAPIPost(get_rs_host() + "/compute_and_save");
  const [showComplete, setShowComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const registeredTimeout = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);
  const context = useContext(InterfaceContext);
  // If we're on /p/apply with no key, redirect to /apply
  const [redirect, setRedirect] = useState(path === 'apply' && !key ? '/apply' : null as null | string);
  const history = useHistory();
  const shouldFastForward = useRef(false);
  const blockFurtherEdits = useRef<BlockEdits>({} as BlockEdits);
  const [infoPendingSave, setInfoPendingSave] = useState<InfoDict | null>(null);

  useEffect(() => {
    if (redirect) history.push(redirect);

    return () => {
      if (registeredTimeout.current) {
        clearTimeout(registeredTimeout.current);
      }
    }
  }, [redirect]);

  useEffect(() => {
    (async () => {
      const response = await get_survey({
        form_name: path
      });

      if (response && response.error) {
        if (response.copy) { 
          context.setForbiddenCopy((prevState) => ({
            ...prevState,
            [path]: response.copy as any
          }));
        }
        /** We do this setRedirect to avoid any state leaks because this is in an async useEffect */
        if (response.error === 'subsurvey_expired') {
          setRedirect('/ahh/expired?path=' + path);
        }
        if (response.error === 'subsurvey_part_mismatch' || response.error === 'subsurvey_part_required') {
          setRedirect('/ahh/inaccessible?path=' + path);
        }
        if (response.error === 'subsurvey_not_found') {
          setRedirect('/404');
        }
      }

      if (response && response.sections) {
        // First map the result as the response omitting error after making things required again
        const result = response as Omit<Required<Awaited<ReturnType<typeof get_survey>>>, 'error'>;
        setResp(result as unknown as Sections); // hacky
  
        if (response?.needsKey && !key) {        
          window.location.pathname = '401';
        }
  
        if (response?.initialInfo) {
          setInfo({...response.initialInfo, ...initialInfo});
        }

        if (response.options?.should_fast_forward) {
          shouldFastForward.current = true;
        }

        if (response.options?.block_further_edits) {
          blockFurtherEdits.current = response.options.block_further_edits;
        }
      }
    })();
  }, [key]);

  useEffect(() => {
    // No Saving for now on status pages
    if (path === 'status') return;
    if (!key) return;

    if (registeredTimeout.current) {
      clearTimeout(registeredTimeout.current);
    }
    setInfoPendingSave(info);
    const timeout = setTimeout(async () => {
      registeredTimeout.current = undefined;
      if (redirect) return;
      console.log("Saving keys: ", Object.entries(info || {}).map(([k,v]) => k + ': ' + (v || '').length).join(','));
      await saveInfoRS({
          deploymentKey: get_deployment(), 
          changedKeys: info,
          token: key
      });
      setInfoPendingSave(null);
    }, 3000);

    registeredTimeout.current = timeout;
  }, [info]);

  const refreshInfo = useCallback(async () => {
    const response = await get_survey({ form_name: path })
    if (response?.initialInfo) {
      setInfo({ ...response.initialInfo, ...infoPendingSave });
    }
  }, [path, infoPendingSave]);

  const Submit = async (submit_key?: string, options?: { info: InfoDict }) => {
    if (registeredTimeout.current) clearTimeout(registeredTimeout.current);
    console.log("Saving from submit");
    const resp = await saveInfoRS({
      deploymentKey: get_deployment(), 
      changedKeys: (submit_key ? {...info, [submit_key]: (new Date()).toISOString()} : info) || {},
      token: key,
      ...(submit_key ? { submit_key } : {})
    });
    if (resp.value) {
      setShowComplete(true);
    }
    return resp;
  };

  if (showComplete) {
    return <AllDoneComponent
        info={info}
        content={<></>} 
        customSubmittedModal={resp.options?.custom_submitted_modal}
      />;
  }

  if (submitting) {
    return <SubmittingModal content={<></>} />;
  }

  return <ModularQuestionPage 
    sections={resp} 
    info={info} 
    viewInfo={viewInfo}
    setInfo={setInfo} 
    submit={Submit}
    branding={resp.branding}
    refreshInfo={refreshInfo}
    saveInfo={async (info) => {

    }}
    saveAuth={(auth) => {

    }}
    shouldFastForward={shouldFastForward}
    sequential={true}
    blockFurtherEdits={blockFurtherEdits.current} />
}

export function DashboardPage() {
  const params = new URLSearchParams(window.location.search);
  const key = useLinkKey();
  const get_survey = useAPIPost("/applicants/applicant_dashboard");
  const [info, setInfo] = useState<InfoDict>({});
  const [sectionsAndInfo, setSectionsAndInfo] = useState({} as Sections);
  const update_application = useAPIPost("/applicants/applicant_survey_update");
  const [showComplete, setShowComplete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [uid, setUid] = useState(undefined as string | undefined);

  useEffect(() => {
    (async () => {
      const response = await get_survey({
        status_token: key,
      });

      setSectionsAndInfo(response);
      if (response?.info) {
        setInfo(response.info);
      }
      if (response?.uid) {
        setUid(response.uid);
      }
    })();
  }, [key]);

  const Submit = async (submit_key?: string, options?: { info: InfoDict } ) => {
    const submitInfo = options?.info ? options.info : info;
    console.log("Submitting", submitInfo);
    setSubmitting(true);
    const resp = await update_application({
      info: (submit_key ? {...submitInfo, [submit_key]: (new Date()).toISOString()} : submitInfo),
      auth_code: key
    });
    if (resp.status === 'ok') {
      // setShowComplete(true);
      setSubmitting(false);
    }
  } 

  if (showComplete) {
    return <AllDoneComponent content={<></>} />;
  }

  if (submitting) {
    return <SubmittingModal content={<></>} />;
  }

  return <ModularQuestionPage 
    sections={sectionsAndInfo} 
    info={info}
    uid={uid}
    saveAuth={(auth) => {

    }}
    saveInfo={async (info) => {

    }}
    setInfo={setInfo} 
    submit={Submit}
    sequential={false} />;
}

function ApplicationPage() {
  const { localid } = useParams<{ localid?: string, dynamoAppId?: string }>(); 
  const params = new URLSearchParams(window.location.search);
  const localId = useRef(localid)
  const auth = useRef('');
  const configuration = useContext(ConfigurationContext);
  const marked = useMarkdown(configuration.guard_content || '');
  const publicConfig = useContext(PublicConfigurationContext);
  const programName = publicConfig.name || configuration.program_name;

  if (!programName) {
    return <></>;
  }

  let gparams = (configuration.guard_params || '').split(',').map((p: string) => p.trim());
  if (!localid && configuration.guard_params && !gparams.some((p: string) => params.has(p))) {
    return <div className="m-12 mx-auto w-96 mt-10 bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-5 sm:p-6 guard">
        {marked}
      </div>
    </div>
  }

  return <AuthContext.Provider value={{
      localId: () => {
        return localId.current!;
      },
      token: () => {
        return auth.current;
      },
      setToken: (token) => {
        window.sessionStorage['auth:' + localId.current] = token;
        auth.current = token!;
      },
      setLocalId: (id) => {
        localId.current = id;
      }
    }}>
      <ApplicationPageInner />
    </AuthContext.Provider>;
}

function ApplicationPageInner() {
  const params = new URLSearchParams(window.location.search);
  const key = useLinkKey();
  const get_survey = usePost("/subsurvey/definition");
  const submit = usePost("/subsurvey/submit");
  const getCurrentInfo = usePost("/subsurvey/resume");

  const [applicationSections, setApplicationSections] = useState({} as Sections);
  const urlParams = new URLSearchParams(window.location.hash.slice(1));
  const authContext = useContext(AuthContext);
  const context = useContext(InterfaceContext);

  const history = useHistory();
  const checkpoint = usePost("/subsurvey/checkpoint");

  const [askToContinue, setAskToContinue] = useState(false);//!!window.localStorage['pendingInfo']);
  const [showComplete, setShowComplete] = useState(false);
  const shouldFastForward = useRef(false);
  const blockFurtherEdits = useRef<BlockEdits>({} as BlockEdits);
  const registeredTimeout = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);

  const toSave = ['ref', 'propel_id']
  const initialInfo: InfoDict = {};
  for (const key of Array.from(params.keys())) {
    if (toSave.includes(key)) {
      initialInfo[key] = params.get(key)!;
    }
  }
  const [info, setInfo] = useState<InfoDict>(initialInfo);

  useEffect(() => {
    (async () => {
      const resp = await get_survey({ form_name: 'apply' });

      setApplicationSections(resp as unknown as Sections);
      if (resp?.options?.block_further_edits) {
        blockFurtherEdits.current = resp.options.block_further_edits;
      }

      if (authContext.localId?.() && window.sessionStorage['cache:' + authContext.localId()]) {
        if (window.sessionStorage['auth:' + authContext.localId()]) {
          authContext.setToken(window.sessionStorage['auth:' + authContext.localId()]);
          // TODO: Check if we shoudl resume
        }
        setInfo(JSON.parse(window.sessionStorage['cache:' + authContext.localId()]));
        console.log("Fast forwarding");
        shouldFastForward.current = true;
        // TODO: resume on page load (if revision is older) 
      } else {
        setInfo(initialInfo);
      }
    })();
  }, [key]);

  useEffect(() => {
    if (!authContext.localId) return;
    if (registeredTimeout.current) {
      clearTimeout(registeredTimeout.current);
    }
    registeredTimeout.current = setTimeout(async () => {
      registeredTimeout.current = undefined;
      if (authContext.token?.() && authContext.localId?.()) {
        // TODO: This will try to save before we've authed at the moment
        if (window.sessionStorage['rev:' + authContext.localId()] && window.sessionStorage['rev:' + authContext.localId()] !== window.sessionStorage['saved:' + authContext.localId()]) {
          await checkpoint({
            form_name: 'apply',
            info: info || {},
          });
          window.sessionStorage['saved:' + authContext.localId()] = window.sessionStorage['rev:' + authContext.localId()];
        }
      }
      console.log("Saving")
    }, 3000);
  }, [info]);

  const Submit = async (submit_key?: string) => {
    const resp = await submit({
      form_name: 'apply',
      info: (submit_key ? {...info, [submit_key]: (new Date()).toISOString()} : info) || {},
      language: context.lang,
      ...(submit_key ? { submit_key } : {})
    });
    if (resp.status === 'ok') {
      setShowComplete(true);
    }
    return resp;
  } 

  if (showComplete) {
    return <AllDoneComponent
      content={<></>}
      info={info}
      customSubmittedModal={applicationSections?.options?.custom_submitted_modal}
    />;
  }

  // Include some tailwind colors
  // bg-blue-50 text-blue-700 bg-yellow-50 bg-yellow-700 bg-red-50 bg-red-700

  if (askToContinue) {
    return <ContinueModal restart={() => {
      delete window.localStorage['pendingInfo'];
      setInfo({})
      setAskToContinue(false);
    }} continue={() => {
      const pendingInfo = JSON.parse(window.localStorage['pendingInfo']);
      shouldFastForward.current = true;
      setInfo(pendingInfo);
      setAskToContinue(false);
    }}/>
  }

  return <ModularQuestionPage 
      sections={applicationSections} 
      info={info} 
      setInfo={(newInfo) => {
        newInfo['_lang'] = context.lang;
        let id = authContext?.localId?.();
        if (!id) {
          id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
          authContext.setLocalId(id);
          const applyLang = params.get("lang") || context.lang;
          history.push('/apply/' + id + '?lang=' + applyLang);
        }
        const newSerializedInfo = JSON.stringify(newInfo);
        if (window.sessionStorage['cache:' + id] != newSerializedInfo) {
          window.sessionStorage['cache:' + id] = newSerializedInfo;
          window.sessionStorage['rev:' + id] = window.sessionStorage['rev:' + id] ? parseInt(window.sessionStorage['rev:' + id]) + 1 : 1; 
        }
        setInfo(newInfo);
      }} 
      saveAuth={(auth) => {
        authContext.setToken(auth!);
        window.sessionStorage['auth:' + authContext.localId] = auth;
      }}
      saveInfo={async (info) => {
        //console.log("SaveInfo");
      }}
      submit={Submit} 
      refreshInfo={async () => {
        shouldFastForward.current = true;
        let curResponse = await getCurrentInfo({ form_name: "apply" })
        if (curResponse.info) setInfo(curResponse.info);
      }}
      loadInfo={(info, token) => {

        // Redirect to a new local id
        const id = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
        authContext.setLocalId(id);
        authContext.setToken(token!);
        history.push('/apply/' + id);

        shouldFastForward.current = true;
        setInfo(info);
      }}
      shouldFastForward={shouldFastForward}
      sequential={true} 
      blockFurtherEdits={blockFurtherEdits.current}
      />;
}

export default ApplicationPage;
