import { ExportMetadata } from "aidkit/lib/admin/export";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { usePost } from "./API";
import { BUTTON_CLASS } from "./Util";

export function Exports(): JSX.Element {
  const getDataExportNames = usePost('/admin/getMetadataForExports');
  const exportDataAsync = usePost('/admin/exportAsync');
  const [initiateResponse, setInitiateResponse] = useState<{ status: string, message?: string } | null>(null);
  const [foundExports, setFoundExports] = useState<ExportMetadata[]>([]);

  useEffect(() => {
    if (initiateResponse?.status && initiateResponse.status !== 'ok') {
      return;
    }

    (async () => {
      const exports = await getDataExportNames({});
      if (Array.isArray(foundExports)) {
        setFoundExports(exports);
      } else {
        toast.error(exports as any);
      }
    })();

    setInitiateResponse(null);
  }, [initiateResponse]);

  const initiateExport = async (exportName: string) => {
    const response = await exportDataAsync({ exportName });
    setInitiateResponse(response);
    alert(JSON.stringify(response));
  }

  return (
    <div className="p-3">
      <h1>Exports</h1>
      <div className="ml-2 flex-1 flex sm:flex-col space-x-2 sm:space-x-0">
        <p>The table below groups exports by the name they were given. Existing exports can be downloaded by clicking on the file-name links.</p>
        <p>The "Latest Job" column shows the status of the last call to generate the export.</p>
        <p>If the latest job has any status besides "QUEUED" or "STARTED" then the "Initiate Export" button can used to initiate a new one.</p>
        <p>At this time, reports can only be generated once per day.</p>
      </div>
      <table className="table-auto min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th className="py-2.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
            <th className="py-2.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Existing reports</th>
            <th className="py-2.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Latest job</th>
            <th className="py-2.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(foundExports) && foundExports.map((foundExport) =>
            <tr className="border border-b-4 border-gray-600" key={foundExport.exportName}>
              <td className="whitespace-wrap py-4 px-2 text-sm text-gray-900">{foundExport.exportName}</td>
              <td className="whitespace-wrap py-4 px-2 text-sm text-gray-900">
                <span className="pl-2">{(foundExport.existingExports || []).length === 0 && 'No existing exports'}</span>
                <ul className="ml-4" style={{ 'listStyle': 'revert' }}>
                  {foundExport.existingExports.map(existingExport =>
                    <li className="pl-3" key={existingExport.key}>
                      {existingExport.signedUrl && <a href={existingExport.signedUrl}>{existingExport.key}</a>}
                      {existingExport.error && <p className="text-red-600">{existingExport.error}</p>}
                    </li>
                  )}
                </ul>
              </td>
              <td className="whitespace-wrap py-4 px-2 text-sm text-gray-900">
              {!foundExport.exportJob
                ? <div>No existing jobs for this export.</div>
                : foundExport.exportJob.error
                  ? <span>Error: {foundExport.exportJob.error}</span>
                  : <ul>
                      {foundExport.exportJob.created_by_uid && <li><b>Created by user ID:</b> {foundExport.exportJob.created_by_uid}</li>}
                      {foundExport.exportJob.created_by_name && <li><b>Created by name:</b> {foundExport.exportJob.created_by_name}</li>}
                      <li><b>Created at:</b> {foundExport.exportJob.created_at}</li>
                      <li><b>Started at:</b> {foundExport.exportJob.started_at}</li>
                      {foundExport.exportJob.finished_at && <li><b>Finished at:</b> {foundExport.exportJob.finished_at}</li>}
                      {foundExport.exportJob.errored_at && <li><b>Errored at:</b> {foundExport.exportJob.errored_at}</li>}
                      <li><b>Status:</b> {foundExport.exportJob.status}</li>
                      <li><b>Result:</b> {JSON.stringify(foundExport.exportJob.result)}</li>
                    </ul>
              }
              </td>
              <td className="whitespace-wrap py-4 px-2 text-sm text-gray-900">
                {foundExport.exportJob && !('error' in foundExport.exportJob) && ['QUEUED', 'STARTED'].includes(foundExport.exportJob?.status || '')
                  ? <span>Job already in progress...</span>
                  : <button className={BUTTON_CLASS + ' mb-4'} onClick={() => initiateExport(foundExport.exportName)}>
                      Initiate Export
                    </button>}
              </td>
          </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}