import { createFromRawValue, State, TrackedArray, TrackedRecord } from "@aidkitorg/typesheets/lib/collab";
import { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { InfoDict } from "./Questions/Props";
import { TailwindSwitch } from "./Components/Switch";


export function PersonaModal({
    state,
    mockInfo,
    showModal,
    setShowModal,
    activePersonaName,
    setActivePersonaName
  }: {
    state: State,
    mockInfo: InfoDict,
    showModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    activePersonaName?: string,
    setActivePersonaName: React.Dispatch<React.SetStateAction<string>>
  }) {
    const [newPersonaName, setNewPersonaName] = useState("");
    const [mode, setMode] = useState<'create' | 'update'>(activePersonaName ? 'update' : 'create');

    async function saveNewPersona() {
        const personaAttrs = Object.keys(mockInfo).map((k) => {
            return {
                field: k,
                value: mockInfo[k] ? mockInfo[k] : '',
            };
        });

        const personas = (((state as State).workingSet['root'] as TrackedRecord).value['personas'] as TrackedArray);
        const newPersona = createFromRawValue(state as State, {
            kind: 'Persona',
            name: newPersonaName,
            attrs: personaAttrs,
            });
        
        personas.insert(0, newPersona);
        setActivePersonaName(newPersonaName);
        setNewPersonaName('');
        setShowModal(false);
    }

    async function updateActivePersona() {
        const personaAttrs = Object.keys(mockInfo).map((k) => {
            return {
                field: k,
                value: mockInfo[k]!.toString(),
            };
        });

        const personas = (((state as State).workingSet['root'] as TrackedRecord).value['personas'] as TrackedArray);
        const existingPersonaIndex = personas.value.findIndex((persona: any) => persona.value.name.value === activePersonaName);

        if (existingPersonaIndex !== -1) {
            const updatedPersona = createFromRawValue(state as State, {
                kind: 'Persona',
                name: activePersonaName,
                attrs: personaAttrs,
            });

            personas.remove(existingPersonaIndex);
            personas.insert(existingPersonaIndex, updatedPersona);
        }
        setShowModal(false);
    }

    return <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center p-4 max-w-100"
            style={{ display: showModal ? 'block' : 'none', 
                zIndex: 1000 }}>
        <div className="bg-white rounded-lg shadow-xl p-6 mx-4 max-w-full overflow-scroll" style={{ height: '90vh' }}>
            <div className="border-b pb-3 mb-3">
                <h2 className="text-2xl font-semibold">{mode === 'update' ? `Updating Persona: "${activePersonaName}"` : 'Creating New Persona' + (newPersonaName ? `: "${newPersonaName}"` : '')}</h2>
            </div>
            <div className="mb-4">
                {activePersonaName ? 
                    <>
                    <form className="max-w-sm p-0">
                        <h2 className="text-2xl font-semibold mb-4">You can choose to update the existing persona, or create a new one</h2>
                        <div className="mb-4 flex flex-row space-x-2">
                            <div className="mb-4">
                                <label className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    value="update"
                                    checked={mode === 'update'}
                                    onChange={() => setMode('update')}
                                    className="form-radio text-indigo-600"
                                />
                                <span className="ml-2 text-gray-700">Update Persona</span>
                                </label>
                            </div>
                            <div className="mb-4">
                                <label className="flex items-center mb-2">
                                <input
                                    type="radio"
                                    value="create"
                                    checked={mode === 'create'}
                                    onChange={() => setMode('create')}
                                    className="form-radio text-indigo-600"
                                />
                                <span className="ml-2 text-gray-700">Save as New Persona</span>
                                </label>
                            </div>
                        </div>
                    </form>
                    <p className="h4 mb-2">{mode === 'update' ? 'Updating ' + activePersonaName : 'Creating new Persona'}</p>
                    </> : null}
                    {mode === 'create' ?
                        <>
                            <p className="h4 mb-2">Please enter persona name:</p>
                            <input 
                                className="form-control"
                                type="text" 
                                value={newPersonaName} 
                                onChange={(e) => setNewPersonaName(e.target.value)}
                            />
                        </>
                    : null}
                </div>
                
                <div className="mt-4">
                    <p className="h5 mb-2">Persona Attributes</p>
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Target Field</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(mockInfo).map((key, index) => (
                                <tr key={index}>
                                    <td>{key}</td>
                                    <td>{mockInfo[key]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="flex justify-end space-x-3">
                    <button onClick={() => setShowModal(false)} className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">Cancel</button>
                    <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                        disabled={mode === 'create' && !newPersonaName}
                        onClick={() => (mode === 'update') ? updateActivePersona() : saveNewPersona()}>
                        {(mode === 'update') ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>
        </div>
}