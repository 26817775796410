import React from 'react';
import { useLocalizedStrings } from './Localization';
import { AidKitLogo } from './Util';

export function SiteNotFound() {
    const L = useLocalizedStrings();
    
    return (
        <>
        <div className="d-flex justify-content-center">
        <AidKitLogo width={300} height={150} />
        </div>
        <div className="d-flex justify-content-center">
            {L.this_site_does_not_exist}&nbsp;<a href="https://aidkit.org" title="AidKit">{L.click_here}</a>
        </div>
        </>
    );
}