import { interfaceNumber } from "@aidkitorg/types/lib/survey";
import { NavigationNode } from "@aidkitorg/types/lib/translation/permissions";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { usePost } from "./API";
import { PublicConfigurationContext } from "./Context";

export function HomePage() {
    const publicConfigurationContext = useContext(PublicConfigurationContext);
    const getNav = usePost("/navigation");

    const [pageToRedirect, setPageToRedirect] = useState<string | null>(null);

    useEffect(() => {
        if (interfaceNumber(publicConfigurationContext?.interface?.version) > 0 ||
            publicConfigurationContext.interface?.useNewDashboards) {
            getNav({}).then((result: any) => {
                const nav = result.navigation as NavigationNode[];

                // Find the first thing with an href
                const findFirst = (nav: NavigationNode[]): NavigationNode | null => {
                    for (const node of nav) {
                        if (node.href) {
                            return node;
                        }
                        if (node.subnodes) {
                            const found = findFirst(node.subnodes);
                            if (found) {
                                return found;
                            }
                        }
                    }
                    return null;
                }

                const first = findFirst(nav);
                if (first) {
                    setPageToRedirect(first.href);
                } else {
                }
            });
        } else {
            if (publicConfigurationContext.interface) {
                setPageToRedirect("/dashboard/screened_by_me");
            }
        }
    }, [publicConfigurationContext]);

    return pageToRedirect ? <Redirect to={pageToRedirect} /> : null;
}