import React, { useContext, useEffect } from "react";
import { Dropdown } from "react-bootstrap";

import { NumberQuestion } from "./TextEntry";
import { QuestionOption, QuestionProps } from "./Props";

import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { safeParse, languageContent } from "../Util";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function NumberWithUnitQuestion(props: QuestionProps) {
	const metadata = safeParse(props.Metadata as string);
	const additionalOptions = props["Additional Options"] || [];
	const L = useLocalizedStrings();
	const context = useContext(InterfaceContext);

	const numberQuestion = metadata.questions?.find((q: any) => q.type === 'number') || {};
	const unitQuestion = metadata.questions?.find((q: any) => q.type === 'unit') || {};

	const numberProps = {
		// Formats as NumberQuestion props
		...props,
		'Target Field': numberQuestion.targetField,
		'Field Type': 'Number',
		extraClass: metadata.format === 'usd' ? 'pl-4' : '',
		noLabel: true, // Hides the NumberQuestion label since NumberWithUnit has its own label
	};

	useEffect(() => {
		// If the unit question's value has not been set, set a default value
		if (!props.info[unitQuestion.targetField]) {
			let defaultValue = unitQuestion.choices?.[0]?.Name;
			if (defaultValue) {
				props.setInfoKey(
					unitQuestion.targetField,
					defaultValue,
					true,
					false
				);
			}
		}
	}, [props.info, unitQuestion]);

	async function confirmDelete() {
		if (window.confirm(L.questions.choice.are_you_sure)) {
			// Resets all questions
			for (const question of metadata.questions) {
				props.setInfoKey(question.targetField, "", true, false);
			}
		}
	}

	const content = additionalOptions.indexOf("Formatted") !== -1
		? useModularMarkdown({
			content: props[languageContent(context.lang)] || '',
			info: props.info
		})
		: <b>{props[languageContent(context.lang)]}</b>;

	if ((additionalOptions).indexOf("Hidden") !== -1) {
		return <span></span>
	}

	return (
		<div className="w-full">
			<fieldset>
				<legend>
					{content}
					<Dropdown className="applicant-led-hidden" style={{ display: 'inline' }}>
						<Dropdown.Toggle variant="link">...</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item key={`clear-answers-for-${props[languageContent(context.lang)]}`}
								onClick={() => confirmDelete()}>
								{L.questions.choice.clear_answer}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</legend>
			</fieldset>
		<div className="flex">
			<div className="relative mt-1 rounded-md w-full sm:w-80">
				{metadata.format === 'usd' &&
					<div className="absolute inset-y-0 left-0 flex items-center pl-[0.9rem]">
						<span className="text-gray-500 sm:text-sm">$</span>
					</div>
				}
				<NumberQuestion {...numberProps} formatUsd={metadata.format === 'usd'} />
				<div className="absolute inset-y-0 right-0 flex items-center sm:text-sm">
					<label htmlFor={unitQuestion.targetField} className="sr-only">
						{unitQuestion.targetField}
					</label>
					<select
						id={unitQuestion.targetField}
						name={unitQuestion.targetField}
						className="rounded-md border-transparent bg-transparent h-11 sm:h-10 py-0 pl-2 pr-7 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm appearance-none bg-no-repeat cursor-pointer"
						// Removing browser-specific style with 'appearance-none' and manually adding chevron down icon
						style={{
							backgroundImage: `url('data:image/svg+xml;utf8,<svg width="18" fill="none" stroke="rgb(156 163 175)" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>')`,
							backgroundPosition: "right 6px bottom 45%",
						}}
						value={props.info[unitQuestion.targetField]}
						onChange={(e: any) => props.setInfoKey(unitQuestion.targetField, e.target.value, true, false)}
					>
						{(unitQuestion.choices || []).map((option: QuestionOption) => (
							<option key={option.Name} value={option.Name}>
								{(option as any)[
									languageContent(context.lang).replace("Content", "Text")
								] || (option as any)[context.lang]}
							</option>
						))}
					</select>
				</div>
			</div>
		</div>
    </div>
  );
}
