import { Switch } from "@headlessui/react";
import { Dispatch, SetStateAction } from "react";
import { useRef, useState } from "react";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}  

export function TailwindSwitch(props: { 
    checked: boolean,
    onChange: () => void,
} & ({ children: any } | { label: string, labelDescription?: string })) {

    const enabled = useRef(props.checked);

    return <Switch.Group as="div" className="mt-1 flex items-center">
        <Switch
            checked={enabled.current}
            onChange={() => { 
                props.onChange()
                enabled.current = !enabled.current; 
            }}
            className={classNames(
                enabled.current ? 'bg-indigo-600' : 'bg-gray-200',
            'relative py-0 pl-1 inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            )}
        >
            <span
                aria-hidden="true"
                className={classNames(
                    enabled.current ? 'translate-x-5' : '-translate-x-1',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
        <Switch.Label as="span" className="ml-3">
            {(props as any).children ?? null}
            {(props as any).label ? <>
            <span className="text-sm font-medium text-gray-900">{(props as any).label}</span>&nbsp;
            <span className="text-sm text-gray-500">{(props as any).labelDescription ?? ''}</span>
            </> : null}
        </Switch.Label>
    </Switch.Group>
}