import React from "react";
import { Navbar } from "react-bootstrap";
import { LanguageDropdown } from "./Components/LanguageDropdown";
import Nav from "react-bootstrap/Nav";
import { useContext } from "react";
import { ConfigurationContext } from "./Context";
import "./App.css";
import { useLocalizedStrings } from "./Localization";
import { AidKitLogo } from "./Util"; 

function Unauthorized() { 
    const configuration = useContext(ConfigurationContext); 
    const L = useLocalizedStrings();
    return (
        <>
        <Navbar className="flex">
            <Navbar.Brand>
                <AidKitLogo width={100} height={50}/>
            </Navbar.Brand>
            <Nav className="flex flex-end">
                <LanguageDropdown languages={(configuration.languages || 'en,es').split(',')}/>
            </Nav>
        </Navbar>
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-indigo-100" style={{minHeight: '100vh'}}>
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="indigo" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
            </div>
            <div className="py-16">
                <div className="text-center">
                <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">{L.unauthorized.type}</p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{L.unauthorized.title}</h1>
                <p className="mt-2 text-base text-gray-500">{L.unauthorized.message}</p>
                <div className="mt-6">
                    <a href="https://www.aidkit.org" className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                    {L.unauthorized.back}<span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>
                </div>
            </div>
            </main>
        </div>
        </>
        )
}

export default Unauthorized