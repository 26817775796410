import React, { useContext, useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";
import { languageContent, safeParse } from "../Util";
import InterfaceContext from "../Context";
import { useAPIPost } from "../API";
import Button from "react-bootstrap/Button";
import { Dropdown } from "react-bootstrap";
import { useLocalizedStrings } from "../Localization";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

function PropertyLookup(props: QuestionProps) {
  const context = useContext(InterfaceContext);
  const rawMetadata = props["Metadata"]!;
  const metadata = useMemo(() => safeParse(rawMetadata), [rawMetadata]);
  const field = metadata['address_field'];
  const lookupOwner = useAPIPost('/propertyowners/lookup_name');
  const L = useLocalizedStrings();
  const [searchAddress, setSearchAddress] = useState('');

  const property_name = (safeParse(props.info[props["Target Field"]!] || "{}") as any)["name"] || "";
  const property_addr = (safeParse(props.info[props["Target Field"]!] || "{}") as any)["addr"] || "";
  const property_source = (safeParse(props.info[props["Target Field"]!] || "{}") as any)["source"] || "";

  async function doLookup() {
    const addr = props.info[field];
    let result = await lookupOwner({ addr });
    if (result.name) {
        props.setInfoKey(props["Target Field"]!, JSON.stringify({
          name: result.name,
          addr: addr,
          source: 'api'
        }), true, false);
    }
    if (result.error === 'no_owner_found') {
        setSearchAddress(addr);
        props.setInfoKey(props["Target Field"]!, '', true, false);
    }
  }

  useEffect(() => {
    const property_addr = (safeParse(props.info[props["Target Field"]!] || "{}") as any)["addr"] || "";
    if (property_addr) {
      if (property_addr !== props.info[field]) {
        props.setInfoKey(props["Target Field"]!, '', true, false);
      }
    }
  }, [props, props.info, field]);

  function confirmDelete() {
    props.setInfoKey(props["Target Field"]!, '', true, false);
  }

  function setManually(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target?.value || "";
    if (value.length === 0) {
      props.setInfoKey(props["Target Field"]!, '', true, false);
    } else {
      props.setInfoKey(props["Target Field"]!, JSON.stringify({
        name: value,
        addr: property_addr || searchAddress,
        source: 'manual'
      }), true, false);
    }
  }

  // props.info[props["Target Field"]!] will return json array of options
  const targetField = props["Target Field"]!;
  return (
    <Form.Group>
      <div> {useModularMarkdown({
        content: props[languageContent(context.lang)] || '', 
        info: props.info,
        replacer: str => str.replace("\\(", "(").replace("\\[", "[")
      })}</div>
      { (props.info[field] && <>
      {
        ((property_addr === props.info[field] && property_source !== 'manual') && 
        <>
          <b>{property_name}</b>
          <Dropdown style={{ display: 'inline' }}>
            <Dropdown.Toggle variant="link">...</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item key={`clear-answer-for-${targetField}`}
                onClick={() => confirmDelete()}>
                {L.questions.choice.clear_answer}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>) || 
        <>
          {((searchAddress === props.info[field] || (property_source === 'manual' && property_addr === props.info[field])) &&
            <>
            {L.questions.property_lookup.not_found}
            <Form.Control
              as={props.textarea === true ? "textarea" : "input"}
              className={property_name.length > 0 ? "valid-input" : "invalid-input"}
              value={property_name}
              onChange={setManually}
            />
            </>
          ) || <>
            <Button onClick={doLookup}>{L.questions.property_lookup.lookup}</Button>
          </>
          }
        </>
      }</>) || <>{L.questions.property_lookup.please_enter_address}</>}
    </Form.Group>
  );
}

export { PropertyLookup };
