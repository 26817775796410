import { useContext } from "react";
import InterfaceContext, { SupportedLanguage } from "../Context";
import { langToWord } from "../Localization";
import { Dropdown } from "./Dropdown";

export function LanguageIcon(props: { className?:string }={}) {
  return (
    <svg height="20px" className={props.className} data-slot="icon" fill="none" strokeWidth="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"></path>
    </svg>
  )
}

  export function LanguageDropdown(props: { 
      languages: SupportedLanguage[],
      withOpen?: (open: boolean) => void,
      onPointerEnter?: () => void,
      onPointerLeave?: () => void,
      className?: string,
      startExpanded?: Parameters<typeof Dropdown>[0]['startExpanded'],
      direction?: Parameters<typeof Dropdown>[0]['direction']
     }) {
    const context = useContext(InterfaceContext);
    return <div className={`flex h-10 ${props.className || ''}`}>
      <Dropdown description="Language" 
        className="h-10"
        withOpen={props.withOpen}
        onPointerEnter={props.onPointerEnter}
        onPointerLeave={props.onPointerLeave}
        direction={props.direction}
        startExpanded={props.startExpanded}
        label={<>
        <LanguageIcon />
        <span className="ml-1.5 mr-0.5">
          {langToWord(context.lang as SupportedLanguage, 'Name')}
        </span>
      </>} options={
      props.languages.map((l: SupportedLanguage) => {
        return { label: langToWord(l, 'Name')!, callback: () => {
          console.log("Setting context language to: ", l);
          context.setLanguage(l);
        }}
      })} />
    </div>
  }