import React, { useContext, useEffect, useState } from "react";
import { Switch } from '@headlessui/react'
import { useLocalizedStrings } from "../Localization";
import { PencilSquareIcon, TrashIcon, QuestionMarkCircleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { QuestionProps } from "./Props";
import { wrapWithBdi, safeParse } from "../Util";
import * as v0 from "@aidkitorg/types/lib/survey";
import InterfaceContext from "../Context";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

function SwitchButton(props: { enabled: boolean, setEnabled: (enabled: boolean) => void }) {
    const L = useLocalizedStrings();

  return (
    <Switch
      checked={props.enabled}
      onChange={props.setEnabled}
      className={classNames(
        props.enabled ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6.5 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      )}
    >
      <span className="sr-only">{L.questions.income.use_setting}</span>
      <span
        aria-hidden="true"
        className={classNames(
          props.enabled ? 'translate-x-3' : '-translate-x-1',
          ' pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  )
}

type Income = {
    type: string,
    amount: number,
    frequency: string,
    startDate?: [string, string],
    endDate?: [string, string],
    yearround?: boolean,
}

function LabelledInput(props: { label: string, value: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
    return <div className="max-w-lg flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 py-2 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            {props.label}
        </span>
        <input
            type="text"
            value={props.value}
            onChange={props.onChange}
            className="flex-1 block border w-full pl-2 focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            style={{'borderLeft': 'none'}}
        />
    </div>;
}

function FormRow(props: {
    title: string | React.ReactNode,
    children: React.ReactNode,
}) {
    return <div className="mt">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:py-2 align-center">
        <label htmlFor="username" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
            {props.title}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2 flex">
          {props.children}
        </div>
      </div>
    </div>;
}

function Select(props: {
    name: string,
    value: string,
    size?: string,
    setValue: (value: string) => void,
    initial: string,
    options: [string, string][],
    optional?: boolean
}) {
    return <select
        id={"select_" + props.name}
        name={"select_" + props.name}
        className={`appearance-none mr-2 mt-1 shadow-sm block w-100 overflow-hidden ${props.size || ''} pl-3 bg-white pr-2 py-2 text-black text-base border-2 border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
        value={props.value || '--'}
        onChange={(e) => props.setValue(e.target.value)}
    >
        <option key="default" value="--" disabled={props.optional ? false : true}>{props.initial}</option>
        {props.options.map(([value, label]) => <option key={value} value={value}>{label}</option>)}
    </select>;
}

function FormTable(props: {
    children: React.ReactNode,
}) {
    return <div className="">
        {props.children}
    </div>
}

function Income(props: { income: Income, setIncome: (income?: Income) => any, metadata: any }) {
    const L = useLocalizedStrings();
    const context = useContext(InterfaceContext);

    const DEFAULT_JOB_KINDS: [string, string][] = [
        ["job", L.questions.income.job],
        ["self_employment", L.questions.income.self_employment],
        ["rental_royalty", L.questions.income.rental_royalty],
        ["farming_fishing", L.questions.income.farming_fishing]
    ];

    const CASH_BENEFIT_JOB_KINDS: [string, string][] = [
        ["unemployment", L.questions.income.unemployment],
        ["social_security", L.questions.income.social_security],
        ["public_benefits", L.questions.income.public_benefits],
        ["retirement", L.questions.income.retirement],
        ["child_support", L.questions.income.child_support],
        ["pension", L.questions.income.pension],
        ["investment", L.questions.income.investment],
        ["capital_gains", L.questions.income.capital_gains],
        ["alimony", L.questions.income.alimony],
        ["taxable_scholarship", L.questions.income.taxable_scholarship],
        ["cancelled_debt", L.questions.income.cancelled_debt],
        ["court_awards", L.questions.income.court_awards],
        ["prizes", L.questions.income.prizes],
        ["jury_duty", L.questions.income.jury_duty]
    ];

    const OTHER: [string, string][] = [
        ["other", L.questions.income.other]
    ];

    let jobKinds: [string, string][];
    let jobNames: Record<string, string>;

    if (props.metadata?.customJobKinds?.length > 0) {
        jobKinds = props.metadata.customJobKinds.map((jobKind: v0.CustomJobKind) => [jobKind.value, jobKind.label[context.lang]]);
        jobNames = Object.fromEntries(jobKinds);
    } else if (props.metadata?.excludeCashBenefits) {
        jobKinds = DEFAULT_JOB_KINDS.concat(OTHER);
        jobNames = Object.fromEntries(jobKinds);
    } else {
        jobKinds = DEFAULT_JOB_KINDS.concat(CASH_BENEFIT_JOB_KINDS.concat(OTHER));
        jobNames = Object.fromEntries(jobKinds);
    }

    let FREQUENCY_NAME: Record<string, string> = {
        'weekly': L.questions.income.weekly,
        'biweekly': L.questions.income.biweekly,
        'monthly': L.questions.income.monthly,
        'yearly': L.questions.income.yearly,
        'one_time': L.questions.income.one_time,
    }

    let MONTHS: [string, string][] = [
        ["01",L.months.jan],
        ["02",L.months.feb],
        ["03",L.months.mar],
        ["04",L.months.apr],
        ["05",L.months.may],
        ["06",L.months.jun],
        ["07",L.months.jul],
        ["08",L.months.aug],
        ["09",L.months.sep],
        ["10",L.months.oct],
        ["11",L.months.nov],
        ["12",L.months.dec],
    ]

    // Removes the 1 from 1 - January, 2 - February, etc.
    const MONTH_NAMES = MONTHS.map(([value, name]) => name.split(' - ').pop());

    const [editing, setEditing] = useState(props.income.type === '');
    const [yearround, setYearround] = useState(props.income.yearround ?? true);

    const [pendingIncome, setPendingIncome] = useState<Income | null>(props.income);
    const [displayAmount, setDisplayAmount] = useState(isNaN(props.income.amount) ? '' : props.income.amount);

    const [valid, setValid] = useState(false);
    const [activeIncome, setActiveIncome] = useState(props.income);

    useEffect(() => {
        if (!pendingIncome) return;

        // startDate is required but endDate is optional. 
        // if endDate is completely blank, we should count the dates as making sense.
        const endDateExists = pendingIncome.endDate && 
            ((pendingIncome.endDate[0] !== '--' && pendingIncome.endDate[0] !== '') ||
             (pendingIncome.endDate[1] !== '--' && pendingIncome.endDate[1] !== ''));
        const startDateEntered = pendingIncome.startDate && 
            ((pendingIncome.startDate[0] !== '--' && pendingIncome.startDate[0] !== '') &&
             (pendingIncome.startDate[1] !== '--' && pendingIncome.startDate[1] !== ''));

        const datesMakeSense = startDateEntered && ((!endDateExists) || 
            (new Date(`2021-${pendingIncome.endDate![0]}-${pendingIncome.endDate![1]}`).getTime() >=
                new Date(`2021-${pendingIncome.startDate![0]}-${pendingIncome.startDate![1]}`).getTime()));
        const valid = pendingIncome.type && pendingIncome.frequency && (yearround || datesMakeSense) && !(isNaN(pendingIncome.amount));

        setValid(!!valid);
        setActiveIncome(pendingIncome);
    }, [pendingIncome]);

    if (!editing) {
        if (!props.income.type) return <></>;
        return <div className="flex mb-2">
            <p className="flex-1 text-gray-600"><b>{jobNames[props.income.type]}</b>:&nbsp;${formatCurrencyNumberForDisplay(props.income.amount || 0)} {FREQUENCY_NAME[props.income.frequency]}
                {props.income.frequency !== 'one_time' && props.income.frequency !== 'yearly' &&
                (yearround 
                    ? ', year-round' 
                    : wrapWithBdi(
                        ' ' + L.date.from_to.replace('{1}', MONTH_NAMES[parseInt(props.income.startDate![0]) - 1] + ' ' + props.income.startDate![1].replace(/^0/, ''))
                                .replace('{2}', MONTH_NAMES[parseInt(props.income.endDate![0]) - 1] + ' ' + props.income.endDate![1].replace(/^0/, ''))
                    ))} 
            </p>
            <div className="w-24 flex-none">
                <button 
                    className="ml-2 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => { setPendingIncome(props.income); setEditing(true); }}>
                    <PencilSquareIcon className="inline-block h-5 w-5 text-gray-500" />
                    </button>
                <button 
                    className="ml-2 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => props.setIncome()}>
                    <TrashIcon className="inline-block h-5 w-5 text-red-500" />
                    </button>
            </div>
        </div>
    }
    
    const total = annualize(activeIncome) || 0;
    const amount = activeIncome.amount || 0;
    const multiplier = Math.round(total / amount);    
    const howOftenTooltip = <Tooltip id="how-often-tooltip">
        <>{
        // because the explanation might translate phrases like "weekly" or "yearly" differently than
        // they are represented in the "How often" dropdown. So we do string replacement after the fact
        // to guarantee that the translation is consistent
        L.questions.income.how_often_explanation
            .replaceAll(/(\$|\{)+weekly\}*/ig, L.questions.income.weekly)
            .replaceAll(/(\$|\{)+yearly\}*/ig, L.questions.income.yearly)

        }</> 
    </Tooltip>;

    const howOftenSummary : string[] = [
        L.questions.income.how_often_summary
            .replaceAll(/(\$|\{)+frequency\}*/ig, (L.questions.income[activeIncome.frequency as keyof typeof L.questions.income] as string)?.replaceAll('_', ' '))
            .replaceAll(/(\$|\{)+amount\}*/ig, `﹩${amount.toString()}`)
    ];
    if(multiplier > 1){
        howOftenSummary.push(
            L.questions.income.how_often_summary_multiplier_statement
                .replaceAll(/(\$|\{)+multiplier\}*/ig, multiplier.toString())
                .replaceAll(/(\$|\{)+total\}*/ig, `﹩${formatCurrencyNumberForDisplay(total)}`)
        );
    }

    const howOftenSummaryNode = activeIncome.frequency && <div className="p-2 my-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md bg-gray-100 flex gap-1">
        <InformationCircleIcon className="w-6 h-6 text-blue-500 shrink-0" />
        <div>
            {howOftenSummary.join(' ')}
        </div>
    </div>


    return <div className="border-t-2 border-b border-gray-400 mb-2" style={{
        'borderTop': '1px solid #ddd',
        'borderBottom': '1px solid #ddd',
    }}>
        <FormTable>
            <FormRow title={L.questions.income.type}>
                <Select name="type" value={activeIncome.type} setValue={(value) => setPendingIncome({...activeIncome, type: value})} initial="--" options={jobKinds} />
            </FormRow>
            <FormRow title={L.questions.income.amount}>
                <LabelledInput label="$" value={displayAmount?.toString() || ''} 
                    onChange={(e) => {
                        let amt = (e.target.value || '').replace(/[^0-9.]/g,'');
                        let parts = amt.split('.'); // Split the string into parts divided by the decimal point
                        if (parts.length > 2) { // If there are more than one decimal points
                            parts = parts.slice(0, 2); // Keep only the first two parts
                        }
                        if (parts[1]) { // If there's a decimal part
                            parts[1] = parts[1].slice(0, 2); // Keep only the first two digits after the decimal
                        }
                        if (parts[0]) {
                            // Format the number with commas and remove any non-digit characters introduced by the formatter
                            parts[0] = new Intl.NumberFormat().format(parseInt(parts[0])).replace(/[^\d,]/g, '');
                        }
                        const formattedAmount = parts.join('.');
                        setDisplayAmount(formattedAmount);
                        const numericAmount = parseFloat(parts.join('.').replace(/,/g, ''));
                        setPendingIncome({ ...activeIncome, amount: numericAmount });
                    }} />
            </FormRow>
            <FormRow title={
                <OverlayTrigger placement="top" overlay={howOftenTooltip}>
                    <span className="cursor-pointer">{L.questions.income.how_often} <QuestionMarkCircleIcon className="w-4 h-4" /></span>
                </OverlayTrigger>
                }>
                <Select name="frequency" value={activeIncome.frequency} setValue={(value) => setPendingIncome({...activeIncome, frequency: value})} initial="--" options={[
                    ["weekly", L.questions.income.weekly],
                    ["biweekly", L.questions.income.biweekly],
                    ["monthly", L.questions.income.monthly],
                    ["yearly", L.questions.income.yearly],
                    ["one_time", L.questions.income.one_time],
                ]} />
            </FormRow>
            {(activeIncome.frequency === "weekly" || activeIncome.frequency === 'monthly' || activeIncome.frequency === "biweekly") &&
                <>
                    <FormRow title={L.questions.income.year_round}>
                        <SwitchButton enabled={yearround} setEnabled={(enabled) => {
                            setYearround(enabled);
                            setPendingIncome({ ...activeIncome, yearround: enabled })
                        }} />
                    </FormRow>
                    {!yearround && <>
                        <FormRow title={L.questions.income.start_date}>
                            <Select name="start_month" 
                                size="w-28"
                                value={(activeIncome.startDate || ['--', ''])[0]} 
                                setValue={(value) => setPendingIncome({...activeIncome, startDate: [value, (activeIncome.startDate || ['', '--'])[1]]})} 
                                initial="--" 
                                options={MONTHS} />
                            <Select name="start_day" 
                                size="w-16"
                                value={(activeIncome.startDate || ['--', ''])[1] ? activeIncome.startDate![1].padStart(2, '0') : ''} 
                                setValue={(value) => setPendingIncome({...activeIncome, startDate: [(activeIncome.startDate || ['', '--'])[0], value]})} 
                                initial="--" 
                                options={Array.apply(null, Array(31)).map(((_: any, k: number) => [(k + 1).toString().padStart(2, '0'), (k + 1).toString()]) as any)} />
                        </FormRow>
                        <FormRow title={L.questions.income.end_date}>
                            <Select name="end_month" 
                                size="w-28"
                                value={(activeIncome.endDate || ['--', ''])[0]} 
                                setValue={(value) => setPendingIncome({...activeIncome, endDate: [value, (activeIncome.endDate || ['', '--'])[1]]})} 
                                initial="--" 
                                optional 
                                options={MONTHS} />
                            <Select name="end_day" 
                                size="w-16"
                                value={(activeIncome.endDate || ['--', ''])[1] ? activeIncome.endDate![1].padStart(2, '0') : ''} 
                                setValue={(value) => setPendingIncome({...activeIncome, endDate: [(activeIncome.endDate || ['', '--'])[0], value]})} 
                                initial="--" 
                                optional
                                options={Array.apply(null, Array(31)).map(((_: any, k: number) => [(k + 1).toString().padStart(2, '0'), (k + 1).toString()]) as any)} />
                        </FormRow>
                    </>}
                </>
            }
            {howOftenSummaryNode}
        </FormTable>

        <div className="flex justify-end">
        {<button 
            className={(valid ? "text-gray-700 hover:bg-gray-100 hover:text-gray-900" : "text-gray-300") + " px-4 mt-4 mb-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md bg-white"}
            onClick={() => { 
                if(valid) {
                    if (activeIncome.startDate && (!activeIncome.endDate || activeIncome.endDate[0] === '--' || activeIncome.endDate[1] === '--')) {
                        // if we have a startDate but a blank end date, set 12/31 as the default end date
                        activeIncome.endDate = ["12", "31"];
                    }
                    props.setIncome(activeIncome); 
                    setEditing(false); 
                    setPendingIncome(null); 
                }}}>{L.questions.textentry.save}</button>}
        {<button 
            className="ml-2 px-4 py-2 mt-4 mb-4 border border-gray-300 shadow-sm mr-2 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
            onClick={() => props.income.type === '' ? props.setIncome() : setEditing(false)}>{L.apply.cancel}</button>}
        </div>
    </div>
}

function annualize(income: Income): number {
    // console.log(`income dates: ${JSON.stringify(income)}, ${income.startDate}, ${income.endDate}`);
    const useDates = !income.yearround && income.startDate && income.endDate;
    const currentYear = new Date().getFullYear();
    switch (income.frequency) {
        case "weekly":
            if (useDates) {
                const seconds = new Date(`${currentYear}-${income.endDate![0]}-${income.endDate![1]}`).getTime() -
                    new Date(`${currentYear}-${income.startDate![0]}-${income.startDate![1]}`).getTime();
                return (income.amount * Math.round(seconds / (1000 * 60 * 60 * 24 * 7)));
            }
            return income.amount * 52;
        case "biweekly":
            if (useDates) {
                const seconds = new Date(`${currentYear}-${income.endDate![0]}-${income.endDate![1]}`).getTime() -
                    new Date(`${currentYear}-${income.startDate![0]}-${income.startDate![1]}`).getTime();
                return (income.amount * Math.round(seconds / (1000 * 60 * 60 * 24 * 14)));
            }
            return income.amount * 26;
        case "monthly":
            if (useDates) {
                const seconds = new Date(`${currentYear}-${income.endDate![0]}-${income.endDate![1]}`).getTime() -
                    new Date(`${currentYear}-${income.startDate![0]}-${income.startDate![1]}`).getTime();
                return (income.amount * Math.round(seconds / (1000 * 60 * 60 * 24 * 30)));
            }
            return income.amount * 12;
        case "yearly":
        case "one_time":
            return income.amount;
        default:
            return 0;
    }
}

type Person = {
    role: 'you' | 'spouse' | 'child',
    name?: string,
    incomes: Income[],
}

function Person(props: { person: Person, setPerson: (person: Person | null) => any, metadata: any }) {

    const L = useLocalizedStrings();

    const titleClass = "text-gray-700 font-bold text-lg mb-2 mt-2"
    const total = props.person.incomes.reduce((acc, income) => acc + annualize(income), 0);
    // console.log("Person income:", props.person.role, props.person.incomes)
    return <div className="border rounded-lg p-2 mt-2 mb-2">
        {props.person.role === 'you' && <div className={titleClass}>{L.questions.income.person.you} - ${formatCurrencyNumberForDisplay(total)}</div>}
        {(props.person.role === 'spouse' || props.person.role === 'child') && <div className="flex mb-2">
            {props.person.role === 'spouse' && <div className={"flex-1 " + titleClass}>{L.questions.income.person.your_spouse} - ${formatCurrencyNumberForDisplay(total)}</div>}
            {props.person.role === 'child' && <div className={"flex-1 flex " + titleClass}>
                <div>{L.questions.income.person.child_slash_dependent} - ${formatCurrencyNumberForDisplay(total)}</div>
            </div>}
            <button 
                className="ml-2 px-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
                onClick={() => props.setPerson(null)}>
                <TrashIcon className="inline-block h-5 w-5 text-red-500" />
                </button>
        </div>} 
        {props.person.role === 'child' &&
            <input className="w-full border-gray-300 border-solid border rounded-lg p-2 mb-2" 
            value={props.person.name} 
            onChange={(e) => props.setPerson({...props.person, name: e.target.value})} placeholder={L.questions.income.person.name_optional}/>}
        {
            props.person.incomes.map((income, index) => 
                <Income key={index} income={income} setIncome={(income?: Income) => {
                    return income ?
                    props.setPerson({ ...props.person, incomes: [...props.person.incomes.slice(0, index), income, ...props.person.incomes.slice(index + 1)] })
                    : props.setPerson({ ...props.person, incomes: [...props.person.incomes.slice(0, index), ...props.person.incomes.slice(index + 1)] })
                }
                    } metadata={props.metadata} />)
        }
        {(props.person.incomes.length === 0 || props.person.incomes[props.person.incomes.length - 1].type !== '') && <button 
            className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
            onClick={() => props.setPerson({ ...props.person, incomes: [...props.person.incomes, { type: "", amount: 0, frequency: "" }] })}>+ {L.questions.income.person.add_income}</button>}
        <br />
    </div>
}

function formatCurrencyNumberForDisplay(number: number): string {
    return (number).toLocaleString(
        navigator?.language || 'en-US',
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    )
}

type HouseholdIncome = {
    you: Person,
    spouse: Person,
    children: Person[],
}

export default function IncomeCalculation(props: QuestionProps) {

    const L = useLocalizedStrings();
    const metadata = safeParse(props["Metadata"] || '{}');

    const startDetails = safeParse(props.info[props['Target Field']! + '_details'] || '{ "you": { "role": "you", "incomes": [] }, "spouse": null, "children": [] }');
    const [you, setYou] = useState<Person>( startDetails?.you || { name: 'You', role: 'you', incomes: [] });
    const [spouse, setSpouse] = useState<Person | null>(startDetails?.spouse || null);
    const [children, setChildren] = useState<Person[]>(startDetails?.children || []);
    const [totalIncome, setTotalIncome] = useState(parseInt(props.info[props['Target Field']!] || '0'));

    useEffect(() => {
        const income = you.incomes.reduce((total, income) => total + annualize(income), 0) 
            + (spouse ? spouse.incomes.reduce((total, income) => total + annualize(income), 0) : 0) 
            + children.reduce((total, child) => total + child.incomes.reduce((total, income) => total + annualize(income), 0), 0)
        setTotalIncome(parseFloat(income.toFixed(2)) || 0);
        if(JSON.stringify(startDetails) !== JSON.stringify({ you, spouse, children })) {
           props.setInfoKey(props['Target Field']!, '', false, false);
        }
    }, [you, spouse, children]);

    function saveIncome() {
        props.setInfoKey(props['Target Field']!, totalIncome.toString(), true, false);
        props.setInfoKey(props['Target Field'] + '_details', JSON.stringify({
            you: you,
            spouse: spouse,
            children: children,
        }), true, false);
    }

    return <div className="w-128">
        <div className="w-full border bg-white p-3 shadow rounded-lg ">
            <div>{L.questions.income.income_calculator}</div> 
            <div>
                <Person person={you} setPerson={setYou as (person: Person | null) => any} metadata={metadata} />
                {spouse && 
                    <Person person={spouse} setPerson={setSpouse} metadata={metadata} />}
                {children.map((child, index) => 
                    <Person key={index} person={child} setPerson={(child: Person | null) => 
                        child ? setChildren([...children.slice(0, index), child, ...children.slice(index + 1)])
                        : setChildren([...children.slice(0, index), ...children.slice(index + 1)])} metadata={metadata} />)}
                {!spouse && <button 
                    className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setSpouse({name: '', role: 'spouse', incomes: []})}>+ {L.questions.income.add_spouse}</button>}
                {!metadata.excludeDependents && <button 
                    className="ml-2 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setChildren([...children, {name: '', role: 'child', incomes: []}])}>+ {L.questions.income.add_dependent}</button>}
                <div className="mt-2 pt-3 font-bold text-lg" style={{ 'borderTop': '1px solid #eee' }}>{L.questions.income.household_income_colon} ${formatCurrencyNumberForDisplay(totalIncome)}</div>
            </div>
            {totalIncome.toString() !== (props.info[props['Target Field']!]) && <button 
                className={"hover:bg-indigo-700 bg-indigo-600 px-4 mt-4 mb-2 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white"}
                onClick={saveIncome}
                >{L.questions.income.ive_added_everything}</button>}
        </div>
    </div>
}
