import * as v0 from "@aidkitorg/types/lib/survey";
import { useEffect, useState } from "react";
import { usePost } from "../API";
import { ComparativeReview } from "./ComparativeReview";
import { useInterval } from "../Util";
import { useLocalizedStrings } from "../Localization";

export function SimilarDocumentReview(props: { 
    component: v0.SimilarDocumentReview,
    setInfo: (key: string, value: string) => void,
    info: Record<string, string | undefined>,
    uid: string,
}) {
    const getInfo = usePost('/applicant/get_similar_documents');
    const [toReview, setToReview] = useState<Record<string, Record<string, string>>>({});
    const [comparisonFields, setComparisonFields] = useState([] as string[]);
    
    const updateUidFieldMap = async () => {
        const dupeInfo = await getInfo({ applicant: props.uid, id: props.component.id, __prototype: props.component })
        if (dupeInfo.similarDocuments) {
            setToReview(dupeInfo.similarDocuments);
            setComparisonFields(dupeInfo.comparisonFields);
        }  
    }

    useEffect(() => {
        (async () => { await updateUidFieldMap(); })();
    }, [props.info[props.component.documentField]]);

    // This is to avoid needing to refresh the page if a Duplicate Review question on the same page
    // merges a program identity, thus making a similar doc with that program identity moot.
    // TODO: Create a trigger similar to props.refreshInfo() that can be called from the duplicate review Q that would trigger a refresh here.
    useInterval(async () => {
        await updateUidFieldMap();
    }, 10000);

    const L = useLocalizedStrings();

    return <ComparativeReview 
        component={props.component}
        uidFieldMap={toReview} 
        comparisonFields={comparisonFields}
        instructions={{
            classification: props.component.suspiciousLabel ?? 'Suspicious',
            topLevel: 'similar documents have been found. Look at the rows below to determine whether these documents come from same template and overlap in ways they shouldn\'t. Select the rows that represent suspicious documents below and then hit the button below to confirm.',
            duplicateFromAnotherProgram: 'Duplicate is from another program.\nUse link in header to handle them as desired',
            confirmNone: 'If you are sure there are none, you may click the button below to confirm.',
            confirmSome: 'To save this group of documents as coming from the same template, click the button below.',
            noneFound: 'No documents from the same template found. To make changes select suspicious above.',
            numberConfirmed: 'document(s) confirmed.',
            confirmNoneButton: 'No Similar Document Found',
            confirmSomeButton: 'Confirm Template',
            noneValue: 'no_suspicious',
        }}
        setInfo={props.setInfo}
        info={props.info}
        uid={props.uid}
        updateUidFieldMap={updateUidFieldMap}
    />
}