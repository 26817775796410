import React, { useEffect, useState, useContext } from "react";
import { usePost, get_deployment } from "./API";
import { ConfigurationContext, PublicConfigurationContext } from "./Context";
import { TableComponent } from "./DistroDashboard";
import { AidKitLogo } from "./Util";
import { Link } from 'react-router-dom';
import { useLocalizedStrings } from "./Localization";
import type { TableComponentProps } from "./DistroDashboard";
import type { ReconciliationOverview } from 'aidkit/lib/program/admin/accounting';

type ReconciliationReportType = 'allFunds' | 'fundsIn' | 'fundsOut';

type ReconciliationReport = {
    type: ReconciliationReportType;
    snakeCasedType: string,
    label: string;
    count: number;
};

function convertIsoMonthToDescription(isoMonth: string) {
    // Create a date object by appending '-01' to the ISO month string
    const date = new Date(`${isoMonth}-01`);
    // Format the date as "Month Year" for display
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', timeZone: 'UTC' });
}

export default function AccountingPage() {
    const fetchMonthlyReconciliationOverview = usePost("/accounting/monthly_reconciliation_overview");
    const [availableMonthlyReports, setMonthlyReports] = useState<ReconciliationOverview[] | undefined>();
    const [selectedMonth, setSelectedMonth] = useState<string>('');
    const [overviewLoading, setOverviewLoading] = useState(true);
    const [reportsError, setReportsError] = useState<string | null>(null);
    const selectedReport: ReconciliationOverview | undefined = availableMonthlyReports?.find((report) => report.month === selectedMonth);

    const configContext = useContext(ConfigurationContext);
    const publicConfig = useContext(PublicConfigurationContext);
    const applicantFacingLogo = publicConfig.interface?.applicantFacingLogo?.url || configContext.applicant_facing_logo;
    const applicantFacingLogoWidth = publicConfig.interface?.applicantFacingLogo?.width || configContext.applicant_facing_logo_width || '50';
    const program = get_deployment();
    const programDisplayName = publicConfig.name || configContext.program_name;
    const L = useLocalizedStrings();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchMonthlyReconciliationOverview({});
                if (!data || 'error' in data || data instanceof Error) {
                    throw new Error();
                } else {
                    setMonthlyReports(data);
                    setSelectedMonth(data[0]?.month || '');
                    setReportsError(null);
                }
            } catch (e) {
                setMonthlyReports(undefined);
                setReportsError("Hmmm, something's gone wrong. Please try again later.");
            } finally {
                setOverviewLoading(false);
            }
        };
        fetchData();
    }, []);

    const reportsInfo: ReconciliationReport[] = [
        { type: 'allFunds', snakeCasedType: 'all_funds', label: 'All Funds', count: selectedReport?.allFundsCount || 0 },
        { type: 'fundsIn', snakeCasedType: 'funds_in', label: 'Funds In', count: selectedReport?.fundsInCount || 0 },
        { type: 'fundsOut', snakeCasedType: 'funds_out', label: 'Funds Out', count: selectedReport?.fundsOutCount || 0 },
    ];

    // Determine the SQL query based on the report type
    const getSqlForReportType = (reportType: ReconciliationReportType) => {
        let condition = '';
        if (reportType === 'fundsIn') {
            condition = "AND action_kind = 'grant_funding'";
        } else if (reportType === 'fundsOut') {
            condition = "AND action_kind <> 'grant_funding'";
        }
        return `
            SELECT booked_date, action_created_utc, action_id, action_kind, action_state,
                entry_role, action_reference, program, uid, payment_name, booked_amount,
                transaction_date, transaction_ref, transaction_state, to_card_id
            FROM rc_public.monthly_reconciliation_cached_${program}
            WHERE action_state = 'COMPLETE'
                AND DATE_TRUNC('month', action_created_utc) = DATE_TRUNC('month', TO_TIMESTAMP('${selectedMonth}', 'YYYY-MM'))
                ${condition};
        `;
    };

    const tableComponentProps = (reportType: ReconciliationReportType): TableComponentProps => ({
        L,
        component: {
            download: {
                filename: `${selectedMonth}_${program || 'aidkit'}_reconciliation_${(reportsInfo.find(report => report.type === reportType)?.snakeCasedType) || 'report'}`,
                downloadPath: '0',
                downloadOnly: true,
            },
            kind: 'Table',
        },
        dashboard: {
            kind: 'Dashboard',
            components: [{
                kind: 'Custom Query',
                sql: getSqlForReportType(reportType),
                visualization: {
                    kind: 'Table',
                    download: {
                        filename: '0',
                    },
                },
            }],
            path: '',
            title: '',
        },
        filterConfig: {},
        refreshButton: <></>,
        sqlButton: <></>,
        results: [],
    });

    const ProgramBanner = () => (
        <div className="program-banner -m-6 mb-4 px-4 py-2 bg-gray-100 flex gap-4 items-center border-gray-300 border-solid border-b border-l-0 border-t-0 border-r-0">
            <Link to={"/"}>
                {applicantFacingLogo ? (
                    <img
                        src={applicantFacingLogo}
                        width={applicantFacingLogoWidth}
                        style={{ maxHeight: 30 }}
                        alt={programDisplayName}
                    />
                ) : (
                    <AidKitLogo width={60} height={30} />
                )}
            </Link>
            <h2 className="text-sm m-0">{programDisplayName}</h2>
        </div>
    );

    const ReportsDropdown = () => (
        availableMonthlyReports?.length ? (
            <>
                <label htmlFor="report-month-year" className="block mt-3 text-lg text-gray-900">
                    Reports available:
                </label>
                <select
                    id="report-month-year"
                    name="report-month-year"
                    value={selectedMonth}
                    onChange={(e) => setSelectedMonth(e.target.value)}
                    className="mt-2.5 mb-4 block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 text-lg ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 cursor-pointer"
                >
                    {availableMonthlyReports!.map(report => (
                        <option key={report.month} value={report.month}>
                            {convertIsoMonthToDescription(report.month)}
                        </option>
                    ))}
                </select>
            </>
        ) : (
            <p className="mt-4 text-xl">No reports available</p>
        )
    );

    const ReportList = () => (
        <ul>
            {reportsInfo.map(report => (
                report.count > 0 && (
                    <li key={report.type} className="mt-5">
                        <div className="text-gray-900">
                            <h5 className="mb-1">{report.label} - {convertIsoMonthToDescription(selectedMonth)}</h5>
                            <p>Transaction count: {report.count}</p>
                        </div>
                        <TableComponent {...tableComponentProps(report.type)} />
                    </li>
                )
            ))}
        </ul>
    );

    return (
        <div className="p-4">
            <ProgramBanner />
            <h1 className="text-2xl text-gray-900">Accounting</h1>
            <hr />
            <div className="mb-16">
                <h2>Download Reconciliation Reports</h2>
                {overviewLoading ? (
                    <p className="mt-4 text-base">Getting available reports...</p>
                ) : (
                    <>
                        {availableMonthlyReports && <ReportsDropdown />}
                        {!reportsError && selectedReport && (
                            <ReportList />
                        )}
                    </>
                )}
                {reportsError && (
                    <p className="text-red-500 text-lg mt-4">{reportsError}</p>
                )}
            </div>
        </div>
    );
}
