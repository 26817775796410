import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { snakeToEnglish, safeParse, SpacedSpinner, copyToClipboard } from "../Util";
import InterfaceContext, { LoggedInConfigurationContext, PublicConfigurationContext } from "../Context";
import { interfaceNumber, DuplicateReview, SimilarDocumentReview, Text } from "@aidkitorg/types/lib/survey";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon, Square2StackIcon, InformationCircleIcon, MagnifyingGlassPlusIcon, MagnifyingGlassMinusIcon  } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import { AppsWithDetailInfo, filterDict } from "@aidkitorg/types/lib/util";
import { get_deployment, usePost } from "../API";
import { Attachment } from "../Questions/Attachment";
import { getURLsFromCSV } from "@aidkitorg/roboscreener/lib/util/urls_from_csv";
import * as v0 from "@aidkitorg/types/lib/survey";
import { useLocalizedStrings } from "../Localization";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";


// Define some types for later:
type DecisionValue = Required<DuplicateReview>["customDecisions"] extends Array<{ value: infer V }> ? V : never;
type DecisionKey = Required<DuplicateReview>["customDecisions"] extends Array<{ sharedKeyField?: infer V }> ? V : never;

// TODO remove after full migration to useModalUI
type Instructions = {
    classification: 'Duplicate' | 'Suspicious' | v0.Text,
    topLevel: string,
    duplicateFromAnotherProgram: string,
    confirmNone: string,
    confirmSome: string,
    noneFound: string,
    numberConfirmed: string,
    confirmNoneButton: string,
    confirmSomeButton: string,
    noneValue: string,
};

export function ComparativeReview(props: {
    // Map of uids to key/value pairs of target fields
    uidFieldMap: Record<string, Record<string, string>>,
    comparisonFields: string[],
    instructions: Instructions,
    setInfo: (key: string, value: string) => void,
    info: Record<string, string | undefined>,
    uid: string,
    component: DuplicateReview | SimilarDocumentReview
    updateUidFieldMap: () => Promise<void>
}) {

    const { targetField } = props.component;
    const L = useLocalizedStrings();
    const instructions = props.component.kind === 'Duplicate Review' ? L.questions.dup_review : L.questions.sim_doc_review;

    const customDecisions = (props.component as DuplicateReview).customDecisions;

    // Shared Key
    const [sharedKey, sharedLabel] = props.component.kind === 'Duplicate Review'
        ? ['program_identity', 'Program Identity']
        : [targetField + "_documentid", 'Program Document ID'];

    const sharedKeys: string[] = customDecisions ? (customDecisions || []).map(f => f.sharedKeyField ?? '').filter(v => !!v) : [sharedKey];

    const context = useContext(InterfaceContext);

    // Maintain non custom decisions by adding them in to this array.
    // If there is only one option, we'll make it a checkbox and the decisions string
    // will look like
    // uid1,uid2 OR props.component.noneValue;
    const decisionsArray = customDecisions || [{
        value: '',
        text: props.component.kind === 'Similar Document Review' && props.component.suspiciousLabel ?
            props.component.suspiciousLabel : { [context.lang]: instructions.classification, en: instructions.classification } as Text,
        sharedKeyField: sharedKey
    }];

    // Whether we will use a radio or checkbox
    const radioOrCheckbox = decisionsArray.length > 1 ? 'radio' : 'checkbox';

    const noneValue = props.component.kind === 'Duplicate Review' ? 'no_duplicates' : 'no_suspicious';

    // Helper Functions
    const decisionsToValue = (decisions: Record<string, DecisionValue>) => {
        // console.log("Checking", decisions, radioOrCheckbox, Object.keys(decisions).sort().map(uid => uid).join(','));
        return Object.keys(decisions).sort().map(uid => uid + (radioOrCheckbox === 'radio' ? ':' + decisions[uid] : '')).join(',') 
            || noneValue;
    }

    const decisionsValueToMatrix = (decisionsStr?: string) => {
        if (!decisionsStr || decisionsStr === noneValue) return {};
        
        const matrix: Record<string, string> = {};
        for (const uidStr of (decisionsStr || '')?.split(',')) {
            const spl = uidStr.split(':');
            const uid = spl[0];
            if (spl.length > 1) {
                matrix[uid] = spl[1];
            } else {
                matrix[uid] = customDecisions ? customDecisions[0].value : ''
            }
        }
        return matrix;
    };

    // This is a matrix like:
    // app1: duplicate
    // app2: fraud
    // app3: neither
    // app4: '' (empty, reviewer hasnt decided)
    const [decisions, setDecisions] = useState<Record<string, DecisionValue>>(decisionsValueToMatrix(props.info[targetField]));
    const [decisionsValue, setDecisionsValue] = useState(props.info[targetField] || noneValue);
    // for resetting if the user cancels the confirm modal 
    const [prevDecisionsValue, setPrevDecisionsValue] = useState(props.info[targetField] || '');

    const publicConfig = useContext(PublicConfigurationContext);
    const aOrApplicant = interfaceNumber(publicConfig.interface?.version) > 0 ? 'a' : 'applicant';

    const relatedApplicants = Object.keys(props.uidFieldMap);

    const buttonClass = `inline-flex h-10 justify-center rounded-md border 
    border-gray-300 px-2 md:px-4 py-2.5 bg-gray-50 text-sm font-medium text-gray-700 
    hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 
    focus:ring-indigo-500`;

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [showReviewModal, setShowReviewModal] = useState(false);
    const [applicantBeingReviewed, setApplicantBeingReviewed] = useState(0);
    const [reviewedStatus, setReviewedStatus] = useState<Record<string, boolean>>({});
    const [zoomLevel, setZoomLevel] = useState(1);
    const incrementZoom = () => setZoomLevel((prev) => Math.min(prev + 0.1, 3));
    const decrementZoom = () => setZoomLevel((prev) => Math.max(prev - 0.1, 1));

    useEffect(() => {
        // For now we will assume that if the target field is set, then all applicants have been reviewed.
        if (props.info[targetField]) {
            setReviewedStatus(Object.keys(props.uidFieldMap).reduce((acc: Record<string, boolean>, uid) => {
                acc[uid] = true;
                return acc;
            }, {}));
        }
    }, [props.info[targetField], props.uidFieldMap]);

    // These 3 groups are the outcome for a program that uses multiplexing/program identity.
    // -- The main app will "gobble" the other apps into its identity when confirming duplicates.
    // -- When exorcising an app, it will need a new identity.
    // -- When not including an app, it will retain its current identity.
    const [changes, setChanges] = useState<null | {
        [sharedKey: string]: {
            toMerge?: AppsWithDetailInfo,
            needsNewIdentity?: AppsWithDetailInfo,
            willRetainIdentity?: AppsWithDetailInfo
        }
    }>(null);

    const [updates, setUpdates] = useState<null | Record<string, Record<string, string | undefined>>>(null);

    const computeSharedKeyChanges = usePost("/applicant/compute_or_modify_identities");

    const computeOrModifySharedKeys = async (decisions: Record<string, Required<DuplicateReview>["customDecisions"] extends Array<{ value: infer V }> ? V : never>, mode: "dry" | "execute") => {
        // If nothing shares a shared Key, we don't need to compute anything.
        if (customDecisions && sharedKeys.length === 0) return;
        if (!customDecisions && sharedKey === 'program_identity' && !props.info[sharedKey]) return;

        // migrate from uid1,uid2 to uid1:program_identity,uid2:program_identity,uid3:fraud_id,uid4:none

        let valueToSharedKey = decisionsArray.reduce<Record<DecisionValue, DecisionKey>>((acc, cur) => {
            acc[cur.value] = cur.sharedKeyField || '';
            return acc;
        }, {});

        const passDecisions = Object.assign({}, decisions);

        for (let key in decisions) {
            passDecisions[key] = valueToSharedKey[decisions[key]];
        }

        const { changes, updates } = await computeSharedKeyChanges({
            reviewTargetField: targetField,
            thisUid: props.uid,
        sharedKeys,
            relatedApplicants,
            decisions: passDecisions,
            comparisonFields: props.comparisonFields,
            mode
        });

        if (mode === 'dry') {
            setShowConfirmModal(true);
            setChanges(changes);
            setUpdates(updates);
        } else {
            await props.updateUidFieldMap();
        }
    }

    const marked = props.component.content ? useModularMarkdown({
        content: props.component.content[context.lang] || '',
        info: props.info
    }) : ( <div>{instructions.title}:<pre>{props.component.targetField}</pre></div> );

    const thisProgram = get_deployment();

    return props.component.useModalUI ? (
        <div>
            {showConfirmModal && changes && updates && Object.keys(updates).length > 0
                ? <ConfirmSharedKeyChangesModal
                    mode={props.component}
                    uid={props.uid}
                    save={() => computeOrModifySharedKeys(decisions, "execute")}
                    cancel={() => {
                        props.setInfo(targetField, prevDecisionsValue);
                    }}
                    comparisonFields={props.comparisonFields.sort((a, b) => {
                        // if legal name, do that first
                        if (a === 'legal_name') return -1;
                        if (b === 'legal_name') return 1;

                        // Everything else just sort alpha
                        if (a < b) return -1;
                        if (a > b) return 1;
                        return 0;
                    })}
                    changes={changes}
                    onClose={() => setShowConfirmModal(false)} />
                : null}
            {Object.keys(props.uidFieldMap).length && <div>
                {marked}
                <p>{Object.keys(props.uidFieldMap).length} {instructions.topLevel}</p>
            </div>}
            {Object.keys(props.uidFieldMap).length === 0 ?
                <div className="">
                    <div>{instructions.title}:<pre>{props.component.targetField}</pre></div>
                    <div>{props.info[targetField] ? instructions.confirmNoneButton :
                        <button className={buttonClass} onClick={() => props.setInfo(targetField, noneValue)}>{instructions.confirmNoneButton}</button>
                    }</div>
                </div>
                :
                <div className="inline-block align-middle w-full">
                    <div className="inline-block align-middle w-full">
                        <table className="min-w-full table-auto border border-gray-300">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{L.applicant.legal_name}</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{L.banking.status}</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {relatedApplicants.map((applicant, index) => {
                                    const legalName = props.uidFieldMap[applicant].legal_name || 'Unknown';
                                    const reviewed = reviewedStatus[index] ? 'Reviewed' : 'Not Reviewed';
                                    const currentSelection = decisionsArray.find(decision => decision.value === decisions[applicant])?.text[context.lang] || reviewed;

                                    return (
                                        <tr key={applicant}
                                            className="cursor-pointer hover:bg-gray-100"
                                            onClick={() => {
                                                if (!customDecisions) {
                                                    setReviewedStatus((prevState) => {
                                                        return { ...prevState, [applicant]: true }
                                                    });
                                                }
                                                setZoomLevel(1);
                                                setShowReviewModal(true);
                                                setApplicantBeingReviewed(index)
                                            }}
                                        >
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{legalName}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{currentSelection}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    {showReviewModal && <Transition.Root show={showReviewModal} as={Fragment}>
                        <Dialog as="div" className="relative z-50" onClose={() => setShowReviewModal(false)}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-y-hidden overflow-x-scroll rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6">
                                            <div>
                                                <h4 className="text-xl font-bold mb-4">{instructions.modal_title}</h4>
                                                <p className="mb-2">{L.questions.comparative_review.reviewing_x_of_y.replace('$x', (applicantBeingReviewed + 1).toString()).replace('$y', (relatedApplicants.length).toString())}</p>
                                                <div className="flex justify-end mb-4">
                                                    <button className="px-2 py-1 text-gray-900 rounded-md" onClick={decrementZoom}><MagnifyingGlassMinusIcon className="h-8 w-8 text-gray-700" /></button>
                                                    <button className="px-2 py-1 text-gray-900 rounded-md" onClick={incrementZoom}><MagnifyingGlassPlusIcon className="h-8 w-8 text-gray-700" /></button>
                                                </div>
                                                <div className="overflow-auto">
                                                    <table className="min-w-full table-auto border border-gray-300" style={{ transform: `scale(${zoomLevel})`, transformOrigin: 'top left' }}>
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: '10%' }}>{L.questions.comparative_review.field}</th>
                                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: '45%' }}>{L.questions.comparative_review.this_application}</th>
                                                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: '45%' }}>
                                                                    <a
                                                                        className="flex items-center"
                                                                        target={'_blank'}
                                                                        href={`https://${props.uidFieldMap[relatedApplicants[applicantBeingReviewed]]?.program || thisProgram}.aidkit.org/${aOrApplicant}/${relatedApplicants[applicantBeingReviewed]}`}
                                                                    >
                                                                        {L.questions.comparative_review.potential_duplicate}<ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1" />
                                                                    </a>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {props.comparisonFields.map((field) => ['uid', sharedKey].includes(field) ? null : (
                                                                <tr key={field}>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900" title={field}>{snakeToEnglish(field)}</td>
                                                                    {[props.uid, relatedApplicants[applicantBeingReviewed]].map((uid) => {
                                                                        return <td key={field + '-' + uid} className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                            {(() => {
                                                                                const value = props.uid === uid
                                                                                    ? field === 'document' && props.component.kind === 'Similar Document Review'
                                                                                        ? props.info[props.component.documentField]
                                                                                        : (field === 'similarity' && props.component.kind === 'Similar Document Review')
                                                                                            ? 'N/A'
                                                                                            : props.info[field]
                                                                                    : props.uidFieldMap[uid][field];
                                                                                if (!value) return <span className="text-gray-400" title={`${field} is empty or does not exist for this applicant`}><em>(empty)</em></span>
                                                                                if (!(value || '').includes('http')) {
                                                                                    const targetFieldValue = safeParse(value, value);
                                                                                    const isJson = typeof targetFieldValue === 'object' && targetFieldValue !== null;
                                                                                    if (isJson) {
                                                                                        return <pre>{JSON.stringify(targetFieldValue, null, 2)}</pre>;
                                                                                    }
                                                                                    return <>{targetFieldValue}</>;
                                                                                }
                                                                                const urls = getURLsFromCSV(value);
                                                                                return urls.map((url) => {
                                                                                    return <Attachment key={url} url={url} Viewer={"screener"} style={{ listStyle: "none", maxWidth: "100%" }} />
                                                                                });
                                                                            })()}
                                                                        </td>
                                                                    })}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="mt-4">
                                                    <p className="text-sm text-gray-500 mb-2">{L.questions.comparative_review.select_an_option}</p>
                                                    {decisionsArray.map((decision) => (
                                                        <div key={decision.value} className="mb-2">
                                                            {(!props.uidFieldMap[relatedApplicants[applicantBeingReviewed]]?.program || props.uidFieldMap[relatedApplicants[applicantBeingReviewed]]?.program === thisProgram) ? <div className="flex items-center"><input
                                                                type={radioOrCheckbox}
                                                                className="h-4 w-4 text-indigo-600 border-gray-300"
                                                                name={props.component.targetField + "_" + relatedApplicants[applicantBeingReviewed]}
                                                                checked={decisions[relatedApplicants[applicantBeingReviewed]] === decision.value}
                                                                onChange={(e) => {
                                                                    let shouldAlert = false;
                                                                    setDecisions((prevState) => {
                                                                        let nextState = Object.assign({}, prevState);
                                                                        if (e.target.checked) {
                                                                            nextState[relatedApplicants[applicantBeingReviewed]] = decision.value;
                                                                            const sharedKey = (decision as any).sharedKey;
                                                                            if (sharedKey && props.uidFieldMap[relatedApplicants[applicantBeingReviewed]][sharedKey]) {
                                                                                for (let otherUid in props.uidFieldMap) {
                                                                                    if (otherUid === relatedApplicants[applicantBeingReviewed]) continue;
                                                                                    if (props.uidFieldMap[otherUid][sharedKey] === props.uidFieldMap[relatedApplicants[applicantBeingReviewed]][sharedKey]) {
                                                                                        nextState[otherUid] = decision.value;
                                                                                        shouldAlert = true;
                                                                                    }
                                                                                }
                                                                            }
                                                                        } else if (radioOrCheckbox === 'checkbox') {
                                                                            delete nextState[relatedApplicants[applicantBeingReviewed]];
                                                                        }
                                                                        if (radioOrCheckbox === 'radio') {
                                                                            for (let uid in props.uidFieldMap) {
                                                                                if (!nextState[relatedApplicants[applicantBeingReviewed]]) {
                                                                                    nextState[relatedApplicants[applicantBeingReviewed]] = '';
                                                                                }
                                                                            }
                                                                        }
                                                                        let newValue = decisionsToValue(nextState);
                                                                        setReviewedStatus(prevState => {
                                                                            return { ...prevState, [relatedApplicants[applicantBeingReviewed]]: true }
                                                                        });
                                                                        setDecisionsValue((cur) => {
                                                                            setPrevDecisionsValue(cur);
                                                                            return newValue
                                                                        });
                                                                        return nextState;
                                                                    });

                                                                    if (shouldAlert) {
                                                                        alert('At least one of the duplicates you selected already has confirmed duplicates, so we will mark those as duplicates also. If this is a mistake, please uncheck those applicants in the duplicate review question.');
                                                                    }
                                                                }}
                                                            />
                                                                <label htmlFor={props.component.targetField + "_" + relatedApplicants[applicantBeingReviewed]} className="ml-2 mb-0 text-sm text-gray-900">
                                                                    {decision.text[context.lang]}
                                                                </label>
                                                            </div> : <div className="flex items-center">
                                                                <div className="text-sm text-gray-900">{decision.text[context.lang]}</div>
                                                                <div className="text-sm text-gray-400 ml-2">This is from another program</div>
                                                            </div>}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="mt-6 flex justify-end space-x-2">
                                                    {applicantBeingReviewed + 1 < relatedApplicants.length && (
                                                        <button
                                                            className="px-4 py-2 bg-blue-600 text-white rounded-md"
                                                            onClick={() => {
                                                                setApplicantBeingReviewed(applicantBeingReviewed + 1)
                                                                if (!customDecisions) {
                                                                    // If there is only one decision options we mark these reviewed once they've been viewed
                                                                    setReviewedStatus(prevState => {
                                                                        return { ...prevState, [relatedApplicants[applicantBeingReviewed + 1]]: true }
                                                                    })
                                                                }
                                                                setZoomLevel(1);
                                                            }}>Next</button>
                                                    )}
                                                    {applicantBeingReviewed > 0 && (
                                                        <button
                                                            className="px-4 py-2 bg-gray-300 text-gray-900 rounded-md"
                                                            onClick={() => {
                                                                setApplicantBeingReviewed(applicantBeingReviewed - 1)
                                                                if (!customDecisions) {
                                                                    // If there is only one decision options we mark these reviewed once they've been viewed
                                                                    setReviewedStatus(prevState => {
                                                                        return { ...prevState, [relatedApplicants[applicantBeingReviewed - 1]]: true }
                                                                    })
                                                                }
                                                                setZoomLevel(1);
                                                            }}>Previous</button>
                                                    )}
                                                    {applicantBeingReviewed + 1 === relatedApplicants.length && (
                                                        <button className="px-4 py-2 bg-blue-600 text-white rounded-md" onClick={() => setShowReviewModal(false)}>Finish</button>
                                                    )}
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>}
                    {relatedApplicants.some((app) => !reviewedStatus[app]) ?
                        <div className="my-2">{L.questions.comparative_review.review_all_before_proceeding}</div>
                        :
                        decisionsValue !== props.info[targetField] ?
                            <>
                                {decisionsValue === noneValue ?
                                    <>
                                        <div className="my-2">{instructions.confirmNone}</div>
                                        <button className={buttonClass} onClick={async () => {
                                            props.setInfo(targetField, decisionsValue)
                                            await computeOrModifySharedKeys(decisions, "dry");
                                        }}>{instructions.confirmNoneButton}</button>
                                    </>
                                    :
                                    <>
                                        <div className="my-2">{instructions.confirmSome}</div>
                                        <button className={buttonClass} onClick={async () => {
                                            props.setInfo(targetField, decisionsValue);
                                            await computeOrModifySharedKeys(decisions, "dry");
                                        }}>{instructions.confirmSomeButton}</button>
                                    </>
                                }
                            </>
                            :
                            <div className="my-2">
                                {decisionsValue === noneValue
                                    ? instructions.noneFound
                                    : `${decisionsValue.split(',').length} ${instructions.numberConfirmed}`}
                            </div>
                    }
                </div>}
        </div>
    ) : (
        // TODO remove after full migration to useModalUI
        <div>
            {showConfirmModal && changes && updates && Object.keys(updates).length > 0
                ? <ConfirmSharedKeyChangesModal
                    mode={props.component}
                    uid={props.uid}
                    save={() => computeOrModifySharedKeys(decisions, "execute")}
                    cancel={() => {
                        props.setInfo(targetField, prevDecisionsValue);
                    }}
                    comparisonFields={props.comparisonFields.sort((a, b) => {
                        // if legal name, do that first
                        if (a === 'legal_name') return -1;
                        if (b === 'legal_name') return 1;

                        // Everything else just sort alpha
                        if (a < b) return -1;
                        if (a > b) return 1;
                        return 0;
                    })}
                    changes={changes}
                    onClose={() => setShowConfirmModal(false)} />
                : null}
            {Object.keys(props.uidFieldMap).length ?
                <div>
                    <div>{props.component.kind}:<pre>{props.component.targetField}</pre></div>
                    <p>{Object.keys(props.uidFieldMap).length} {props.instructions.topLevel}</p>
                </div>
                : null}
            {Object.keys(props.uidFieldMap).length === 0 ?
                <div className="">
                    <div>{props.component.kind}:<pre>{props.component.targetField}</pre></div>
                    <div>{props.info[targetField] ? props.instructions.confirmNoneButton :
                        <button className={buttonClass} onClick={() => props.setInfo(targetField, props.instructions.noneValue)}>{props.instructions.confirmNoneButton}</button>
                    }</div>
                </div>
                :
                <div className="inline-block align-middle w-full [overflow:overlay]">
                    <table className="table-auto my-4 border border-gray-300 mr-5"
                        style={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            margin: '0 auto',
                            display: 'block'
                        }}>
                        <tbody>
                            <tr className="">
                                <td className="sticky left-0 z-10 border border-gray-800 p-2 font-bold bg-indigo-200">UID</td>
                                <td key={'this-app-uid'} className="border border-gray-800 p-2 bg-gray-200">
                                    This App
                                </td>
                                {relatedApplicants.map((uid) => (
                                    <td key={uid} className="border border-gray-300 p-2">
                                        <a target={'_blank'}
                                            href={`https://${props.uidFieldMap[uid]?.program || thisProgram}.aidkit.org/${aOrApplicant}/${uid}`}>{uid}</a>
                                    </td>
                                ))}
                            </tr>
                            {props.comparisonFields.map((field) => ['uid', sharedKey].includes(field) ? null : (
                                <tr key={field}>
                                    <td className="sticky left-0 border border-gray-800 border-2 p-2 font-bold bg-indigo-200" title={field}>{snakeToEnglish(field)}</td>
                                    {[props.uid, ...relatedApplicants].map((uid) => {
                                        return <td key={field + '-' + uid} className={classNames(`border border-gray-800 p-2`, props.uid === uid ? 'bg-gray-200' : '')}>
                                            {(() => {
                                                const value = props.uid === uid
                                                    ? field === 'document' && props.component.kind === 'Similar Document Review'
                                                        ? props.info[props.component.documentField]
                                                        : (field === 'similarity' && props.component.kind === 'Similar Document Review')
                                                            ? 'N/A'
                                                            : props.info[field]
                                                    : props.uidFieldMap[uid][field];
                                                if (!value) return <span className="text-gray-400" title={`${field} is empty or does not exist for this applicant`}><em>(empty)</em></span>
                                                if (!(value || '').includes('http')) {
                                                    const targetFieldValue = safeParse(value, value);
                                                    const isJson = typeof targetFieldValue === 'object' && targetFieldValue !== null;
                                                    if (isJson) {
                                                        return <pre>{JSON.stringify(targetFieldValue, null, 2)}</pre>;
                                                    }
                                                    return <>{targetFieldValue}</>;
                                                }
                                                const urls = getURLsFromCSV(value);
                                                return urls.map((url) => {
                                                    //const urllower = url.toLowerCase().split('?')[0];
                                                    return <Attachment key={url} url={url} Viewer={"screener"} style={{
                                                        listStyle: "none",
                                                        maxWidth: "200px"
                                                    }} />
                                                });
                                            })()}
                                        </td>
                                    })}
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            {decisionsArray.map(decision => {
                                return (
                                    <tr key={'decision:' + decision.value}>
                                        <th key={"decision-header:" + decision.value} className="sticky left-0 border border-gray-300 p-2 bg-indigo-200">{decision.text[context.lang]}</th>
                                        <td key={"this-app-header:" + decision.value} className="border border-gray-800 border-2 p-2 bg-gray-200 font-medium">N/A</td>
                                        {relatedApplicants.map((uid) => (
                                            <td key={decision.value + ':' + uid} className="border border-gray-300 p-2 items-center justify-between">
                                                {(!props.uidFieldMap[uid]?.program || props.uidFieldMap[uid]?.program === thisProgram)
                                                    ? <input type={radioOrCheckbox} className="h-5 w-5" name={props.component.targetField + "_" + uid} checked={decisions[uid] === decision.value} onChange={(e) => {
                                                        let shouldAlert = false;
                                                        setDecisions((prevState) => {
                                                            let nextState = Object.assign({}, prevState);
                                                            if (e.target.checked) {
                                                                nextState[uid] = decision.value;
                                                                // If we're confirming a shared key, we should also confirm all other that also have that shared key value.
                                                                const sharedKey = (decision as any).sharedKey;
                                                                if (sharedKey && props.uidFieldMap[uid][sharedKey]) {
                                                                    for (let otherUid in props.uidFieldMap) {
                                                                        if (otherUid === uid) continue;
                                                                        if (props.uidFieldMap[otherUid][sharedKey] === props.uidFieldMap[uid][sharedKey]) {
                                                                            nextState[otherUid] = decision.value;
                                                                            shouldAlert = true;
                                                                        }
                                                                    }
                                                                }
                                                            } else if (radioOrCheckbox === 'checkbox') {
                                                                delete nextState[uid];
                                                            }
                                                            // Add any uids to a none decision if they have not been decided
                                                            if (radioOrCheckbox === 'radio') {
                                                                for (let uid in props.uidFieldMap) {
                                                                    if (!nextState[uid]) {
                                                                        nextState[uid] = '';
                                                                    }
                                                                }
                                                            }
                                                            let newValue = decisionsToValue(nextState);
                                                            setDecisionsValue((cur) => {
                                                                setPrevDecisionsValue(cur);
                                                                return newValue
                                                            });
                                                            return nextState;
                                                        });

                                                        if (shouldAlert) {
                                                            alert('At least one of the duplicates you selected already has confirmed duplicates, so we will mark those as duplicates also. If this is a mistake, please uncheck those applicants in the duplicate review question.');
                                                        }
                                                    }} />
                                                    : <pre>{props.instructions.duplicateFromAnotherProgram}</pre>}
                                            </td>
                                        ))}
                                    </tr>
                                )
                            })}
                        </tfoot>
                    </table>
                    {decisionsValue !== props.info[targetField] ?
                        <>
                            {decisionsValue === props.instructions.noneValue ?
                                <>
                                    <div className="my-2">{props.instructions.confirmNone}</div>
                                    <button className={buttonClass} onClick={async () => {
                                        props.setInfo(targetField, decisionsValue)
                                        await computeOrModifySharedKeys(decisions, "dry");
                                    }}>{props.instructions.confirmNoneButton}</button>
                                </>
                                :
                                <>
                                    <div className="my-2">{props.instructions.confirmSome}</div>
                                    <button className={buttonClass} onClick={async () => {
                                        props.setInfo(targetField, decisionsValue);
                                        await computeOrModifySharedKeys(decisions, "dry");
                                    }}>{props.instructions.confirmSomeButton}</button>
                                </>
                            }
                        </>
                        :
                        <div className="my-2">
                            {decisionsValue === props.instructions.noneValue
                                ? props.instructions.noneFound
                                : `${decisionsValue.split(',').length} ${props.instructions.numberConfirmed}`}
                        </div>
                    }
                </div>}
        </div>);
}

export function ConfirmSharedKeyChangesModal(props: {
    mode: DuplicateReview | SimilarDocumentReview,
    uid: string,
    changes: {
        [sharedKey: string]: {
            toMerge?: AppsWithDetailInfo,
            needsNewIdentity?: AppsWithDetailInfo,
            willRetainIdentity?: AppsWithDetailInfo
        }
    }
    comparisonFields: string[],
    onClose: () => void,
    save: () => Promise<void>,
    cancel: () => void
}) {

    const saving = useRef(false);

    // Localization is dependent on if this is a person identiy or a document identity
    const applicationsOrDocuments = props.mode.kind === 'Duplicate Review' ? 'applications' : 'documents';
    const peopleOrDocuments = props.mode.kind === 'Duplicate Review' ? 'people' : 'documents';

    return <Transition.Root show={true} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.onClose}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-y-hidden overflow-x-scroll rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                            <div>
                                <div className="mx-auto flex h-14 w-14 items-center justify-center rounded-full">
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900 text-lg">
                                        <span>Changes</span>&nbsp;
                                        <span className="h-6 w-6" title="Please confirm your changes below.">
                                            <InformationCircleIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                                        </span>
                                    </Dialog.Title>
                                    <div className="divide-y divide-gray-200">
                                        {Object.keys(props.changes).map(sharedKey => {
                                            return <div className="p-2 bg-gray-50 m-2 divide-y divide-gray-100">
                                                {[props.changes[sharedKey].toMerge, props.changes[sharedKey].needsNewIdentity, props.changes[sharedKey].willRetainIdentity].map((dict, idx) => {
                                                    return (
                                                        (dict && Object.keys(dict).length > 0) ?
                                                            <>
                                                                <div className="mt-2">
                                                                    <h4 className="text-sm text-gray-500">
                                                                        {/* The following people will be merged into the current applicant's program identity. */}
                                                                        {/* Be more specific if this is a radio Q */}
                                                                        {props.mode.kind === 'Duplicate Review' && props.mode.customDecisions ? (
                                                                            <>
                                                                                {idx === 0 ? `The following ${applicationsOrDocuments} will have the same ${snakeToEnglish(sharedKey)}.` : null}
                                                                                {/* The following people will be given a new program identity */}
                                                                                {idx === 1 ? `The following ${applicationsOrDocuments} will ${sharedKey === 'program_identity'
                                                                                    ? 'be assigned a new Program Identity'
                                                                                    : 'have their ' + sharedKey + ' erased.'}` : null}
                                                                                {idx === 2 ? `The following ${applicationsOrDocuments} will remain different ${peopleOrDocuments}.` : null}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {idx === 0 ? `The following ${applicationsOrDocuments} will be marked as duplicates/similar.` : null}
                                                                                {/* The following people will be given a new program identity */}
                                                                                {idx === 1 ? `The following ${applicationsOrDocuments} will be marked as different ${peopleOrDocuments}` : null}
                                                                                {idx === 2 ? `The following ${applicationsOrDocuments} will remain different ${peopleOrDocuments}.` : null}
                                                                            </>
                                                                        )}
                                                                    </h4>
                                                                </div>
                                                                <div className="mt-3 overflow-x-auto">
                                                                    <table className="min-w-full table-fixed divide-y divide-gray-300 overflow-x-scroll">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" key={'uid'} className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">UID</th>
                                                                                {props.comparisonFields.map((field) => <th key={field} scope="col" className="px-3 py-3.5 text-left text-xs font-semibold text-gray-900">
                                                                                    {snakeToEnglish(field)}
                                                                                </th>)}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                                            {dict && Object.keys(dict || {}).map((uid) => (
                                                                                <tr key={uid} className={classNames(dict[uid] ? 'bg-gray-50' : '')}>
                                                                                    <td className="px-3 w-20 py-4 text-sm text-gray-500 max-w-md break-word" title={uid}>
                                                                                        <Square2StackIcon className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => copyToClipboard(uid, "Copied UID to clipboard!")} />
                                                                                    </td>
                                                                                    {props.comparisonFields.map((field, idx) => {
                                                                                        if (idx === 0) {
                                                                                            return <td className={classNames(
                                                                                                'whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900'
                                                                                            )}>{dict[uid][field] || "--"}</td>
                                                                                        }

                                                                                        return <td className="text-left whitespace-nowrap px-3 py-4 text-sm text-gray-500">{dict[uid][field]}</td>
                                                                                    })}
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div><br /><hr />
                                                            </> : null)
                                                })}
                                            </div>
                                        })}
                                    </div>

                                    <div className="mt-5 sm:mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-1/2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:text-sm"
                                            onClick={async () => {
                                                saving.current = true;
                                                await props.save();
                                                saving.current = false;
                                                props.onClose();
                                            }}
                                            disabled={saving.current}
                                        >{saving.current ? <SpacedSpinner /> : null}<span>Save</span></button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center w-1/2 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300 sm:text-sm"
                                            onClick={() => {
                                                props.cancel();
                                                props.onClose();
                                            }}
                                        >Cancel</button>

                                    </div>
                                </div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
}

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}

