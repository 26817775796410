import { Block, DataDictionary as DataDictionaryType, ExpandedSurvey, Subsurvey } from "@aidkitorg/types/lib/survey";
import { PermissionScope } from "@aidkitorg/types/lib/translation/permissions";
import { getDataDictionary } from "@aidkitorg/types/lib/translation/questions";
import { expandTemplates } from "@aidkitorg/types/lib/translation/v0_to_legacy";
import { CollectionComponent } from "@aidkitorg/types/lib/survey";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import Papa from "papaparse";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { usePost } from "../API";
import { BUTTON_CLASS, SpacedSpinner } from "../Util";

function findSubsurvey(survey: ExpandedSurvey['survey'], subsurveyPath: string) {

  function traverse(node: CollectionComponent | Block): Subsurvey | undefined {
    if (node.kind === 'Subsurvey' && node.path === subsurveyPath) {
      return node;
    }
    if (node.kind === 'Section' || node.kind === 'Collection') {
      return node.components.map(traverse).find(found => found);
    }
  }

  return survey.map(traverse).find(found => found);
}

export function DataDictionary(props: { component: DataDictionaryType }) {
  const loadEntireSurvey = usePost('/survey/load_survey');
  const getScope = usePost('/program/enumerate_scope');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleDataDictionaryDownload = async () => {
    setIsLoading(true);

    await (async () => {
      const everything = await loadEntireSurvey({ name: 'entireprogram' }) as any;
      const expandedSurvey = expandTemplates(everything) as any;
      const surveyToUse = props.component.limitToSubsurvey
        ? findSubsurvey(expandedSurvey.config.survey, props.component.limitToSubsurvey)
        : expandedSurvey.config.survey;

      if (props.component.limitToSubsurvey && !surveyToUse) {
        toast.error(`Subsurvey ${props.component.limitToSubsurvey} not found`);
        return;
      }

      const scope = await getScope({});
      const dataDictionary = getDataDictionary(
        surveyToUse,
        (scope as PermissionScope),
        props.component.excludeFields,
        props.component.includeComputations);

      let blob: Blob;
      // Create a new anchor element
      const a = document.createElement('a');

      if (props.component.type === 'json') {
        // Stringify your JSON data.
        const jsonData = JSON.stringify(dataDictionary.rows, null, 2); // `null` and `2` are for formatting with 2-space indentation.

        blob = new Blob([jsonData], { type: 'application/json' });
        a.download = 'dataDictionary.json';
      } else if (props.component.type === 'csv') {
        // Convert JSON to csv.
        const csvData = Papa.unparse(dataDictionary.rows, { columns: dataDictionary.columns });

        blob = new Blob([csvData], { type: 'text/csv' });
        a.download = 'dataDictionary.csv';
      } else {
        toast.error('Unrecognized DataDictionary type' + props.component.type);
        return;
      }

      const url = window.URL.createObjectURL(blob);

      // Set the href to the object URL and initiate download.
      a.href = url;

      // This is necessary as some browsers won't support direct .click() on the element without appending it to the DOM.
      document.body.appendChild(a); // Append anchor to body.
      a.click();
      document.body.removeChild(a); // Remove anchor from body after download starts.

      // It's important to revoke the object URL to avoid memory leaks.
      window.URL.revokeObjectURL(url);
    })();

    setIsLoading(false);
  }

  return <div className="my-2">
    <button className={BUTTON_CLASS}
      onClick={handleDataDictionaryDownload}>
      Get Data Dictionary {props.component.limitToSubsurvey} ( {props.component.type} )
      <span className="ml-2">
        <ArrowDownOnSquareIcon width="20" height="20" className="inline" />
      </span>
    </button>
    {isLoading && <SpacedSpinner />}
  </div>
}
