import React, { useContext, useState } from "react";
import InterfaceContext from "../Context";
import { useLocalizedStrings } from "../Localization";
import { languageContent, safeParse } from "../Util";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionProps } from "./Props";
import * as v0 from "@aidkitorg/types/lib/survey";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";

export function Explanation(props: QuestionProps) {
    const context = useContext(InterfaceContext);
    const metadata = safeParse(props.Metadata || '{}');
    const content = props[languageContent(context.lang)]?.replace("\\(", "(").replace("\\[", "[") || '';

    const collapsible = (props['Additional Options'] || []).indexOf('Collapsible') !== -1;
    const [collapsed, setCollapsed] = useState(collapsible);

    const L = useLocalizedStrings();

    // Make sure all asterisks (**) for boldness are snug up next to the next they need to be next to.
    // Also, make sure all underscores (_) for italics are snug up next to the next they need to be next to.
    const marked = useModularMarkdown({
        content: metadata?.modernType ? (metadata.modernType as v0.Explanation).content : content, 
        info: {...props.info, uid: props.uid || 'No UID'},
        replacer: (str) => str.replace(' **\n','**\n').replace('^** ','**'),
        collapsible
    });

    function toggle(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        if (!collapsible) return;

        let target = e.target as HTMLElement;
        while (target.parentNode && ((target.parentNode as HTMLElement).className || '').indexOf("markdown") === -1)  {
            target = target.parentNode as HTMLElement;
        }
        if (target && target.tagName === "BUTTON") {
            target.ariaExpanded = collapsed ? 'true' : 'false';
            target.focus();
            setCollapsed(!collapsed);
        }
    }

    return <div onClick={toggle} style={{ "--click-to-expand": "'" + L.applicant.history.click_to_show + "'" } as React.CSSProperties} 
        className={"explanation " + (collapsible ? " collapsible " : "") + (collapsed && collapsible ? "collapsed" : "")}
        >{marked}{(props["Options (if relevant)"] || []).length ? <div className="flex flex-col sm:flex-row">
            <div className="flex-1 md:w-1/4"></div>
            {(props["Options (if relevant)"] || []).map((o) => { 
                return <div className="flex-1 flex sm:flex-col space-x-2 sm:space-x-0 items-center text-center">{(o as any)[languageContent(context.lang).replace('Content','Text')].split('-').map((str: string) => {
                    return <small>{str.trim()}</small>
                })}</div>
            })}
            </div>
        : <></>}</div>;
}
  