import React, { Suspense, useContext, useRef, useState } from "react";
import { useToken } from "./API";
import { PublicConfigurationContext } from "./Context";
import { useMarkdown } from "./Util";

export type ToDoList = {
    title: string,
    tasks: string[]
}

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));
export function DistroTaskPage() {
    const [output, setOutput] = useState('');
    const [input, setInput] = useState('');

    const [_, auth_token] = useToken() as any;
    function testSSE() {
        const body = encodeURIComponent(btoa(JSON.stringify({
            token: auth_token.split('=')[1],
            model: 'gpt-4o',
            messages: [
                { role: 'user', content: unescape(encodeURIComponent(input)) },
            ],
            temperature: 0,
            max_tokens: 4000,
            presence_penalty: 0.0,
            //stop: ['\n'],
        })));
        let source = new EventSource('https://ha3vuuy7cyop5lnpfs4zvjwbxe0nphga.lambda-url.us-east-2.on.aws/?body=' + body, {
            withCredentials: false
        });
        source.onmessage = (e) => {
            setOutput((o) => {
                return o + JSON.parse(e.data);
            })
            console.log(e);
        }
        // Apparently these things are resilient and will try to restart
        // if they stop receiving data.
        source.onerror = (e) => {
            source.close();
        }
    }

    const [state, setState] = useState<ToDoList>({ title: 'My ToDo List', tasks: [] });
    const intro = useMarkdown(INTRO);

    return <div className="p-4 container-sm">
        <input value={input} onChange={(e) => setInput(e.target.value)} />
        <button onClick={testSSE}>test</button>
        <pre style={{'whiteSpace': 'pre-wrap'}}>{output}</pre>
        <Suspense>
            <Distro 
                value={state}
                types='DistroTask.tsx' name='ToDoList' onChange={setState}/>
        </Suspense>
        <div className="mt-4">
            {intro}
        </div>
    </div>;
}

const INTRO = `
# Welcome to Distro!

This page is contains a few exercises for a developer new to Distro to get familiar with how it works and the power of types.

This basic setup is a toy todo list with minimal functionality. Your job is to add a few features to it.

## Exercise 1: Use Distro

See if you can figure out how to use Distro to edit the todo list. Try adding a new task, editing the title, and deleting a task.

## Exercise 2: Add the ability to mark a task as done

Change the type of tasks to one that contains a task name and a boolean whether the task is done or not.

## Exercise 3: Add summary statistics

Below the Distro editor, show the number of total tasks and the number of tasks that are done.

## Exercise 4: Assignment

Let's say we want to keep track of who is assigned to tasks _only_ while they're in progress. 

Modify the "done" boolean to instead be one of:

- • Not Started
- • In Progress (with a string field for the name of the person assigned to the task)
- • Done
`
