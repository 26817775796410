import { Survey, ForceSourceMap } from "@aidkitorg/types/lib/survey"
import { Sections } from "@aidkitorg/types/lib/legacy/airtable"
import { v0ToLegacy, AirtableSurveyToSurveyDefinition } from "@aidkitorg/types/lib/translation/v0_to_legacy"
import { Suspense, useEffect, useRef, useState } from "react";
import { ModularQuestionPage } from "./Apply";
import { usePost } from "./API";
import React from "react";
import { ReconstructState, State } from "@aidkitorg/typesheets/lib/collab";
import { useInterval } from "./Util";
import { useChannel } from "./Realtime";

type SimpleObject = {
    str: string,
    num: number,
    other: string | number,
    /**
     * @width 4
     */
    nested?: SimpleObject
}

export type Base = {
    objectArray: SimpleObject[],
    objectArray2: SimpleObject[],
    stringArray: string[]
}

export default function DistroTestPage() {
    const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));
    const [lastSeen, setLastSeen] = useState({} as Record<string, number>);
    const name = useRef("");

    const sendData = useChannel("testpubsub", "test", (event) => {
        if (event.event === "heartbeat") {
            setLastSeen((lastSeen) => ({ ...lastSeen, [event.data.name.split(' ').filter((v: string) => v).map((w: string) => w[0]).join('')]: Date.now() }));
        }
    });

    useInterval(() => {
        sendData({
            realm: 'testpubsub',
            channel: 'test',
            event: 'heartbeat',
            data: {
                name: name.current
            }
        }); 
        const lS = lastSeen;
        let changed = false;
        for (const key in lS) {
            if (Date.now() - lS[key] > 2000) {
                delete lS[key];
                changed = true;
            }
        }
        if (changed) {
            setLastSeen({ ...lS });
        }
    }, 1000);

    const leftDistro = useRef<React.ComponentRef<typeof Distro>>(null);
    let [leftBase, setLeftBase] = useState<Base | null>(null);
    let leftState = useRef<State>();

    const rightDistro = useRef<React.ComponentRef<typeof Distro>>(null);
    let [rightBase, setRightBase] = useState<Base | null>(null);
    let rightState = useRef<State>();

    function loadLeftA() {
        leftDistro.current?.initialize({
            objectArray: [{
                str: 'A',
                num: 0,
                other: 'string'
            }],
            objectArray2: [],
            stringArray: []
        })
    }

    function loadLeftB() {
        leftDistro.current?.initialize({
            objectArray: [{
                str: 'B',
                num: 0,
                other: 888
            }],
            objectArray2: [],
            stringArray: []
        })
    }

    function loadRightFromLeft() {
//        rightDistro.current?.initialize(leftBase, leftState.current);
    }

    function syncRight() {
        rightDistro.current?.mergeState(leftState.current!);
    }

    function syncLeft() {
        leftDistro.current?.mergeState(rightState.current!);
    }

    return <>
        <div className="flex">
            <div className="flex-1 h-auto overflow-y-auto">
                <Suspense>
                    <Distro ref={leftDistro} types='DistroTest.tsx' name='Base' onChange={(v, s) => {
                        leftState.current = s;
                        setLeftBase(v);
                    }}/>
                    <Distro ref={rightDistro} types='DistroTest.tsx' name='Base' onChange={(v, s) => {
                        rightState.current = s;
                        setRightBase(v);
                    }}/>
                </Suspense>
            </div>
        </div>
        <input onChange={(e) => name.current = e.target.value} />
        <button onClick={syncLeft}>Sync Left</button>
        <button onClick={syncRight}>Sync Right</button>
        <button onClick={loadLeftA}>Load Left A</button>
        <button onClick={loadLeftB}>Load Left B</button>
        <button onClick={loadRightFromLeft}>Load Right</button>
        <div>{Object.keys(lastSeen).map((k) => {
            return <div>{k}</div>
        })}</div>
        <div>{JSON.stringify(leftBase)}</div>
        <div>{JSON.stringify(rightBase)}</div>
    </>
}