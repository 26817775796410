import * as v0 from "@aidkitorg/types/lib/survey";
import { useEffect, useState } from "react";
import { usePost } from "../API";
import { ComparativeReview, ConfirmSharedKeyChangesModal } from "./ComparativeReview";
import { AppsWithDetailInfo, filterDict } from "@aidkitorg/types/lib/util";
import { DistroQuestionProps } from "../Questions/Props";
import { useLocalizedStrings } from "../Localization";

export function DuplicateReview(props: DistroQuestionProps<v0.DuplicateReview>) {
    const getInfo = usePost('/applicant/get_duplicate_details');
    const [toReview, setToReview] = useState<AppsWithDetailInfo>({});
    const [comparisonFields, setComparisonFields] = useState([] as string[]);
    const L = useLocalizedStrings();

    const updateAppsToReview = async () => {
        const dupeInfo = await getInfo({ applicant: props.uid, id: props.component.id, __prototype: props.component })
        if (dupeInfo.relatedApplicants) {
            setToReview(dupeInfo.relatedApplicants);
            setComparisonFields(dupeInfo.comparisonFields);
        }  
    }

    useEffect(() => {
        (async () => {
            await updateAppsToReview();
        })();
    }, [props.info[props.component.flagField]]);

    return <ComparativeReview 
            component={props.component}
            uidFieldMap={toReview} 
            comparisonFields={comparisonFields}
            // TODO remove instructions after full migration to useModalUI
            instructions={{
                classification: 'Duplicate',
                topLevel: 'potential duplicate(s) have been found. Select the rows that represent actual duplicates below and then hit the button below to confirm.',
                duplicateFromAnotherProgram: 'Duplicate is from another program.\nUse link in header to handle them as desired',
                confirmNone: 'If you are sure there are no duplicates, you may click the button below to confirm.',
                confirmSome: 'To save these decisions, click the button below.',
                noneFound: 'No duplicates found. To make changes select duplicates above.',
                numberConfirmed: 'decision(s) confirmed.',
                confirmNoneButton: 'No Duplicates Found',
                confirmSomeButton: 'Confirm Decision',
                noneValue: 'no_duplicates',
            }} // todo: localize the above?
            setInfo={props.setInfo}
            info={props.info}
            uid={props.uid}
            updateUidFieldMap={updateAppsToReview}
        />
    
}