import { Survey, Persona, ForceSourceMap } from "@aidkitorg/types/lib/survey"
import { Sections } from "@aidkitorg/types/lib/legacy/airtable"
import { v0ToLegacy, AirtableSurveyToSurveyDefinition } from "@aidkitorg/types/lib/translation/v0_to_legacy"
import { useEffect, useState } from "react";
import { ModularQuestionPage } from "./Apply";
import { usePost } from "./API";
import React from "react";

type Distinct<T> = T | { __dont: '__elide' };

/**
 * @component TextArea
 */
export type Multiline = Distinct<string>;

export type User = {
    name: string,
    role: 'Screener' | 'Admin' | 'Reviewer',
    email: string,
    phone?: string 
}

export type Organization = {
    name: string,
    members: User[],
}

export type FCFSPayment = {
    payment_field: string,
    max_count?: number,
    max_amount?: number   
}

export type FirstComeFirstServe = {
    mode: 'First Come First Serve',
    limits: FCFSPayment[]
}

export type Lottery = {
    mode: 'Lottery',
    strata_field: string,
    strata_targets: {
        strata: string,
        treatment_size: number,
        control_size: number,
    }[],
}

/**
 * @display stacked
 */
export type USIOConfig = {
    distributor_id: string
}

/**
 * @display stacked
 */
export type PaymentConfig = {
    ach?: 'yes',
    wu?: 'yes',
    usio_phsyical?: USIOConfig
}

/**
 * @display stacked
 */
export type General = {
    name: string,
    url: string,
    mode: FirstComeFirstServe | Lottery,
    payment_types: PaymentConfig
}

/**
 * @display tabs 
 */
export type RichText = {
    /**
     * @name English
     */
    'en': Multiline,
    /**
     * @name Spanish
     */
    'es'?: Multiline,
}

export type Never = {
    'kind': 'Never'
};

export type FieldExists = {
    'kind': 'FieldExists'
    'field': string
}

export type FieldEquals = {
    'kind': 'FieldEquals'
    'field': string,
    'value': string,
}

export type Or = {
    'kind': 'Or',
    'clauses': Expr[]
}

export type And = {
    'kind': 'And',
    'clauses': Expr[]
}

export type Not = {
    'kind': 'Not',
    /**
     * @width 4
     */
    'clause': Expr
}

export type Expr = Never | FieldExists | FieldEquals | Or | And | Not;

function Eval(expr: Expr, fields: Record<string, string>): boolean {
    switch (expr.kind) {
        case 'Never':
            return false;
        case 'FieldEquals':
            return fields[expr.field] == expr.value;
        case 'FieldExists':
            return fields[expr.field] !== undefined;
        case 'And':
            return expr.clauses.map((c) => Eval(c, fields)).every(e => e);
        case 'Or':
            return expr.clauses.map((c) => Eval(c, fields)).some(e => e);
        case 'Not':
            return !Eval(expr.clause, fields);
        default:
            return false;
    }
}

export type Notification = {
    target_field: string,
    phone: boolean,
    email: boolean,
    call: boolean,
    /**
     * @width 4
     */
    content: RichText,
    /**
     * @width 4
     */
    condition: Expr,
    example_data?: {
        description: string,
        fields: {
            target_field: string,
            value: string
        }[]
    }[]
}

export type Notifications = {
    active: Notification[],
    archived: Notification[]
}

/**
 * @display tabs
 */
export type Program = {
    general: General,
    survey: {

    },
    notifications: Notifications,
    personas: Persona[],
    users: Organization[]
}

export default function ProgramConfigPage() {
    
    let [program, setProgram] = useState<Program>({
        general: {
            name: '',
            url: '',
            mode: {
                mode: 'First Come First Serve',
                limits: []
            },
            payment_types: {}
        },
        survey: {
            
        },
        notifications: {
            active: [],
            archived: []
        },
        personas: [],
        users: []
    });


    const DistroEditor = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));
    return <DistroEditor types='ProgramConfig.tsx' name='Program' value={program} onChange={setProgram}/>
    
    return <>
        <div className="flex">
            <div className="flex-1 h-auto overflow-y-auto">
            </div>
        </div>
        <div>
            {program.notifications.active.map(n => {
                return <div>
                    <h1>{n.target_field}</h1>
                    {(n.example_data || []).map(t => {
                        let content = n.content.en as string;
                        let fields: Record<string, string> = {};
                        for (const p of t.fields) {
                            content = content.replaceAll('$' + p.target_field, p.value);
                            fields[p.target_field] = p.value;
                        }
                        const send = Eval(n.condition, fields);
                        return <div>
                            <h3>{t.description}</h3>
                            {send ?
                                <pre>
                                    {content}
                                </pre>
                            : 'Will not send'}
                        </div>
                    })}
                </div>
            })}
        </div>
    </>
}