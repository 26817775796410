import { Survey, ForceSourceMap, ValueExpr, BooleanExpr, Computed } from "@aidkitorg/types/lib/survey"
import { Sections } from "@aidkitorg/types/lib/legacy/airtable"
import { CompileExpressionToJS } from "@aidkitorg/types/lib/translation/expr_to_js"
import { v0ToLegacy, AirtableSurveyToSurveyDefinition } from "@aidkitorg/types/lib/translation/v0_to_legacy"
import { Suspense, useEffect, useRef, useState } from "react";
import { ModularQuestionPage } from "./Apply";
import { usePost } from "./API";
import React from "react";
import { CompileExpressionToSQL } from "@aidkitorg/types/lib/translation/expr_to_sql";

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

export default function CompilePage() {
    let [expr, setExpr] = useState<Computed>({
        kind: 'Computed',
        targetField: '',
        content: { 'en': 'Test' },
        formula: {
            kind: 'StringValue',
            value: ''
        }
    });

    return <>
        <Suspense>
            <Distro types='src/survey.ts' name='Computed' value={expr} onChange={setExpr}/>
        </Suspense>
        <div>
            <h1>Output JS</h1>
            <pre>{CompileExpressionToJS(expr.formula as ValueExpr)}</pre>
            <h1>Output SQL</h1>
            <pre>{CompileExpressionToSQL({cond: expr.formula as ValueExpr})}</pre>
        </div>
    </>;
}