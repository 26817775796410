import React, { useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import bsCustomFileInput from "bs-custom-file-input";

import { useAPIPost, useAPIUpload, usePost } from "../API";
import { Attachment } from "../Questions/Attachment";

import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import InterfaceContext, { ConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import TranslateWrapper from "../Translator";
import { renderLinks, safeParse } from "../Util";
import { Note } from "aidkit/lib/program/note";

type NotesProps = { 
  uid: string; 
  canRequestChanges: boolean;
  canDeleteNotes: boolean;
  internalAudit?: boolean;
  unsubmittedApp?: boolean;
};

function DeleteNoteButton(props: any) {
  const delete_note = useAPIPost("/note/" + props.id + "/delete_note");
  const delete_contact_note = usePost("/support/delete_note");
  const L = useLocalizedStrings();
  
  async function deleteNote() {
    if (!window.confirm("Are you sure?")) return;
    let response;
    if (props.unsubmittedApp) {
      response = await delete_contact_note({ id: props.id });
    } else {
      response = await delete_note({});
    }
    if (response.status === "ok") {
      props.refresh();
    }
  }

  return (
    <Button 
      variant="link"
      onClick={deleteNote}
      size="sm"
      style={{ float: "right" }}>{L.applicant.notes.delete}</Button>
  );
}

function Notes(props: NotesProps) {
  const L = useLocalizedStrings();
  const [addingAttachment, setAddingAttachment] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([] as File[]);
  const addNote = usePost('/applicant/add_note');
  const addNoteWithAttachment = usePost('/applicant/add_note_with_attachment');
  const uploadURL = usePost('/document/upload_url');
  const upload = useAPIUpload('');
  const getNotes = usePost('/applicant/get_notes');
  const addContactNote = usePost('/support/create_note');
  const getContactNotes = usePost('/support/get_notes');
  const fileInput = useRef(null as any);
  const fileInputLabel = useRef(null as any);

  const [showNotifyReviewer, setShowNotifyReviewer] = useState(false);

  const [message, setMessage] = useState("");

  const [notes, setNotes] = useState<{ data: Note[] } | undefined>();
  const refreshNotes = async (params: {applicant: string, isInternalAudit?: boolean}) => {
    let getNotesResponse;
    if (props.unsubmittedApp) {
      getNotesResponse = await getContactNotes({
        contact: params.applicant
      });
    } else {
      getNotesResponse = await getNotes(params);
    }
    
    if (!('error' in getNotesResponse)) {
      setNotes(getNotesResponse);
    }
  }

  const configuration = useContext(ConfigurationContext);
  const context = useContext(InterfaceContext);

  async function saveNote() {
    if (message.length === 0) {
      alert(L.applicant.notes.please_enter_a_message);
      return;
    }
    if (configuration.enable_notes_to_reviewers === 'true') {
      setShowNotifyReviewer(true);
    } else {
      actuallySaveNote(false);
    }
  }

  async function actuallySaveNote(notify: boolean) {
    setShowNotifyReviewer(false);

    if (props.unsubmittedApp) {
      const result = await addContactNote({
        contact: props.uid,
        note: message,
      });
    } else if (addingAttachment && selectedFiles.length > 0) {
      let result;
      let savedPaths = [];
      for (const file of selectedFiles) {
        const uploadUrlResponse = await uploadURL({
          path: file.name,
          length: file.size,
        });
        if (!('error' in uploadUrlResponse)) {
          // Actually upload file
          await upload([file], {}, uploadUrlResponse.uploadURL, "PUT");
          savedPaths.push(uploadUrlResponse.savedPath);
        }
      }
      result = await addNoteWithAttachment({
        applicant: props.uid,
        message: message,
        savedPaths: savedPaths,
        notify: notify,
        isInternalAudit: props.internalAudit,
      });
    } else {
      await addNote({ applicant: props.uid, message, notify, ...(props.internalAudit && { isInternalAudit: true }) });
    }
    setMessage("");
    await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
  }

  const requestChange = usePost('/applicant/add_note_and_request_changes');
  async function doRequestChange() {
    await requestChange({ applicant: props.uid, message: message });
    await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
    setMessage("");
  }

  const resolveRequest = usePost('/applicant/resolve_note');
  async function doResolveRequest(note_id: string) {
    await resolveRequest({ id: note_id });
    await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
  }

  useEffect(() => {
    (async () => {
      await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit });
    })();
  }, [props.uid, props.internalAudit]);

  /* For attachments with uploads */

  useEffect(() => {
    bsCustomFileInput.init();
  });

  return (
    <div>
      <Modal
        show={showNotifyReviewer}
        onHide={() => setShowNotifyReviewer(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{L.applicant.notes.notify_reviewers}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
           {L.applicant.notes.if_you_want_a_response}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => actuallySaveNote(true)}>
            {L.applicant.notes.save_and_notify}
          </Button>
          <Button variant="success" onClick={() => actuallySaveNote(false)}>
            {L.applicant.notes.save}
          </Button>
        </Modal.Footer>
      </Modal>

      <FormControl
        as="textarea"
        style={{ minHeight: "50px", maxHeight: "400px" }}
        onChange={(e) => setMessage(e.target.value)}
        value={message}
      />
      <InputGroup className="mt-2 d-flex justify-content-end">
        <InputGroup.Append>
          <Button variant="secondary" onClick={saveNote}>
            {L.applicant.notes.add_note}
          </Button>
          {!addingAttachment && props.canRequestChanges && (
            <Button variant="danger" onClick={doRequestChange}>
              {L.applicant.notes.request_change}
            </Button>
          )}
        </InputGroup.Append>
      </InputGroup>
      <br />
      {(!props.unsubmittedApp && !addingAttachment) && (
        <Button
          variant="link"
          onClick={(e) => {
            e.preventDefault();
            setAddingAttachment(true);
          }}
        >
          {L.applicant.notes.add_attachments}
        </Button>
      )}
      {addingAttachment && (
        <form ref={fileInput}>
          <InputGroup>
            <div className="custom-file">
              <input
                multiple
                className="custom-file-input"
                id="fileupload"
                onChange={(e) =>
                  setSelectedFiles(Array.from(e.target.files || []))
                }
                type="file"
              />
              <label
                ref={fileInputLabel}
                className="custom-file-label"
                htmlFor="fileupload"
              >
                {L.applicant.notes.choose_files}
              </label>
            </div>
          </InputGroup>
          {L.applicant.notes.add_a_description}
        </form>
      )}
      <br />

      <br />
      <ListGroup>
        {notes &&
          notes.data &&
          notes.data.map((n: Note) => {
            const metadata = safeParse(n.metadata || '{}') as Record<string, any>;
            return (
              <ListGroup.Item key={n.id}>
                {(((n.action_required) || (n.requested_change)) && (
                  <Alert variant={((n.action_completed) || n.resolved) ? "success" : "danger"}>
                    <b>
                      {n.message} {((n.action_completed) || n.resolved) && "(resolved)"}
                    </b>
                  </Alert>
                )) || (
                  <>
                    <b key={`bold-note-${n.id}`}>
                      {React.createElement(TranslateWrapper, {
                        id: `note-${n.id}`,
                        desired_lang: context.lang,
                        body: renderLinks(n.message), 
                        metadata
                    })}
                    </b>
                    <br />
                  </>
                )}
                {n.author}, {moment.unix(n.created_at).calendar()}
                {
                  ((n.action_required && !n.action_completed) || (n.requested_change && !n.resolved)) && (
                  <Button
                    variant="success"
                    onClick={(e) => doResolveRequest(n.id!)}
                    size="sm"
                    style={{ float: "right" }}
                  >
                    {L.applicant.notes.mark_resolved}
                  </Button>
                )}
                {props.canDeleteNotes && (
                  React.createElement(DeleteNoteButton, {
                    key: n.id,
                    unsubmittedApp: props.unsubmittedApp,
                    refresh: async () => await refreshNotes({ applicant: props.uid, isInternalAudit: props.internalAudit }),
                    ...n
                  })
                )}
                <ul>
                  {metadata && metadata['attachments']?.map((url: string) => {
                    return <Attachment key={url} url={url} />;
                  })}
                </ul>
              </ListGroup.Item>
            );
          })}
      </ListGroup>
    </div>
  );
}

export default Notes;
