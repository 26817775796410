import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment, ReactNode, useContext, useState, useLayoutEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Link } from "react-router-dom";
import InterfaceContext from "../Context";

import { usePopper } from 'react-popper';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function Dropdown(props: { 
  label: string | ReactNode, 
  description?: string, 
  className?: string,
  color?: 'blue' | 'green' | 'white' | 'indigo',
  colorIntensity?: 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800,
  options: { label: JSX.Element | string, callback: () => Promise<void> | void }[],
  direction?: 'right' | 'left',
  usePopper?: boolean,
  startExpanded?: boolean,
  withOpen?: (open: boolean) => void,
  onPointerEnter?: () => void,
  onPointerLeave?: () => void
}) {
  const context = useContext(InterfaceContext);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const dir = props.direction || 'left';
  const otherDir = dir === 'left' ? 'right' : 'left';

  const oppositeDir = context.textAlign === 'right' ? dir : otherDir;
  const color = props.color || 'gray';
  const colorIntensity = props.colorIntensity || 50;
  const hoverColorIntensity = colorIntensity === 50 ? 100 : colorIntensity + 100;

  // Popper state
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = props.usePopper ? usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    strategy: 'fixed',
  }): { styles: { popper: {} }, attributes: { popper: {}} };

  useLayoutEffect(
    () => {
      // on initial render, if startExpanded is true, click the button to create the expanded state
      if(buttonRef.current && props.startExpanded){
        buttonRef.current.click();
      }
    },
    []
  );

  const dropDownContent = <Transition
    as={Fragment}
    enterFrom="transform opacity-0 scale-95 z-0"
    enterTo="transform opacity-100 scale-100 z-50"
    leave="transition ease-in duration-75 z-50"
    leaveFrom="transform opacity-100 scale-100 z-50"
    leaveTo="transform opacity-0 scale-95 z-0"
  >
    <Menu.Items
      ref={props.usePopper ? setPopperElement : () => {}}
      unmount className={`origin-top-${oppositeDir}
      absolute ${oppositeDir}-0 z-10 mt-2 w-56
      rounded-md shadow-lg bg-white ring-black ring-opacity-5`}
      style={{
        maxHeight: '88vh',
        overflowY: 'scroll',
        ...styles.popper
      }}
      {...attributes.popper}>
        {props.options.map((option, index) =>
          <Menu.Item key={props.label + '-dropdown-option-' + index}>
            {({ active }) => (
              <button type="button"
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                  option.callback();
                }}
                className={classNames(
                  'w-full border-0',
                  'bg-transparent font-medium px-4 py-2 text-xs outline-none focus:outline-none ease-linear transition-all duration-150',
                  context.textAlign === 'left' ? 'text-left' : 'text-right',
                  active ? 'text-indigo-300' : 'text-gray-500',
                )}
              >
                {option.label}
              </button>
            )}
          </Menu.Item>
        )}
    </Menu.Items>
  </Transition>

  return <Menu as="div" className="inline-block z-0">
    {({ open }) => {
      if (props.withOpen) props.withOpen(open);

      return (
        <div className="relative">
          <Menu.Button
            ref={props.usePopper ? setReferenceElement : buttonRef}
            onPointerEnter={props.onPointerEnter}
            onPointerLeave={props.onPointerLeave}
            {... props.description ? {'aria-label': props.description } : {}} 
            className={`${props.className || ''}
              inline-flex justify-center items-center w-full rounded-md border leading-1
              border-gray-300 px-2 md:px-4 py-2.5 xl:text-sm bg-${color}-${colorIntensity} text-xs font-medium text-gray-700 
              hover:bg-${color}-${hoverColorIntensity} focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 
              focus:ring-indigo-500`}>
            {props.label}
            <ChevronDownIcon className={`${context.textAlign === 'left' ? '-mr-1 ml-2' : '-ml-1 mr-2'} h-5 w-5`} aria-hidden="true" />
          </Menu.Button>
          { props.usePopper ? createPortal(dropDownContent, document.body) : dropDownContent }
        </div>
      )
    }}
      </Menu>;
  }
  