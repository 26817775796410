type Distinct<T> = T; // & { __distro_tagged: T }; (Parser adds this)

export type WithLegacy<Modern, Legacy> = Modern | Legacy;

type AtLeast = {
    0: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
    1: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
    2: 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9,
    3: 3 | 4 | 5 | 6 | 7 | 8 | 9,
    4: 4 | 5 | 6 | 7 | 8 | 9,
    5: 5 | 6 | 7 | 8 | 9,
    6: 6 | 7 | 8 | 9,
    7: 7 | 8 | 9,
    8: 8 | 9,
    9: 9
}

export function atLeastVersion<V extends number, A = unknown, B = unknown>(a: WithLegacy<A, B>): a is A & { version: V extends keyof AtLeast ? AtLeast[V] : never } {
    return true;
}


export type Slug<T> = string & { __slug: T };

export type SuffixSlug = Slug<'suffix'>;
export type StandaloneSlug = Slug<'standalone'>;

export type SlugsAndDependencies = {
    slugs?: (SuffixSlug | StandaloneSlug)[],
    dependencies?: StandaloneSlug[],
};

/**
 * @display tabs
 * @validateInput Text
 */
export type Text = {
    /**
     * @name English
     */
    'en': string,
    /**
     * @name Spanish
     */
    'es'?: string,
    /**
     * @name French 
     */
    'fr'?: string,
    /**
     * @name Amharic
     */
    'am'?: string;
    /**
     * @name Chinese (Simplified)
     */
    'zh_CN'?: string,
    /**
     * @name Chinese (Traditional)
     */
    'yue'?: string,
    /**
     * @name Arabic 
     * @direction rtl
     */
    'ar'?: string,    
    /**
     * @name Tagalog 
     */
    'tl'?: string,
    /**
     * @name Polish 
     */
    'pl'?: string,
    /**
     * @name Pashto
     * @direction rtl
     */
    'ps_AF'?: string,
    /**
     * @name Dari
     * @direction rtl
     */
    'fa_AF'?: string,
    /**
     * @name Korean
     */
    'ko'?: string,
    /**
     * @name Somali
     */
    'so'?: string,
    /**
     * @name Vietnamese
     */
    'vi'?: string,
    /**
     * @name Persian
     * @direction rtl
     */
    'fa'?: string,
    /**
     * @name Portuguese
     */
    'pt'?: string,
    /**
     * @name Bengali
     */
    'bn'?: string,
    /**
     * @name Haitian Creole
     */
    'ht'?: string,
    /**
     * @name Asháninka
     */
    'cni'?: string,
    /**
     * @name Purépecha
     */
    'pua'?: string,
    /**
     * @name Marshallese
     */
    'mh'?: string,
    /**
     * @name Hausa
     */
    'ha'?: string,
    /**
     * @name Nepali
     */
    'ne'?: string,
    /**
     * @name Russian
     */
    'ru'?: string,
    /**
     * @name Oromo
     */
    'om'?: string,
    /**
     * @name Hmong
     */
    'hmn'?: string,
    /**
     * @name Ukrainian
     */
    'ua'?: string,
    /**
     * @name ID!
     * @hidden yes
     */
    '_id'?: string
}

/**
 * @display tabs
 * @validateInput Text
 */
export type RichText = {
    /**
     * @name English
     */
    'en': Markdown,
    /**
     * @name Spanish
     */
    'es'?: Markdown,
    /**
     * @name French 
     */
    'fr'?: Markdown,
    /**
     * @name Amharic
     */
    'am'?: Markdown;
    /**
     * @name Chinese (Simplified)
     */
    'zh_CN'?: Markdown,
    /**
     * @name Chinese (Traditional)
     */
    'yue'?: Markdown,
    /**
     * @name Arabic 
     * @direction rtl
     */
    'ar'?: Markdown,    
    /**
     * @name Tagalog 
     */
    'tl'?: Markdown,
    /**
     * @name Polish 
     */
    'pl'?: Markdown,
    /**
     * @name Pashto
     * @direction rtl
     */
    'ps_AF'?: Markdown,
    /**
     * @name Dari
     * @direction rtl
     */
    'fa_AF'?: Markdown,
    /**
     * @name Korean
     */
    'ko'?: Markdown,
    /**
     * @name Somali
     */
    'so'?: Markdown,
    /**
     * @name Vietnamese
     */
    'vi'?: Markdown,
    /**
     * @name Persian
     */
    'fa'?: Markdown,
    /**
     * @name Portuguese
     */
    'pt'?: Markdown,
    /**
     * @name Bengali
     */
    'bn'?: Markdown,
    /**
     * @name Haitian Creole
     */
    'ht'?: Markdown,
    /**
     * @name Asháninka
     */
    'cni'?: Markdown,
    /**
     * @name Purépecha
     */
    'pua'?: Markdown,
    /**
     * @name Marshallese
     */
    'mh'?: Markdown,
    /**
     * @name Hausa
     */
    'ha'?: Markdown,
    /**
     * @name Nepali
     */
    'ne'?: Markdown,
    /**
     * @name Russian
     */
    'ru'?: Markdown,
    /**
     * @name Oromo
     */
    'om'?: Markdown,
    /**
     * @name Hmong
     */
    'hmn'?: Markdown,
     /**
     * @name Ukrainian
     */
    'ua'?: Markdown,
    /**
     * @name ID!
     * @hidden yes
     */
    '_id'?: string
}

/**
 * @component TextArea 
 */
export type Markdown = Distinct<string>;

/**
 * @component TextArea
 */
export type Code = Distinct<string>;

/**
 * @referencedIn fields
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage TargetFields must start with a letter or _ and contain no spaces
 */
export type TargetField = Distinct<string>

/**
 * @references fields
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage TargetFieldRefs must start with a letter or _ and contain no spaces
 */
export type TargetFieldRef = Distinct<string>

/**
 * @referencedIn fields
 * @references fields
 * @format ^[a-zA-Z_][a-zA-Z0-9_$]*$
 * @formatMessage Target Fields must start with a letter or _ and contain no spaces
 */
export type SelfReferencingTargetField = Distinct<string>

/**
 * @display stacked
 */
export type Config = {
    /**
     * The subdomain prefix used to reach this program.
     */
    deployment_id: string,
    /**
     * The full (english) name for this program.
     * @name Program Name
     */
    program_name: string
    /**
     * A URL that links to the logo used on applicant facing surveys. 
     */
    applicant_facing_logo?: string,
    /**
     * Content to show on the /apply page if the application isn't open yet.
     */
    guard?: Markdown
}


export type TopLevelTemplatedBlock = {
    kind: 'Templated Block',
    iterations: { 
        /**
         * @width 4
         */
        substitutions: { key: string, value: string }[]
    }[]
    /**
     * @name _ 
     */
    components: Survey
}

export type GenericTemplatedBlock<Component extends any[]> = {
    kind: 'Templated Block',
    iterations: { 
        /**
         * @width 4
         */
        substitutions: { key: string, value: string }[]
    }[],
    /**
     * @name _ 
     */
    components: Component 
}

export type Collection = {
    kind: 'Collection',
    /**
     * @width 3
     */
    name: Text,
    /**
     * @width 4
     * @collapsible starts-collapse
     */
    options: {
        /**
         * @name Limited To Users Tagged
         */
        limitedToTags?: UserTagRef[],
        /**
         * @width 4
         */
        limitedToApplicants?: BooleanExpr, 

        notificationEmail?: string,
        notificationService?: string,
        /**
         * @width 2
         */
        subsurveyBranding?: {
            /**
             * @width 4
             */
            name: string,
            /**
             * @width 4
             */
            logo: string
        },
        /**
         * Disables all payments and notifications in a section
         */
        archived?: true
    }
    /**
     * @name _
     * @collapsible starts-collapse
     * @macros PaymentModule
     */
    components: CollectionComponent[]
} 


export type SectionComponent = Block;

export type CollectionComponent = (Section & CommonProperties) | Collection | Subsurvey |
    TopLevelTemplatedBlock | Payment | NotificationGroup | UsioMailing | GiveCardMailing | Dashboard | ApplicantIdentities;

export type SurveyComponent = SectionComponent | CollectionComponent;

export type Survey = CollectionComponent[];

export type UsioMailing = {
    kind: 'Usio Mailing',
    targetField: TargetField,
    mailingAddress: TargetFieldRef,
    /**
     * @width 4
     */
    condition: ClickQuery,
    /**
     * if the payment kind is USIO Mailed Grant (usio_mailed_grant),
     * this field will cause the card mailed to the applicant to be
     * pre-associated.
     */
    autoAssociate?: boolean,
}

export type GiveCardMailing = {
    kind: 'GiveCard Mailing',
    targetField: TargetField,
    /**
     * Only required if overriding the default, "usio_mailing_address".
     *
     * Sets the mailing address field to be used with givecard_mail card types.
     *
     * @name Address Field
     */
    mailingAddress?: TargetFieldRef,

    /**
     * Enables this mailing component.
     * If this is not set properly (see payment tab in Distro),
     * no cards will be mailed.
     *
     * @name Enable Key
     * @width 2
     * @validateInput ConditionEnableKey
     */
    enableKey: string,

    /**
     * The criteria that determines the eligible applicants to process.
     *
     * @name Eligibility Criteria
     * @width 4
     */
    condition: ClickQuery | SQLQuery,

    /**
     * Automatically sets the applicant's Card ID target field (usually card_id) to the ordered card's id.
     *
     * If this is false (or not set at all), the applicant will need to associate it manually
     * using a CardManagement kind in a Subsurvey.
     *
     * @name Auto Associate
     * @width 1
     */
    autoAssociate?: boolean,

    /**
     * GiveCard Only Field
     *
     * Cards that are ordered as a result of this Payment kind
     * will be allowed a higher limit (up to 8k).
     *
     * The default target_field is givecard_higher_spending_limit
     *
     * @name Higher Spending Limit
     * @width 2
     */
    higherSpendingLimit?: TargetFieldRef,

    /**
     * Indicates that a card is either virtual or physical.
     * Must be one of these values: givecard_mail, givecard_virtual
     *
     * (for backward compatibility reasons) IF THIS IS NOT INCLUDED, IT IS ASSUMED TO ALWAYS BE givecard_mail.
     *
     * @name Card Type
     * @width 2
     */
    type?: TargetFieldRef,

    /**
     * DEPRECATED. This does not do anything.
     * @name (Do Not Use) Card Field
     * @width 1
     */
    cardField?: TargetField,

    /**
     * DEPRECATED. This does not do anything.
     * @name (Do Not Use) Mailing Name Field
     * @width 1
     */
    mailingName?: TargetFieldRef,

}

export type Section = {
    kind: 'Section',
    /**
     * @width 3
     */
    title: Text,

    /**
     * @collapsible starts-collapsed 
     * @width 4
     */
    components: Block[],
    /**
     * @width 2
     */
    screenerMode?: 'All At Once' | 'Progressive',
    /**
     * @name Limited To Users Tagged
     */
    limitedToTags?: UserTagRef[],
    /**
     * @name Hide Next Button
     * @width 2
     */
    hideNextButton?: boolean,
} 

export type CommonProperties = {
    /**
     * @width 4
     * @validateJS (() => expr )
     */
    conditionalOn?: Code | BooleanExpr,
    optional?: boolean,
    hidden?: boolean,
    /**
    * Optional text read by audio reader but not visible in survey.
    * @width 4
    * @name Text-to-Speech 
    */  
    textToSpeech?: {
        mode: "append" | "replace",
        /**
        * @width 4
        */  
        content: RichText
    },
    /**
    * Audio file to replace the TTS reader. Upload file and generate URL here: https://[program].aidkit.org/publicS3
    * @width 4
    * @name Custom-Audio-File
    */  
    customAudio?: {
        /**
        * @width 4
        */  
        audio_link: Text
    }
}

/**
 * @intersection-naming first-only
 */
export type Block = ((
    | Action
    | Address
    | ApplicantCreator
    | Assignee
    | Attachment
    | BankRoutingNumber
    | BenefitsCalculator
    | CardManagement
    | Consent
    | Computed
    | Confirmation
    | ContactConfirmation
    | Date
    | EncryptedValueVerification
    | Explanation
    | HouseholdCalculator
    | FillableForm
    | Flag
    | DuplicateReview
    | IncomeCalculator
    | InlineLanguageSelector
    | InlineNotification
    | InlineSignature
    | Likert
    | LivenessDetection
    | LoginWidget
    | Lookup
    | Number
    | NumberWithUnit
    | PlaidBankAccount
    | PlaidIncome
    | RankingQuestion
    | ResumeWidget
    | SearchSelect
    | Section
    | Select
    | SelectFromData
    | ShowField
    | SimilarDocumentCheck
    | SimilarDocumentReview
    | SubmitButton
    | Subsurvey
    | SupportButton
    | TextEntry
    | ThirdPartyCheck
    | Validated
    | Milestone
    | RelatedApplicants
) & CommonProperties)
    | TemplatedBlock
    | ConditionalBlock;

export type ConditionalBlock = {
    kind: 'Conditional Block',
    /**
     * @width 4
     * @validateJS (() => expr )
     */
    conditionalOn: Code | BooleanExpr,
    /**
     * @name _
     * @collapsible starts-shown
     */
    components: Block[],
    /**
     * @name Otherwise
     * @collapsible starts-shown
     */
    otherwise?: Block[]
}

export type TemplatedBlock = {
    kind: 'Templated Block',
    iterations: { 
        /**
         * @width 4
         */
        substitutions: { key: string, value: string }[]
    }[]
    /**
     * @name _ 
     */
    components: Block[],
}

export type Explanation = {
    kind: 'Explanation',
    /**
     * @width 4
     */
    content: RichText | ConditionalContentList,
    collapsible?: boolean
}

export type CardManagement = {
    kind: 'Card Management',
    /**
     * @width 3
     */
    targetField: TargetField,
    viewOnly?: boolean,
    cardType?: 'GiveCard' | 'USIO',
}


/**
 * @referencedIn PersonaName
 */
type PersonaName = Distinct<string>;

/**
 * @references PersonaName
 * @name Persona
 */
type PersonaNameRef = Distinct<string>;

/**
 * @display stacked
 */
export type Persona = {
    kind: 'Persona',
    name: PersonaName,
    /**
     * @collapsible starts-collapse
     */
    attrs: {
        field: TargetFieldRef,
        /**
         * @width 3
         */
        value: string,
    }[],
}

/**
 * @migration PlaidBankLegacyV0
 */
type PlaidBankLegacyV0 = {
    /**
     * @width 2
     */
    kind: 'Plaid Bank Account'
    /**
     * @width 2
     */
    targetField: TargetField,
    version?: 0
}

export type PlaidBankAccount = WithLegacy<{
    /**
     * @width 2
     */
    kind: 'Plaid Bank Account'
    /**
     * @width 2
     */
    targetField: TargetField
    /**
     * @width 4
     */
    content: RichText
    version?: 1
    /**
     * Uses Assets by default. For Dev Use Only.
     * @name Product
     */
    product?: 'transactions' | 'income_verification' | 'assets',
}, PlaidBankLegacyV0>

export type PlaidIncome = {
    /**
     * @width 2
     */
    kind: 'Plaid Income',
    /**
     * @width 2
     */
    targetField: TargetField
}

export type Connection = PlaidBankAccount | PlaidIncome;

export type Milestone = {
    kind: 'Milestone'
    name: string
}

export type TextEntry = {
    kind: 'TextEntry',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText 
    multiline?: boolean,
    format?: 'phone' | 'email' | 'legalName',
    minLength?: number,
    maxLength?: number,
    confirmationField?: TargetFieldRef,
}

export type Address = {
    kind: 'Address',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    lookups?: string[]
}

export type ContactConfirmation = {
    kind: 'ContactConfirmation',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    contactField?: string,
    resume?: boolean,
    /**
     * @width 2
     */
    ingestedResumesToSubsurvey?: SubsurveyPathRef
    /**
     * Appears directly above the "Send Confirmation Code" button.
     * @width 4
     */
    customSMSConsentText?: RichText,
    /**
     * Appears directly above the button.
     * @width 4
     */
    customEmailConsentText?: RichText
    /**
     * default: Send Confirmation Code.
     * @width 4
     */
    customSendButtonText?: Text
    /**
     * default: Resume.
     * @width 4
     */
    customResumeButtonText?: RichText
}

export type Confirmation = {
    kind: 'Confirmation',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText 
}

export type ViewOnly = { 
    /**
     * @width 2
     */
    modeType: 'view_only' 
}
export type SendOnly = { 
    /**
     * @width 2
     */
    modeType: 'send_only', 
    /**
     * @width 4
     */
    sendLink: RichText 
}
export type SendAndView = { 
    /**
     * @width 2
     */
    modeType: 'send_and_view', 
    /**
     * @width 4
     */
    sendLink: RichText 
}

/**
 * @referencedIn subsurveyPaths
 * @format ^[a-z_-]+$
 * @formatMessage Lowercase characters, - , and _ only. No spaces.
 */
type SubsurveyPath = Distinct<string>;
/**
 * @references subsurveyPaths
 * @format ^[a-z_-]+$
 * @formatMessage Lowercase characters, - , and _ only. No spaces.
 */
type SubsurveyPathRef = Distinct<string>;

export type SubsurveyRef = {
    kind: "Subsurvey",
    path: SubsurveyPathRef
}

/** 
 * @display stacked
 */
export type ApplicantIdentities = {
    kind: 'Applicant Identities',
    /**
     * @name Dashboard title
     */
    title: Text,
    /** 
     * @format ^[a-zA-Z_][a-zA-Z0-9_]*$
     * @formatMessage Can only contain alphanumeric characters and underscores.
     */
    path: string,
    /**
     * Specify fields here to see their values and if they are shared across the applicants on this dashboard.
     * @name Fields to watch. 
     */
    info: (ValueExpr | NamedExpression)[],
    /**
     * @name Shared Key
     */
    sharedKey?: string
}

/** 
 * @display stacked
 */
export type Subsurvey = {
    kind: 'Subsurvey',
    /**
     * @name URL Path (/p/<path>)
     */
    path: SubsurveyPath,

    /**
     * The title that will appear if this subsurvey is linked. i.e. "Application" or "Appeal"
     * @width 2
     */
    title?: Text

    /**
     * Which applicants can access this subsurvey. 
     * If an applicant matches the requirements here, they will be able to access the subsurvey.
     * @width 4
     * @name Conditional Access
     */
    accessConditional?: BooleanExpr,

    /**
     * @name Protected Search Config
     * @width 4
     */
    protectedSearch?: {
        /**
         * @width 4
         */
        condition: ClickQuery,
        /**
         * @width 4
         * @name Allowed User Tags
         */
        roles: UserTagRef[],
        /**
         * Content uses $link for URL. This will replace the "View" button with two Send Link options to send via SMS or via Email.
         * @width 4
         * @name Send Link (With Content)
         */
        sendLink?: RichText,
        /**
         * @width 4
         * @name Assignee Field
         */
        assigns?: string,
        /**
         * The key=value pairs will be passed into the subsurvey url in the view_info url param.
         * @width 4
         * @name View Info (Custom Views)
         */
        viewInfo?: {
            /**
             * @width 2
             */
            key: string,
            /**
             * @width 2
             */
            value: string
        }[],
        /**
         * @width 4
         * @name Mode
         */
        mode?: ViewOnly | SendOnly | SendAndView,
        /**
         * If your search may return more than one applicant (common in multiplexing), you can provide
         * target field values here to help the searcher determine which applicant they are looking for.
         * @width 4
         * @name Applicant display fields
         */
        displayFields?: TargetFieldRef[],
    }
    /**
     * @name Allow Anonymous viewing
     */
    anonymous?: boolean,
    /**
     * @name Custom popup text on submit
     * @display stacked
     */
    customSubmittedModal?: {
        title?: RichText,
        message?: RichText,
    },
    /**
     * @name Redirect to subsurvey on submit
     */
    redirectToSubsurvey?: SubsurveyPathRef,
    /**
     * @name Load to first incomplete section
     */
    shouldFastForward?: boolean,
    /**
     * @collapsible starts-collapse
     */
    sections: (Section & CommonProperties)[],
    defaultExpiration?: {
        days: number,
        weeks: number,
        months: number
    },
    branding?: {
        name: string,
        logo: string
    },
    hideFromNavigation?: boolean,
    /**
     * @name Block further edits
     */
    blockFurtherEdits?: {
        /**
         * @width 4
         */
        block_when: BooleanExpr,
        /**
         * @width 4
         */
        blocked_message: RichText
    },
    /**
     * @width 4
     * @name Bottom Buttons
     */
    sectionBottomButtons?: {

        /**
         * @width 4
         * @name Logout Redirects To
         */
        logoutRedirectPath: SubsurveyPathRef,

        /**
         * @width 4
         * @name Hide in First Section
         */
        hideOnFirstSection?: boolean,
    },

}

export type Select = {
    kind: 'Select',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    multiple?: boolean,
    maxAllowedSelections?: number,
    randomizeOrder?: boolean,
    choices: {
        /**
         * @width 4
         * @format ^[a-zA-Z0-9_]+$
         * @formatMessage Alphanumeric characters and underscores only
         */
        value: string,
        /**
         * @width 4
         */
        label: Text,
        exclusive?: boolean,
        selectAll?: boolean,
        otherField?: boolean,
    }[]
}

export type SelectFromData = {
    kind: 'SelectFromData',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    multiple?: boolean,
    maxAllowedSelections?: number,
    /**
     * @name sourceField (json stringified array of string values)
     * @width 4
     */
    sourceField: TargetFieldRef
}

/**
 * @migration SearchSelectV1
 */
export type LegacySearchSelectV0 = {
    kind: 'SearchSelect',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    optionsFileUrl: Text,
    maxOptions?: number,
};

type ClientSide = {
    kind: 'ClientSide';
    optionsFileUrl: Text;
};

export type ServerSide = {
    kind: 'ServerSide';
    /**
     * @width 3
     */
    baseTableName: string;
    /**
     * @width 4
     */
    loc: {
        /**
         * @width 2
         */
        bucket: string,
        /**
         * @width 2
         */
        key: string,
    },
    /**
     * @name csv columns
     * @width 4
     */
    columns: {
        /**
         * Column that contains the text to search
         * @width 2
         */
        searchColumn: string,
        /**
         * Column that contains unique values for each row, used to differentiate any duplicate search column values
         * @width 2
         */
        uniqueColumn?: string,
        /**
         * @width 2
         */
        idColumn?: string,
    },
    /**
     * @name check for updates every
     * @width 4
     */
    updateInterval?: {
        /**
         * @width 2
         */
        amount: number,
        /**
         * @width 2
         */
        unit: 'hours' | 'days' | 'weeks' | 'months',
    },
    /**
     * @width 4
     */
    allowNotFound?: {
        /**
         * @width 4
         */
        customOptionLabel?: Text,
    }
};

export type SearchSelect = WithLegacy<{
    kind: 'SearchSelect';
    /**
     * @width 3
     */
    targetField: TargetField;
    /**
    * @width 4
    */
    content: RichText,
    maxOptions?: number,
    /**
     * @width 4
     */
    searchType: ClientSide | ServerSide,
    /**
    * @hidden yes
    */
    version: 1,
}, LegacySearchSelectV0>;

export type ShowField = {
    kind: 'Show Field',
    /**
     * @width 3
     */
    targetField: TargetFieldRef,
    /**
     * @width 4
     */
    headerText: Text,
    showsVersions?: boolean,
    allowDecrypting?: boolean
    keepVisibleForReview?: boolean,
}

export type Date = {
    kind: 'Date',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
}

export type Number = {
    kind: 'Number',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    encrypted?: boolean,
    min?: number | string,
    max?: number | string,
    min_length?: number,
    max_length?: number,
    integers_only?: boolean,
    format?: 'usd',
    startsWith?: string,
    doesNotStartWith?: string,
}

export type EncryptedValueVerification = {
    kind: 'Encrypted Value Verification',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    /**
     * @width 4
     */
    sourceField: TargetFieldRef,
    lastFourOnly?: boolean
}

export type BankRoutingNumber = {
    kind: 'BankRoutingNumber',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
}

export type VerificationRequirements = {
    facePresent?: boolean,
    /**
     * @width 4
     */
    textPresent?: {
        kind: 'Any Text' 
    } | {
        anyOf?: (string | Field)[],
        allOf?: (string | Field)[],
    },
    advisoryOnly?: boolean,
    /**
     * @width 4
     */
    errorMessage: Text
}

export type Attachment = {
    kind: 'Attachment',
    /**
     * @width 3
    */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    limitOne?: boolean,
    detectFaces?: boolean,
    extractText?: boolean,
    directUploadOnly?: boolean,
    allowSendingLinkToUpload?: boolean,
    enableCamera?: boolean,

    /**
     * @width 4
     */
    realtimeVerifications?: VerificationRequirements[]
}

export type IPCheck = {
    /**
     * @width 4
     */
	kind: 'IP VPN Check',
    /**
     * @width 4
     */
	ipField?: '_ip' | string 
}

export type SharedKeyComputation = {
    /**
     * @width 2
     */
    kind: "Shared Key Computation",
    /**
     * All applicants with the same value for this field will be included in the computation specified in propagateUpdates
     * @width 2
     * @name Shared Key
     */
    sharedKey: "program_identity" | string,
    /**
     * All fields that are referenced in propagateUpdates. Any field referenced there without
     * being listed here will not evaluate correctly
     * @width 4
     * @name Depends On
     */
    dependsOn: Field[] // example: program_identity, duplicate_review_1, metastate
    /**
     * Code that will be executed for each of the applicants with matching values for the sharedKey specified,
     * generally used to set/update multiple other fields.
     * Propagate Updates should be a Record<uid, Record<infoKey, value>>
     * We will run RoboScreener after propagating these updates.
     * @width 4
     * @validateJS ((shared_apps) => { const newInfo = expr; return newInfo; })
     */
    propagateUpdates: Code
}

export type ITINCheck = {
    /**
     * @width 4
     */
    kind: 'ITIN Check',
    /**
     * @width 4
     */
    itinField: TargetFieldRef
    nameField?: 'legal_name' | TargetFieldRef;
}

export type EmailDomainCheck = {
    /**
     * @width 4
     */
	kind: 'Email Domain Check'
    /**
     * @width 4
     */
    emailField: TargetFieldRef
}

export type IDCheck = {
    /**
     * @width 4
     */
    kind: 'ID Check',
    /**
     * @width 4
     */
    detailsField: TargetFieldRef
}

export type ProgramSpecificCheck = {
    /**
     * @width 4
     */
	kind: 'Program Specific',
    /**
     * @width 2
     */
	name: string,
    dependencies?: TargetFieldRef[],
}

export type ThirdPartyCheck = {
    /**
     * @width 4
     */
    kind: "Third Party Check"
    /**
     * @width 4
     */
	condition: BooleanExpr, // Who should be included
    /**
     * @width 4
     */
	type: IPCheck | EmailDomainCheck | IDCheck | ITINCheck | ProgramSpecificCheck | SharedKeyComputation,
    /**
     * @width 2
     */
    targetField: TargetField,
    /**
     * @width 2
     */
	statusField?: TargetField // defaults to targetField_status. Stores 'pending' | 'done'
}

export type LivenessDetection = {
    /**
     * @width 2
     */
    kind: 'Liveness Detection',
    /**
     * The content that appears in the survey, above the start button
     * @width 4
     * @name Survey Content
     */
    content: RichText
    /**
    * This is the target field the entire liveness video will be stored in.
    * @width 2
    * @name Target Field
    */
    targetField: TargetField,
    /**
     * @width 4
     */
    selfie: {
        /**
         * We will automatically Detect Faces in the selfie capture
         * @width 2
         * @name Capture Target Field
         */
        targetField: TargetField,
        /**
         * Optional content to display above the capture button
         * @width 4
         */
        content?: RichText
    },
    /**
     * @width 4
     */
    identification?: {
        /**
         * @width 4
         */
        front: {
            /**
             * @width 2
             */
            targetField: TargetField,
            /**
             * @width 4
             */
            content: RichText,
            /**
             * Adds a button to allow the user to take a "tall" picture of their ID,
             * useful for Paper IDs or other non-standard ID types.
             * @width 2
             */
            allowTallImages?: boolean
        },
        /**
         * @width 4
         */
        back: {
            /**
             * @width 2
             */
            targetField: TargetField,
            /**
             * @width 4
             */
            content: RichText,
            /**
             * By default, we assume this is back of id.
             * But liveness can be used to capture all sorts of images!
             * This lets you specify an alternative document kind in place of the "Back of ID"
             * @name Alternative Document
             * @width 4
             */
            alternativeDocument?: {
                /**
                 * @width 2
                 */
                kind: "Debit Card"
            }
        }
    },

    /**
     * What the button to start the liveness detection process should say.
     * Default is "I'm ready, send me the text!"
     * @width 4
     */
    startButtonText?: Text,

    /**
     * What to show after the process is done.
     * @width 4
     */
    completionContent?: RichText,

    /**
     * Which contacts to send the link to. 
     * We will attempt these in order, the first field with a valid contact value will be sent the link.
     * @name Contact Fields
     * @width 4
     */
    contactFields?: TargetFieldRef[],

    /**
     * This option will result in lower quality images 
     * because webcams are generally way lower MP than phone cameras.
     * However, it provides a more seamless UX for applicants.
     * @name Allow Desktop Webcam
     * @width 2
     */
    allowDesktopWebcam?: boolean

    /**
     * @uuid hidden
     * @hidden yes
     */
    id: string,
}

export type InlineSignature = {
    /**
     * @width 2
     */
    kind: 'InlineSignature',
    /** 
     * @width 2
     */
    targetField: TargetField,
    /** 
     * @width 4
     */
    content: RichText | ConditionalContentList,
    /**
     * @width 2
     */
    signerNameTargetField?: string | {
        special: 'Viewer Name'
    }
    /**
     * If this flag is set, then this signature will not include contact confirmation, and will not be available via a public URL
     */
    anonymous?: boolean;
    /**
     * For use with Fillable pdf forms
     * @width 2
     */
    captureRawSignature?: boolean
}

export type FillableForm = {
    /**
     * @width 2
     */
    kind: "Fillable Form",
    /**
     * @width 2
     */
    targetField: TargetField,
    /**
     * @display stacked
     * @width 4
     */
    form: {
        kind: "w9",
        legalNameField: TargetFieldRef,
        mailingAddressField: TargetFieldRef,
        /**
         * @name Encrypted SSN or EIN field
         */
        tinField: TargetFieldRef,
        /**
         * For storing the raw signature for use elsewhere
         * @name Signature Storage Field
         */
        signatureStorageField: SelfReferencingTargetField
    },
    /**
     * Instructions are static and depend on the document. They will be imported in questions.ts 
     * depending on the document kind used
     * @hidden
     */
    instructions?: Text,
    /**
     * Defaults to 'Document Signed, click below to download a copy for your records'
     * @width 4
     */
    finishedContent?: RichText | ConditionalContentList
    /**
     * Defaults to 'You still need to fill out some things before you can continue: (lists the target fields)'
     * @width 4
     */
    notYetReadyContent?: RichText | ConditionalContentList
}

export type LikertQuestions = {
   /**
    * @width 2
    */
   label: Text,
   targetField: TargetField,
};

export type LikertDataSource = {
   /**
    * Target field where this data is stored in applicant info. Each Likert From Data only pulls from one Target Field.
    * This data is expected to be an array type.
    * @name Data Target Field
    * @width 2
    */
   targetField: TargetFieldRef,
   /**
    * Field to display as the label on the likert - if not present in the object, the entire value will display.
    * This value should be unique for each question to avoid confusion / answers being overwritten.
    * @width 2
    * @name Display Field
    */
   displayField: string,
   /**
    * When the likert is filled out, this is where the answer will go per data item.
    * @width 2
    * @name Answer Field
    */
   answerField: string,
}

export type Likert = {
    kind: 'Likert',
    /**
     * @width 3
     */
    content: RichText,
    /**
     * The value scale option displays only the first/last option labels and all option values,
     * e.g. 1 - Strongly Disagree, 2, 3, 4, 5 - Strongly Agree
     */
    showValueScale?: boolean,
    /**
     * Display Format: 'horizontal' for Likert tabular format on all screens (best for <=5 options). 'responsive_horizontal' adapts to screen size, showing Likert on desktop and stacked choice on mobile. Default is Likert for desktop and stacked for mobile or >5 options.
     * @width 2
     */
    display?: 'horizontal' | 'responsive_horizontal',
    /**
     * @width 4
     */
    choices: {
        value: string,
        /**
         * @width 2
         */
        label: Text 
    }[],
    /** 
     * @name Questions or Data Source
     * @width 4
     */
    questions: LikertQuestions[] | LikertDataSource,
    randomizeOrder?: boolean,
}

/**
 * @migration LoginWidgetV2
 */
export type LegacyLoginWidgetV0 = {
    kind: 'LoginWidget'
    page: string
}

export type LoginWidget = WithLegacy<
{
    kind: 'LoginWidget'
    page: string
    /**
     * @width 2
     */
    buttonText: Text
    /**
     * Allows storing the contact info (e.g. person@email.com)
     * in a specified field upon successful login
     * @width 4
     */
    saveContactInfo?: {
        /**
         * @width 2
         */
        contactInfoField: TargetField,
    }
    /**
     * @uuid hidden
     * @hidden yes
     */
    id?: string,
    /**
     * @hidden yes
     */
    version: 2
}, LegacyLoginWidgetV0>

export type CustomJobKind = {
    /**
     * @width 4
     */
    value: string,
    /**
     * @width 4
     */
    label: Text
}

export type IncomeCalculator = {
    kind: 'Income Calculator',
    /**
     * @width 3
     */
    targetField: TargetField,
    customJobKinds?: CustomJobKind[],
    excludeDependents?: boolean,
    excludeCashBenefits?: boolean;
}

export type InlineLanguageSelector = {
    kind: "Inline Language Selector",
    /**
     * @width 4
     */
    content: RichText,
}

export type CustomSelect = {
    kind: 'Select';
    /**
     * @width 4
     */
    key: string;
    /**
     * @width 4
     */
    content: RichText;
    /**
         * @width 4
         */
    choices: {
        /**
         * @width 4
         */
        value: string;
        /**
         * @width 4
         */
        label: Text;
    }[],
}

type MaxCountFromField = {
    /**
    * @width 4
    * @name Dynamic limit from another field
    */
    field: TargetFieldRef
};

export type CustomTextEntry = {
    kind: 'TextEntry';
    /**
     * @width 4
     */
    key: string;
    /**
     * @width 4
     */
    content: RichText;
};

export type CustomDate = {
    kind: 'Date',
    /**
     * @width 4
     */
    key: string;
    /**
     * @width 4
     */
    content: RichText;
    /**
     * @width 4
     */
    showAge?: boolean;
    /**
     * @width 4
     * @name min allowed time in past
     */
    minAllowedTimeInPast?: {
        /**
         * @width 2
         */
        amount: number,
        /**
         * @width 2
         */
        unit: 'days' | 'weeks' | 'months' | 'years',
        /**
         * @width 4
         * @name error message
         */
        errorMessage?: Text,
    };
    /**
     * @width 4
     * @name max allowed time in past
     */
    maxAllowedTimeInPast?: {
        /**
         * @width 2
         */
        amount: number,
        /**
         * @width 2
         */
        unit: 'days' | 'weeks' | 'months' | 'years',
        /**
         * @width 4
         * @name error message
         */
        errorMessage?: Text,
    };
    /**
     * @width 4
     * @name min allowed time in future
     */
    minAllowedTimeInFuture?: {
        /**
         * @width 2
         */
        amount: number,
        /**
         * @width 2
         */
        unit: 'days' | 'weeks' | 'months' | 'years'
        /**
         * @width 4
         * @name error message
         */
        errorMessage?: Text,
    };
    /**
     * @width 4
     * @name max allowed time in future
     */
    maxAllowedTimeInFuture?: {
            /**
         * @width 2
         */
        amount: number,
        /**
         * @width 2
         */
        unit: 'days' | 'weeks' | 'months' | 'years'
        /**
         * @width 4
         * @name error message
         */
        errorMessage?: Text,
    };
}

export type Role = {
	/**
	 * @width 4
	 */
	type: string;
	/**
	 * @width 4
	 */
	label: {
		/**
		 * @width 4
		 */
		singular: Text;
		/**
		 * @width 4
		 */
		plural?: Text;
	};
	/**
	 * @width 4
	 * @name data collect options
	 */
	dataCollectOptions: {
		/**
		 * @width 4
		 * @name Require full name
		 */
		requireFullName?: boolean;
		/**
		 * @width 4
		 * @name Hide name
		 */
		hideName?: boolean;
		/**
		 * @width 4
		 * @name Collect birthdate
		 */
		collectBirthdate?: "full" | "year_only";
		/**
		 * @width 4
		 * @name Dependence on applicant
		 */
		dependence?:
			| { preset: "independent" | "dependent_on_applicant" | "dependent_on_someone_else" | "applicant_dependent_on_them" }
			| "collect";
		/**
		 * @width 4
		 * @name Max # members in this role
		 */
		maxCount?: number | MaxCountFromField;
		/**
		 * @width 4
		 * @name Custom questions
		 */
		customQuestions?: (CustomSelect | CustomTextEntry | CustomDate)[];
	};
	/**
	 * @width 4
	 * @name display options
	 */
	displayOptions: {
		/**
		 * @width 4
		 * @name Form open to start
		 */
		startOpen?: boolean;
		/**
		 * @width 4
		 * @name Hide member count for this role
		 */
		hideCount?: boolean;
	};
}

export type HouseholdCalculator = {
    /**
     * @width 2
     */
    kind: 'Household Calculator';
    /**
     * @width 2
     */
    targetField: TargetField;
    roles: Role[];
	/**
	 * @width 4
	 * @name Custom title
	 */
	title?: Text;
	/**
	 * @width 4
	 * @name Include applicant as a member
	 */
	showYou?: {
		/**
		 * @width 4
		 * @name Applicant's name field
		 */
		nameField: TargetFieldRef;
		/**
		 * @width 4
		 * @name Applicant's birthdate field
		 */
		birthdateField?: TargetFieldRef;
	};
	/**
	 * @width 4
	 * @name Custom label for total count
	 */
	memberCountLabel?: {
		/**
		 * @width 4
		 */
		singular: Text;
		/**
		 * @width 4
		 */
		plural: Text;
	};
    /**
     * @width 4
     * @name Reference date
     */
    referenceDate?: {
        year: number,
        month: number,
        day: number
    } | TargetFieldRef;
};

/**
 * @display stacked
 */
export type NumberWithUnit = {
    kind: 'NumberWithUnit',
    numberQuestion: {
        /**
         * @width 4
         */
        targetField: TargetField,
    },
    unitQuestion: {
        /**
         * @width 4
         */
        targetField: TargetField,
        choices: {
            /**
             * @width 4
             */
            value: string,
            /**
             * @width 4
             */
            label: Text,
        }[],
    }
    content: RichText,
    format?: 'usd',
    integers_only?: boolean,
}

export type Assignee = {
    kind: 'Assignee',
    /**
     * @width 3
     */
    targetField: TargetField 
    /**
     * @width 4
     */
    content: RichText,
}

export type Computed = {
    kind: 'Computed',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    /**
     * @width 4
     * @validateJS (() => expr )
     */
    formula: Code | ValueExpr,
    format?: 'currency',

    /**
     * @width 4
     */
    testCases?: {
        /**
         * @width 4
         */
        description: string,
        /**
         * Input can be either a JSON Object (string, { "birth_date": "01/01/2000" } ) or a Persona
         * @width 4
         * @validateJS (() => { const newInfo = expr })
         */
        input: Code | PersonaRef,
        /**
         * Use output as the input variable here, e.g. (output === 'yes' or JSON.parse(output).eligible === true)
         * @width 4
         * @validateJS (() => expr )
         */
        test: Code,
    }[]
}

type PersonaRef = { 
    kind: "Persona", 
    /**
     * @width 3
     */
    persona: PersonaNameRef 
}

export type Validated = {
    kind: 'Validated',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    /**
     * @width 4
     * @validateJS ((info) => { try { expr } catch (e) { if (!e.toString().includes('$content')) throw e; } })
     */
    formula: Code,
    advisory?: boolean
}

export type RankingQuestion = {
    kind: 'Ranking',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    choices: {
        value: string,
        label: Text,
    }[],
    /**
     * If specified, this will dictate the number of choices that the user must select.
     * If omitted, all choices must be ranked.
     */
    number_of_required_choices?: number
}

export type ResumeWidget = {
    kind: 'ResumeWidget',
    /**
     * @width 4
     */
    ingestedResumesToSubsurvey?: SubsurveyPathRef,
    /**
     * @width 4
     * @name Button Text
     */
    customButtonText?: Text
}

export type SubmitButton = {
    kind: 'SubmitButton',
    /**
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    customText?: Text,
    hideRequestChangesButton?: boolean
}

/**
 * @display stacked
 */
export type Action = {
    kind: 'Action',
    /**
     * @name Button Text
     */
    content: Text,
    /**
     * Should return an updates object like { key: 'value', key2: 'value2' }, each key/value pair will be applied to the applicant info.
     * @name Action
     * @validateJS (() => { const newInfo = expr } )
     */
    action: Code,
    confirmation?: Text,
    toast?: Text,
    /**
     * Describe any custom fields that get set here
     * @width 4
     */
    fields?: TargetField[]
}

export type ApplicantCreator = {
    kind: 'ApplicantCreator',
    /**
     * This is the field for the existing applicant where the ID of the newly created applicant will be stored.
     * @name linkedApplicantUIDField
     * @width 2
     */
    targetField: TargetField,
    /**
     * @name Limited To Users Tagged
     * @width 2
     */
    allowedUserTags: UserTagRef[],
    /**
     * @width 2
     */
    buttonText: Text,
    /**
     * @width 4
     */
    newApplicantInitialInfo: {
        nameSourceField: TargetField,
        /**
         * We store the ID and name of the existing applicant in the info of the new applicant.
         * so if the prefix is created_by, then created_by_id will be the existing applicant's UID
         * and created_by_name will be the existing applicant's legal_name
         */
        existingApplicantReferencePrefix: Distinct<string>,
        otherFields?: {
            /**
             * @width 3
             */
            source: ValueExpr,
            destinationField: TargetField,
        }[]
    },
}

type CommonFlagProperties = {
    /**
     * Optional max number of folks who can meet the criteria before it's a problem
     */
    maxCount?: number,
    maxSum?: number,
}

/**
 * @name Unique Fields Flag
 */
type UniqueFlag = {
    kind: 'Unique',
    /**
     * @width 4
     */
    programs: {
        /**
         * Program must refer to an active program e.g. chicagoerdw
         * @width 4
         * @format ^[a-z]+$
         * @formatMessage Lowercase characters only, no spaces.
         */
       program: string,
        /**
         * @width 4
         */
       fields: { 
            /**
             * @width 2
             */
            field: TargetFieldRef,
            mode: 'exact' | 'fuzzy'
       }[],
        /**
         * Required Info to be part of the group checked for others to be flagged.
         * @width 4
         */
        requiredInfo?: {
            key: TargetFieldRef,
            value: string
        }[]
    }[]
}

type QueryFlag = {
    kind: 'Query Flag',
    /**
     * @width 4
     */
    programs: {
        /**
         * @width 4
         * @format ^[a-z]+$
         * @formatMessage Lowercase characters only, no spaces.
         */
        program: string,
        /**
         * @width 4
         */
        query: SQLQuery,
        depends_on?: TargetFieldRef[],
        params?: TargetFieldRef[],
        /**
         * Required Info to be part of the group checked for others to be flagged.
         * @width 4
         */
        requiredInfo?: {
            key: TargetFieldRef,
            value: string
        }[]
    }[]
}

export type Flag = {
    kind: 'Flag',
    /**
     * @width 3
     */
    targetField: TargetField,
    /** 
     * @width 4
     */
    content: RichText,
    /**
     * @width 4
     */
    flag: (UniqueFlag | QueryFlag) & CommonFlagProperties,
    advisory?: boolean,
    /**
     * Configures whether this Flag check should be run asynchronously in the background at a later time (async=true)
     * or if it needs to be recomputed immediately when applicantinfo is ingested or updated (async=false)
     */
    async?: boolean
}

export type DuplicateReview = {
    kind: 'Duplicate Review'
    /**
     * @width 4
     */
    content?: RichText,
    /**
     * Field defined elsewhere, used to determine if an applicant shows up in this Duplicate Review
     */
    flagField: TargetFieldRef,
    /**
     * The results of this Duplicate Review will be stored here for a given applicant.
     * It will be of the form: uid1:value1,uid2:value2,...
     * where uid is the applicant that was compared against and value was the chosen comparison decision value for that other applicant.
     */
    targetField: TargetField,
    /**
     * Field used to determine which applicant(s) is/are considered duplicates and which are not
     * @width 4
     */
    tiebreakerField: {
        /**
         * @width 1
         */
        kind: 'Earliest Submission',
        /**
         * @width 3
         */
        field: TargetFieldRef
    }
    /**
     * The values of these fields will be displayed to the reviewer when they review the potential duplicates
     * @width 4
     */
    comparisonFields: TargetFieldRef[],
    /**
     * @width 4
     */
    customDecisions?: {
        /**
         * @width 4
         */
        text: Text,
        /**
         * @width 2
         */
        value: "same_person" | "fraud" | "neither" | string,
        /**
         * This field gets set to the same value for each set of applicants that are matched together with a given
         * comparison decision in this Duplicate Review. If there is already a value for this field for an applicant,
         * matching applicants will have their value set to that, otherwise a new, unique value will be generated and used
         * @width 2
         */
        sharedKeyField?: TargetField,
    }[],
    /**
     * @uuid hidden
     * @hidden yes
     */
    id: string,
    /**
     * Controls whether the original horizontal scroll UI or the new one by one modal review UI is used.
     */
    useModalUI?: boolean
}

/**
 * @name Similar Document Check
 * @display stacked
 */
export type SimilarDocumentCheck = {
    /**
     * Configure this question to enable a similar document check to be conducted on the documentTextField within this program.
     * The system will analyze all applicants and flag similar documents above the similarityThreshold configured 
     * in the targetField.
     */
    kind: 'Similar Document Check',
    /**
     * The target field where the text we want to compare is stored. This is often the target field
     * where text extraction writes to but could be any text target field we want to analyze.
    */
    documentTextFields: TargetFieldRef[],
    targetField: TargetField,
    /**
     * Similarity threshold is in percent between 0 and 100. We recommend a value above 90.
     */
    similarityThreshold: number,
    /**
     * Use this to ensure both applicants who are being flagged as having similar documents are updated.
     * For example, if Applicant A submits their application with a doc and later Applicant B submits identical documentation,
     * we would normally flag only Applicant B.
     * Checking enableReflection will flag both Applicant a and B.
     */
    enableReflection?: boolean,
    /**
     * Use this to display the Processing status in the screener view. It will show if the applicant's
     * similarity check has been processed yet and when the last processing took place.
     */
    showStatus?: boolean,
}

export type SimilarDocumentReview = {
    kind: 'Similar Document Review'
    /**
     * @width 4
     */
    content?: RichText,
    documentField: TargetFieldRef,
    targetField: TargetField,
    /**
     * @width 4
     */
    maxCount?: number,
    /**
     * Cutoff is in percent be between 0 and 100.
     * @width 4
     */
    cutoff?: number;
    /**
     * @uuid hidden
     * @hidden yes
     */
    id: string,
    /**
     * default is 'Suspicious?' Use this to override that.
     * @name Suspicious Label Override
     * @width 4
     */
    suspiciousLabel?: Text
    /**
     * @width 4
     */
    comparisonFields?: {
       field: TargetFieldRef
    }[]
    /**
     * Controls whether the original horizontal scroll UI or the new one by one modal review UI is used.
     */
    useModalUI?: boolean
}

/**
 * @name Standard
 * @display stacked
 */
type StandardLookup = {
    kind: 'Standard',
    /**
     * @name Lookup Key
     */
    key: string,
    /**
     * @name Field to Query
     */
    queryField: string
}

/**
 * @name Geo
 * @display stacked
 */
type GeoLookup = {
    kind: 'Geo',
    key: 'community_area' | 'ward' | string,
    queryField: string
}

/**
 * @name Dynamo
 * @display stacked
 */
type DynamoLookup = {
    kind: 'Dynamo',
    contactConfirmationField: string,
    configPath: string,
    queryFields: {
        /**
         * @width 2
         */
        fieldInDynamo: string,
        /**
         * @width 2
         */
        fieldInAidKit: string
    }[],
    sensitiveInfo?: {
        field: string,
        action: "hide" | "encrypt" | "hash"
    }[]
}

export type RelatedApplicants = {
    kind: 'Related Applicants',
    /**
     * The key to gather related applicants by. 
     * This is a field in the applicant info.
     *
     * @name Field to Gather By
     * @width 4
     *
     */
    byKeys: TargetFieldRef[],

    /**
     * @name Title
     * @width 4
     *
     */
    title?: Text,

    /**
     * @name Show View Link
     * @width 4
     */
    showView: boolean
}

export type Lookup = {
    kind: 'Lookup',
    /** 
     * @width 3
     */
    targetField: TargetField,
    /**
     * @width 4
     */
    content: RichText,
    /** 
     * @width 4
     */
    lookup: StandardLookup | GeoLookup | DynamoLookup
}

export type Consent = {
    kind: 'Consent',
}

export type BenefitsCalculator = {
    kind: 'Benefits Calculator',
    /**
     * @width 4
     */
    waivers: {
        wic: boolean,
        snap: boolean,
        tanf: boolean,
        liheap: boolean,
        healthcare?: boolean,
        taxCredits?: boolean,
        childcare?: boolean,
    }
    /**
     * @width 4
     */
    required_info: {
        /**
         * @width 4
         */
        monthly_income: ValueExpr,
        /**
         * @width 4
         */
        live_zip: ValueExpr,
        /**
         * @width 4
         */
        gi_income: ValueExpr,
    }
    /**
     * @width 4
     */
    optional_info: {
        /**
         * This is information required to get a useful response from BK about SNAP.
         * It is NOT information about someone's existing SNAP status; for that, use the foodStamps option.
         * @width 4
         */
        snap?: {
            /**
             * @width 4
             */
            child_care_cost: ValueExpr,
            /**
             * @width 4
             */
            feed_family_cost: ValueExpr,
            /**
             * @width 4
             */
            homeless: ValueExpr,
            /**
             * @width 4
             */
            housing_costs: ValueExpr,
            /**
             * @width 4
             */
            ssi_children_number: ValueExpr,
            /**
             * @width 4
             */
            ssdi_amount: ValueExpr,
            /**
             * @width 4
             */
            ssi_spouse_amount: ValueExpr,
            /**
             * @width 4
             */
            ssi_you_amount: ValueExpr,
            /**
             * @width 4
             */
            ssi_income_kids: ValueExpr,
            /**
             * @width 4
             */
            welfare_amount: ValueExpr
        },
        /**
         * @width 4
         */
        ctc?: {
            /**
             * @width 4
             */
            married: ValueExpr
        },
        /**
         * @width 4
         */
        otherInfo?: {
            /**
             * Use this to send information about someone's EXISTING food stamps/SNAP
             * situation to BK to improve accuracy of eligibility estimations
             * @name Food Stamps/SNAP Amount
             * @width 4
             */
            food_stamps?: ValueExpr
        }
    },
    /**
     * @width 2
     */
    household_calc_target_field?: TargetField,
    /** 
     * @width 2
     */
    targetField?: TargetField,
    /**
     * If this flag is set, the individual values comprising the response from Benefits Kitchen will be stored
     * as separate target fields. NOTE: a targetField must also be set for this to work!
     */
    flattenIntoFieldsWithPrefix?: TargetField,
    /** 
     * @width 4
     */
    hideImpactIf?: BooleanExpr
}

export type SupportButton = {
    kind: 'SupportButton',
    /** 
     * Override the heading text. Leave empty to use auto-translated value.
     * @width 4
     */
    messageTextOverride?: Text,
    /** 
     * Override the button text. Leave empty to use auto-translated value.
     * @width 4
     */
    buttonTextOverride?: Text,
    /** 
     * Override the support email. Defaults to support@<program_name>.aidkit.org
     * @width 4
     */
    supportEmailOverride?: string
}

export type Configuration = {
    enable_audit: 'true' | 'false',
    [key: string]: string
}

export type Never = {
    'kind': 'Never'
};

export type FieldExists = {
    'field': TargetFieldRef 
    'kind': 'Exists'
}

export type FieldDoesntExist = {
    'field': TargetFieldRef 
    'kind': 'DoesntExist'
}

export type FieldEquals = {
    'field': TargetFieldRef,
    'kind': 'Equals'
    'value': string,
}

export type FieldNotEqual = {
    'field': TargetFieldRef,
    'kind': 'Not Equal'
    'value': string,
}

export type FieldLastModifiedBefore = {
    'field': TargetFieldRef,
    'kind': 'Last Modified'
    /**
     * @width 4
     */
    'ago': {
        amount: number,
        unit: 'days' | 'weeks' | 'months'
    }
}

export type Or = {
    'kind': 'Or',
    /**
     * Any of these must be true
     */
    'clauses': (BooleanExpr | GenericTemplatedBlock<BooleanExpr[]>)[]
}

export type And = {
    'kind': 'And',
    /**
     * All of these must be true
     */
    'clauses': (BooleanExpr | GenericTemplatedBlock<BooleanExpr[]>)[]
}

export type Not = {
    'kind': 'Not',
    /**
     * @width 4
     */
    'clause': BooleanExpr
}

export type After = {
    'kind': 'After',
    /**
     * @width 4
     */
    date: {
        year: number,
        month: number,
        day: number
    },
    /**
     * @width 4
     */
    time: {
        hour: number,
        minute: number
    }
}

export type Before = {
    'kind': 'Before',
    /**
     * @width 4
     */
    date: {
        year: number,
        month: number,
        day: number
    },
    /**
     * @width 4
     */
    time: {
        hour: number,
        minute: number
    }
}

export type CurrentUser = {
    'kind': 'Current User',
    property?: 'tags'
}

export type When = {
    'kind': 'When',
    /**
     * @width 4
     */
    when: {
        /**
         * @width 4
         */
        cond: BooleanExpr,
        /**
         * @width 4
         */
        then: ValueExpr 
    }[]
    /**
     * @width 4
     */
    otherwise: ValueExpr 
}

export type ApplicantLuck = {
    kind: 'Applicant Luck',
    prop: 'luck'
}

export type UserLookup = {
    kind: 'User',
    prop: 'name' | 'roles' | 'email'
};

export type MessageLookup = {
    kind: 'Message',
    prop: 'needs_attention',
    messageType: 'sms' | 'email'
    destination?: string,
};

export type PaymentLookup = {
    kind: 'Payment',
    prop: 'status' | 'type' | 'amount'| 'date created' | 'transaction ref'
}

export type TableLookup = UserLookup | MessageLookup | PaymentLookup;

export type Field = {
    'kind': 'Field',
    'field': TargetFieldRef,
    /**
     * @width 4
     */
    lookup?: TableLookup
}

export type FieldLastModifiedDate = {
    /**
     * @width 2
     */
    kind: 'Last Modified Date',
    /**
     * @width 2
     */
    field: TargetFieldRef,
}

export type NumericValue = {
    'kind': 'NumericValue',
    /**
     * @width 4
     */
    'value': number 
}

export type StringValue = {
    'kind': 'StringValue',
    /**
     * @width 4
     */
    'value': string
}

export type BooleanValue = {
    'kind': 'BooleanValue',
    /**
     * @width 4
     */
    'value': boolean
}

export type SyncStatus = {
    'kind': 'Sync Status',
    mini?: boolean
}

type DateExpr = FieldLastModifiedDate | AsDate | ShiftDate | Now

export type Now = {
    'kind': 'Now'
}

export type AsDate = {
    'kind': 'As Date',
    /**
     * @width 3
     */
    'value': StringValue | Field
}

export type ShiftDate = {
    'kind': 'Shift Date',
    /**
     * @width 3
     */
    'interval': {
        minutes?: number,
        hours?: number,
        days?: number,
        weeks?: number,
        months?: number,
        years?: number,
    }
    /**
     * @width 4
     */
    'value': DateExpr,
}

export type ValueContains = {
    'kind': 'Value Contains',
    /**
     * @width 2
     */
    'mode': 'string' | 'comma separated list'
    'caseInsensitive'?: boolean,
    /** 
     * @width 4 
     */
    'value': ValueExpr,
    /** 
     * @width 4 
     */
    'contains': ValueExpr
}

export type ValueEquals = {
    'kind': 'Value Equals',
    /**
     * @width 4
     */
    'value': ValueExpr,
    /**
     * @width 4
     */
    'equals': ValueExpr
}

export type ValueNotEquals = {
    'kind': 'Value Not Equal',
    /**
     * @width 4
     */
    'value': ValueExpr,
    /**
     * @width 4
     */
    'notEqual': ValueExpr 
}

export type ValueEmpty = {
    'kind': 'Value Empty',
    /**
     * @width 4
     */
    'value': ValueExpr
}

export type ValueNotEmpty = {
    'kind': 'Value Not Empty',
    /**
     * @width 4
     */
    'value': ValueExpr
}

export type ValueLessThan = {
    'kind': 'Value Less Than',
    /**
     * @width 4
     */
    'value': ValueExpr,
    /**
     * @width 4
     */
    'lessThan': ValueExpr 
}

export type ValueGreaterThan = {
    'kind': 'Value Greater Than',
    /**
     * @width 4
     */
    'value': ValueExpr,
    /**
     * @width 4
     */
    'greaterThan': ValueExpr
}

export type TransformString = { 
    /**
     * @width 2
     */
    kind: "Transform String",
    /**
     * @width 2
     */
    transform: "lowercase" | "uppercase" | "titlecase" | "trim",
    /**
     * @width 4
     */
    value: ValueExpr
}

export type Expand = {
    kind: 'Expand',
    /**
     * Must be a JSON Array.
     *
     * each element in the array becomes its own row.
     * This will cause all non-expand fields to be duplicated across all
     * of the new rows.
     *
     * @width 4
     * @name Array Reference
     */
    value: TargetFieldRef
};

export type Extract = {
    kind: 'Extract',

    /**
     * The JSON Object to grab the value from.
     *
     * @width 4
     * @name Object Reference
     *
     */
    value: TargetFieldRef | Expand,

    /**
     * The field name (or multiple nested field names) to grab the value from.
     *
     * if a list of strings are used, they will be used to traverse the JSON Object,
     * e.g. a path of ['address', 'city'] would extract the city 
     * from the object { "address": { "city": "MA" }, "name": "Luke", "pets": 1 }
     *
     * @width 4
     * @name Path
     */
    path: string | string[]
};

export type Concat = {
    kind: 'Concat',

    /**
     *
     * The expressions to combine into a single string, using the delimiter
     *
     * @name Expressions
     * @width 4
     */
    value: ValueExpr[]

    /**
     *
     * Defaults to a single space.
     *
     * This is used between the resulting 
     * string values produced by the expressions.
     *
     * @name Delimiter
     * @width 2
     */
    delimiter?: string
}

export type ValueExpr = Field | FieldLastModifiedDate | When | 
    StringValue | NumericValue | BooleanValue | CurrentUser | ApplicantLuck |
    AsDate | ShiftDate | Now | ValueArithmeticExpr | BucketDate | Round |
    TransformString | SyncStatus | Expand | Concat | Extract;

export type Count = {
    kind: 'Count',
}

export type Round = {
    kind: 'Round',
    /**
     * @width 4
     */
    value: ValueExpr,
    /**
     * Places to the right of the decimal to round to. Default is 0.
     */
    places?: number,
}

export type Median = {
    kind: 'Median',
    /**
     * @width 4
     */
    value: ValueExpr,
}

export type Mean = {
    kind: 'Mean',
    /**
     * @width 4
     */
    value: ValueExpr,
}

export type Sum = {
    kind: 'Sum',
    /**
     * @width 4
     */
    value: ValueExpr,
}

export type SummaryExpr = Count | Median | Mean | Sum | SummaryArithmeticExpr;

export type SummaryAdd = {
    kind: 'Add',
    /**
     * @width 4
     */
    baseValue: SummaryExpr | NumericValue,
    /**
     * @width 4
     */
    secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummarySubtract = {
    kind: 'Subtract',
    /**
     * @width 4
     */
    baseValue: SummaryExpr | NumericValue,
    /**
     * @width 4
     */
    secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummaryMultiply = {
    kind: 'Multiply',
    /**
     * @width 4
     */
    baseValue: SummaryExpr | NumericValue,
    /**
     * @width 4
     */
    secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummaryDivide = {
    kind: 'Divide',
    /**
     * @width 4
     */
    baseValue: SummaryExpr | NumericValue,
    /**
     * @width 4
     */
    secondValue: SummaryExpr | NumericValue | SummaryExpr[],
}

export type SummaryRound = {
    kind: 'Round',
    /**
     * @width 4
     */
    value: SummaryExpr | NumericValue,
    /**
     * Places to the right of the decimal to round to. Default is 0.
     */
    places?: number,
}

export type SummaryArithmeticExpr = SummaryAdd | SummarySubtract | SummaryMultiply | SummaryDivide | SummaryRound;

export type ValueAdd = {
    kind: 'Add',
    /**
     * @width 4
     */
    baseValue: ValueExpr
    /**
     * @width 4
     */
    secondValue: ValueExpr | ValueExpr[],
}

export type ValueSubtract = {
    kind: 'Subtract',
    /**
     * @width 4
     */
    baseValue: ValueExpr
    /**
     * @width 4
     */
    secondValue: ValueExpr | ValueExpr[],
}

export type ValueMultiply = {
    kind: 'Multiply',
    /**
     * @width 4
     */
    baseValue: ValueExpr
    /**
     * @width 4
     */
    secondValue: ValueExpr | ValueExpr[],
}

export type ValueDivide = {
    kind: 'Divide',
    /**
     * @width 4
     */
    baseValue: ValueExpr
    /**
     * @width 4
     */
    secondValue: ValueExpr | ValueExpr[],
}

export type ValueArithmeticExpr = ValueAdd | ValueSubtract | ValueMultiply | ValueDivide;

export type OrderBy = {
    /**
     * @width 3
     */
    value: Field | FieldLastModifiedDate | 'Applicant Luck' | 'Random',
    /**
     * @width 1
     */
    order: 'asc' | 'desc'
}

/**
 * This is only used under the hood
 * @hidden true
 */
export type CodeBooleanExpr = {
    kind: 'Code Boolean Expr',
    code: string
}

export type BooleanExpr = Never 
    | FieldExists | FieldDoesntExist | FieldEquals | FieldNotEqual | FieldLastModifiedBefore | FieldLastModifiedDate
    | Or | And | Not 
    | After | Before
    | ValueNotEquals | ValueEquals | ValueEmpty | ValueNotEmpty
    | ValueLessThan | ValueGreaterThan | ValueContains
    | CodeBooleanExpr;

export type ClickQuery = {
    kind: 'Click',
    /**
     * @width 4
     */
    expr: BooleanExpr,
    /**
     * @width 4
     */
    orderBy?: OrderBy,
}

export type SQLQuery = {
    kind: 'SQL',
    /**
     * @width 3
     */
    sql: Code
}

export type Query = ClickQuery | SQLQuery

export type Payment = {
    /**
     * @name Group Name
     */
    name: string,
    /**
     * @name Target Field
     * @width 3
     */
    targetField: TargetField,
    /**
     * @name Amount
     * @width 2
     */
    amount: string
    /**
     * @name Enable Key 
     * @width 2
     */
    enableKey: string,
    /**
     * @name Condition
     * @width 4
     */
    condition: Query,
    /**
     * @name Payment Type
     */
    type: string,
    testUIDs?: string[],
    kind?: 'Payment',
    ledger?: string,
    cardIdField?: string,
    /**
     * if the payment kind is USIO Mailed Grant (usio_mail) OR GiveCard Mail (givecard_mail),
     * this field will cause the card mailed to the applicant to be
     * pre-associated.
     */
    autoAssociate?: boolean,
    /**
     * GiveCard Only Field (see GiveCardMailing kind)
     *
     * Cards that are ordered as a result of this Payment kind
     * will be allowed a higher limit (up to 8k).
     *
     * The default target_field is givecard_higher_spending_limit
     *
     * @name Higher Spending Limit
     * @width 4
     */
    higherSpendingLimit?: TargetFieldRef
    /**
     * 
     *
     * @name Auto Retry
     * @width 4
     */
    autoRetry?: {
        /**
         * Target fields and values to set on failure. For example, you may want to set the payment target field to empty when the payment fails.
         *
         * @name Set on Failure
         * @width 4
         */
        onFailure: {
            // will these get added to infodefs right away, or do I need to change something to get that to happen?
            key: TargetField,
            /**
             * @width 4
             */
            value: ValueExpr
        }[],
        /**
         * A query to exclude certain applicants from retry (for example, if _attempt > 2).
         *
         * @name Disable Retry When
         * @width 4
         */
        disableRetryWhen?: BooleanExpr
    }
}

export type CustomContact = {
    /**
     * @width 4
     */
    emailField: TargetFieldRef,
    /**
     * @width 4
     */
    phoneField: TargetFieldRef
}

export type ConditionalContent = { 
    /**
     * @width 4
     */
    conditional: BooleanExpr
    /**
     * @width 4
     */
    components: (RichText | ConditionalContent)[]
    /**
     * @width 4
     */
    otherwise?: (RichText | ConditionalContent)[]
}

// This is specifically so that ConditionalContentList displays a better name in the editor
type CustomizableContent = RichText | ConditionalContent

export type ConditionalContentList = CustomizableContent[]

// For convenience, to be used only by external callers
export type Content = Text | RichText | ConditionalContentList;

export type TrackedLink = {
    /**
     * @width 2
     */
    link: string,
    /**
     * @width 2
     */
    targetField: TargetField
}
export type Notification = NotificationGroup | InlineNotification;

/**
 * @migration NotificationGroupV1
 */
export type LegacyNotificationGroupV0 = {
    name: string,
    targetPrefix: Distinct<string>,
    recipient: 'Applicant' | 'Screener' | CustomContact
    enableKey: string,
    initial_notification: {
        enabled_when: Query,
        message: RichText | ConditionalContentList,
        email_subject: Text,
        email_message?: RichText | ConditionalContentList,
        subsurveys?: {
            name: SubsurveyPathRef | TrackedLink,
            variable: string
            expiresAfter?: {
                amount: number,
                unit: 'days' | 'weeks' | 'months'
            }
        }[],
    },
    followups?: {
        after: {
            amount: number,
            unit: 'days' | 'weeks' | 'months'
        },
        suffix: string,
        enableKey: string,
        send_if: Query,
        message: RichText | ConditionalContentList,
        email_subject: Text,
        email_message?: RichText | ConditionalContentList,
        recipient?: 'Applicant' | 'Screener' | CustomContact,
        subsurveys?: {
            name: string | TrackedLink,
            variable: string
            expiresAfter?: {
                amount: number,
                unit: 'days' | 'weeks' | 'months'
            }
        }[],
    }[],
    contactMethod: 'all' | 'preferred' | 'confirmed' | 'email' | 'sms'
    testUIDs?: string[],
    emailSender?: string
    smsService?: string,
    channel?: CommsChannelNameRef,
    timezone?: Timezone,
    kind?: 'Notification'
}

export type NotificationGroup = WithLegacy<{
    kind: 'Notification',
    /**
     * @hidden yes
     */
    version: 1,
    /**
     * @name Group Name
     */
    name: string,
    /**
     * We store record of a sent notification in a target field per contact type (sms and email).
     * We derive the target field names from this field prefix.
     * @name Target Field Prefix
     */
    targetPrefix: Distinct<string>,
    /**
     * @name Recipient
     */
    recipient: 'Applicant' | 'Screener' | 'Unsubmitted Applicant' | CustomContact
    /**
     * This is taken from the notifications tab on the right as an acknowledgement
     * that you've tested the notification criteria and copy.
     * @name Enable Key
     * @validateInput NotificationEnableKey
     */
    enableKey: string,
    /**
     * @name Initial Notification
     * @collapsible starts-collapse
     * @width 4
     */
    initial_notification: {
        /**
         * @name Condition
         * @width 4
         */
        enabled_when: Query,
        /**
         * @width 4
         */
        message: RichText | ConditionalContentList,
        /**
         * @width 4
         */
        email_subject: Text,
        /**
         * @width 4
         */
        email_message?: RichText | ConditionalContentList,
        /**
         * @name Linked Subsurveys or Links
         * @width 4
         */
        subsurveys?: {
            /**
             * @width 2
             */
            name: SubsurveyPathRef | TrackedLink,
            variable: string
            /**
             * @name Expires After
             * @width 4
             */
            expiresAfter?: {
                amount: number,
                unit: 'days' | 'weeks' | 'months'
            }
        }[],
    },
    /**
     * @name Followups 
     * @collapsible starts-collapse
     */
    followups?: {
        /**
         * @width 4
         */
        after: {
            /**
             * @default 1
             */
            amount: number,
            unit: 'days' | 'weeks' | 'months'
        },
        /**
         * @name Suffix
         */
        suffix: string,
        /**
         * @name Enable Key 
         */
        enableKey: string,
        /**
         * @name Condition
         * @width 4
         */
        send_if: Query,
        /**
         * @width 2
         */
        message: RichText | ConditionalContentList,
        /**
         * @width 4
         */
        email_subject: Text,
        /**
         * @width 4
         */
        email_message?: RichText | ConditionalContentList,
        /** 
         * @name Different Recipient?
         */
        recipient?: 'Applicant' | 'Screener' | 'Unsubmitted Applicant' | CustomContact,
        /**
         * @name Linked Subsurveys or Links
         * @width 4
         */
        subsurveys?: {
            name: string | TrackedLink,
            variable: string
            /**
             * @name Expires After
             * @width 4
             */
            expiresAfter?: {
                amount: number,
                unit: 'days' | 'weeks' | 'months'
            }
        }[],
    }[],
    /**
     * @name ContactMethods
     * @width 4
     */
    contactMethod: 'all' | 'preferred' | 'confirmed' | 'email' | 'sms' | 'whatsapp',
    /**
     * @name Test UIDs
     */
    testUIDs?: string[],
    /**
     * @name Email Sender
     */
    emailSender?: string,
    /**
     * @name SMS Service SID
     */
    smsService?: string,
    /**
     * @name Comms Channel
     */
    channel?: CommsChannelNameRef,
    /**
     * @name Send Limit
     */
    sendLimit?: number,
    /**
     * @width 4
     */
    scheduleSettings?: {
        timezone: Timezone,
        limitToPartOfWeek?: 'weekdays' | 'weekends'
        // TODO(Riley): it'd be good to include some sort of validation to ensure that startTime
        // is before endTime.
        /**
         * @width 4
         */
        timeWindow?:
        {
            /**
             * @width 4
             */
            startTime: Time,
            /**
             * @width 4
             */
            endTime: Time,
        },
    }
}, LegacyNotificationGroupV0>

export type Timezone = 'America/Chicago' | 'America/New_York' | 'America/Denver' | 'America/Los_Angeles' | 'America/Phoenix';

type Time = {
    hour: number,
    am_pm: 'AM' | 'PM',
    minute?: number
}

export type InlineNotification = {
    kind: 'InlineNotification'
    /**
     * @name Notification Name
     */
    name: string,
    /**
     * We store record of a sent notification in a target field per contact type (sms and email).
     * We derive the target field names from this field prefix.
     * @name Target Field Prefix
     * @width 2
     */
    targetPrefix: Distinct<string>,
    /**
     * @name Initial Notification
     * @collapsible starts-collapse
     * @width 4
     */
    initial_notification: {
        /**
         * @name Condition
         * @width 4
         */
        enabled_when: Query,
        /**
         * @width 4
         */
        message: RichText | ConditionalContentList,
        /**
         * @width 4
         */
        email_subject: Text,
        /**
         * @width 4
         */
        email_message?: RichText | ConditionalContentList,
        /**
         * @name Linked Subsurveys
         * @width 4
         */
        subsurveys?: {
            name: SubsurveyPathRef,
            variable: string
            /**
             * @name Expires After
             * @width 4
             */
            expiresAfter?: {
                amount: number,
                unit: 'days' | 'weeks' | 'months'
            }
        }[],
    },
    /**
     * @name ContactMethods
     */
    contactMethod: 'all' | 'preferred' | 'confirmed' | 'email' | 'sms' | 'whatsapp',
    /**
     * @name Limited To Users Tagged
     */
    allowedUserTags: UserTagRef[],
    /**
     * @name Recipient
     */
    recipient?: CustomContact
    /**
     * @name Test UIDs
     */
    testUIDs?: string[],
    /**
     * @name Email Sender
     */
    emailSender?: string
    /**
     * @name SMS Service SID
     */
    smsService?: string,
    /**
     * @name Comms Channel
     */
    channel?: CommsChannelNameRef,
    /**
     * @name Send Timezone
     */
    timezone?: Timezone,
}

export type MapRegionDensity = {
    kind: 'Map Region Density',
    /**
    * @width 4
    * @name Chart title
    */
    title?: string | Text;
    /**
     * Specify map width in pixels. Default is 300
     */
    width?: number,
    /**
     * Specify map height in pixels. Default is 300
     */
    height?: number,
    /**
     * @references geolookupTypes
     * @width 4
     * @name Region boundary type
     */
    boundaries: Distinct<string>,
    /**
     * @width 4
     * @name Latitude Field
     */
    latitudeField: TargetFieldRef,
    /**
     * @width 4
     * @name Longitude Field
     */
    longitudeField: TargetFieldRef
}

// Needed, else the type will collide with other uses of GenericTemplatedBlock
type DashboardTemplatedBlock = GenericTemplatedBlock<DashboardComponent[]>;

export type DashboardComponent =
    DashboardSection
    | PieChart
    | ApplicantTable
    | CustomQuery
    | HeroCount
    | DashboardExplanation
    | SubsurveySummary
    | BarChart
    | HeroNumber
    | Queue
    | DataDictionary
    | MapRegionDensity
    | DashboardTemplatedBlock

export type DashboardSection = {
    kind: 'Dashboard Section'
    title: string | Text
    showAllSimultaneously: boolean,
    filters: {
        name: string,
        /**
         * @width 4
         */
        filter: BooleanExpr
    }[],
    /**
     * @hidden yes
     */
    activeFilter?: number,
    /**
     * @hidden yes
     */
    filterPath?: string,
    components: (DashboardComponent)[]
}

export type BucketDate = {
    /**
     * @width 4
     */
    kind: 'Bucket Date',
    /**
     * @width 4
     */
    bucket: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year',
    /**
     * @width 4
     */
    value: AsDate | FieldLastModifiedDate | ShiftDate,
}

export type DataDictionary = {
    kind: 'Data Dictionary',
    type: 'json' | 'csv',
    excludeFields?: TargetFieldRef[],
    /**
     * Only include fields from within the specified subsurvey
     */
    limitToSubsurvey?: SubsurveyPathRef,
    /**
     * Computations/Formulas are not included by default.
     * If desired, then JavaScript formulas are included as is, and there are 3 options for handling Distro Click computations:
     *   1. Pretty - converts Distro Click computations to a more human-readable format
     *   2. Compiled JS - converts Distro Click computations to JavaScript
     *   3. As is - leaves Distro Click computations as JSON objects
     */
    includeComputations?: 'Pretty' | 'Compiled JS' | 'As is',
}

type StackedBars = { 
    kind: "Stacked",
    values: ValueExpr[] 
}

type GroupedBars = { 
    kind: "Grouped",
    values: ValueExpr[] 
}

export type BarChart = {
    kind: 'Bar Chart',
    title: string | Text,
    /**
     * @name Y Axis value
     * @width 4
     */
    summary: SummaryExpr,
    /**
     * @name X Axis groups
     * @width 4
     */
    groups: ValueExpr[],
    /**
     * @width 4
     */
    filter?: BooleanExpr,
    /**
     * Specify chart width in pixels. Default is 300
     */
    width?: number,
    /**
     * Specify chart height in pixels. Default is 300
     */
    height?: number,
    countEmpty?: boolean,
    order?: 'ascending' | 'descending',
    hideTotal?: boolean,
    /**
     * Indicates whether we will split a field by commas into separate groups, or include 
     * the whole field (commas and all) as the one value
     * @name Allow commas in groups
     * @width 2
     */
    allowCommasInGroups?: boolean,
    /**
     * Bar charts need to graph numbers without units, but if you want to specify the unit on the chart, 
     * specify that here. (ie: %)
     */
    units?: string,
    /**
     * Specify further stacking and grouping for the bars
     * @name Bar Display
     * @width 4
     */
    bars?: StackedBars | GroupedBars,
    /**
     * When checked, we include historical data (ie, latest != true).
     */
    includeNonLatest?: boolean,

    /**
     * Adds a description to the Chart.
     * Placement is customizable!
     *
     * @width 4
     * @name Description
     */
    description?: DashboardDescription
}

export type HeroNumber = {
    kind: 'Hero Number',
    title: string | Text,
    /**
     * @width 4
     */
    summary: SummaryExpr,
    /**
     * @width 4
     */
    filter?: BooleanExpr,
    /**
     * @width 4
     */
    prefix?: string,
    /**
     * @width 4
     */
    suffix?: string,
    /**
     * Formats as a US number with commas every 3 digits
     */
    useCommas?: boolean,
    /**
     * Default is 2
     */
    decimalPlaces?: number,
}

export type DashboardExplanation = {
    kind: 'Dashboard Explanation',
    content: RichText,
    width?: 'full',
    alignment?: 'left',
} & CommonProperties;

export type SubsurveySummary = {
    kind: 'Subsurvey Summary',
    path: SubsurveyPathRef,
    notificationPrefixes?: string[],
    downloadOnly?: boolean,
    populationCrossSections?: TargetFieldRef[],
    additionalColumns?: ValueExpr[],
    excludeFields?: string[],
    hideDownload?: boolean,
}

export type PieChart = {
    kind: 'Pie Chart',
    title: string | Text,
    /**
     * @width 4
     */
    summary: SummaryExpr,
    /**
     * @width 4
     */
    groups: ValueExpr[],
    /**
     * @width 4
     */
    filter?: BooleanExpr,
    /**
     * Specify chart width in pixels. Default is 300
     */
    width?: number,    
    /**
     * Specify chart height in pixels. Default is 300
     */
    height?: number,
    countEmpty?: boolean,
    /**
     * Defaults to the program config setting, or donut if this is not set.
     */
    pieStyle?: "pie" | "donut",
    /**
     * Indicates whether we will split a field by commas into separate groups, or include 
     * the whole field (commas and all) as the one value
     * @name Allow commas in groups
     * @width 2
     */
    allowCommasInGroups?: boolean,

    /**
     * Adds a description to the Chart.
     * Placement is customizable!
     *
     * @width 4
     * @name Description
     */
    description?: DashboardDescription
};

export type HeroCount = {
    kind: 'Hero Count',
    title: string | Text,
    /**
     * @width 4
     */
    filter: BooleanExpr,
    /**
     * @width 4
     *
     * use offline data to determine the count,
     * instead of the count returned by the database
     */
    useOfflineData?: boolean
}

export type NotificationSender = {
    kind: 'NotificationSender',
    field: TargetFieldRef,
}

export type ApplicantTable = {
    kind: 'Applicant Table'
    /**
     * @width 4
     */
    title?: Text,
    columns: (ValueExpr | NamedExpression | NotificationSender)[],
    /**
     * @width 4
     */
    filter: BooleanExpr | ClickQuery,
    showUids?: boolean,
    hideViewButton?: boolean,
    hideTotalCount?: boolean,
    /**
     * @width 4
     */
    showAllSimultaneously?: boolean,

    /**
     * @width 4
     */
    download?: {
        filename: string,
        downloadOnly?: boolean,
        asyncDownload?: boolean,
        /**
         * @hidden yes
         */
        downloadPath?: string,
    },

    offline?: {
        showDraftApplicants?: boolean,
    },

    includeTableSearch?: boolean
}

export type CustomQuery = {
    kind: 'Custom Query',
    /**
     * @width 4
     */
    sql: Code,

    /**
     * @width 4
     */
    visualization: Table | CustomNumber | CustomBarChart | CustomPieChart
}

export type CustomBarChart = {
    kind: 'Custom Bar Chart',
    title: string | Text,
    /**
     * @width 4
     * @name Columns
     */
    groupKeys: string[],
    /**
     * This is how the data will be grouped and will be what is shown on the X-axis
     */
    summaryKey: string,
    /**
     * This is the key that will be used to determine the total. If omitted, the sum of the groupkeys will be used
     */
    totalKey?: string,
    hideXAxisLabels?: boolean,
    /**
     * Specify chart height in pixels. Default is 300
     */
    height?: number,
    /**
     * Specify chart height in pixels. Default is 300
     */
    width?: number,
    /**
     * Should bars be stacked according to summaryKey
     */
    stack?: boolean,
    hideTotal?: boolean,
    /**
     * Bar charts need to graph numbers without units, but if you want to specify the unit on the chart, 
     * specify that here. (ie: %)
     */
    units?: string,

    /**
     * Adds a description to the Chart.
     * Placement is customizable!
     *
     * @name Description
     * @width 4
     */
    description?: DashboardDescription
}

export type CustomNumber = {
    kind: 'Custom Number',
    title?: Text,
    /**
     * @width 4
     */
    prefix?: string,
    /**
     * @width 4
     */
    suffix?: string,
}

export type CustomPieChart = {
    kind: 'Custom Pie Chart',
    title: string | Text,
    /**
     * These are the key(s) that will be used to group/label each pie segment
     */
    groupKeys: string[],
    /**
     * This is the key that will be used to determine the quantitative value for each pie segment
     */
    summaryKey: string,
    /**
     * This is the key will be used to contribute to the total. If omitted, summaryKey will be used
     */
    totalKey?: string,
    hideTotal?: boolean,
    /**
     * Specify chart width in pixels. Default is 300
     */
    width?: number,  
    /**
     * Specify chart height in pixels. Default is 300
     */
    height?: number,
    /**
     * Defaults to this, the program config setting, or donut. In that order.
     */
    pieStyle?: "pie" | "donut",

    /**
     * Adds a description to the Chart.
     * Placement is customizable!
     *
     * @name Description
     * @width 4
     */
    description?: DashboardDescription
}


export type Table = {
    kind: 'Table'
    /**
     * @width 4
     */
    title?: Text,
    /**
     * @width 4
     */
    showAllSimultaneously?: boolean,
    hideViewButton?: boolean,
    hideTotalCount?: boolean,
    /**
     * @width 4
     */
    download?: {
        filename: string,
        downloadOnly?: boolean,
        asyncDownload?: boolean,
        /**
         * @hidden yes
         */
        downloadPath?: string,
    },
    includeTableSearch?: boolean
}

export type NamedExpression = {
    kind: 'Named Expression',
    /**
     * @width 4
     */
    expression: ValueExpr,
    name?: Text,
}

export type Queue = {
    kind: 'Queue',
    /**
     * @width 4
     */
    title: Text,
    /**
     * @name Applicants
     * @width 4
     */
    condition: BooleanExpr | ClickQuery,
    /** 
     * @name Managed Queue: Assignee Field
     * @width 2
     */
    assigneeField: TargetField,
    /** 
     * To redirect to a subsurvey like /a/:uid/subsurvey, start the path with a / i.e. /review
     * @name Starting Section
     * @width 2
     */
    startSection: string | SubsurveyRef,
    allowedUserTags: UserTagRef[],
     /**
     * @width 4
     */
    description?: RichText,
    /**
     * @hidden yes
     */
    numberInQueue?: number,

    /**
     * List of columns/keys that will be shown when exploring the queue in addition to UID and legal_name.
     * @name Exploration Columns
     */
    columns?: ValueExpr[],

    hideExploreButton?: boolean,

    /**
     * 
     * Notifies the related parties periodically for each queue
     * in the program if there are still unclaimed applicants
     * within them.
     *
     * @name Notify About Queue Activity
     */
    notifyAboutQueueActivity?: UserTagRef[],
}

/**
 * @migration DashboardV1
 */
export type LegacyDashboardV0 = {
    kind?: 'Dashboard',
    title: string,
    /** 
     * @format ^[a-zA-Z_][a-zA-Z0-9_]*$
     * @formatMessage Can only contain alphanumeric characters and underscores.
     */
    path: string,
    /**
     * @width 4
     * @collapsible starts-collapse
     */
    components: (DashboardComponent)[],
    publicAccessKeys?: string[],
};

export type DashboardDescription = {
    /**
     * A sub-title that can be placed either 
     * just below the title of the graph, or
     * just beneath the total.
     *
     * @width 4
     * @name Content
     */
    content: Text,

    /**
     * Determines where the description is located. If not specified, 'bottom' is used.
     * If 'top', it will be just beneath the title.
     * If 'bottom' it will be just below the total (or the chart itself if the total is hidden).
     *
     * @width 2
     * @name Placement
     */
    placement?: 'top' | 'bottom'
};

export type Dashboard = WithLegacy<{
    kind: 'Dashboard',
    title: Text | string,
    /** 
     * @format ^[a-zA-Z_][a-zA-Z0-9_]*$
     * @formatMessage Can only contain alphanumeric characters and underscores.
     */
    path: string,
    /**
     * @width 4
     * @collapsible starts-collapse
     */
    components: (DashboardComponent)[],
    publicAccessKeys?: string[],
    linksToSection?: string | SubsurveyRef,
    /**
     * @hidden yes
     */
    version: 1
}, LegacyDashboardV0>;

export type BadDashboard = {
    title: string,
    components: (DashboardComponent)[]
}

// Used to drive /explore
export type Explore = {
    /**
     * @width 4
     */
    query: PieChart | ApplicantTable | CustomQuery | HeroNumber | BarChart
}

export type Export = {
    /**
     * @width 4
     */
    name: string,
    /**
     * @width 4
     */
    query: Code,
    exportTo: 's3',
    /**
     * @name Limited To Users Tagged
     */
    limitedToTags?: UserTagRef[],
    maxFrequency?: 'daily' | 'weekly' | 'monthly',
}

/**
 * @display tabs 
 */
export type ExpandedSurvey = {
    /**
     * @name Survey
     */
    survey: Survey,
    /**
     * @name Notifications 
     */
    notifications?: Notification[]
    /**
     * @name Personas
     */
    personas: Persona[],
    /**
     * @name Dashboards
     */
    dashboards?: Dashboard[]
    /**
     * @name Payments 
     */
    payments?: Payment[]
    /**
     * @name Users
     */
    users?: Users
    /**
     * @name Program Config
     */
    config?: ProgramConfig,
    /**
     * @name Exports
     */
    exports?: Export[]
    /**
     * @name Fraud Flags
     */
    fraudFlags?: FraudFlag[],

    /**
     * @name Sync
     */
    crossProgramDataExchange?: CrossProgramDataExchange[]
    //accounting?: Accounting

    // Hack to make Distro explore all the types for macros.
    // There's a bug in collecting all the types from macros because
    // they aren't referenced from the root so adding that here
    /**
     * @hidden yes
     */
    macroDefinitions?: GiveCard 
        | Usio 
        | NoCard
        | PaymentModule 
        | CardProviders 
        | OneTimePayment 
        | FixedPaymentAmount
        | VariablePaymentAmount
        | RecurringPayment 
        | PaymentFrequency
        | MacroConfig
        | MacroExpander
}

export type Root = Survey | ExpandedSurvey;

export const ForceSourceMap = () => '';

/**
 * @referencedIn userTags
 */
type UserTag = Distinct<string>;

/**
 * @references userTags
 */
type UserTagRef = Distinct<string>;

export type UserGroup = {
    kind: 'User Group'
    name: string,
    tags: UserTag[],
    members: Users,
    /**
     * @width 4
     */
    limitedToApplicants?: BooleanExpr,
    /**
     * The path the user should be directed to when they log in
     */
    homepage?: string
    /**
     * @name Default Comms Channel
     */
    defaultCommsChannel?: CommsChannelNameRef
}

/**
 * @referencedIn users
 */
type UserName = Distinct<string>;

/**
 * @references users
 */
type UserNameRef = Distinct<string>;

export type User = {
    kind: 'User'
    name: string,
    email: UserName,
    phone?: string,
    pause_notifs?: boolean,
}

export type Users = (User | UserGroup)[];

/**
 * @referencedIn commsChannels
 */
type CommsChannelName = Distinct<string>;

/**
 * @references commsChannels
 */
type CommsChannelNameRef = Distinct<string>;

type LanguageMessageOption = {
    /**
     * @width 4
     * @name Language
     */
    language: keyof Text,
    /**
     * Example: "Press 1 for English"
     * @width 4
     * @name Option Introduction
     */
    messageIntro: string,
    /**
     * @width 4
     * @name Message
     */
    message: string
}

export type CommsChannel = { 
    /**
     * @width 4
     */
    kind: 'Comms Channel',
    /**
     * @width 2
     * @name Channel Name
     */
    channelName: CommsChannelName, 
    /**
     * @width 2
     * @name Email
     */
    email?: string,
    /**
     * Configure additional program emails by entering them here.
     * This exists primarily to cover instances where participants
     * might be replying to multiple official program email addresses
     * @width 4
     * @name Additional Program Emails
     */
    additionalEmails?: {
        /**
         * @width 2
         */
        email: string
    }[]
    /**
     * @width 4
     */
    twilioConfig: {
        /**
         * @width 2
         * @name Phone Number
         */
        number: string, 
        /**
         * @width 2
         * @name SMS Messaging Service SID
         */
        messaging_service_sid: string,
        /**
         * @width 2
         * @name WhatsApp Messaging Service SID
         */
        whatsapp_messaging_service_sid?: string,
        /**
         * @width 2
         * @name Verification Service SID
         */
        verification_service_sid?: string
        /**
         * @width 2
         * @name TwiML App SID
         */
        twiml_app_sid?: string, 
        /**
         * You can either type out the message for TwiML to speak, or you can provide
         * a public URL to an audio file (mp3 required).
         * If you provide a URL, it must be https, it must have no spaces in it, and it must be the only thing you provide for the given language.
         * @width 4
         * @name Voice Message for Inbound Callers
         */
        voiceMessage?: RichText | LanguageMessageOption[],
        /**
         * Configure additional senders (phone numbers) by entering them here
         * @width 4
         * @name Additional Phone Senders
         */
        additionalPhoneNumbers?: {
            /**
             * @width 2
             */
            phone: string
        }[]
    }
    /**
     * @width 4
     */
    smsGatewayConfig?: {
        /**
         * @width 2
         * @name Phone Number
         */
        number: string,
        /**
         * @width 2
         * Experimental: flag to wait for previous message success before attempting to send a new messages.
         * This only applies to notifications that are queued.
         */
        waitForSuccess?: boolean,
    }
    /**
     * @width 4
     * @name Limited To Tags (Who can access this channel)
     */
    limitedToTags?: UserTagRef[]
    /**
     * @name Support Dashboard
     * @width 4
     */
    supportDashboard?: {
        /**
         * @width 3
         * @name Name
         */
        name: Text,
        /**
         * Description of the dashboard that will be shown to all users.
         * (Not yet implemented)
         * @width 4
         * @name Description
         */
        description: RichText,
        /**
         * Overrides the tags on the channel, for who can see the support dashboard.
         * Note all messages sent through this channel will still be visible to the top level tags.
         * @width 4
         * @name Limited to Tags
         */
        limitedToTags?: UserTagRef[],
        /** 
         * @width 4
         * @name Extra Columns
         */
        extraColumns?: {
            /**
             * @width 2
             * @name Target Field
             */
            targetField: TargetFieldRef,
            filterable?: boolean,
        }[]
    }
}
type DefaultCommsChannel = CommsChannel & { channelName: 'default' }

/*
type AccountLedgerPair = {
        kind: 'Mecury Account' ,
        funding_pool: string
    } | {
        kind: 'Dwolla Account',
        funding_pool: string
    } | {
        kind: 'Usio Distributor',
        funding_pool: string
    } | {
        kind: 'Applicant',
    } | {
        kind: 'Applicant Chosen Payment Provider' 
    } | {
        kind: 'Third Party',
        third_party: string
    };


type Accounting = {
    funding_pools: {
        key: string,
        name: string,
        bank_account: string
        dolla_enabled?: boolean,
        paypal_enabled?: boolean,
        givecard_enabled?: boolean,
        usio_distributor?: string
    }[],
    third_parties: {
        key: string,
        name: string,
    }[]
    actions: {
        kind: string,
        name: string,
        mode: 'Anticipated' | 'Proposed' | 'Either'
        debits: AccountLedgerPair,
        credits: AccountLedgerPair,
    }[]
}
*/

type PhoneWithOptionalEmail = { 
    /** 
     * @width 2
     */
    phone_key: TargetFieldRef,
    /**
     * @width 2
     */
    email_key?: TargetFieldRef
}

type EmailWithOptionalPhone = {
    /**
     * @width 2
     */
    email_key: TargetFieldRef
    /** 
     * @width 2
     */
    phone_key?: TargetFieldRef,
}

/**
 * @display stacked
 */
export type AfterTimeElapsed = {
    kind: 'After Time Elapsed',
    days: number,
    hours: number,    
    minutes: number,
}

/** 
 * @display stacked
 */
export type CommsConfig = { 
    kind: 'Comms Config',
    /**
     * Adds experimental mode to the comms, meaning it will be configureable from Distro.
     * @width 3
     */
    experimental?: boolean,
    /**
     * @width 4
     */
    default: DefaultCommsChannel,
    /**
     * @collapsible starts-shown
     */
    otherChannels?: CommsChannel[],
    /**
     * @name Alternate Contacts
     */
    alternateContacts?: ((PhoneWithOptionalEmail | EmailWithOptionalPhone) & ({
        /**
         * @width 2
         */
        name_key: string
        /**
         * @width 4
         */
        label: Text
    }))[],
    /**
     * @name Preferred Contact Method Field
     */
    preferredContactMethodField?: TargetFieldRef,
    /**
     * Adding a phone number here will enable conference calling with this number and the applicant in comms.
     * The optional dial code will be dialed when connecting to the service. `w` is a half second wait. For LanguageLink this code works well:
     * wwww<account_code>#ww2ww9
     *
     * @name Translation Service
     */
    translationService?: {
        phoneNumber: string,
        dialCode?: string
    },
    /**
     * Adding a phone number here will enable conference calling with the number and the applicant in comms.
     * The optional dial code will be dialed when connecting to the number. `w` is a half second wait. For LanguageLink this code works well:
     * wwww<account_code>#ww2ww9
     *
     * @name Conference Call Phone Numbers
     * @width 4
     */
    conferenceNumbers?: {
        label: Text,
        phoneNumber: string,
        dialCode?: string
    }[],
    notifySupportAgentsOnCaseActivity?: boolean,

    /**
     * Enables and configures support case replies to be
     * scheduled to be sent at a later time.
     *
     * Maximum of 35 days in the future, minimum of 15 minutes in the future.
     *
     * @width 4
     * @name Reply Scheduling
     * @collapsible starts-shown
     */
    replyScheduling?: ReplyScheduleConfig,

    /**
     * @name Enable video meetings
     * @width 4
     * @hidden yes
     * @deprecated - use the `videoCalling` object
     */
    enableMeetings?: boolean
    /**
     * @width 4
     * @name Video Calling
     */    
    videoCalling?: {
        /**
         * @name Enable video calling
         * @width 4
         */
        enabled?: boolean;
        /**
         * Select one or more target fields that can be captured as snapshots
         * from the video call interface.
         * 
         * Most commonly used for capturing id docs and selfies
         * 
         * @name Snapshot target fields
         * @width 4
         */
        snapshotTargetFields?: TargetFieldRef[]
    }
    /**
     * @width 4
     * @name Automatic Message Handler
     */
    automaticMessageHandler?: {
        /**
         * What to do with messages that come in
         * @width 4
         */
        handlers: {
            /**
             * @uuid hidden
             * @hidden yes
             */
            id: string,

            /**
             * Enter a unique name for this handler
             * @width 2
             */
            name: string,

            /**
             * disabled by default, this must be true to turn it on.
             * This is so that you can keep your settings without having to delete the whole component.
             * @width 2
             * @validateInput AMHEnableKey
             */
            enableKey: string,

            /**
             * When to look back to handle messages
             * after enabling this configuration. This is to enable
             * looking back in the past and 'handling' messages from the past.
             * @width 4
             */
            startFrom: After

            /**
             * When a message needs attention, 
             * this action will be triggered either Immediately or
             * After a certain amount of time has elapsed.
             * @width 4
             */
            when: "Immediately" | AfterTimeElapsed,

            /**
             * This allows further features down the line, 
             * such as notifying the support agent on support case activity
             * @name Act as Support Agent
             * @width 4
             */
            actAsSupportAgent?: UserNameRef,

            /**
             * Leave this unchecked if you do not wish to auto handle messages
             * Example use case is if you are under high stress and just want to auto-reply, 
             * but don't want to auto-handle so the AST can still pick up the messages,
             * or so that a followup handler can pick it up.
             * @width 2
             */
            handle: boolean,

            /**
             * @width 4
             */
            reply: {
                /**
                 * @width 4
                 */
                subject: Text,
                /**
                 * @width 4
                 */
                message: { 
                    /**
                     * @name Body
                     * @formatMessage Cannot exceed 1600 characters, use the Email Message for longer messages
                     * @format ^[\s\S]{0,1600}$
                     * @width 4
                     * @component TextArea
                     */
                    en: string 
                },

                /**
                * How many days to wait before allowing auto reply
                * to a contact that we've already auto replied to.
                * The cache is created after the first auto reply message is sent.
                * @width 4
                */
                dropCache: "After 30 days" | AfterTimeElapsed

                /**
                 * If the reply to email is different, specify that here
                 * @name Email Message
                 * @width 4
                 */
                emailMessage?: { 
                    /**
                     * @name Body
                     * @width 4
                     * @component TextArea
                     * @format [a-zA-Z]
                     * @formatMessage Must contain some letters
                     */
                    en: string
                }
            }

            /**
             * If dry mode is enabled, the AMH Will publish what it would do to logs instead, which will be accessible in the Automatic Message Handler pane.
             */
            dryMode?: boolean

            /**
             * If this exists, the handler will only auto reply, handle, and/or be assigned to a support case if the incoming contact is in this list.
             * Phone numbers should be in format +1xxxxxxxxxx
             * @width 4
             */
            onlyTheseContacts?: string[]
        }[]
    }
}

export type Challenge = ContactChallenge | InfoChallenge;

export type ContactChallenge = {
    kind: 'contact',
    description?: Text,
    failureMessage?: Text,
}

export type InfoChallenge = {
    /**
     * @width 2
     */
    kind: 'info',
    /**
     * @width 2
     */
    fieldRef: TargetFieldRef,
    /**
     * @width 2
     */
    description?: Text,
    /**
     * @width 2
     */
    failureMessage?: Text,
    // Valid field types thus far:
    // * date - creates the field as a Year Month Day entry.
    // * password - hides the field
    /**
     * @width 2
     */
    fieldType?: 'date' | 'password'
}

type AlwaysChallenge = {
    mode: 'always'
}

type NeverChallenge = {
    mode: 'never'
}

type AfterFirstClickChallenge = {
    mode: 'after_first_click'
}

type TimeAfterFirstClickChallenge = {
    mode: 'time_after_first_click'
    minutes: number
}

type ChallengeRequirement = AlwaysChallenge | NeverChallenge | AfterFirstClickChallenge | TimeAfterFirstClickChallenge; 

type Interface2023 = {
    /**
     * @hidden yes
     */
    version: 1
    /**
     * @width 4
     */
    name: '2023'
}

type InterfaceVersions = 'legacy' | Interface2023;

export function interfaceNumber(version?: InterfaceVersions) {
    if (typeof version === 'string') {
        return 0;
    }
    return version?.version || 0;
}

export type FraudConfigType = NonNullable<ProgramConfig['fraud']>;

export type EncryptedReportConfig = {
    /**
     * Reports are stored in s3 according to the following:
     *   Bucket: 'aidkit-documents',
    *    Key: `/programs/encryptedreports/${reportPrefix}_${new Date().toISOString().slice(0, 10)}`, // YYYY-MM-DD
     */
    reportPrefix: string,
    encryptedEncryptionKey: string,
}

export type FieldGroup = {
    match: 'All' | 'Any',
    value: (TargetFieldRef | FieldGroup)[]
}

/**
 * @display stacked
 */
export type ProgramConfig = {
    version: 'v1'
    name: string,
    interface?: {
        /**
         * @width 2
         */
        version: InterfaceVersions,
        applicationHeadline?: string,
        /**
         * @width 4
         */
        applicantFacingLogo?: {
            /**
             * @width 3
             */
            url?: string,
            /**
             * @width 1
             */
            width?: number,
        },
        /**
         * @width 4
         */
        applicantBanner?: {
            color: 'Red' | 'Yellow' | 'Blue',
            /**
             * @width 4
             */
            content: RichText
        }
        /**
         * @width 4
         */
        staffBanner?: {
            color: 'Red' | 'Yellow' | 'Blue',
            /**
             * @width 4
             */
            content: RichText
        }
        disableReassignScreener?: boolean,
        /**
         * @width 4
         */
        sectionBottomButtons?: {
            /**
             * @width 4
             */
            logoutRedirectPath: 'apply' | SubsurveyPathRef
        },
        includeProtectedSearchInNav?: boolean,
        /**
         * To be used in the legacy interface, if we want to use new interface dashboards but still want everything
         * else to use the legacy interface. 
         */
        useNewDashboards?: boolean,
        /**
         * Overrides the default "donut" style if specified for pie charts.
         */
        defaultPieChartStyle?: "pie" | "donut",
        thirdPartyCheckStatusTab?: UserTagRef[],
    },
    comms?: CommsConfig,
    application?: {
        /**
         * (Deprecated, using login now)
         * @width 4
         */
        applicationLookupMatcher?: Code,
        
        /**
         * @width 4
         * @name Login
         */
        login?: {
            /**
             * @width 4
             */
            allowMultipleApplications?: {
                /**
                 * Inputs are:
                 *  - provided: Info provided by the user logging in (email, date of birth, phone, etc)
                 *  - dbApps: All applications in Dynamo and Postgres databases that match the contact AND the provided info
                 *  - - let dbApps: AppInfo[] = [];
                 * Output should be an array of dbApps that are matched.
                 * You do not need to match contact info that will be done by the backend.
                 * Example:
                 * ```
                 *  dbApps.filter(app => app.birth_date === provided.birth_date)
                 * ```
                 * The routes defined below will populate based on each app returned by this function
                 * @width 4
                 * @validateJS (() => expr )
                 */
                matchingFunction: Code,
                
                /**
                 * @width 4
                 * @validateJS (() => expr )
                 */
                allowNewApplicationFunction?: Code,

                /**
                 * @width 4
                 */
                summaryOfDifferences: RichText

                /**
                 * The list of fields we will dedupe against when ingesting the application.
                 * @width 4
                 */
                defaultDeduplicationTuple?: (TargetFieldRef | FieldGroup)[]

                /**
                 * @width 4
                 */
                matchedContactField?: {
                    targetField: TargetFieldRef,
                    fieldKind: 'array'
                }
                
            },

            /**
             * (Optional) Default is "We found many links for you. 
             * Please choose the one you want to login to."
             * @width 4
             * @name Login Dialog Description
             */
            dialogDescription?: RichText
            
            /** 
             * @width 4 
             **/
            enableSubsurveyLogin?: {
    
                /**
                 * @width 2
                 */
                linkStyle: "button" | "link",

                /**
                 * @width 2
                 */
                openSubsurveyInNewTab?: boolean
                
                /**
                 * @width 4
                 */
                subsurveyLinkExpiration?: { 
                    /**
                     * @default 0
                     */
                    hours: number,
                    /** 
                     * @default 0
                     */
                    days: number,
                    /** 
                     * @default 0
                     */
                    weeks?: number,
                    /**
                     * @default 0
                     */
                    months?: number
                }
            }


            /**
             * @width 4
             */
            challengeRequirement?: ChallengeRequirement,
            defaultChallenge?: Challenge[],

            /**
             * @width 4
             */
            loginPageText?: RichText,

            /**
             * @width 2
             */
            logoutRedirectPath?: SubsurveyPathRef | string
        },
        disableSubmitNotification?: boolean
    },
    payments?: {
        failure_handling: 'request_changes' | 'appinfo'
    },
    fraud?: {
        enableTab?: boolean,
        enableSimilarityCalculations?: boolean,
        // This should be limited to AidKit staff
        useSpecificFaceCollection?: 'cdss',
    },
    experimental?: {
        enableQuickJS?: boolean
        enableOfflineMode?: boolean
        enableCleanUploads?: boolean
        enableCleanAttachments?: boolean
        enableRoboscreenerValidateCleanUploads?: boolean,
        newNavigationButtonStyle?: boolean,
        lateralUnnestingInDashboards?: boolean,
        speechEngine?: 'standard' | 'neural',
        extendedSpeechLength?: boolean,
        /**
         * Enabling this flag will cause CustomQueries within a filtered DashboardSection
         * to get wrapped such that the filter will be applied to the CustomQuery.
         * This can potentially cause a moderate slowdown of the CustomQuery.
         */
        enableCustomQueryFiltering?: boolean,
        /**
         * Enabling this flag will change the way dashboards are loaded. Instead of the entire dashboard
         * waiting until all components have loaded, placeholders will be used and the dashboard will
         * populate as the queries complete.
         */
        instantLoadDashboards?: boolean,
        /**
         * Enabling this flag will enable macros in Distro Editor. This will enable the options
         * to select avaiilable macros within the survey configuration which will "unfurl" and expand
         * into starter distro content upon publishing the survey.
         */
        macros?: boolean,
    },
    capabilities?: {
        /**
         * Limits showing the "Add Applicant" button to 
         * users that have at least one of the tags specified here.
         *
         * Delete this setting to cause the button to show for all users.
         * Adding this setting, and not adding any tags, hides the button for all users.
         *
         * @width 4
         * @name Limit Add Applicant to Tags
         */
        addApplicant?: UserTagRef[],
        applicantSearch?: UserTagRef[],
        importKeyId?: string,
        encryptedReportConfigs?: EncryptedReportConfig[],
        thirdPartyCheckStatusTab?: UserTagRef[],
    },
    legacyAirtable?: {
        stopSyncing?: boolean
        /**
         * @width 4
         */
        configuration?: {
            key: string,
            /**
             * @width 3
             */
            value: string
        }[]
    },
    accounting?: {
        /**
         * For reconciliation reports sent on the 15th of each month.
         * @width 4
         */
        monthlyReconciliationReports?: {
            recipients: {
                /**
                 * @width 4
                 */
                emailAddress: string,
            }[],
        },
    },
}

export type ReplyScheduleConfig = {
  /**
   * @width 4
   */
  timezone: Timezone,
  /**
   * @width 4
   */
  options: DateOption[]

}

export type FieldMapping = {
    options: {
        /**
         * @width 2
         */
        csvValue: string,
        /**
         * @width 2
         */
        targetFieldValue: string,
    }[]
    allowUnknownValues?: boolean
}

export type Update = {
    kind: 'Update',
    /**
     * @references csvColumns
     */
    uidColumn: string
}

export type Create = {
    kind: 'Create',
    /**
     * @references csvColumns
     */
    nameColumn: string,
    /**
     * @references csvColumns
     */
    hashedFromColumn?: string,
}

export type CSVMapping = {
    /**
     * @width 4
     */
    mode: Update | Create,
    columns: {
        /**
         * @references csvColumns
         */
        csvColumn: string,
        targetField: string,
        /**
         * @width 4
         */
        format: 'As Is' | 'Phone' | 'Email' | 'Date' | FieldMapping,
        encryptThisValue?: boolean,
        criteria?: 'exists',
    }[]
}

export type FraudFlag = {
    kind: 'Fraud Flag',
    /**
     * @name Flag Name
     * @width 4
     */
    flagName: string,
    /**
     * @width 4
     */
    description: RichText,
    /**
     * @width 4
     */
    formula: BooleanExpr,
    /**
     * @width 4
     */
    confidence: 'Always Fraudulent' | 'Potentially Fraudulent'
}

export type DateOption = { 
    name?: string, 
    hours?: number, 
    minutes?: number, 
    days?: number,
    relative?: boolean
}

// Macro expander functions take a configuration as a parameter that is specific to that macro. 
// For every new macro defined, ensure the macro-specific config is defined as a union type here.
export type MacroConfig = PaymentModule;

// Macro expander functions 'unravel' a macro config into one or more collections that will be
// inserted into the survey. Macros must return Collections
export type MacroExpander = (config: MacroConfig) => Collection[];

/**
 * @display stacked
 */
export type Usio = {
    kind: 'Usio',
    virtual: {
        enabled: boolean
    },
    pickup: {
        enabled: boolean
    },
    mail: {
        enabled: boolean,
        /**
         * Enabling auto-associate for this program will bypass a step that requires an applicant
         * to associate a physical card on receipt. For increased security, it is recommended to leave this disabled which
         * will create the requisite subsurvey that an applicant will use to associate their card in addition to 
         * activating the physical card with the provider.
         */
        autoAssociate: boolean,
    }
}

export type NoCard = {
    kind: 'None',
}

/**
 * @display stacked
 */
export type GiveCard = {
    kind: 'GiveCard',
    mail: {
        enabled: boolean,
    },
    pickup: {
        enabled: boolean,
    }
}

export type CardProviders = NoCard | GiveCard | Usio;

export type FixedPaymentAmount = {
    kind: "fixed",
    amount: number
}

export type VariablePaymentAmount = {
    /**
     * Distro will create a separate computed field where you can specify the variable payment amount 
     */
    kind: "variable",
}

/**
 * @display stacked
 */
export type OneTimePayment = {
    kind: 'One Time Payment',
    paymentAmount: FixedPaymentAmount | VariablePaymentAmount
}

/**
 * @display stacked
 */
export type RecurringPayment = {
    kind: 'Recurring Payment',
    /**
     * @width 2
     */
    numberOfPayments: number,
    paymentAmount: FixedPaymentAmount | VariablePaymentAmount
}

export type PaymentFrequency = OneTimePayment | RecurringPayment;

/**
 * @expander createPaymentModule
 * @display stacked
 */
export type PaymentModule = {
    kind: 'Payment Section',
    /**
     * The program name to be used in custom queries for payment lookups
     * @format ^[a-z]+$
     * @formatMessage Lowercase characters only, no spaces.
     */
    program: string,
    ach: {
        enabled: boolean,
    },
    debit: CardProviders,
    frequency: PaymentFrequency,
    /**
     * Specifying a number of cohorts will create basic scaffolding for the templated blocks needed to
     * specify different payments for different populations.
     */
    cohorts?: number,
}

export type CrossProgramDataExchange = {
    kind: 'Cross Program Data Exchange',
    /**
     * the name of the program that will be exchanging data with this one
     *
     * @name Program
     */
    program: string,
    /**
     * will pull data INTO this program 
     *
     * @width 4
     * @name Import
     */
    import?: {
        /**
         * A value that can be used to match an existing applicant in the other program,
         * ensuring no duplicate records are created accidentally.
         *
         * @width 4
         * @name Identity Key
         */
        idempotentKey?: ValueExpr,
        /**
         * the fields requested to be imported into this program. leave this empty to allow all fields to be imported.
         *
         * @name Fields to Copy
         * @width 4
         */
        fields: (NamedExpression | ValueExpr)[],
        /**
         * Fields that will be excluded from the import. Leave 'fields' as empty and specify
         * fields here in excluded to import <all fields from the export except for these excluded fields>
         * 
         * @name Excluded Fields
         * @width 4
         */
        excluded?: TargetFieldRef[],
        /**
         * only attempt to import certain applicants that meet this criteria.
         * IMPORTANT: any target fields used here must
         * be specified in the export config of the corresponding program 
         *
         * @name Filter
         * @width 4
         */
        filter?: BooleanExpr,
        /**
         * Additional transformations to be performed
         * on the new applicant record, before being 
         * imported into this program
         *
         * @width 4
         * @name Post Processing
         */
        postProcessing?: {
            /**
             * the target field the new value should be stored in.
             *
             * @width 2
             * @name Target Field
             */
            targetField: string,
            /**
             * the expression to create the new value.
             *
             * @width 4
             * @name Expression
             */
            expression: ValueExpr
        }[]
    },
    /**
     * Allows specific data to be pulled FROM this program
     *
     * @width 4
     */
    export?: {
        /**
         * the fields that are permitted to be exported. leave this empty to allow all fields to be exported
         *
         * @name Allowed Fields
         * @width 4
         */
        allowed?: TargetFieldRef[],
        /**
         * Fields that will be excluded in the export. Leave 'allowed' as empty and specify fields here to
         * export <all fields except for the fields specified in excluded>
         * 
         * @name Excluded Fields
         * @width 4
         */
        excluded?: TargetFieldRef[],
        /**
         * only allows a subset of the applicant records to be exported
         *
         * @width 4
         * @name Filter
         */
        filter?: BooleanExpr
    },
    /**
     * This will be given by the corresponding program.
     * Both programs MUST have these set correctly to allow the sync to proceed.     
     *
     * @name Enable Key 
     */
    enableKey: string
};
