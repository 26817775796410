import { ApplicantTable, Table, interfaceNumber, NotificationSender, TargetFieldRef, SubsurveyRef } from "@aidkitorg/types/lib/survey";
import { Fragment, memo, ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { usePost } from "../API";
import { Square2StackIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import { OfflineSyncContext, PublicConfigurationContext } from "../Context";
import { copyToClipboard, RigidSpinner, snakeToEnglish, copyAndSortRecords, useOfflineSync, filterRecords } from "../Util";
import { useLocalizedStrings } from "../Localization";
import SyncBadge from "../Components/SyncBadge";
import { ArrowDownTrayIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Dialog, Transition } from "@headlessui/react";

function ZoomableImage(props: {url: string}) {
    const [zoomed, setZoomed] = useState(false);
    return <div className="my-4 mr-4 float-left text-center bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-3 sm:p-6">
            <img src={props.url} className={zoomed ? 'w-full' : 'w-64'} onClick={() => setZoomed(!zoomed)} />
        </div>
    </div>
}

function NotifSendButtonComponent(props: { col: string, uid: string, notifTargetField: TargetFieldRef, prev: any }): JSX.Element {
    const [sending, setSending] = useState(false);
    const [result, setResult] = useState<'unsent' | 'success' | 'failure'>(!props.prev ? 'unsent' : ((props.prev as string).startsWith('error') ? 'failure' : 'success'));
    const issueNotif = usePost('/program/admin/issue_notification');

    const notifType = props.notifTargetField.endsWith('_sms') ? 'sms' : 'email';

    async function sendNotification() {

        // Using a callback here guarantees that multiple clicks will not cause multiple API calls
        // because React automatically queues the set requests and executes them sequentially.
        setSending((currentSending) => {
            if (currentSending) {
                console.log("Already clicked");
                return currentSending;
            }
            (async () => {
                try {
                    const fullNotifTargetField = props.notifTargetField;
                    const res = await issueNotif({
                        target_field: fullNotifTargetField,
                        applicant: props.uid,
                    });


                    if ("error" in res || res[fullNotifTargetField]?.errors > 0) {
                        setResult('failure');
                    } else if (res[fullNotifTargetField]?.successes > 0) {
                        setResult('success');
                    } else {
                        setResult('failure');
                        console.error('Error: unexpected result from issuing notif');
                    }

                } catch (error) {
                    console.error("Error in issueNotif", error);
                    setResult('failure');
                } finally {
                    setSending(false);
                }
            })();

            return true;
        });
    }

    return <td key={props.col} className="px-3 py-4 text-sm text-gray-500 max-w-md break-word">
        {sending
            ? <RigidSpinner />
            : (result === 'unsent'
                ? <button onClick={sendNotification} disabled={sending} className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 my-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                    {'Send ' + notifType + ' notif!'}
                </button>
                : result === 'success'
                    ? 'Sent!' : 'Sending failed.'
            )
        }
    </td>;
};

export function DashboardTableSearch(props: { 
    currFilter: Record<string, string>,
    open: boolean, 
    close: (filters: Record<string, string> | undefined, doSearch?: boolean) => void, 
    cols: string[]
}) {
    const L = useLocalizedStrings();
    const [filter, setFilter] = useState<Record<string, string>>({});
    const handleFilter = useCallback((columnKey: string, columnValue: string) => {
        if (columnValue.trim()) {
            setFilter({
                ...filter,
                [columnKey]: columnValue
            });
        } else {
            setFilter((prevFilter) => {
                const { [columnKey]: _, ...rest } = prevFilter;
                return rest;
            });
        }
    }, [filter]);

    useEffect(() => {
        setFilter(props.currFilter);
    }, [props.currFilter]);

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-y-auto" 
                style={{ zIndex: 20000 }}
                onClose={() => props.close(filter)}>
                <div className="flex items-start justify-center min-h-screen pt-5 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div 
                            className="relative md:w-1/2 w-full h-full overflow-y-auto inline-block align-bottom bg-white rounded-lg px-4 pt-4 pb-4 text-left shadow-xl transform transition-all">
                            <div className="text-xl font-semibold" >{L.dashboard.filter_table_results}</div>
                            <div className="flex flex-wrap justify-between mt-4">
                                {props.cols.map(c => {
                                    if (c === 'uid') {
                                        return;
                                    }
                                    return (
                                    <div key={c} className="w-full sm:w-1/2 lg:w-1/3">
                                        <div className="px-1">
                                            <label htmlFor={c} className="block text-sm font-medium leading-6 text-gray-900">
                                                {snakeToEnglish(c)}
                                            </label>
                                            <input 
                                                value={filter[c] || ''}
                                                id={c}
                                                onChange={(e) => {
                                                    handleFilter(c, e.target.value);
                                                }}
                                                className="max-w-lg block w-full shadow-sm border-solid p-2 mt-1 mb-1 sm:max-w-xs sm:text-sm rounded-md border-2 border-gray-200 focus:ring-gray-400 ring-gray-400 focus:border-gray-400" 
                                            />
                                        </div>
                                    </div>);
                                })}
                            </div>
                            <button
                                className={`shadow rounded-md border border-transparent px-4 py-2 mt-4 float-right text-sm font-medium shadow-sm focus:outline-none 
                                    focus:ring-2 focus:ring-offset-2 bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500`}
                                onClick={() => { props.close(filter, true) }}
                            >
                                {L.dashboard.apply_filters}
                            </button>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}

export function DistroDashboardTable(props: { records: Record<string, any>[], component: ApplicantTable | Table, extra?: ReactNode, linksToSection?: string | SubsurveyRef, title?: string, count?: number, queryExecutionTimeMs?: number}) {
    const L = useLocalizedStrings();
    const config = useContext(PublicConfigurationContext);
    const offlineSync = useContext(OfflineSyncContext);
    const cols = Object.keys(props.records?.[0] || {});
    const notifColumns: string[] = [];
    let offlineStatusCols: string[] = [];

    if (props.component.kind === 'Applicant Table') {
        if (props.component.showUids) {
            cols.splice(1, 0, 'full_uid');
        }

        props.component.columns.filter((col) => col.kind === 'NotificationSender').forEach((notifCol) => {
            notifColumns.push((notifCol as NotificationSender).field + '_sms');
            notifColumns.push((notifCol as NotificationSender).field + '_email');
        });

        offlineStatusCols = props.component.columns
            .filter(c => c.kind === 'Sync Status' || c.kind === 'Named Expression' && c.expression.kind === 'Sync Status')
            .map(c => c.kind === 'Named Expression' ? c.name?.en ?? c.expression.kind : c.kind)
            .map(c => c.toString().toLowerCase());
    }

    const superHeader = !!(props.title || props.count);
    const context = useContext(PublicConfigurationContext);
    const appPath = interfaceNumber(context?.interface?.version) >= 1 ? '/a/' : '/applicant/';

    const [openSearch, setOpenSearch] = useState(false);
    const [filter, setFilter] = useState<Record<string, string>>({});
    const [sort, setSort] = useState<{ key: string, order: 'ascending' | 'descending'} | undefined>();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [filteredRecords, setFilteredRecords] = useState<Record<string, any>[]>([]);
    const [currentPageRecords, setCurrentPageRecords] = useState<Record<string, any>[]>([]);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        let records = Object.keys(filter).length > 0 ? filterRecords(props.records, filter) : props.records;
        records = (sort?.key && sort?.order) ? copyAndSortRecords(records, sort.key, sort.order) : records;
        setFilteredRecords(records);
    }, [filter, sort, props.records]);

    useEffect(() => {
        setCurrentPage(1);
    }, [props.records]);

    useEffect(() => {
        if (props.component.showAllSimultaneously) {
            setCurrentPageRecords(filteredRecords);
            setTotalPages(1);
            return;
        }

        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setCurrentPageRecords(filteredRecords.slice(startIndex, endIndex));
        setTotalPages(Math.ceil(filteredRecords.length / itemsPerPage));
    }, [filteredRecords, currentPage, props.component.showAllSimultaneously]);

    function handleSort(columnKey: string) {
        if (sort?.key === columnKey) {
            setSort({ key: columnKey, order: sort.order === 'ascending' ? 'descending' : 'ascending' });
        } else {
            setSort({ key: columnKey, order: 'ascending' });
        }
    }

    const numberTestRegex = new RegExp(/^-?\d+(?:\.\d+)?$/);

    // Hide View button if specified in Distro or if query doesn't include any UIDs
    const hideViewButton = props.component.hideViewButton || (!cols.includes('uid') && !cols.includes('applicant'));
    return <OfflineSyncContext.Provider value={offlineSync}>
        <div className="clear-both">
            <div className="overflow-x-auto shadow">
                <div className="inline-block min-w-full align-middle">
                    <div className="overflow-hidden ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        {props.component.includeTableSearch && 
                            <DashboardTableSearch 
                            currFilter={filter}
                            open={openSearch} 
                            close={(newFilters: Record<string, string> | undefined, doSearch?: boolean) => { 
                                if (doSearch) {
                                    setFilter({...newFilters});
                                } else {
                                    // We do this to make sure currFilter updates the next time we open the modal.
                                    // This makes sure the filters on open match what is currently set.
                                    setFilter({...filter})
                                }
                                setOpenSearch(false);
                            }} 
                            cols={cols} />
                        }
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                                {/* Ternary needed here because of React's handling of conditionals https://stackoverflow.com/a/53519842 */}
                                {(superHeader || cols.length === 0) ? (<tr className="border-[0] border-solid border-b-2 border-gray-300">
                                    <th scope="col" colSpan={cols.length - (hideViewButton ? 2 : 1) > 2 ? cols.length - (hideViewButton ? 2 : 1) : 2} className="py-3.5 px-3 text-left text-base font-semibold text-gray-900 sm:pl-6">
                                        {(config.experimental?.enableOfflineMode && props.component.kind === 'Applicant Table') && 
                                            <SyncBadge 
                                                id={props.records.map(r => r.uid)}
                                                override={<ArrowDownTrayIcon height={15} width={15} />} 
                                            />} {props.extra}{props.title || ''}{props.queryExecutionTimeMs && <span className='text-gray-400 text-xs'>{' - ' + props.queryExecutionTimeMs + 'ms'}</span>}
                                    </th>
                                    <th scope="col" colSpan={2} className="py-3.5 px-3 text-right text-base font-semibold text-gray-900 sm:pl-6 items-baseline">
                                        {Object.keys(filter).length > 0 && 
                                            <span className="mr-3 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900">
                                                <span>{Object.keys(filter).length} {Object.keys(filter).length > 1 ? L.dashboard.filters_applied : L.dashboard.filter_applied} </span>
                                                <XCircleIcon
                                                    onClick={() => {
                                                        setFilter({});
                                                    }}
                                                    className="ml-1 inline-flex h-7 w-7 flex-shrink-0 rounded-full p-1 text-gray-500 hover:bg-gray-200 hover:text-gray-500 cursor-pointer" />
                                            </span>
                                        }
                                        {props.component.includeTableSearch && 
                                            <MagnifyingGlassIcon className="w-6 h-6 m-2 text-gray-400 hover:text-gray-500 cursor-pointer mr-4" 
                                                aria-hidden="true" onClick={() => setOpenSearch(true)}
                                            />
                                        }
                                        {!props.component.hideTotalCount && <span className="mr-2">{L.dashboard.total_count + ':' + filteredRecords.length}</span>}
                                    </th>
                                </tr>) : null}
                                {cols.length > 0 && <tr>
                                    {cols.map((c, i) => {
                                    const colSpan = (cols.length - 1) === i && hideViewButton ? 2 : 1;
                                    const isSorted = sort?.key === c;
                                    const arrowColor = isSorted ? "text-gray-900" : "text-gray-400";
                                    return (
                                        <th
                                        colSpan={colSpan}
                                        key={c}
                                        scope="col"
                                        className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                        {(!superHeader && i === 0) && props.extra}
                                        {snakeToEnglish(c)}
                                        {isSorted && sort.order === 'descending' ? (
                                            <ArrowUpIcon
                                            className={`w-4 h-4 ${arrowColor} hover:text-gray-600 cursor-pointer inline`}
                                            onClick={() => handleSort(c)}
                                            />
                                        ) : (
                                            <ArrowDownIcon
                                            className={`w-4 h-4 ${arrowColor} hover:text-gray-600 cursor-pointer inline`}
                                            onClick={() => handleSort(c)}
                                            />
                                        )}
                                        </th>
                                    );
                                    })}
                                    {!(hideViewButton) && <th scope="col" className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900 sm:pl-6" />}
                                </tr>}
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {cols.length === 0
                                    ? (<tr className={"bg-gray-50"}><td colSpan={3} className="px-3 py-4 text-sm text-gray-500 max-w-md break-word w-full">{L.dashboard.no_results}</td></tr>)
                                    : currentPageRecords.map((r, i) => {
                                        return <tr key={r.id} className={i % 2 === 0 ? "bg-gray-100" : "bg-gray-50"}>
                                            {cols.map((c, ci) => {
                                                const colSpan = (cols.length - 1) === ci && hideViewButton ? 2 : 1;
                                                let content: string | JSX.Element[] = String(r[c] ?? '');
                                                let JSONContent: Object | undefined;
                                                try {
                                                    // Parsing strings into numbers can have unintended consequences like removing trailing 0s
                                                    JSONContent = (typeof content === 'string' && !numberTestRegex.test(content)) ? JSON.parse(content) : undefined;
                                                } catch (e) {
                                                    JSONContent = undefined;
                                                };

                                                if (content.includes('aidkit-documents')) {
                                                    content = content.split(',').map((url: string) => <ZoomableImage url={url} />);
                                                }
                                                if (c === 'uid') {
                                                    return <td colSpan={colSpan} key={c + '_copy_button'} className="px-3 w-20 py-4 text-sm text-gray-500 max-w-md break-word">
                                                        <Square2StackIcon className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer" onClick={() => copyToClipboard(r.uid, 'UID Copied')} />
                                                    </td>
                                                }
                                                if (c === 'full_uid') {
                                                    return <td colSpan={colSpan} key={c} className="px-3 py-4 text-sm text-gray-500 max-w-md break-word">
                                                        {r.uid}
                                                    </td>;
                                                }
                                                if(offlineStatusCols.includes(c.toLowerCase())) {
                                                    return <td colSpan={colSpan} key={c} className="px-3 w-[8em]">
                                                        <SyncBadge id={r.uid} />
                                                    </td>;
                                                }
                                                // phone_number, email, and notif target field(s) are automatically added to the table SQL query if a notification reference column is included
                                                if (notifColumns.includes(c)) {
                                                    if ((c.endsWith('_sms') && r['phone_number']) || (c.endsWith('_email') && r['email'])) {
                                                        return <NotifSendButtonComponent col={c} uid={r.uid} notifTargetField={c} prev={r[c]} />
                                                    }
                                                }
                                                return <td colSpan={colSpan} key={c} className="px-3 py-4 text-sm text-gray-500 max-w-md break-word">
                                                    {JSONContent ? <pre className="">{JSON.stringify(JSONContent, undefined, 2)}</pre> : <span>{content}</span>}
                                                </td>
                                            })}
                                            {(!hideViewButton) && <td key='view' className="w-36 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {r.uid && <a href={appPath + r.uid + (props.linksToSection 
                                                        ? (typeof props.linksToSection === 'string' 
                                                            ? '#' + encodeURIComponent(props.linksToSection) 
                                                            : '/' + props.linksToSection.path) : '')} target='_blank' className="text-indigo-600 hover:text-indigo-900 mr-3">
                                                    {L.dashboard.view}
                                                </a>}
                                            </td>}
                                        </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {cols.length > 0 && (
                <div className="mt-4 flex justify-between items-center">
                    <button
                        className={`shadow inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                        currentPage === 1
                            ? "bg-gray-300 text-gray-500"
                            : "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
                        }`}
                        disabled={currentPage === 1}
                        onClick={() => setCurrentPage(currentPage - 1)}
                    >
                        <ArrowLeftIcon className="w-5 h-5 mr-1" />
                        {L.dashboard.previous_page}
                    </button>
                    <div className="text-gray-700">
                        Page {currentPage} of {totalPages}
                    </div>
                    <button
                        className={`shadow inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                        currentPage === totalPages
                            ? "bg-gray-300 text-gray-500"
                            : "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500"
                        }`}
                        disabled={currentPage === totalPages}
                        onClick={() => setCurrentPage(currentPage + 1)}
                    >
                        {L.dashboard.next_page}
                        <ArrowRightIcon className="w-5 h-5 ml-1" />
                    </button>
                </div>
            )}
        </div>
    </OfflineSyncContext.Provider>
}
