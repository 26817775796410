import React from "react";

function DeviceSetupPage() {
  const params = new URLSearchParams(window.location.search);
  const imei = params.get("imei");
  const sim = params.get("sim");
  const phone = params.get("phone");

  return (
    <div>
        <h1>{"Device Setup Page"}</h1>
        <h2>{"IMEI: " + imei}</h2>
        <h2>{"SIM: " + sim}</h2>
        <h2>{"PHONE: " + phone}</h2>
    </div>
  );
}

export default DeviceSetupPage;
