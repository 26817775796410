import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { get_deployment, usePost } from "../API";
import { ClickableButton } from "../Components/Button";
import { Dropdown } from "../Components/Dropdown";
import { TailwindSwitch } from "../Components/Switch";
import { ConfigurationContext, SupportedLanguage } from "../Context";
import { langToWord, useLocalizedStrings } from "../Localization";
import { Explanation } from "../Questions/Explanation";
import { InfoDict } from "../Questions/Props";
import { safeParse, SpacedSpinner } from "../Util";

export function OutreachPage(props: any) {

    const L = useLocalizedStrings();

    const config = useContext(ConfigurationContext);
    const [languages, setLanguages] = useState([] as SupportedLanguage[]);

    const [translations, setLanguageContent] = useState({} as Record<SupportedLanguage, string>);
    const [emailSubjects, setSubject] = useState({} as Record<SupportedLanguage, string>);

    type Contact = {
        info: Record<string, string>,
        contact: string, 
        language: SupportedLanguage
    }
    const [unparsedContacts, setUnparsedContacts] = useState("");
    const [contacts, setContacts] = useState([] as Contact[])

    const [inputMode, setInputMode] = useState("JSON" as "JSON" | "CSV");
    const [method, setMethod] = useState("sms" as "email" | "sms");
    const [refPrefix, setRefPrefix] = useState(get_deployment() === 'ecepayequity' ? "f5eceSL2022" : "");
    const [guardParam, setGuardParam] = useState('');

    const [sending, setSending] = useState(false);
    const doOutreach = usePost("/messaging/mass_text");
    
    const doOutreachClick = async () => {
        if (sending) return;
        if (contacts.length <= 0 || contacts.every((c) => !c.contact)) { 
            toast.error("Cannot send without at least one contact.")
            return;
        }

        for (const lang of languages) {
            if (contacts.some((c) => c.language === lang && !translations[lang])) {
                toast.error("Some applicants have " + lang + ", but content for that language is missing.");
                return;
            }
        }

        const yn = window.confirm("Really send outreach to " + contacts.length + " contacts?");
        if (!yn) {
            return;
        }

        setSending(true);

        const response = await doOutreach({
            method,
            contacts,
            translations,
            emailSubjects,
            guardParam,
            refPrefix
        });
        
        console.log("Setting sending.current to false")
        setSending(false);
        if (response && (response as any).value) {
            toast.success("Outreach queued!");
        }

    };

    useEffect(() => {
        if (!unparsedContacts) return;
        
        if (inputMode === 'JSON') {
            let parsed = safeParse(unparsedContacts, []);
                        
            // console.log("Parsed:",parsed,unparsedContacts);
            if (!parsed || typeof parsed !== 'object') return;
            if (!Array.isArray(parsed)) return;
            
            let newWave = [];
            for (const contact of parsed) {
                let info: Record<string, string> = {};
                for (const v in contact) {
                    info[v] = contact[v];
                    if (v === 'Phone' && method === 'sms') {
                        info['contact'] = contact[v];
                    }
                    if (v === 'Email' && method === 'email') {
                        info['contact'] = contact[v];
                    }
                    if (v === 'Language') {
                        info['language'] = contact[v];
                    }
                }
                let language = (({ 'English':'en','Spanish':'es','Amharic':'am' } as any)[info['language']] || 'en') as SupportedLanguage;
                newWave.push({ info, contact: info['contact'], language});
            }
            console.log("NewWave:", newWave)
            if (parsed && newWave.length > 0) {
                setContacts(newWave);
            }
        } else if (inputMode === 'CSV') {
            let columns, rows;
            rows = unparsedContacts.split('\n');
            let delim = rows[0].match(/\t/) ? '\t' : ',';
            columns = rows[0].split(delim);
            if (rows.length < 2 || columns.length < 2) return;

            let newWave: Contact[] = [];
            for (let i = 1; i < rows.length; i++) {
                let row = rows[i].split(delim);
                let info: Record<string, string> = {};
                for (let j = 0; j < columns.length; j++) {
                    info[columns[j]] = row[j];
                }
                newWave.push({
                    info,
                    contact: row[columns.indexOf(method === 'sms' ? 'Phone' : 'Email')],
                    language: (({ 'English':'en','Spanish':'es','Amharic':'am' } as any)[row[columns.indexOf('Language')]] || 'en') as SupportedLanguage
                });
            }
            setContacts(newWave);
        }
    }, [unparsedContacts, method, inputMode]);

    return (
        <div>
            <Dropdown label={method === 'sms' ? 'SMS' : 'Email'} options={[
                {label: 'SMS', callback: () => setMethod('sms')},
                {label: 'Email', callback: () => setMethod('email')}
            ]}/>
            <div className="my-2">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Outreach Reference Link Prefix
                </label>
                <div className="mt-1">
                    <input
                    type="email"
                    value={refPrefix} onChange={e => setRefPrefix(e.target.value)} 
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm h-8 px-1 sm:text-sm border-gray-300 rounded-md"
                    placeholder="somesecretCodeButStillHelpfulToPM2022"
                    />
                </div>
                <label htmlFor="" className="mt-2 block text-sm font-medium text-gray-700">
                    Outreach Guard Param (optional)
                </label>
                <div className="mt-1">
                    <input
                    value={guardParam} onChange={e => setGuardParam(e.target.value)} 
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full max-w-sm h-8 px-1 sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                    />
                </div>
                <p className="mt-2 text-sm text-gray-500" id="email-description">
                    The link in the message will show up as <code>https://{get_deployment()}.aidkit.org/apply?{guardParam ? guardParam + '=UniqueContactHash&' : ''}ref={refPrefix}-UniqueContactHash</code>
                </p>
            </div>
            <hr/>
            <h3 className="text-xl text-gray-900 font-bold">Language Content for Messages</h3>
            <div className="my-2 flex space-x-8 flex-wrap max-w-lg">
                {(config.languages || 'en,es').split(',').map((un: string) => {
                    let lang = un as SupportedLanguage;
                    let checked = languages && languages.indexOf(lang) !== -1;
                    return <TailwindSwitch checked={checked} 
                        label={langToWord(lang, "Name")} 
                        onChange={() => {
                            setLanguages((prevState) => ([
                                ...(prevState || []).filter((l) => l !== lang).concat(!checked ? [lang] : [])
                            ]));
                        }} />
                })} 
            </div>
            {(languages || []).map((lang) => {
                return <div key={"templates-" + lang} className="my-2">
                <fieldset>
                    <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                        {method === 'email' && <>
                        <label className="sr-only">{langToWord(lang, "Name")} Email Subject</label>
                        <input
                            type="text"
                            onChange={(e) => {
                                let value = e.target.value;
                                setSubject((prevState) => ({...prevState, [lang]: value }))
                            }}
                            className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
                            placeholder={langToWord(lang, "Name") + " Email Subject"}
                        /></>}
                        <label className="sr-only">{langToWord(lang, "Name")} Message content</label>
                        <textarea 
                            className="block w-full border-0 py-0 resize-none placeholder-gray-500 focus:ring-0 sm:text-sm"
                            onChange={(e) => { 
                                let value = e.target.value;
                                setLanguageContent((prevState) => ({
                                    ...prevState,
                                    [lang]: value
                                }));
                            }} 
                        rows={10} cols={50} 
                        placeholder={langToWord(lang, "Name") + " Message Content"} 
                        />
                    </div>
                </fieldset>
                
                </div>
            })}
            <hr/>
            <fieldset>
                <label className="hidden sm:block text-xl text-gray-900 font-bold"><Dropdown label={inputMode}
                    options={[
                        {label: 'JSON', callback: () => setInputMode('JSON')},
                        {label: 'CSV', callback: () => setInputMode('CSV')}
                ]} /> Formatted Contact Info</label>
                <div className="my-2 border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                    <label className="sr-only">Contact Info</label>
                    <textarea 
                        placeholder={inputMode === 'JSON' 
                            ? 'Enter the JSON Array of Contact Info. Format [{ "Name": "Value", "Phone": "111222333444" }]'
                            : 'Enter the CSV of Contact Info. Name,Phone,Email,Language'}
                        className="block w-full border-0 py-0 resize-none placeholder-gray-500 focus:ring-0 sm:text-sm"
                        onChange={(e) => {
                            let value = e.target.value;
                            setUnparsedContacts(value);
                        }} 
                        rows={10} cols={50} />
                    </div>
            </fieldset>
            <hr/>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Outreach Recipients</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Will outreach to the following people.
                    </p>
                    </div>
                    {false && <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        Add person
                    </button>
                    </div>}
                </div>
                <div className="mt-8 mb-8 flex flex-col w-full mr-2 overflow-y-scroll" style={{maxHeight: "500px"}}>
                    <div className="-my-2">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table className="min-w-full border-separate" style={{ borderSpacing: 0 }}>
                            <thead className="bg-gray-50">
                            <tr key="recipients-thr">
                                <th
                                scope="col"
                                key="thc"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                >
                                Contact
                                </th>
                                <th key="thl"
                                scope="col"
                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                >
                                Language
                                </th>
                                <th key="thi"
                                scope="col"
                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                >
                                Info
                                </th>
                            </tr>
                            </thead>
                            <tbody className="bg-white">
                            {contacts.map((contact, idx) => (
                                <tr key={contact.contact + '-' + idx}>
                                <td
                                    className={`
                                    ${idx !== contacts.length - 1 ? 'border-b border-gray-200' : ''} 
                                    whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8
                                    `}
                                >
                                    {contact.contact}
                                </td>
                                <td
                                    className={`
                                        ${idx !== contacts.length - 1 ? 'border-b border-gray-200' : ''} 
                                    whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell
                                    `}
                                >
                                    {contact.language}
                                </td>
                                <td
                                    className={`
                                    ${idx !== contacts.length - 1 ? 'border-b border-gray-200' : ''} 
                                    whitespace-pre-wrap break-all px-3 py-4 text-sm text-gray-500 hidden lg:table-cell
                                    `}
                                >
                                    {/** Simple way: */}
                                    {true && JSON.stringify(contact.info)}
                                    {/** Fancy way: */}
                                    {false && React.createElement(Explanation, {
                                        Question: "Info",
                                        Viewer: "screener",
                                        "Field Type": "Explanation",
                                        setInfoKey: (...args: any) => { },
                                        "Additional Options": ["Collapsible"],
                                        "English Content": "### Info\n<p>" + JSON.stringify(contact.info) + "</p>"
                                    })}
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <hr/>
            <ClickableButton color="green" 
                onClick={doOutreachClick}
                disabled={sending}>
                {sending && <SpacedSpinner />}Queue Outreach
            </ClickableButton>
        </div>
    )


}