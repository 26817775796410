import { safeParse } from "@aidkitorg/roboscreener/lib/util/json";
import React, { useContext, useState } from "react";
import { usePost } from "../API";
import InterfaceContext from "../Context";
import { languageContent } from "../Util";
import { QuestionProps } from "./Props";

export function ApplicantCreator(props: QuestionProps) {
  const createAssociatedApplicant = usePost('/applicant/create_associated_applicant');
  const [creating, setCreating] = useState(false);
  const context = useContext(InterfaceContext);
  const content = props[languageContent(context.lang)];

  async function createNewApplicant() {
    // Using a callback here guarantees that multiple clicks will not cause multiple API calls
    // because React automatically queues the set requests and executes them sequentially.
    setCreating((currentCreating) => {
      if (currentCreating) {
        console.log("Already clicked");
        return currentCreating;
      }
      (async () => {
        await createAssociatedApplicant({ existingApplicantUID: props.uid || '', applicantCreatorTargetField: props['Target Field'] || '' });
        setCreating(false);
      })();

      return true;
    });
  }

  return <div>
    {(props.info[props["Target Field"]!] || creating)
      ? <div className='my-1.5'>{creating ? 'Creating new applicant...' : 'Associated applicant already created. UID is: ' + props.info[props["Target Field"]!]}</div>
      : (
        <button
          onClick={createNewApplicant}
          type='button'
          disabled={creating}
          className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 my-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
        >
          {content}
        </button>
      )
    }
    </div>;
}
