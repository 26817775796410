import { Section } from "@aidkitorg/types/lib/legacy/airtable";
import { NavigationNode, filterNavigation } from "@aidkitorg/types/lib/translation/permissions";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { get_deployment, get_rs_host, useAPIPost, usePost } from "./API";
import { ApplicantComms, LegacyComms, useAuthorizedCommsChannels } from "./Applicant/Comms";
import Notes from "./Applicant/Notes";
import { ModularQuestionPage } from "./Apply";
import { StandardMenu, SubTab, ThreeColumnPage } from "./Components/ThreeColumnPage";
import { safeParse, useInterval } from "./Util";
import History from "./Applicant/History";
import { AdminTab } from "./Applicant/AdminTab";
import { ConfigurationContext, PublicConfigurationContext } from "./Context";
import RCPayments from "./RCPayments";
import { useLocalizedStrings } from "./Localization";
import { ApplicantIdentities } from "./ApplicantIdentities";
import { captureException } from "@sentry/react";
import { FraudFlags } from "./Applicant/FraudFlags";
import { LoggedInConfig } from "@aidkitorg/types/lib/config";
import { ApplicantMeetings } from "./Applicant/Meetings";
import { Text, interfaceNumber } from "@aidkitorg/types/lib/survey";
import { ThirdPartyCheckStatusTab } from "./Applicant/ThirdPartyCheckStatus";

export function ApplicantPage(props?: { readonly?: boolean }) {
    const { uid, dynamoAppId, subsurvey, applicantDash } = useParams() as Record<string, string>;
    const [sections, setSections] = useState<{ sections: Section[] }>({ sections: [] });
    const [nav, setNav] = useState<NavigationNode[]>([]);
    const [globalNav, setGlobalNav] = useState<NavigationNode[]>([]);
    const [info, setInfo] = useState<{ [key: string]: any }>({});
    const [fetchedInfo, setFetchedInfo] = useState<{ [key: string]: any }>({});
    const getSurvey = usePost("/survey");
    const getNav = usePost("/navigation");
    const config = useContext(ConfigurationContext);
    const publicConfig = useContext(PublicConfigurationContext);
    const L = useLocalizedStrings();
    const commsChannels = useAuthorizedCommsChannels({ config });
    const getLoggedInConfig = usePost('/program/logged_in_configuration');
    const [loggedInConfig, setLoggedInConfig] = useState<LoggedInConfig | undefined>();
    const [subTabs, setSubTabs] = useState<SubTab[]>([]);

    const [applicant, setApplicant] = useState<Awaited<ReturnType<typeof getEverything>> | null>(null);
    const getEverything = usePost('/applicant/get_everything');

    const [fraudFlagsLength, setFraudFlagsLength] = useState<number | null>(null);

    useEffect(() => {
        if (!loggedInConfig) {
            (async () => {
                setLoggedInConfig(await getLoggedInConfig({}));
            })();
        }
    }, [loggedInConfig]);

    const refreshInProgress = useRef<Promise<any> | null>(null)
    async function refreshApplicant() {
        if (refreshInProgress.current) return;

        let app;
        try {
            refreshInProgress.current = getEverything({ uid: uid || dynamoAppId });
            app = await refreshInProgress.current;

            if (app.ingestedUid) {
                const appPath = interfaceNumber(publicConfig?.interface?.version) >= 1 ? '/a/' : '/applicant/';
                window.location.href = appPath + app.ingestedUid;
              }
        } finally {
            refreshInProgress.current = null;
        }

        // We may have changed things since in the await
        if (Object.keys(pendingChanges.current).length > 0) {
            return;
        }

        setInfo(app.info || {});
        setFetchedInfo(app.info || {});
        setApplicant(app);
    }
    useEffect(() => {
        refreshApplicant();
    }, [])

    useEffect(() => {
        (async () => {
            const responses = await Promise.all([
                getNav({ applicant: uid || dynamoAppId }),
                getSurvey({}) as any
            ]);
            setNav(responses[0].navigation);
            setSections(responses[1]);
        })()
    }, [])

    const menu = [{
        name: 'Applicant',
        href: '#',
        subnodes: nav[0]?.subnodes || []
    },
    ...StandardMenu];

    useEffect(() => {
        // temporary convention till we can implement explicit RBAC.
        // this scoops up all "no-[name]" tags and lets us use em 
        // in the tab array below to more easily control what is shown.
        //
        // TODO: Remove this once we have a better RBAC!
        const disallowed =
            (config.roles as string[] | undefined)
                ?.filter(r => r.startsWith('no-'))
                ?.map(r => r.replace('no-', '')) ?? [];

        setSubTabs([
            {
                name: L.applicant.notes.title,
                show: !disallowed.includes('notes'),
                children: <>
                    <Notes
                        uid={dynamoAppId || uid}
                        canRequestChanges={true}
                        canDeleteNotes={true}
                        unsubmittedApp={!!dynamoAppId}
                    />
                </>,
            },
            ...(!dynamoAppId ? [{
                name: L.applicant.payments,
                show: !disallowed.includes('payments'),
                children: <>
                    <RCPayments applicant_uid={uid} />
                </>}] : []),
            {
                name: L.applicant.comms.title,
                show: !disallowed.includes('comms') && !!Object.keys(config).length && config.experimental_comms !== 'true',
                children: <LegacyComms uid={uid} info={fetchedInfo} setUnhandledCount={() => { }} canSendComms={true} />
            },
            ...(commsChannels.map(channel => ({
                name: 'Comms' + (channel !== 'default' ? ` (${channel})` : ''),
                show: !disallowed.includes('comms') && commsChannels && commsChannels.length > 0,
                children: <ApplicantComms key={'comms-' + channel} uid={uid} info={fetchedInfo} setUnhandledCount={() => { }} canSendComms={true} channel={channel} />
            }))),
            {
                name: 'IA Notes',
                show: ((config.roles || '').indexOf('internal audit') !== -1),
                children: <>
                    <Notes
                        uid={uid}
                        canRequestChanges={true}
                        canDeleteNotes={true}
                        internalAudit={true}
                    />
                </>
            },
            {
                name: 'IA Comms',
                show: !disallowed.includes('comms') && config.experimental_comms !== 'true' || !commsChannels?.length,
                children: <LegacyComms uid={uid} info={fetchedInfo} setUnhandledCount={() => { }} canSendComms={true} internalAudit={true} />
            },
            {
                name: L.applicant.meeting.meetings,
                show: !disallowed.includes('meetings') && loggedInConfig?.comms?.videoCalling?.enabled,
                children: <ApplicantMeetings applicantId={uid} info={fetchedInfo} />
            },
            ...(!dynamoAppId ? [{
                name: L.applicant.history.title,
                show: !disallowed.includes('history'),
                children: <>
                    <History uid={uid} canViewChanges={true} />
                </>}] : []),
            ...(!dynamoAppId ? [{
                name: 'Debug',
                show: !disallowed.includes('debug'),
                children: <>
                    <AdminTab uid={uid} />
                </>
            }] : []),
            ...(!dynamoAppId ? [{
                name: L.applicant.fraud.title + (fraudFlagsLength !== null ? ` (${fraudFlagsLength})` : ''),
                show: !disallowed.includes('fraud') && loggedInConfig?.fraud?.enableTab && ((config.roles || '').includes('admin') || (config.roles || '').includes('reviewer')),
                children: <FraudFlags uid={uid} setLength={(l) => setFraudFlagsLength(l)} />
            }] : []),
            ...(!dynamoAppId ? [{
                name: 'Third Party Checks',
                show: !disallowed.includes('history') && (
                    publicConfig?.capabilities?.thirdPartyCheckStatusTab && config.roles?.some((role: string) => {
                        return publicConfig?.capabilities?.thirdPartyCheckStatusTab?.includes(role)
                })) || false,
                children: <ThirdPartyCheckStatusTab uid={uid} info={fetchedInfo} saveInfo={saveInfo} isAdmin={config.roles?.includes('admin')} />
            }] : []),

        ].filter(t => (t.show === undefined) || t.show /* by default, we show. hence show === undefined is TRUE */));
    }, [loggedInConfig, config, commsChannels, publicConfig, fetchedInfo]);

    let section = sections.sections[sections.sections?.findIndex(section => section["English Content"]?.trim() === decodeURIComponent(window.location.hash.slice(1)))];
    let activeSections = []
    if (!section && sections.sections.length > 0) {
        section = sections.sections[0];
    }
    if (section) {
        activeSections.push(section);
    }

    if (subsurvey) {
        // Traverse the navigation until we find the appropriate subsurvey
        const findSections = (nav: NavigationNode[]): NavigationNode[] | null => {
            for (const node of nav) {
                if (node.name === '/p/' + subsurvey && node.subnodes) {
                    return node.subnodes;
                }
                if (node.subnodes) {
                    const found = findSections(node.subnodes);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        }

        const subsurveysections = findSections(nav);
        activeSections = [];
        for (const section of subsurveysections || []) {
            const c = sections.sections.filter(s => s["English Content"] === section.name || s["English Content"] === (section.name as Text).en);
            if (c.length > 0) {
                activeSections.push(c[0]);
            }
        }
    }

    // We store a ref of pending changes to prevent race conditions between other callbacks
    // that might otherwise close around this state
    const pendingChanges = useRef<Record<string, any>>({})
    const pendingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
    const saveInfoRS = useAPIPost(get_rs_host() + "/compute_and_save", { includeTokenInData: true });

    function saveInfo(update: Record<string, any>) {
        if (props?.readonly) return;

        // Figure out what changed (note, we know that "deleted" keys are set as '' so we don't
        // need to search for keys that have disappeared)
        setInfo(update);
        const changes = Object.keys(update).reduce((prev, k: string) => {
            if (update[k] !== info[k]) {
                prev[k] = update[k];
            }
            return prev;
        }, {} as Record<string, any>)

        if (Object.keys(changes).length) {
            // Update any pending changes
            Object.assign(pendingChanges.current, changes);

            // Debounce saving by 1 second
            if (pendingTimeout.current) {
                clearTimeout(pendingTimeout.current)
            }
            pendingTimeout.current = setTimeout(async () => {
                while (Object.keys(pendingChanges.current).length > 0) {
                    const toSave = { ...pendingChanges.current };

                    // We could add to pending changes during this await so we loop here 
                    const result = await saveInfoRS({
                        changedKeys: toSave,
                        uid,
                        deploymentKey: get_deployment()
                    })
                    // We need to delete the keys that we attempted to save 
                    // even if there was an error else this while loop will 
                    // never terminate
                    if (result.error) {
                        console.error("Error saving", result.error, Object.keys(pendingChanges.current).join(","));
                        captureException(result.error, { extra: { uid, deploymentKey: get_deployment() } });
                    }
                    for (const k of Object.keys(toSave)) {
                        if (pendingChanges.current[k] === toSave[k]) {
                            delete pendingChanges.current[k];
                        }
                    }
                }
            }, 1000);
        }
    }

    // Refresh every 3 seconds unless there are pending changes
    useInterval(() => {
        if (Object.keys(pendingChanges.current).length > 0) {
            console.log("Pending changes", pendingChanges.current)
            return;
        }
        refreshApplicant();
    }, 3000);

    return <ThreeColumnPage
        nav={nav}
        title={info["legal_name"] || "Applicant"}
        main={<>
            {applicantDash ? <ApplicantIdentities
                applicant={uid}
                path={applicantDash} />
                : <ModularQuestionPage
                    sections={{
                        sections: activeSections as any
                    }}
                    info={info}
                    uid={uid}
                    viewInfo={{ readonly: props?.readonly ? 'true' : undefined }}
                    setInfo={saveInfo}
                    submit={async () => { }}
                    saveInfo={async (info) => {

                    }}
                    saveAuth={(auth) => {

                    }}
                    noNavBar={subsurvey ? undefined : true}
                    sequential={true}
                    Viewer='screener' />
            }
        </>}
        subtabs={subTabs}
    />
}
