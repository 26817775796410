import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useAPIPost, usePost } from "./API";
import { useCookies } from "react-cookie";
import { useHistory, useLocation } from "react-router-dom";
import { useLocalizedStrings } from "./Localization";
import { SpacedSpinner } from "./Util";
import { ClickableButton } from "./Components/Button";
import { toast } from "react-toastify";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { UserInfoContext } from "./Context";
import * as Sentry from "@sentry/react";

function AuthPage() {
  const L = useLocalizedStrings();
  const [email, setEmail] = useState("");
  const [emailOnly, setEmailOnly] = useState(false);
  const [sending, setSending] = useState(false);

  const [code, setCode] = useState("");
  const verificationToken = useRef("");
  const [,setCookie,] = useCookies(["auth_token"]);
  const [showPassword, setShowpassword] = useState(false);
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [verifyCodeMode, setVerifyCodeMode] = useState(false);

  const location = useLocation();
  const next = decodeURIComponent(location.search.slice("?next=".length));

  const sendVerification = usePost("/authenticate");
  const verifyCode = usePost("/authenticate/complete", {
    token: () => verificationToken.current
  });
  const userContext = useContext(UserInfoContext);

  // We may do this in a later addition
  // For now we will just have RS use the same auth token aidkit uses, with fernet decryption.
  // const saveSession = useAPIPost(get_rs_host() + "/complete_signin");

  async function submitEmail(e: React.MouseEvent, params: { emailOnly: boolean }) {
    e.preventDefault();

    setSending(true);
    let result = await sendVerification({ email: email, email_only: params['emailOnly'] });

    if (result?.popup) {
      toast.info(result.popup);
    }
    
    setSending(false);

    // Hide the "Send code by email" button after we have sent an email.
    if (result?.email_only || (params['emailOnly'] === true && !emailOnly)){
      setEmailOnly(true);
    }

    verificationToken.current = result.intermediate_token;
    setVerifyCodeMode(true);
    setShowpassword(result.require_password);
  }

  async function submitCode(e: React.MouseEvent) {
    e.preventDefault();

    const result = await verifyCode({
      code: code,
      password: password
    });

    if (result?.error) {
      // Reset state if verification failed
      verificationToken.current = '';
      setVerifyCodeMode(false);
      setCode('')
    } else {
      // Create auth token that self-expires after 86400 seconds (~1 rotation of the earth -- 24 hours)
      setCookie("auth_token", result.auth_token, {
        maxAge: 86400, path: "/"
      });

      // Causes the UserInfoWrapper which provides UserInfoContext to call /user/info again now that we have a verified token
      if (userContext?.refreshContext) {
        userContext.refreshContext();
      } else {
        Sentry.captureMessage('Could not refresh UserInfoContext, callback missing', Sentry.Severity.Warning);
      }

      // Find the place they came in on.
      history.push(next);
    }
  }

  return (
    <div>
      <Container className="col-md-6 col-md-offset-3">
        <br />
        <h3>{L.auth.please_authenticate}</h3>
        <Form>
          {!verifyCodeMode && (
            <>
              <Form.Group>
                <Form.Label>{L.auth.email_address}</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={L.auth.enter_email}
                />
                <Form.Text className="text-muted">
                  {"" /*L.auth.we_will_send_you_a_text*/}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="checkbox">
                <Form.Check type="checkbox" 
                  checked={emailOnly}
                  onChange={(e) => setEmailOnly(e.currentTarget.checked)} label={L.auth.send_code_to_email} capture={true}/>
              </Form.Group>
              <Button variant="primary" type="submit" onClick={(e) => submitEmail(e, { emailOnly })}>
                {L.auth.submit}
              </Button>
              {errorMessage !== "" && (
                <div className="mt-2 alert alert-danger">{errorMessage}</div>
              )}
            </>
          )}
          {verifyCodeMode && (
            <>
              <Form.Group>
                {showPassword && (
                  <>
                    <Form.Label>{L.auth.password}</Form.Label>
                    <Form.Control
                      value={password}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={L.auth.enter_your_password}
                    />
                    <br />
                  </>
                )}
                <Form.Label>{L.auth.verification_code}</Form.Label>
                <Form.Control
                  value={code}
                  autoComplete="one-time-code"
                  onChange={(e) => setCode(e.target.value)}
                  placeholder={L.auth.enter_code}
                />
              </Form.Group>
              <div className="mt-2 alert alert-info">
                <p><InformationCircleIcon height={'1.5rem'} className="inline pr-1"/>
                  {(emailOnly
                    ? L.auth.code_sent_to_email
                    : L.auth.code_sent_to_phone_or_email
                  ).replace('$email', email)}
                </p>
                <p>{L.auth.it_may_take_a_minute}</p>
              </div>
              <Button variant="primary" type="submit" onClick={submitCode}>
                {L.auth.submit_code}
              </Button>
              &nbsp;
              <Button
                variant="secondary"
                onClick={() => { verificationToken.current = ""; setVerifyCodeMode(false); }}
              >
                {L.auth.back}
              </Button>
            </>
          )}
          <p className="mt-4">{L.auth.we_use_cookies}</p>
        </Form>
      </Container>
    </div>
  );
}

export default AuthPage;
