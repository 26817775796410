import React, { useContext, useRef, useState } from 'react';
import { Badge, Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { get_deployment, useAPIPost, usePost } from '../API';
import InterfaceContext, { ConfigurationContext } from '../Context';
import { useLocalizedStrings } from '../Localization';
import { getApplicantLink, safeParse, SpacedSpinner } from '../Util';

type SearchResult = {
    deployment: string;
    uid: string;
    name: string;
    value: string;
    stage: string;
    ranking: number;
    mine: boolean;
};
type AddNewProps = {
    show: boolean;
    setShow: (show: boolean) => void;
};
export function AddNew(props: AddNewProps) {
    const search = useAPIPost("/applicants/search");
    const legacyCreateApplicant = useAPIPost("/applicants/create");
    const createApplicant = usePost('/applicant/create');
    const claimApplicant = useAPIPost("/applicants/:uid:/create");
    const [name, setName] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [results, setResults] = useState([] as SearchResult[]);
    const [searchComplete, setSearchComplete] = useState(false);
    const [searching, setSearching] = useState(false);
    const submitting = useRef(false);
    const history = useHistory();
    const L = useLocalizedStrings();
    const configuration = useContext(ConfigurationContext);
    const context = useContext(InterfaceContext);
    const thisDeployment = get_deployment();
  
    const searchBy = safeParse(configuration.applicant_search_by || '{}');
    const searchLabel = searchBy[context.lang] || L.dashboard.phone;
    const searchPlaceholder = searchBy['placeholder'] || '';
    const duplicateMessage = searchBy["duplicate_message_" + context.lang] || '';
    const deploymentLabelMap = safeParse(configuration.external_program_labels || '{}');
    const applicantImport = searchBy['import_data'];
    // console.log(applicantImport);

    const hitColors = searchBy['duplicate_colors'];
  
    async function startSearch() {
      setSearching(true);
      const responses = await search({ name, term: searchValue });
      if (responses && responses.data !== undefined) {
        setSearchComplete(true);
        setResults(responses.data);
      } else {
        setSearchComplete(false);
      }
      setSearching(false);
    }
  
    async function addNew() {
      if (submitting.current) {
        console.log("Already clicked");
        return;
      }
      submitting.current = true;
  
      const result = await createApplicant({ legal_name: name, [searchBy.key ?? 'phone_number']: searchValue });
      if (result.error) {
        alert(result.error);
      } else {
        history.push("/applicant/" + result.newId);
      }
      submitting.current = false;
    }
  
    async function claim(uid: string) {
      const result = await claimApplicant({}, '/applicant/' + uid + '/claim_applicant');
      if (result.error) {
        alert(result.message);
      } else {
        history.push("/applicant/" + uid);
      }
    }

    async function addAndImport(previous_deployment: string, previous_uid: string) {
      if (submitting.current) {
        console.log("Already clicked");
        return;
      }
      submitting.current = true;
  
      const result = await legacyCreateApplicant({ name: name, phone: searchValue, previous_deployment, previous_uid });
      if (result.error) {
        alert(result.message);
      } else {
        history.push("/applicant/" + result.data.uid);
      }
      submitting.current = false;
    }
  
    function close() {
      setName("");
      setSearchValue("");
      setResults([]);
      setSearchComplete(false);
      props.setShow(false);
    }
  
    return (
      <Modal show={props.show} onHide={close}>
        <Modal.Header closeButton >
          <Modal.Title>{L.dashboard.add_new_applicant}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {!configuration.disable_add_applicant_search  && L.dashboard.in_order_to_add_a_new_applicant}
            {configuration.disable_add_applicant_search && L.dashboard.enter_applicant_info}
          </p>
          <InputGroup>
            <InputGroup.Prepend>
            <InputGroup.Text>
              {L.dashboard.placeholder_name}
            </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={L.dashboard.placeholder_name}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>{searchLabel}</InputGroup.Text>
            </InputGroup.Prepend>
            {configuration.applicant_search_by && (
              <Form.Control 
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.replace(/[^0-9/]/g,''))}
                placeholder={searchPlaceholder}
                />
            )}
            {!configuration.applicant_search_by && (
              <Form.Control
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value.replace(/[^0-9]/g, ''))}
                placeholder={L.dashboard.placeholder_phone_number}
              />
            )}
            {!configuration.disable_add_applicant_search && (
            <InputGroup.Append>
              <Button onClick={startSearch}>{searching && <SpacedSpinner />}<span>{L.dashboard.search}</span></Button>
            </InputGroup.Append>
            )}
          </InputGroup>
          <br />
          {searchComplete && results.length === 0 && <b>{L.dashboard.no_results}</b>}
          {searchComplete && results.length > 0 && (
            <>
              <b>{L.dashboard.possible_matches}</b>
              <Table bordered>
                <tbody>
                  {results.map((r: SearchResult, index: number) => {
                    const hitColor = hitColors && hitColors[r.deployment] ? hitColors[r.deployment] : '#ffeeee';
  
                    return (
                      <tr key={`search-result-${index}`} style={(r.ranking > 1 ? { 'backgroundColor' : hitColor } : {})}>
                        <td>
                          {getApplicantLink(r.deployment, thisDeployment, r.uid, r.name)}&nbsp;
                          {r.value !== searchValue && <><Badge variant="info">{L.dashboard.similar_name}</Badge>&nbsp;</>}
                          {r.value === searchValue && r.ranking === 1 && <><Badge variant="warning">{L.dashboard.same_value.replace('%v',searchLabel.toLowerCase()) + ': ' + r.value}</Badge>&nbsp;</>}
                          {r.value === searchValue && r.ranking > 1 && <><Badge variant="danger">{L.dashboard.similar_name}</Badge>&nbsp;
                            <Badge variant="danger">{L.dashboard.same_value.replace('%v', searchLabel.toLowerCase()) + ": " + r.value}</Badge></>}
                        </td>
                        <td>
                            {r.stage}
                            {(r.stage === 'Unreachable' || r.stage === 'Unassigned' || r.stage === 'Opted Out') && !r.mine && r.deployment === thisDeployment &&
                                <>&nbsp;<Button variant="success" size="sm" onClick={() => claim(r.uid)}>{L.dashboard.claim}</Button></>}
                            {r.mine &&
                                <>&nbsp;<Badge variant="dark">{L.dashboard.yours}</Badge></>}
                            {(deploymentLabelMap && !!deploymentLabelMap[r.deployment]) && 
                                <>&nbsp;
                                <Badge variant={deploymentLabelMap[r.deployment]['variant'] ?? 'warning'}>
                                {deploymentLabelMap[r.deployment][context.lang] && <span>{deploymentLabelMap[r.deployment][context.lang]}</span>}
                                </Badge>
                                </>
                            }
                            {(applicantImport && !!applicantImport[r.deployment] && r.value === searchValue && r.ranking > 1) &&    
                                <Button variant="success" onClick={() => addAndImport(r.deployment, r.uid)}>{L.applicant.data_request.add_and_request_data}</Button>
                            }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <b>{duplicateMessage}</b>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} variant="secondary">
            {L.dashboard.close}
          </Button>
          {configuration.disable_add_applicant_search && (
            <Button variant="primary" onClick={addNew}>
              {L.dashboard.add_new_applicant}
            </Button>
          )}
          {searchComplete && (
            <Button variant="primary" onClick={addNew}>
              {L.dashboard.no_match_add_new_applicant}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
