import React, { useEffect, useState, useContext } from 'react';
import { useAPIPost } from '../API';
import { QuestionProps } from '../Questions/Props';
import type { GenericMetadata } from '@aidkitorg/airtable/src/types';
import { Card } from 'react-bootstrap';
import { safeParse } from '../Util';
import InterfaceContext from '../Context';
import { useLocalizedStrings } from '../Localization';
/**
 * 
 * @param props 
 * @returns Emptiness if valid, or a styled Invalid message box if invalid
 */
export function QuotaCheck(props: QuestionProps & { block: JSX.Element}) {

    const [valid, setValid] = useState(true);

    const checkHasQuota = useAPIPost('/applicants/quota_available_for_key_value_uid');

    const L = useLocalizedStrings();
    const context = useContext(InterfaceContext);

    // Store the relevant info and only call if the info has changed
    const metadata = safeParse(props.Metadata || '{}') as GenericMetadata;
    const quota_check = metadata['quota_check'];

    // We only need to run this once, since it is unlikely the quota will not already be reached when loading the question
    // We have blockers to prevent setting info so no need to call this more than once.
    useEffect(() => {
      console.log("Checking quota for question ", props.Question);
      const interval = setInterval(async () => {
        const hasQuota = await checkHasQuota({ 
            quota_check,
            key: props["Target Field"],
            value: props.info[props["Target Field"]!] || '',
            uid: props.uid
        });

        if (!hasQuota) { 
          setValid(false);
        } else { 
          setValid(true);
        }
      }, 10000);
      return () => {
        clearInterval(interval);
      }
    }, []);

    if (!valid) {
        return (
            <Card>
              <Card.Header as="h6">
                {L.applicant.quota_reached}
              </Card.Header>
              <Card.Body as="div" className={`alert alert-warning mb-0`}>
                <Card.Text>
                  <span>{quota_check?.translations ? quota_check.translations[context.lang] : ''}</span>
                </Card.Text>
              </Card.Body>
            </Card>
          );
    }

    return props.block;
}