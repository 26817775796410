import { Root, Survey, Block, ForceSourceMap, ExpandedSurvey, Query, Notification, Persona, Subsurvey, Text, TextEntry, Attachment, Select, Lookup, CustomContact, Collection, TopLevelTemplatedBlock, Payment, NotificationGroup, Dashboard, Computed, TrackedLink, UsioMailing, GiveCardMailing, ClickQuery, ApplicantIdentities, CommsConfig, RichText, CollectionComponent } from "@aidkitorg/types/lib/survey"
import { CompileExpressionToSQL, CompileNudgeExprToSQL } from "@aidkitorg/types/lib/translation/expr_to_sql"
import { CollectFieldPermissions, CollectScopeForUser, CollectUserTagsAndFilters, EmailToUserId } from "@aidkitorg/types/lib/translation/permissions"
import { Sections } from "@aidkitorg/types/lib/legacy/airtable"
import { v0ToLegacy, AirtableSurveyToSurveyDefinition, hash, expandTemplates, deepCopy, extractNotificationsAndPayments } from "@aidkitorg/types/lib/translation/v0_to_legacy"
import { ContextType, Suspense, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AllDoneComponent, ModularQuestionPage } from "./Apply";
import { get_deployment, get_rs_host, useAPIPost, usePost, useToken } from "./API";
import React from "react";
import { InfoDict } from "./Questions/Props";
import { Dropdown } from "./Components/Dropdown";
import { copyToClipboard, getDistroBrowserIncompatibility, safeParseValidatedFormula, useInterval, safeParse, SpacedSpinner, classNames } from "./Util";
import { BaseRealtimeEvent, FacePile, useChannel } from "./Realtime";
import { AuthContext, ConfigurationContext, LoggedInConfigurationContext, PublicConfigurationContext, UserInfoContext } from "./Context";
import { ChangeSet, UserActivity, State } from "@aidkitorg/typesheets/lib/collab";
import { walk } from "@sentry/utils";
import { getFormulaParents } from "@aidkitorg/types/lib/util";
import { CompileExpressionToJS } from "@aidkitorg/types/lib/translation/expr_to_js";
import CytoscapeComponent from 'react-cytoscapejs';
import Cytoscape from 'cytoscape';
import { CollectionTree } from "./Components/ThreeColumnPage"
import ConfigSearch, { highlightSearchTerm } from "./ConfigSearch"
import { DistroDashboard } from "./DistroDashboard"
import { MIGRATIONS } from "./Migrations"
import { ClickableButton } from "./Components/Button"
import jsonDiff from "json-diff";
import { v4 as uuidv4 } from 'uuid';
import { TranslationModal } from "./TranslationModal";
import { PersonaModal } from "./PersonaModal"
import { SurveyErrorsModal } from "./SurveyErrorsModal"
import { BeakerIcon } from "@heroicons/react/24/solid"
import { toast } from "react-toastify"
import { compress, decompress } from "./utils/compress"
import { MACROS } from "./macros/Macros"
import { supportedLanguages } from "./Localization"
import RoboNavConsole from "./Components/RoboNavConsole"

const elk = require('cytoscape-elk');
Cytoscape.use(elk);

type SearchableComponent = Block | Collection | NotificationGroup | TopLevelTemplatedBlock | Payment | Dashboard | UsioMailing | GiveCardMailing | ApplicantIdentities;

function extractDedupedTranslations(root: any, stack: Set<string> = new Set()): { en: string, [key: string]: string }[] {
    if (typeof root.en === "string") {
        if (!root._id || stack.has(root._id)) {
            root._id = uuidv4();
        }
        stack.add(root._id);
        return [{ ...root }];
    }
    if (Array.isArray(root)) {
        return root.flatMap(i => extractDedupedTranslations(i, stack));
    }
    if (typeof root === "object") {
        return Object.values(root).flatMap(i=> extractDedupedTranslations(i, stack));
    }
    return [];
}

function importTranslations(root: any, strings: { _id?: string, [key: string]: string | undefined }[]) {
    if (typeof root.en === "string" && root._id) {
        let translation = strings.find((s) => s._id === root._id);
        if (translation) {
            Object.keys(translation).forEach((key) => {
                if (key !== '_id' && translation![key]) {
                    root[key] = translation![key];
                }
            });
        }
    }
    if (Array.isArray(root)) {
        root.flatMap((r) => importTranslations(r, strings));
    }
    if (typeof root === "object") {
        Object.values(root).flatMap((r) => importTranslations(r, strings));
    }
}

export function Stats(props: { stats: {name: string, stat: string}[] }) {
    return (
      <div>
        <dl className="ml-2 mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {props.stats.map((item) => (
            <div key={item.name} className="overflow-hidden rounded-lg bg-white px-4 py-2 shadow sm:p-6">
              <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
            </div>
          ))}
        </dl>
      </div>
    )
  }

function Followup(props: {
        task: NonNullable<ExpandedSurvey['notifications']>[number],
        flup: Exclude<NonNullable<NotificationGroup>['followups'], undefined>[number]
    }) {
    let testExpression = usePost('/program/admin/test_expression');
    let simulateNotifications = usePost('/program/admin/simulate_notifications');
    let [eligible, setEligible] = useState<Awaited<ReturnType<typeof testExpression>> | null>(null);
    let [simulated, setSimulated] = useState<Awaited<ReturnType<typeof simulateNotifications>> | null>(null);
    let simulating = useRef(false);
    let checking = useRef(false);
    let [testContactMethod, setTestContactMethod] = useState<string>('sms');

    useEffect(() => {
        if (['email', 'sms', 'whatsapp'].includes(props.task.contactMethod)) {
            setTestContactMethod(props.task.contactMethod);
        }
    }, [props.task.contactMethod]);

    const thisHash = hash(props.task.targetPrefix + 
        props.flup.suffix + 
        JSON.stringify(props.flup.send_if));

    let expanded: Query = props.flup.send_if.kind === 'SQL' ?
        props.flup.send_if :
            (props.task.recipient !== 'Unsubmitted Applicant' ? {kind: 'Click', expr: {
                kind: 'And',
                clauses: [
                    { kind: 'Or', 
                        clauses: [
                            { 
                                kind: 'And', 
                                clauses: [{
                                        field: props.task.targetPrefix + '_sms' as any,
                                        kind: 'Exists'
                                    },
                                    {
                                        field: props.task.targetPrefix + '_sms' as any,
                                        kind: 'Last Modified',
                                        ago: props.flup.after
                                    }
                                ]
                            },
                            { 
                                kind: 'And', 
                                clauses: [{
                                        field: props.task.targetPrefix + '_email',
                                        kind: 'Exists'
                                    },
                                    {
                                        field: props.task.targetPrefix + '_email',
                                        kind: 'Last Modified',
                                        ago: props.flup.after
                                    }
                                ]
                            },
                        ]
                    },
                    props.flup.send_if.expr
                ]
            }} : props.flup.send_if);

    async function checkTask() {
        (async () => {
            checking.current = true;
            setEligible(await testExpression({
                query: expanded,
                orderBy: props.flup.send_if.kind !== 'SQL' ? props.flup.send_if.orderBy : undefined,
                ...(props.task.recipient === 'Unsubmitted Applicant' ? 
                    {nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
                    targetField: props.task.targetPrefix + '_' + testContactMethod,
                    followupTargetField: props.task.targetPrefix + '_' + props.flup.suffix + '_' + testContactMethod,
                    followupAfter: {...props.flup.after} } 
                    : {})
            }));
            checking.current = false;
        })();
    }

    async function simulate() {
        if (simulating.current) return;
        (async () => {
            simulating.current = true;
            setSimulated(await simulateNotifications({
                uids: props.task.testUIDs || [],
                emailKey: typeof props.task.recipient === 'object' ? props.task.recipient.emailField : '',
                phoneKey: typeof props.task.recipient === 'object' ? props.task.recipient.phoneField : '',
                content: props.flup.message as Record<string, string>,
                links: (props.flup.subsurveys || []).reduce((obj, curr) => {
                    obj[curr.variable] = curr.name;
                    return obj
                }, {} as Record<string, string | TrackedLink>),
                ...(Array.isArray(props.flup.message) ? { 
                    messageBlocks: props.flup.message as any
                } : {})
            }));
            simulating.current = false;
        })();
    }

    return <div className="border border-gray-300 p-2 bg-gray-100">
        <b>Followup: {props.flup.suffix}</b><br />
        <b>{thisHash == props.flup.enableKey ? 'Enabled' : 'Not Enabled. Key is: ' + thisHash}</b>
        <Stats stats={[
            ...(eligible ? [{ name: 'Initially Eligible', stat: eligible.count?.toString() || 'Error'}] : [])
        ]}/>
        <button
        onClick={checkTask}
        type="button"
        disabled={checking.current}
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
        {checking.current ? 'Checking...' : 'Check Query'}
        </button>
        <button
        onClick={simulate}
        type="button"
        disabled={simulating.current}
        className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
        {simulating.current ? 'Simulating...' : 'Simulate Formatted Notification'}
        </button>
        {props.task.recipient === 'Unsubmitted Applicant' && !(['email', 'sms', 'whatsapp'].includes(props.task.contactMethod)) &&
            <select className="ml-2 inline-flex items-center shadow-sm overflow-hidden bg-white px-2.5 py-1.5 text-black text-xs font-medium text-base text-gray-700 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded" 
                onChange={(e) => setTestContactMethod(e.target.value)}>
                <option value="sms">SMS</option>
                <option value="email">Email</option>
                <option value="whatsapp">WhatsApp</option>
            </select>
        }
        {Object.keys(simulated || {}).map((u) => {
            if ((typeof (simulated as any)[u]) === 'string') {
                return <div key={u}>
                    <div><b>{u}</b></div>
                    <pre className="pb-5">{(simulated as any)[u]}</pre>
                </div>
            }
        })}
        <pre className="pb-5">{
            expanded.kind === 'SQL' ?
            expanded.sql :
                props.task.recipient === 'Unsubmitted Applicant' ?
                CompileNudgeExprToSQL({
                    cond: expanded.expr,
                    nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
                    targetField: props.task.targetPrefix + '_' + testContactMethod,
                    followupTargetField: props.task.targetPrefix + '_' + props.flup.suffix + '_' + testContactMethod,
                    followupAfter: {...props.flup.after}            
                }) :
                CompileExpressionToSQL({ 
                    cond: expanded.expr,
                    orderBy: props.flup.send_if.kind !== 'SQL' ? props.flup.send_if.orderBy : undefined
                })
        }</pre>
    </div>
}
 
type TestOutcome = {
    title: string,
    passed: boolean,
    output: any
};

function TestCases(props: {survey: ExpandedSurvey}) {
    const [computedFields, setComputedFields] = useState<TestOutcome[]>([]);

    useEffect(() => {
        const computedField: Computed[] = [];
        function traverse(node: any) {
            if (Array.isArray(node)) {
                node.map(traverse);
            } else if (typeof node === 'object') {
                if (node.kind === 'Computed') {
                    if (Array.isArray(node.testCases)) {
                        computedField.push(node);
                    }
                } else {
                    Object.values(node).map(traverse);
                }
            }
        }
        traverse(props.survey);


        const results: TestOutcome[] = [];
        for (const field of computedField) {
            const testCases = field.testCases!;
            for (const testCase of testCases) {
                try {
                    let input: () => Record<string, string> = () => ({} as Record<string, string>);
                    if (typeof testCase.input === 'string') {
                        input = Function(
                            "return (function() { const out = " + testCase.input + "; return out });"
                        )();
                    } else if (testCase.input.kind === 'Persona') {
                        let personaName = testCase.input.persona;
                        input = () => (props.survey.personas.find(n => n.name === personaName)?.attrs || [])
                            .reduce<Record<string, string>>((acc, cur) => {
                                acc[cur.field] = cur.value;
                                return acc;
                            }, {});
                    }
                    const func = Function(
                    "return (function(info, org, screener) { const out = " + field.formula + "; return out });"
                    )();
                    const check = Function(
                        "return (function(output) { const out = " + testCase.test + "; return out });"
                    )();
                    const out = func(input());
                    const ok = check(out);

                    results.push({
                        title: `${field.targetField} - ${testCase.description}`,
                        passed: ok,
                        output: out 
                    });
                } catch (e) {
                    results.push({
                        title: `${field.targetField} - ${testCase.description}`,
                        passed: false,
                        output: "Did not compile: " + e
                    });
                }
            }
        }
        setComputedFields(results);


    }, [props.survey]);


    return <>{computedFields.map((f) => {
        let outputJson = safeParse(f.output);
        return <div>
        <h3>{f.title}</h3>
            {f.passed ? "✅ Passed!" : <>
                ❌ Failed!
                {f.output.includes("{") ? 
                    <pre>
                        {JSON.stringify(outputJson, null, 2)}
                    </pre> : f.output}
                </>}
        </div>
    })}</>;
}

function Changes(props: {current: any, updated: any}) {
    const [logDiff, setLogDiff] = useState('');
    useEffect(() => {
        if (JSON.stringify(props.current) === '{}' || JSON.stringify(props.updated) === '{}') {
            setLogDiff('');
            return;
        }
        setLogDiff(jsonDiff.diffString(props.current, props.updated) || 'No changes!');
    }, [props.current, props.updated]);
    return <pre>{logDiff}</pre>
}

function TaskDetail(props: {task: NonNullable<ExpandedSurvey['notifications']>[number]}) {
    let testExpression = usePost('/program/admin/test_expression');
    let simulateNotifications = usePost('/program/admin/simulate_notifications');
    let [eligible, setEligible] = useState<Awaited<ReturnType<typeof testExpression>> | null>(null);
    let [simulated, setSimulated] = useState<Awaited<ReturnType<typeof simulateNotifications>> | null>(null);
    let simulating = useRef(false);
    let checking = useRef(false);
    let [testContactMethod, setTestContactMethod] = useState<string>('sms');

    useEffect(() => {
        if (['email', 'sms', 'whatsapp'].includes(props.task.contactMethod)) {
            setTestContactMethod(props.task.contactMethod);
        }
    }, [props.task.contactMethod]);

    async function checkTask() {
        (async () => {
            checking.current = true;
            setEligible(await testExpression({
                query: task.initial_notification.enabled_when,
                orderBy: task.initial_notification.enabled_when.kind !== 'SQL' ? task.initial_notification.enabled_when.orderBy : undefined,
                ...(task.recipient === 'Unsubmitted Applicant' ? 
                    {nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
                    targetField: props.task.targetPrefix + '_' + testContactMethod
                } : {})
            }));
            checking.current = false;
        })();
    }
    async function simulate() {
        if (simulating.current) return;
        (async () => {
            simulating.current = true;
            setSimulated(await simulateNotifications({
                uids: task.testUIDs || [],
                emailKey: typeof props.task.recipient === 'object' ? props.task.recipient.emailField : '',
                phoneKey: typeof props.task.recipient === 'object' ? props.task.recipient.phoneField : '',
                content: task.initial_notification.message,
                links: (task.initial_notification.subsurveys || []).reduce((obj, curr) => {
                    obj[curr.variable] = curr.name;
                    return obj
                }, {} as Record<string, string | TrackedLink>)
            }));
            simulating.current = false;
        })();
    }

    let task = props.task;
    const thisHash = hash(task.targetPrefix + JSON.stringify(task.initial_notification.enabled_when));

    return <div className="border border-gray-300 p-2">
        <h3>{task.name}</h3>
        {task.kind !== 'InlineNotification'
            ? <b>{thisHash == task.enableKey ? 'Enabled' : 'Not Enabled. Key is: ' + thisHash}</b>
            : <b>Inline Notif</b>}
        <Stats stats={[
            ...(eligible ? [{ name: 'Initially Eligible', stat: eligible.count?.toString() || 'Error'}] : []),
        ]}/>
        <button
        onClick={checkTask}
        type="button"
        disabled={checking.current}
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
        {checking.current ? 'Checking...' : 'Check Query'}
        </button>
        <button
        onClick={simulate}
        type="button"
        disabled={simulating.current}
        className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
        {simulating.current ? 'Simulating...' : 'Simulate Formatted Notification'}
        </button>
        {props.task.recipient === 'Unsubmitted Applicant' && !(['email', 'sms', 'whatsapp'].includes(props.task.contactMethod)) &&
            <select className="ml-2 inline-flex items-center shadow-sm overflow-hidden bg-white px-2.5 py-1.5 text-black text-xs font-medium text-base text-gray-700 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded" 
                onChange={(e) => setTestContactMethod(e.target.value)}>
                <option value="sms">SMS</option>
                <option value="email">Email</option>
                <option value="whatsapp">WhatsApp</option>
            </select>
        }
        {Object.keys(simulated || {}).map((u) => {
            if ((typeof (simulated as any)[u]) === 'string') {
                return <div key={u}>
                    <div><b>{u}</b></div>
                    <pre className="pb-5">{(simulated as any)[u]}</pre>
                </div>
            }
        })}
        <pre className="pb-5">{
            task.initial_notification.enabled_when.kind === 'SQL' ?
            task.initial_notification.enabled_when.sql :
                props.task.recipient === 'Unsubmitted Applicant' ?
                CompileNudgeExprToSQL({
                    cond: task.initial_notification.enabled_when.expr || task.initial_notification.enabled_when,
                    nudgeContact: testContactMethod === 'email' ? 'email' : 'phone_number',
                    targetField: props.task.targetPrefix + '_' + testContactMethod
                }) :
                CompileExpressionToSQL({
                    cond: task.initial_notification.enabled_when.expr || task.initial_notification.enabled_when,
                    orderBy: task.initial_notification.enabled_when.orderBy
                })}
        </pre>
        {((task.kind !== 'InlineNotification' && task.followups) || []).map((followup) => {
            return <Followup task={props.task} flup={followup} key={followup.suffix} />
        })}
    </div>
}

function TaskEditor(props: {tasks: ExpandedSurvey['notifications']}) {
    if (!props.tasks?.length) {
        return <div className="mt-4 ml-1">No notifications yet!</div>
    }
    return <>{props.tasks?.map((t) => <TaskDetail key={t.name} task={t} />)}</>
}

function DashboardDetail(props: {dashboards: Dashboard[]}) {
    if (!props.dashboards?.length) {
        return <div className="mt-4 ml-1">No dashboards yet!</div>
    }
    return <>{props.dashboards.map((dashboard => DistroDashboard({dashboard: dashboard.path, isEmbedded: true})))}</>
}

function PaymentDetail(props: {task: NonNullable<ExpandedSurvey['payments']>[number]}) {
    let testExpression = usePost('/program/admin/test_expression');
    let [eligible, setEligible] = useState<Awaited<ReturnType<typeof testExpression>> | null>(null);

    let checking = useRef(false);

    async function checkTask() {
        (async () => {
            checking.current = true;
            if (task.condition.kind == 'Click') {
                setEligible(await testExpression({
                    query: { kind: 'Click', expr: task.condition.expr },
                    orderBy: task.condition.orderBy
                }));
            }
            checking.current = false;
        })();
    }

    let task = props.task;
    const thisHash = hash(task.targetField + JSON.stringify(task.condition) + (task.ledger ? task.ledger : ''));

    return <div className="border border-gray-300 p-2">
        <h3>{task.name}</h3>
        <b>{thisHash == task.enableKey ? 'Enabled' : 'Not Enabled. Key is: ' + thisHash}</b>
        <Stats stats={[
            ...(eligible ? [{ name: 'Initially Eligible', stat: eligible.count?.toString() || 'Error'}] : []),
        ]}/>
        <button
        onClick={checkTask}
        type="button"
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
        {checking.current ? 'Checking...' : 'Check Query'}
        </button>
        <pre className="pb-5">{CompileExpressionToSQL({
            cond: (task.condition as any).expr, 
            orderBy: (task.condition as ClickQuery).orderBy 
        })}</pre>
    </div>
}

function PaymentsEditor(props: {payments: ExpandedSurvey['payments']}) {
    if (!props.payments?.length) {
        return <div className="mt-4 ml-1">No payments yet!</div>
    }
    return <>{props.payments.map((t) => <PaymentDetail key={t.name} task={t} />)}</>
}

function AutomaticMessageHandler(props: {amh: CommsConfig['automaticMessageHandler']}) {
    const getDryModeLogs = usePost("/admin/amh/get_dry_mode_logs");
    const [logs, setLogs] = useState<Awaited<ReturnType<typeof getDryModeLogs>> | null>(null);

    useEffect(() => {
        (async () => {
            setLogs(await getDryModeLogs({ amh: props.amh }));
        })();
    }, [])

    return <>
        {props.amh?.handlers?.map((h) => (
            <div key={h.id}>
                <h3>{h.name}</h3>
                <pre>EnableKey: {hash(JSON.stringify({ ...h, enableKey: '' }))}</pre>
                {Object.entries(logs?.[h.id] || {}).map(([key, value]) => (
                    <>
                        <h4>{key}</h4>
                        <ul>{Array.isArray(value) 
                            ? value.map(v => <li key={JSON.stringify(v)}><pre>{JSON.stringify(v,undefined,2)}</pre></li>) 
                            : typeof value === 'object' 
                                ? <li key={JSON.stringify(value)}><pre>{JSON.stringify(value, undefined, 2)}</pre></li> 
                                :<li key={value}>{value}</li>}
                        </ul>
                    </>
                ))}
            </div>
        ))}
    </>
}

function getComputedGraph(expandedSurvey: ExpandedSurvey) {
    let survey = expandedSurvey.survey
    if (survey) {
        survey = expandTemplates(survey) as ExpandedSurvey['survey'];
    }
    const deps = {} as Record<string, string[]>;

    const kinds = {} as Record<string, string[]>;

    function traverse(block: SearchableComponent): Subsurvey[] {
        if (block.kind == 'Computed') {
            let parents = [];
            if (typeof block.formula == 'string') {
                parents = getFormulaParents(block.formula);
            } else {
                parents = getFormulaParents(CompileExpressionToJS(block.formula))
            }

            kinds[block.targetField] = kinds[block.targetField] || [];
            kinds[block.targetField].push('computed')

            parents.forEach((p) => {
                deps[p] = deps[p] || [];
                deps[p].push(block.targetField);
                deps[block.targetField] = deps[block.targetField] || []
            })
        }
        if (block.kind == 'Subsurvey') {
            block.sections.flatMap(traverse);
        }
        if (block.kind == 'Section') {
            block.components.flatMap(traverse);
        }
        if (['TextEntry', 'Select', 'Attachment', 'Date', 'Address', 'Number', 'Income Calculator', 'SubmitButton', 'Inline Signature'].includes(block.kind || '')) {
            const b: TextEntry | Select | Attachment = block as any;
            if (b.targetField) {
                //deps[b.targetField] = deps[b.targetField] || [];
                kinds[b.targetField] = kinds[b.targetField] || [];
                kinds[b.targetField].push('entered')
            }
        }
        if (block.kind === 'Collection') {
            block.components.flatMap(traverse);
        }
        if (block.kind === 'Address') {
            block.lookups?.map((l: string) => {
                l = block.targetField + '_' + l;
                deps[block.targetField] = deps[block.targetField] || [];
                deps[block.targetField].push(l);

                deps[l] = deps[l] || [];
                kinds[l] = kinds[l] || [];
                kinds[l].push('lookup')

                deps[block.targetField] = deps[block.targetField] || []
            });
        }
        if (['Lookup'].includes(block.kind || '')) {
            const b: Lookup = block as any;
            kinds[b.targetField] = kinds[b.targetField] || [];
            kinds[b.targetField].push('lookup')
            if (b.lookup.kind === 'Standard') {
                deps[b.lookup.key] = deps[b.lookup.key] || [];
                deps[b.lookup.key].push(b.targetField);
            }
            if (b.lookup.kind === 'Geo') {
                deps[b.lookup.key] = deps[b.lookup.key] || [];
                deps[b.lookup.key].push(b.targetField);
            }
            if (b.lookup.kind === 'Dynamo') {
                // Uhh rob help
            }
        }
        return [];
    }
    (survey || []).flatMap(traverse);

    for (const payment of expandedSurvey.payments || []) {
        let parents = [] as string[];
        if (payment.condition.kind === 'SQL') {
            // This doesn't really work
        } else {
            parents = getFormulaParents(CompileExpressionToJS(payment.condition.expr));
        }

        kinds[payment.targetField] = kinds[payment.targetField] || [];
        kinds[payment.targetField].push('payment')

        parents.forEach((p) => {
            deps[p] = deps[p] || [];
            deps[p].push(payment.targetField);
            deps[payment.targetField] = deps[payment.targetField] || []
        })
    }

    for (const notif of expandedSurvey.notifications || []) {
        let parents = [] as string[];
        if (notif.initial_notification.enabled_when.kind === 'SQL') {
            // This doesn't really work
        } else {
            parents = getFormulaParents(CompileExpressionToJS(notif.initial_notification.enabled_when.expr))
        }

        kinds[notif.targetPrefix] = kinds[notif.targetPrefix] || [];
        kinds[notif.targetPrefix].push('notif')

        parents.forEach((p) => {
            deps[p] = deps[p] || [];
            deps[p].push(notif.targetPrefix);
            deps[notif.targetPrefix] = deps[notif.targetPrefix] || []
        })

        if (notif.kind !== 'InlineNotification') {
            for (const followup of notif.followups || []) {
                let parents = [] as string[];
                if (followup.send_if.kind === 'SQL') {
                    // This doesn't really work
                } else {
                    parents = getFormulaParents(CompileExpressionToJS(followup.send_if.expr))
                }
                const prefix = notif.targetPrefix + '_' + followup.suffix;

                kinds[prefix] = kinds[prefix] || [];
                kinds[prefix].push('notif');

                (deps[notif.targetPrefix] || []).push(prefix);

                parents.forEach((p) => {
                    deps[p] = deps[p] || [];
                    deps[p].push(prefix);
                    deps[prefix] = deps[prefix] || []
                })
            }
        }
    }

    const colormap: Record<string, string> = {
        'computed': 'blue',
        'entered': 'yellow',
        'lookup': 'purple',
        'payment': 'green',
        'notif': 'orange',
    }

    const toReturn = [
        ...Object.keys(deps).map(k => ({data: { id: k, label: k, 
                color: (kinds[k] || []).length === 1 ? colormap[kinds[k][0]] : ((kinds[k] || []).length > 1 ? 'red' : '#a00')
            }})),
        ...Object.keys(deps).flatMap(k => deps[k].map(d => ({data: { 
                source: k, 
                target: d, 
                label: `Edge from ${k} to ${d}`, 
            }}))) 
    ];
    console.log(toReturn);
    return toReturn;
}

function enumerateSubsurveys(survey: ExpandedSurvey['survey']) {
    if (survey) {
        survey = expandTemplates(survey) as ExpandedSurvey['survey'];
    }

    function traverse(block: SearchableComponent): Subsurvey[] {
        if (block.kind == 'Subsurvey') {
            return [block, ...block.sections.flatMap(traverse)];
        }
        if (block.kind == 'Section') {
            return block.components.flatMap(traverse);
        }
        if (block.kind == 'Collection') {
            return block.components.flatMap(traverse);
        }
        return [];
    }
    const subsurveys = (survey || []).flatMap(traverse);
    return subsurveys;
}

function generateRandomString(length: number, chars: string): string {
    let result = '';
    const charsLength = chars.length;
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
}

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

type DistroPartialChangeEvent = BaseRealtimeEvent<
    'partial_change',
    {
        compressedChunk: string,
        totalChunks: number,
        userActivity?: UserActivity,
        uuid: string
    }
>;

type DistroChangeEvent = BaseRealtimeEvent<
    'change',
    {
        events: ChangeSet,
        userActivity?: UserActivity,
        uuid: string
    }
>;

type DistroEnableCollabEvent = BaseRealtimeEvent<
    'enable_collab',
    {
        tabId: string,
        uid?: string,
        tags?: string[],
        refreshContext?: (() => void),
    }
>;

type DistroDisableCollabEvent = BaseRealtimeEvent<
    'disable_collab',
    {
        tabId: string,
        uid?: string,
        tags?: string[],
        refreshContext?: (() => void),
    }
>;

type DistroSavedSurveyEvent = BaseRealtimeEvent<
    'saved_survey',
    {
        changesSinceLastPublish: any,
        username?: string,
        tabId: string,
    }
>;

type ConfigRealtimeEvents =
    DistroPartialChangeEvent
    | DistroChangeEvent
    | DistroEnableCollabEvent
    | DistroDisableCollabEvent
    | DistroSavedSurveyEvent;

export default function ConfigPage() {
    const [survey, setSurvey] = useState<Root>({ survey: [], notifications: [], personas: [] });
    const [translated, setTranslated] = useState<Sections | null>(null);   
    const distroRef = useRef<React.ComponentRef<typeof Distro>>(null);
    const stateRef = useRef<State | undefined>(undefined);

    const { personas } = survey as ExpandedSurvey || {};
    const [activePersonaName, setActivePersonaName] = useState<string>('');
    const [recompute, setRecompute] = useState(0);

    const [, token] = useToken();
    const user = useContext(UserInfoContext);
    const publicConfiguration = useContext(PublicConfigurationContext);
    const [tabId, setTabId] = useState(generateRandomString(4, 'abcdefghjkmnpqrstuvwxyz123456789'));

    const [mockInfo, setMockInfo] = useState<InfoDict>({});
    const [mockViewer, setMockViewer] = useState("applicant" as "applicant" | "screener");
    const [name, setName] = useState(window.location.hash.slice(1));
    const [loadedHash, setLoadedHash] = useState("");
    const [latestHash, setLatestHash] = useState("");
    const [latestSurvey, setLatestSurvey] = useState<any>({});
    const [showRightPanel, setShowRightPanel] = useState(true);
    const [translations, setTranslations] = useState<Text[]>([]);
    const [translationModalOpen, setTranslationModalOpen] = useState(false);
    const initialTranslations = useRef('');
    const [expanded, setExpanded] = useState<any>(null);

    const [surveys, setSurveys] = useState<Awaited<ReturnType<typeof listSurveys>> | null>(null);
    const loadingSurveys = useRef(false);
    const [surveyName, setSurveyName] = useState("");
    const [activeSurvey, setActiveSurvey] = useState<string | null>(null);
    const [extractedNotifsAndPayments, setExtractedNotifsAndPayments] = useState<[Notification[], Payment[]]>([[], []]);
    const [showPersonaModal, setShowPersonaModal] = useState(false);

    const [selectedPreview, setSelectedPreview] = useState<'survey' | 'notifications' | 'payments' | 'targetFields' | 'graph' | 'dashboards' | 'testCases' | 'changes' | 'amh' | 'fieldMismatches' | 'robonav'>('survey');
    const config = useContext(ConfigurationContext);
    const [subsurveys, setSubsurveys] = useState(null as null | Subsurvey[]);

    const [surveyValidationErrors, setSurveyValidationErrors] = useState<(string | {hint: string, error: string})[]>();

    const loadSurvey = usePost('/survey/load_survey');
    const saveSurvey = usePost('/survey/save_survey', { compressRequestPayload: true });
    const doMagic = usePost('/program/admin/magicV2');
    const doesSurveyExist = usePost('/survey/does_survey_exist');
    const sendNotifications = usePost("/program/admin/force_notifications_immediately");
    const validateSurveyRS = useAPIPost(get_rs_host() + "/check_survey_validity", 
        {   includeTokenInData: true, 
            includeDeploymentKeyInData: true,
            compressRequestPayload: true
        }
    );

    const getEverything = usePost('/applicant/get_everything');
    const listSurveys = usePost('/survey/list_surveys');
    const resetProgram = usePost('/admin/reset');

    // ----- State management & API endpoints for Collab Mode -------------

    const saveEventsTimer = useRef<number | undefined>(undefined);
    const sendEventsTimer = useRef<number | undefined>(undefined);
    const eventsToSend = useRef<ChangeSet>();
    const eventsToSave = useRef<ChangeSet>();
    const eventsAwaitingMerge = useRef<Record<string, ChangeSet[]>>({});

    const [publishing, setPublishing] = useState(false);
    const [canPublish, setCanPublish] = useState(true);
    const [unsavedCollabChanges, setUnsavedCollabChanges] = useState(false);
    const incomingEditsTimer = useRef<number | undefined>(undefined);

    const [collabEnabled, setCollabEnabled] = useState(false);
    const [changesSinceLastPublish, setChangesSinceLastPublish] = useState(0);
    const [enablingCollab, setEnablingCollab] = useState(false);
    const [lastPublished, setLastPublished] = useState(null as null | string);
    
    const lastActivityTime = useRef(Date.now());
    const nextAutosaveTime = useRef(Date.now() + (20 * 1000));

    const autoSaveCollabVersion = usePost('/survey/save_collab_version', { compressRequestPayload: true });
    const saveCollabEvents = usePost('/survey/save_collab_events', { compressRequestPayload: true });
    const [fieldMismatches, setFieldMismatches] = useState <{ node: Text, fieldsInLangMap: Record<keyof Text, string[]> }[]>([]);

    const refreshCollabEvents = usePost('/survey/get_collab_events');
    const clearCollabEvents = usePost('/survey/clear_collab_events');

    // -------------------------------------------------------------------

    let channel = get_deployment() + ":" + name;

    const removeDuplicateEvents = (events: ChangeSet) => {
        let eventIDs = new Set<string>();
        let uniqueEvents : ChangeSet = [];
        events?.forEach(e => {
            if (!eventIDs.has(e[2].id)) {
                eventIDs.add(e[2].id);
                uniqueEvents.push(e);
            }
        })

        return uniqueEvents;
    }

    const sendEvent = useChannel<ConfigRealtimeEvents>('distro', channel, (realtimeEvent) => {
        switch(realtimeEvent.event) {
            case 'enable_collab': // Fall through
            case 'disable_collab':
                if (user.uid !== realtimeEvent.data.uid || tabId !== realtimeEvent.data.tabId) {
                    location.reload();
                }
                break;
            case 'saved_survey':
                setChangesSinceLastPublish(prev => realtimeEvent.data.changesSinceLastPublish === undefined ? prev : realtimeEvent.data.changesSinceLastPublish);
                if (realtimeEvent.data.username !== config?.user?.name || realtimeEvent.data.tabId !== tabId) {
                    toast.success(`${realtimeEvent.data.username} just published!`);
                }
                break;
            case 'change':
                if (realtimeEvent.data.events?.length) {
                    setUnsavedCollabChanges(true);
                    setCanPublish(false);
                }

                console.log("Merging", realtimeEvent.data.uuid, realtimeEvent.data.events.length, realtimeEvent.data.userActivity);
                distroRef.current?.mergeEvents(realtimeEvent.data.events, realtimeEvent.data.userActivity);
                setChangesSinceLastPublish(prev => prev + 1);
                break;
            case 'partial_change':
                if (realtimeEvent.data.compressedChunk?.length) {
                    setUnsavedCollabChanges(true);
                    setCanPublish(false);
                }

                const currentlyCollected = eventsAwaitingMerge.current[realtimeEvent.data.uuid] || [];
                const decompressedEvents = decompress(realtimeEvent.data.compressedChunk) as ChangeSet;
                eventsAwaitingMerge.current[realtimeEvent.data.uuid] = [...currentlyCollected, decompressedEvents];

                // When all chunks have come in, we can go ahead and merge.
                if (eventsAwaitingMerge.current[realtimeEvent.data.uuid]?.length === realtimeEvent.data.totalChunks) {
                    const flattenedEvents = eventsAwaitingMerge.current[realtimeEvent.data.uuid].reduce((acc, value) => acc.concat(value), []);
                    console.log("Merging chunked changes", realtimeEvent.data.uuid, flattenedEvents.length, realtimeEvent.data.userActivity);
                    distroRef.current?.mergeEvents(flattenedEvents, realtimeEvent.data.userActivity);
                    eventsAwaitingMerge.current[realtimeEvent.data.uuid] = [];
                    setChangesSinceLastPublish(prev => prev + 1);
                }
                break;
            default: {
                // Ignore unexpected event
                return;
            }
        }

        if (incomingEditsTimer.current) clearTimeout(incomingEditsTimer.current);
        incomingEditsTimer.current = window.setTimeout(() => {
            setCanPublish(true);
        }, 3000);
    });

    const doSendEvents = async (myActivity?: UserActivity) => {
        const toSend = removeDuplicateEvents(eventsToSend.current || []);
        const uuid1 = uuidv4();
        console.log('Sending events', uuid1, toSend);

        // 100 events has a payload length of roughly 30,000. We have a 32,768 length (32 KB) limit on our production websocket API.
        // If we have more than 100 events to send, let's split and compress them.
        if (toSend.length > 100) {
            // we can compress 700 events into ~29,000, which is small enough to send.
            const chunkSize = 700;
            const numChunks = Math.ceil(toSend.length / chunkSize);
            for (let i = 0; i < toSend.length; i += chunkSize) {
                const chunk = toSend.slice(i, i + chunkSize);
                const compressedChunk = compress(chunk);
                sendEvent({
                    realm: 'distro',
                    channel,
                    event: 'partial_change',
                    data: {
                        compressedChunk: compressedChunk,
                        totalChunks: numChunks,
                        userActivity: myActivity,
                        uuid: uuid1
                    }
                });

                // need this at the moment in order for the listening websocket to correctly 
                // recieve eveything. Not sure if this is just a dev thing...
                await new Promise(resolve => setTimeout(resolve, 500));
            }
        } else {
            sendEvent({
                realm: 'distro',
                channel,
                event: 'change',
                data: {
                    events: toSend,
                    userActivity: myActivity,
                    uuid: uuid1
                }
            });
        }
        eventsToSend.current = undefined;
    };

    const handleUpdatesRef = useRef<typeof handleUpdatesInner | null>(null);
    const handleUpdatesInner = (survey: Root, state: State, events: ChangeSet, expanded: Root) => {
        if (events.length && collabEnabled) {
            setCanPublish(false);
            setUnsavedCollabChanges(true);
            if (incomingEditsTimer.current) clearTimeout(incomingEditsTimer.current);

            // we debounce 100 ms, then send events to other listening distros
            if (eventsToSend.current) {
                eventsToSend.current = eventsToSend.current.concat(events);
            } else {
                eventsToSend.current = events;
            }
            if (sendEventsTimer.current) {
                clearTimeout(sendEventsTimer.current);
            }
            sendEventsTimer.current = window.setTimeout(() => {
                doSendEvents(state.myActivity);
            }, 100);

            // we debounce 1 full second, then save events to dynamo
            if (eventsToSave.current) {
                eventsToSave.current = eventsToSave.current.concat(events);
            } else {
                eventsToSave.current = events;
            }
            if (saveEventsTimer.current) {
                clearTimeout(saveEventsTimer.current);
            }
            saveEventsTimer.current = window.setTimeout(() => {
                writeCollabEvents(name, eventsToSave.current);
                eventsToSave.current = undefined;
            }, 1000);

            incomingEditsTimer.current = window.setTimeout(() => {
                setCanPublish(true);
            }, 3000);
        }

        stateRef.current = state;

        if (expanded) {
            setSurvey(expanded);
        } else {
            setSurvey(survey);
        }
    }
    
    handleUpdatesRef.current = handleUpdatesInner;

    // Gross hack because distro doesn't pick up changes
    const handleUpdates = (survey: Root, state: State, events: ChangeSet, expanded: Root) => {
        if (handleUpdatesRef.current) {
            handleUpdatesRef.current(survey, state, events, expanded);
        }
    }

    useEffect(() => {
        if (surveys || loadingSurveys.current) return;
        loadingSurveys.current = true;
        (async () => {
            setSurveys(await listSurveys({}));
            loadingSurveys.current = false;
        })();
    }, []);

    useEffect(() => {
        const matchedPersona: Persona = personas?.find((p) => p.name === activePersonaName) as Persona || {};
        // Clear the active persona if its name changes
        // TODO: Use a stable reference for the persona so we don't need to do this
        if (!matchedPersona.attrs) {
            setActivePersonaName('');
        } else {
            const activePersonaMockInfo: Record<string, string> = {};
            for (let { field, value } of (matchedPersona.attrs || [])) {
                activePersonaMockInfo[field] = value;
            }
            setMockInfo(activePersonaMockInfo);
        }
        
        setRecompute((prevState) => prevState + 1);
    }, [activePersonaName, personas]);

    useEffect(() => {
        const storedShowRightPanel = localStorage.getItem('showRightPanel');
        if (storedShowRightPanel) {
            setShowRightPanel(JSON.parse(storedShowRightPanel));
        }
    }, []);
        
    useEffect(() => {
        localStorage.setItem('showRightPanel', JSON.stringify(showRightPanel));
    }, [showRightPanel]);

    function findFieldMismatches(node: any) {

        function traverseTree(node: any) {
            if (typeof node.en === 'string' && Object.keys(node).length > 1) {

                // Create mapping of language code to array of target fields contained in the text for that language
                const fieldsInLangMap: Record<string, string[]> = {};
                Object.entries(node).forEach(([lang, content]) => {
                    if (lang === '_id') return;
                    fieldsInLangMap[lang] = ((content as string).match(/\$[a-zA-Z\\_][a-zA-Z\\_0-9]+/g) || []) as string[];
                    if (fieldsInLangMap[lang].length === 0) {
                        fieldsInLangMap[lang] = ['[Empty]'];
                    }
                });
                
                // For each language that is not English, compare included fields to those in English, 
                let fieldsMatch = true;
                outer: for (const lang of Object.keys(fieldsInLangMap)) {
                    if (lang === 'en') continue;
                    if (fieldsInLangMap[lang]?.length !== fieldsInLangMap['en']?.length) {
                        fieldsMatch = false;
                        break;
                    }
                    for (let i = 0; i < fieldsInLangMap[lang].length; i++) {
                        if (!(fieldsInLangMap['en'] || []).includes(fieldsInLangMap[lang][i])) {
                            fieldsMatch = false;
                            break outer;
                        }
                    }
                }

                if (!fieldsMatch) {
                    badNodes.push({ node, fieldsInLangMap });
                }
                return;
            }
    
            if (typeof node === 'object') {
                if (Array.isArray(node)) {
                    node.flatMap((subNode) => traverseTree(subNode));
                    return;
                }
    
                Object.values(node).flatMap((subNode) => traverseTree(subNode));
                return;
            }
            return;
        }

        const badNodes: { node: Text, fieldsInLangMap: Record<keyof Text, string[]>}[] = [];
        traverseTree(node);
        setFieldMismatches(badNodes);
    }

    async function load(_n?: string) {
        let n = _n || name;
        if (!n) {
            let _n = prompt('Enter a name to load');
            if (_n) {
                n = _n;
                setName(_n);
            }
        }
        if (!n) {
            return;
        }
        window.location.hash = '#' + n;
        
        let surveyResp = await loadSurvey({ name: n, purpose: "load function called" })
        if (!surveyResp) {
            alert('Error loading survey');
            return;
        }

        let survey = surveyResp.config;

        // TODO: might be better not to expand?
        const expandedSurvey = expandTemplates(survey) as any;
        findFieldMismatches(expandedSurvey);

        setLoadedHash(surveyResp.hash);
        setLatestHash(surveyResp.hash);
        setLastPublished(surveyResp.lastModified);
        // Make sure survey has personas property
        if (!Array.isArray(survey) && !survey?.personas) {
            survey = {...survey, personas: []};
        }

        const collabResp = await refreshCollabEvents({ name: n });
        if (collabResp.collabEnabled) {
            setChangesSinceLastPublish(collabResp.changesSinceLastPublish || 0);
            setCollabEnabled(true);
        } else {
            setCollabEnabled(false);
        }

        function tryLoad() {
            if (distroRef.current) {
                distroRef.current?.initialize(survey, {...user, name: config?.user?.name, tabId }, collabResp.collabEnabled ? collabResp.events : undefined, surveyResp.references);
            } else {
                setTimeout(tryLoad, 200);
            }
        }
        tryLoad();
        //setSurvey(survey);
        setSurveyName(n);
    }

    const reloadSurvey = useCallback(async (syncEvents?: boolean) => {
        if (!surveyName) return;
        let surveyResp = await loadSurvey({ name: surveyName, purpose: "reloadSurvey called" });
        setLatestHash(surveyResp.hash);
        setLastPublished(surveyResp.lastModified);
        setLatestSurvey(surveyResp.config);

        // if we've been inactive in collab mode, we should re-initialize the distro events 
        // to make sure we always have an up to date version
        const currentTime = Date.now();
        const inactivityDuration = currentTime - lastActivityTime.current;
        const doSync = syncEvents || (inactivityDuration > 3 * 60 * 1000);
        if (collabEnabled && doSync) {
            const collabResp = await refreshCollabEvents({ name: surveyName });
            distroRef.current?.mergeEvents(collabResp.events);
            setChangesSinceLastPublish(collabResp.changesSinceLastPublish);
            lastActivityTime.current = Date.now();
        }
    }, [lastActivityTime, surveyName, collabEnabled]);
    useInterval(reloadSurvey, 3000);

    useEffect(() => {
        const handleVisibilityChange = () => {
            // if we are just coming back, reload to make sure we didn't miss anything.
            if (!document.hidden && collabEnabled) {
                reloadSurvey(true);
                doSendEvents(stateRef.current?.myActivity);

            // if we are leaving, remove user presence from the document.
            } else if (collabEnabled && user.uid) {
                doSendEvents({
                    uid: user.uid,
                    name: config?.user?.name,
                    tabId,
                    lastEditedObject: ''
                })
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        const handleActivity = () => {
            lastActivityTime.current = Date.now();
        };
    
        const activityEvents = ['mousemove', 'mousedown', 'keydown', 'touchstart'];
        activityEvents.forEach(event => {
            document.addEventListener(event, handleActivity);
        });

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            activityEvents.forEach(event => {
                document.removeEventListener(event, handleActivity);
            });
        };
    }, [collabEnabled]);
    
    useEffect(() => {
        if (window.location.hash.slice(1)) {
            load(window.location.hash.slice(1));
        }
    }, [user]);
    
    // This makes the bundler include the file that is otherwise just types
    ForceSourceMap();


    useEffect(() => {
        const localSubsurveys = enumerateSubsurveys(Array.isArray(survey) ? survey : survey.survey);
        setSubsurveys(localSubsurveys);

        // TODO: We expand templates a gajillion times
        setExtractedNotifsAndPayments(extractNotificationsAndPayments(expandTemplates(Array.isArray(survey) ? survey : survey.survey) as any, []));
        
        let s: Survey;
        if (!activeSurvey) {
            s = Array.isArray(survey) ? survey : survey.survey;
        } else {
            s = localSubsurveys.find((s) => s.path === activeSurvey)?.sections || [];
        }
        let airtableSurvey = v0ToLegacy(s);
        setTranslated(AirtableSurveyToSurveyDefinition(airtableSurvey) as unknown as Sections);
    }, [survey, activeSurvey]);
    
    async function validate() {
        const results = await validateSurveyRS({
            survey: JSON.stringify(survey)
        });
        return results?.value;
    }

    useEffect(() => {
        if (!canPublish) return;
        if (nextAutosaveTime.current >= Date.now()) return;
        if (!unsavedCollabChanges) return;

        nextAutosaveTime.current = Date.now() + (20 * 1000);
        let saveCollabVersionTimer : NodeJS.Timeout;
        try {
            const prunedEvents = distroRef.current!.getPrunedEvents(stateRef.current!);
            
            // Since everyone's browsers will be ready to save at about the same time (once all changes are merge in),
            // spread the auto save calls out a bit so someone will get there first.
            saveCollabVersionTimer = setTimeout(() => {
                autoSaveCollabVersion({
                    name: surveyName,
                    events: JSON.stringify(prunedEvents)
                });
            }, Math.floor(5 * 1000 * Math.random()));
        } catch (e) {
            alert('This Distro is in a bad state, so events could not be auto-saved. Try refreshing the page and reach out to the tech team if the issue continues.');
        }

        return () => clearTimeout(saveCollabVersionTimer);
    }, [surveyName, canPublish, unsavedCollabChanges]);

    // When not migrating, this should only save to dynamo.
    async function writeCollabEvents(name: string, events?: ChangeSet, migrating?: true) {
        if (migrating) {
            const toSend = JSON.stringify(distroRef.current!.getPrunedEvents(stateRef.current!));
            await autoSaveCollabVersion({
                name,
                events: toSend,
                migrating
            });
        } else {
            const uniqueEvents = removeDuplicateEvents(events || []);
            const toSend = JSON.stringify(uniqueEvents);

            // These collections of events can get big. If they're REALLY big, we don't want to block up dynamo by saving them there. 
            // Instead, lets auto-save immediately.
            if (toSend.length > 100000) {
                nextAutosaveTime.current = Date.now();
                return;
            }

            await saveCollabEvents({
                name,
                events: toSend
            });
        }
    }

    async function enableCollab() {
        if (confirm("This is a new experimental feature. It may not work well, we don't recomend it for surveys/etc people that are live. Continue?")) {
            setEnablingCollab(true);
            await writeCollabEvents(name, undefined, true);
            setEnablingCollab(false);
            setCollabEnabled(true);
            sendEvent({
                realm: 'distro',
                channel,
                event: 'enable_collab',
                data: {
                    ...user, tabId
                }
            });
        }
    }
    
    async function disableCollab() {
        if (confirm("Note that if you haven't published/saved recently this may revert the world to a surprising state. Continue?")) {
            setCollabEnabled(false);
            await clearCollabEvents({ name });
            sendEvent({
                realm: 'distro',
                channel,
                event: 'disable_collab',
                data: {
                    ...user, tabId
                }
            });
        }
    }

    const save = useCallback(async () => {
        let n = name;
        if (!n) {
            let _n = prompt('Enter a name to save');
            if (_n) {
                n = _n;
                setName(_n);
            }
        }
        if (!n) {
            return;
        }
        
        setPublishing(true);
        //validating.current = true;
        try {
            let res = await validate();
            if (res?.errors) {
                setSurveyValidationErrors(res.errors);
                setPublishing(false);
                return;
            }
        } catch (e) {
            console.log(e);
            setPublishing(false);
            return;
        }
        //validating.current = false;

        let surveyResp;
        try {
            surveyResp = await loadSurvey({ name: n, purpose: "checking survey has not changed" });
            if (loadedHash && surveyResp.hash !== loadedHash) {
                let x = confirm("The survey has changed since you first loaded it, do you want to overwrite it?");
                if (!x) {
                    setPublishing(false);
                    return;
                }
            }
        } catch (e) {
            // It's only best effort;
        }

        if (collabEnabled && eventsToSave.current?.length) {
            alert("There are collab changes that still need to sync before you can publish. Please try again.");
        }

        // Check for updates before saving to reduce clutter in versions page
        if (JSON.stringify(surveyResp?.config) !== JSON.stringify(survey)) {
            alert(JSON.stringify(await saveSurvey({
                name: n,
                content: JSON.stringify(survey),
                browserTab: tabId
            })));
        } else {
            alert("Everything's up to date! \n\nNo changes to save.");
        }

        if (collabEnabled) {
            const collabResp = await refreshCollabEvents({ name: n });
            distroRef.current?.mergeEvents(collabResp.events);
            setChangesSinceLastPublish(collabResp.changesSinceLastPublish);

            sendEvent({
                realm: 'distro',
                channel,
                event: 'saved_survey',
                data: {
                    changesSinceLastPublish: collabResp.changesSinceLastPublish,
                    username: config?.user?.name,
                    tabId
                }
            });
        }
        surveyResp = await loadSurvey({ name: n, purpose: "final load after save" });
        setPublishing(false);
        setLoadedHash(surveyResp.hash);
        setLatestHash(surveyResp.hash);
        setLastPublished(surveyResp.lastModified);
    }, [name, collabEnabled, survey, tabId, config, channel]);

    const [showSearch, setShowSearch] = useState(false);
    function doShowSearch() {
        setShowSearch(true);
    }

    async function rename() {
        let n = name;
        let _n = prompt('Enter a new name');
        let doesExist;
        if (_n) {
            n = _n;
            doesExist = await doesSurveyExist({ name: n });

            // If does exist, we want to make sure to default to not saving
            let yesSave = !doesExist;
            if (doesExist) {
                yesSave = confirm(`A survey already exists with name: ${n}\nDo you wish to overwrite it?`);
            }

            if (yesSave) {
                setName(_n);
                setPublishing(true);
                alert(JSON.stringify(await saveSurvey({name: n, content: JSON.stringify(survey),browserTab: tabId})));
                setPublishing(false);
                window.location.hash = '#' + n;
            }
        }
    }

    function injestTranslations() {
        if (translations) {
            importTranslations(survey, translations);
            console.log("Initializing distro with injestTranslations()", survey);
            distroRef.current?.initialize(survey, {...user, name: config?.user?.name, tabId });
            alert("Injected translations, please save!");
        }
    }

    function openTranslationsModal() {
        if (collabEnabled) {
            alert("You must disable collab to edit translations");
            return;
        }
         if (confirm('This will save the current state of your survey. Continue?')) {
            const extractedTranslations = extractDedupedTranslations(survey); 
            console.log("Initializing distro with openTranslationsModal()", survey);
            distroRef.current?.initialize(survey, {...user, name: config?.user?.name, tabId });
            // We need to save state after extracting so the ids persist.
            save();
            setTranslations(extractedTranslations);
            initialTranslations.current = JSON.stringify(extractedTranslations);
            setTranslationModalOpen(true);
        }
    }

    function ConvertToObject() {
        setSurvey({
            survey: survey as any,
            notifications: [],
            personas: [],
        })
    }

    async function loadInfoFromApplicant() {
        const uid = prompt("Please enter a UID of an applicant");
        if (uid) {
            setMockInfo((await getEverything({ uid })).info)
        }
    }

    function CreateNew() {
        distroRef.current?.initialize({
            survey: [],
            personas: [],
        }, {...user, name: config?.user?.name, tabId });
    }

    const personaApi = usePost('/bots/persona', { handleErrors: () => {} });
    const [generating, setGenerating] = useState(false);
    async function createPersona(retries: number = 0, background?: string): Promise<Persona> {
        let result: Persona | { error: string } = null as any;
        try {
            result = await personaApi({ background });
            if('error' in result) {
                throw new Error(result['error']);
            } else {
                return result;
            }
        } catch (e) {
            if(!retries) {
                toast.error('failed to create persona, please try again later', { autoClose: 1000 });
                throw e;
            }
           return await createPersona(Math.max(0, retries - 1)); 
        }
    }


    (window as any).magic = async (data: any) => {
        if(!data.question) {
            throw new Error('no question asked');
        }
        console.info('doing magic with data', data);
        if(data.qualifier?.toLowerCase() === 'persona') {
            const prompt = data.question;
            const makeResult = (content: any) => ({ choices: [{ message: { content: JSON.stringify(content) } }] })
            
            if(isNaN(parseInt(prompt))) {
                return makeResult(await createPersona(10, prompt))
            }

            const persons = Array(parseInt(prompt)).fill(null).map(() => createPersona(10));

            return makeResult(
                (await Promise.allSettled(persons))
                    .filter(r => r.status === 'fulfilled')
                    .map((r: any) => r.value)
            );
        }
        return await doMagic(data);
    }
    async function generateTempPersona() {
        setGenerating(true);
        const persons = parseInt(prompt('how many should I create?', '1')!);
        const personasToMake = Array(persons).fill(null).map(() => createPersona(5));
        const successful = (await Promise.allSettled(personasToMake)).filter(p => p.status === 'fulfilled');
        personas.push(...successful.map((p: any) => p.value));
        setActivePersonaName((successful[0] as any)?.value?.name);
        setGenerating(false);
    }

    const surveyChanged = loadedHash && latestHash && loadedHash !== latestHash;

    const [mockAllDone, setMockAllDone] = useState(false);
    const MockSubmit = async (submit_key?: string, options?: { info: InfoDict } ) => {
        const submitInfo = options?.info ? options.info : mockInfo;
        console.log("Submitting", submitInfo);
        setMockInfo((prevInfo) => ({
            ...prevInfo, 
            [submit_key!]: new Date().toISOString()
        }));
        setMockAllDone(true);
    } 

    const incompatible = getDistroBrowserIncompatibility();
    if (incompatible) return incompatible;

    // This code enables the resizing of the left and right panes
    const [rightPaneWidth, setRightPaneWidth] = useState(33.3);
    const resizingRef = useRef(false);
    const onMouseDown = useCallback(() => {
        resizingRef.current = true;
    }, []);

    const onMouseUp = useCallback(() => {
        resizingRef.current = false;
    }, []);

    const onMouseMove = useCallback(
        (e: React.MouseEvent) => {
            if (!resizingRef.current) return;

            const container = e.currentTarget as HTMLElement;
            const newWidth = ((container.clientWidth - e.clientX) / container.clientWidth) * 100;

            setRightPaneWidth(newWidth);
        },
        [],
    );

    async function reset() {
        const phrase = prompt('What is the passphrase?')
        if (phrase) alert(JSON.stringify(await resetProgram({ passphrase: phrase })));
    }

    return <>
        <UserInfoContext.Provider value={user}>
            <div className={"flex h-14 border-b border-blue-300 z-[60] relative top p-1 " + ((surveyChanged && !collabEnabled) ? 'bg-red-200' : 'bg-blue-200')}>
                <div className="font-bold text-2xl text-blue-400 ml-3 mt-2">Distro:
                    <button onClick={() => rename()} 
                        disabled={publishing}
                        className="border-0 bg-transparent ml-2 mr-3 text-blue-600 hover:text-blue-800 font-normal">
                        {name || '[untitled]'}
                    </button>
                    {!name && <button onClick={CreateNew}>Create New</button>}
                </div>
                <div className="flex-grow z-0 items-center justify-center px-2 py-1">
                    <button className="bg-blue-50 border-green-200 rounded-md text-sm mt-2 p-1 hover:bg-blue-300" onClick={doShowSearch}>Search</button>
                </div>
                <div className="my-1" title={lastPublished ? `${name} Last Published: ${new Date(lastPublished).toLocaleString()}` : ''}>
                    {enablingCollab && <SpacedSpinner className='mr-2' />}
                    {!collabEnabled && <button className={`border-0 rounded-md p-1 ${!enablingCollab && 'hover:bg-blue-300'} bg-blue-100 mr-2`} disabled= {enablingCollab} onClick={enableCollab}>Enable Collab</button>}
                    {collabEnabled && <button className="border-0 rounded-md hover:bg-blue-300 bg-blue-100 mr-2 p-1 " onClick={disableCollab}>Disable Collab</button>}
                    {!collabEnabled && loadedHash && latestHash && loadedHash !== latestHash && "Loaded: " + loadedHash.slice(0, 8)}
                    {!collabEnabled && loadedHash && latestHash && loadedHash !== latestHash && "Survey has changed! If you save you may overwrite someone else's work!"}
                    <Dropdown 
                        color="blue" className="border-0 bg-transparent" colorIntensity={200}
                        label={'Misc'} options={[
                            { label: 'Load Info From Applicant', callback: () => loadInfoFromApplicant() },
                            { label: 'Manage translations', callback: () => openTranslationsModal()},
                            { label: 'Toggle Right Panel', callback: () => setShowRightPanel(!showRightPanel)},
                            { label: 'Reset Program (Delete All Applicants)', callback: () => reset()},
                            { label: 'View Published History', callback: () => window.open(`/config-versions#${name}`, '_blank') },
                            ...(collabEnabled ? [{ label: 'View Auto-Saved History', callback: () => window.open(`/collab-versions#${name}`, '_blank') }] : []),
                            { label: 'Get Scope for User', callback: () => {
                                let user = window.prompt('Enter a user email');
                                if (!user) { alert("Must enter a user."); return; }
                                if (Array.isArray(survey)) { alert("Survey is not a whole survey"); return; }
                                let scope = CollectScopeForUser(EmailToUserId(user), survey as ExpandedSurvey);
                                console.log("scope for user", scope);
                                alert("Check the console.");
                            }},
                            ...(get_deployment().includes('postgres') || get_deployment().includes('demo') ? [{
                                label: 'Force notifications immediately', callback: async () => alert(JSON.stringify(await sendNotifications({})))
                            }] : [])
                        ]} />
                    <Dropdown 
                        color="blue" className="border-0 bg-transparent" colorIntensity={200}
                        label={activeSurvey || 'Global View'} options={[
                            { label: 'Global View', callback: () => setActiveSurvey(null) },
                            ...((subsurveys || []).map((s) => ({
                                label: s.path,
                                callback: () => setActiveSurvey(s.path)
                            })))
                    ]} />
                    {['#entireprogram','#audit'].includes(window.location.hash) ? <Dropdown 
                        color="blue" className="border-0 bg-transparent" colorIntensity={200}
                        label={surveyName ? surveyName : "Surveys"} options={[
                        ...(surveys && Array.isArray(surveys) ? surveys : []).filter(s => !!s).map((s) => ({
                            label: s || '',
                            callback: async () => {
                                let toLoad = s?.replace('dso-' + get_deployment() + '-', '')
                                    .replace('.json', '');
                                window.location.href = '/config#' + toLoad;
                                window.location.reload();
                                //setName(toLoad!);
                                //setActivePersonaName('');
                                //await load(toLoad);
                            }
                        }))
                    ]} /> : null}
                    <FacePile name={config?.user?.name} channel={get_deployment() + ":" + name} browserTab={tabId} unsavedChanges={() => {
                        if (collabEnabled) return false;
                        const latest = JSON.stringify(latestSurvey);
                        const current = JSON.stringify(survey);
                        return latest !== '{}' && current !== '{}' && latest !== current;
                    }} />
                </div>
                <button onClick={save} disabled={publishing|| !canPublish} 
                    className={'border-0 rounded-md ml-2 mr-3 my-1.5 bg-blue-100 p-1.5 ' + (canPublish ? 'text-blue-600 hover:text-blue-800 hover:bg-blue-300' : 'text-gray-400')}>
                    {!publishing ? 
                    <div className="relative inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 m-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>
                        {!!changesSinceLastPublish && <div className="absolute h-5 w-5 flex items-center justify-center -right-4 -top-3 p-1 bg-red-500 rounded-full text-white text-xs">{changesSinceLastPublish}</div>}
                    </div> : 
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 animate-spin" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                    </svg>
                    }
                </button>
            </div>
            {showSearch && <div className="z-100">
                <ConfigSearch survey={(survey as ExpandedSurvey).survey} close={() => setShowSearch(false)} />
            </div>
            }
            <div className="flex" style={{ height: 'calc(100vh - 6.5rem)' }} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                {/* Distro editor */}
                <div className="flex-1 h-auto overflow-y-auto">
                    <Suspense fallback={<h1>Loading Distro</h1>}>
                        <Distro ref={distroRef} types='src/survey.ts' name='Root' migrations={MIGRATIONS} onChange={handleUpdates as (survey: Root, state: State, events: ChangeSet, expanded: any) => void} macros={MACROS} programConfig={publicConfiguration}/>
                    </Suspense>
                </div>
                {/* Resizing bar */}
                <div className="cursor-col-resize w-3" onMouseDown={onMouseDown}>
                    <div className="w-1 bg-gray-100 m-auto h-full"/>
                </div>
                {/* Preview/Right pane */}
                <div className={"h-auto overflow-y-auto" + (showRightPanel ? '' : ' hidden') } style={{ width: `${rightPaneWidth}%`}}>
                {Array.isArray(survey) && <button onClick={ConvertToObject}>Convert to Object</button>}
                <div className="my-2">
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 bg-transparent inline-block w-auto m-1 p-0 ' + (selectedPreview === 'survey' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('survey')}
                    >
                        Survey
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'notifications' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('notifications')}
                    >
                        Notifications
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'payments' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('payments')}
                    >
                        Payments
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'targetFields' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('targetFields')}
                    >
                        Target Fields
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'graph' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('graph')}
                    >
                        Graph
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'dashboards' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('dashboards')}
                    >
                        Dashboards
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'testCases' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('testCases')}
                    >
                        TestCases 
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'testCases' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('robonav')}
                    >
                        RoboNav 
                    </button>
                    <button
                        className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'changes' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('changes')}
                    >
                        Changes 
                    </button>
                    <button
                            className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'fieldMismatches' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('fieldMismatches')}
                    >
                        {'Field Mismatches {' + fieldMismatches.length + '}'}
                    </button>
                    {(!Array.isArray(survey) && survey.config?.comms?.automaticMessageHandler) ? <button className={'border-b-2 border-l-0 border-r-0 border-t-0 inline-block bg-transparent w-auto m-1 p-0 ' + (selectedPreview === 'amh' ? 'text-blue-600 border-blue-600' : 'text-gray-400 border-transparent')}
                        onClick={() => setSelectedPreview('amh')}
                        >Automatic Message Handler</button> : null}
                </div>

                {selectedPreview === 'targetFields' &&
                    <div className='mx-3'>
                        <h2>Target Fields <ClickableButton onClick={() => { setMockInfo({}) }} color="blue">Clear Fields</ClickableButton></h2>
                        {Object.keys(mockInfo || {}).length > 0 ? 
                        <table className='w-full'>
                            <thead>
                                <tr>
                                    <th>Field Name</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(mockInfo).map((k) =>
                                    <tr>
                                        <td className='border border-gray-300 px-2'>{k}</td>
                                        <td className='border border-gray-300 px-2'>{mockInfo[k] ? mockInfo[k] : ''}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table> : <p>No fields entered yet</p>}
                    </div>
                }

                {selectedPreview === 'notifications' &&
                    <TaskEditor tasks={[...(Array.isArray(survey) ? [] : survey.notifications || []), ...extractedNotifsAndPayments[0]]} /> }

                {selectedPreview === 'payments' &&
                    <PaymentsEditor payments={[...(Array.isArray(survey) ? [] : survey.payments || []), ...extractedNotifsAndPayments[1]]} /> }

                {selectedPreview === 'amh' && 
                    <AutomaticMessageHandler amh={Array.isArray(survey) ? {} as any : survey.config?.comms?.automaticMessageHandler} />}

                {selectedPreview === 'survey' &&
                    <div className="flex justify-between items-center text-gray-600 mt-2 mb-1 mr-3 ml-1">
                        <button 
                            className="h-9 rounded border-2 px-2.5 py-1.5 cursor-pointer bg-gray-100 hover:bg-gray-200 transition-all font-medium border border-gray-500"
                            onClick={() => setShowPersonaModal(true)}
                        >
                            {!activePersonaName ? "Save as New Persona" : "Update Persona Or Save as New"}
                        </button>
                        {!!personas && <div className="flex">
                        <button
                            onClick={generateTempPersona}
                            className={classNames(
                                "h-9 rounded-l px-2.5 py-1.5 cursor-pointer bg-gray-100 hover:bg-gray-200 transition-all font-medium border border-gray-500",
                                generating ? "from-green-300 to-green-800 bg-gradient-to-b animate-pulse" : null,
                            )}
                            >
                                <BeakerIcon className={classNames(
                                    "w-5 h-5 text-indigo-800"
                                )} />
                        </button>
                        <select
                            id="persona-selector"
                            value={activePersonaName}
                            disabled={!personas.length}
                            onChange={(e) => {
                                setActivePersonaName(e.target.value)
                                if (!e.target.value) setMockInfo({});
                            }}
                            className={
                                "h-9 rounded-r border-2 px-2.5 py-1.5 transition-all " +
                                (!activePersonaName
                                  ? "bg-gray-100 hover:bg-gray-100 border-gray-500 border font-medium "
                                  : "bg-emerald-500 hover:bg-emerald-500 border-emerald-600 text-white font-semibold shadow-sm ") +
                                (personas.length ? "cursor-pointer " : "")
                              }
                        >
                            {!activePersonaName ? (
                                <option value="" disabled>View as Persona</option>
                            ) : (
                                <option value="">Clear Persona</option>
                            )}
                            {personas?.map((p, i) => (
                                <option key={i} value={p.name}>{p.name}</option>
                            ))}
                        </select></div>}
                    </div>
                }

                {surveyValidationErrors && 
                    <SurveyErrorsModal 
                        errors={surveyValidationErrors}
                        closeModal={() => setSurveyValidationErrors(undefined)}
                    />
                }

                {showPersonaModal && (
                    <PersonaModal 
                    state={stateRef.current as State}
                    mockInfo={mockInfo}
                    showModal={showPersonaModal}
                    setShowModal={setShowPersonaModal}
                    activePersonaName={activePersonaName}
                    setActivePersonaName={setActivePersonaName}
                    />
                )}

                {selectedPreview === 'graph' &&
                    <div className="w-full h-full">
                        <CytoscapeComponent
                            layout={{
                                name: 'elk',
                                elk: {
                                    algorithm: 'layered',
                                    'elk.direction': 'RIGHT',
                                    'elk.layered.spacing.edgeEdgeBetweenLayers': 10,
                                    'elk.layered.spacing.edgeNodeBetweenLayers': 20,
                                    'elk.spacing.nodeNode': 40,
                                }
                            } as any}
                            stylesheet={[
                                {
                                    selector: 'node',
                                    style: {
                                        'background-color': 'data(color)',
                                        'label': 'data(id)'
                                    }
                                },
                                {
                                    selector: 'edge',
                                    style: {
                                        'mid-target-arrow-color': '#900',
                                        'mid-target-arrow-shape': 'triangle',
                                        'target-distance-from-node': 2,
                                        'arrow-scale': 1,
                                    }
                                }
                            ]}
                            elements={getComputedGraph(Array.isArray(survey) ? {} as any : survey)}
                            style={{ width: '100%', height: '100%' }}
                        />
                    </div>}
                
                {selectedPreview === 'dashboards' &&
                    <DashboardDetail dashboards={Array.isArray(survey) ? {} as any : survey.survey.filter(component => component.kind === 'Dashboard')} />
                }

                {selectedPreview === 'robonav' &&
                    <RoboNavConsole surveys={Array.isArray(survey) ? [] : enumerateSubsurveys(survey.survey)} personas={Array.isArray(survey) ? [] : survey.personas} />}

                {selectedPreview === 'testCases' &&
                    <TestCases survey={Array.isArray(survey) ? {} as any : survey} />}

                {selectedPreview === 'changes' &&
                    <Changes current={latestSurvey} updated={survey} />}

                {selectedPreview === 'fieldMismatches' &&
                    <FieldMismatches fieldMismatches={fieldMismatches}/>}

                <AuthContext.Provider value={{
                    localId: () => {
                        return 'foo';
                    },
                    token: () => {
                        return token.replace('auth=','');
                    },
                    setToken: (token) => {},
                    setLocalId: (id) => {}
                    }}>
                    {mockAllDone && 
                        <AllDoneComponent content={<></>} />
                    }
                    {translated && 
                        <div className={selectedPreview === 'survey' ? '' : 'hidden'}>
                            {(mockViewer === 'applicant' ?
                                <ModularQuestionPage 
                                    sections={translated as any} 
                                    info={mockInfo} 
                                    setInfo={(info) => {
                                        setMockInfo(info);
                                    }}
                                    submit={MockSubmit}
                                    saveInfo={async () => { }}
                                    saveAuth={() => {}}
                                    noHistory={true}
                                    sequential={true} 
                                    recompute={recompute}
                                    /> 
                                :  
                                <ModularQuestionPage 
                                    sections={translated as any}
                                    info={mockInfo}
                                    setInfo={setMockInfo}
                                    saveInfo={async () => { }}
                                    saveAuth={() => {}}
                                    noHistory={true}
                                    sequential={true} />
                            )}
                        </div>}
                </AuthContext.Provider>
                </div>
            </div>
            <TranslationModal 
                translations={translations} 
                setTranslations={setTranslations}
                translationModalOpen={translationModalOpen} 
                setTranslationModalOpen={setTranslationModalOpen}
                injestTranslations={injestTranslations}
                initialTranslations={initialTranslations}/>
        </UserInfoContext.Provider>
    </>
}

/**
 * Displays a list of instances in the Distro config where for a given piece of content, the target fields in one language
 * do not match those in another language.
 */
function FieldMismatches(props: { fieldMismatches: { node: Text, fieldsInLangMap: Record<string, string[]> }[] }): JSX.Element {
    
    if (props.fieldMismatches.length === 0) {
        return <div>No mismatches 🎉</div>
    }

    return (
        <div>
            {props.fieldMismatches.map((mismatch, i) => 
                <div key={mismatch.node._id || mismatch.node.en.length + '_' + i} className='p-2'>
                    <h4>ID: {(mismatch.node as any)['_id'] ?? '[None]'}</h4>
                    {Object.keys(mismatch.fieldsInLangMap).map((langKey) =>
                        <div className='flex'>
                            {/* Languages and their fields */}
                            <div className='px-2 border-r-2 mr-2'>
                                <div key={(mismatch.node._id || mismatch.node.en.length + '_' + i) + '_fm_' + langKey}>
                                    <strong>{supportedLanguages[langKey as keyof Text]}</strong>
                                    {mismatch.fieldsInLangMap[langKey].map((field, index) =>
                                        <div key={(mismatch.node._id || mismatch.node.en.length + '_' + i) + '_f_' + langKey + '_' + field + '_' + index}
                                            className='pl-2'>
                                            <span style={field === '[Empty]' ? { backgroundColor: 'moccasin', fontWeight: 'bold' } : {}}>{field}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Languages and their content, with target fields highlighted */}
                            <div key={(mismatch.node._id || mismatch.node.en.length + '_' + i) + '_t_' + langKey}>
                                <br />
                                {langKey !== '_id' && highlightSearchTerm((mismatch.node as any)[langKey], mismatch.fieldsInLangMap[langKey].map(field => field.slice(1)), { wholeMatchOnly: true, matchCase: true })}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
