import React, { Suspense, useRef, useState } from "react";
import { useMarkdown } from "./Util";

export type Object = {
    kind: "Object",
    name: string,
    properties: {
        key: string,
        optional: boolean
        /**
         * @width 4
         */
        value: Type
    }[] 
}

export type List = {
    kind: "List",
    name: string,
    /**
     * @width 4
     */
    elements: Type
}

export type DynamicString = {
    kind: "Dynamic String"
}

export type DynamicNumber = {
    kind: "Dynamic Number"
}

export type DynamicBoolean = {
    kind: "Dynamic Boolean"
}

export type FixedNumber = {
    kind: "Fixed Number",
    /**
     * @width 3
     */
    value: number
}

export type FixedString = {
    kind: "Fixed String"
    /**
     * @width 3
     */
    value: string
}

export type FixedBoolean = {
    kind: "Fixed Boolean"
    /**
     * @width 3
     */
    value: boolean
}


export type OneOfType = {
    kind: "One Of"
    /**
     * @width 4
     */
    types: Type[]
}

export type Type = Object | List | DynamicString | DynamicNumber | DynamicBoolean | FixedNumber | FixedString | FixedBoolean | OneOfType;

export type TypeGeneratorInput = Type[];
export function generateTypeScriptTypes(types: TypeGeneratorInput, prefix = ""): string {
    const typeDefinitions: string[] = [];

    function generateType(type: Type): string {
        switch (type.kind) {
            case "Object":
                const properties = type.properties
                    .map(
                        (property) =>
                            `
  /**
   * @width 4
   */
  "${property.key}"${property.optional ? "?" : ""}: ${generateType(property.value)}`
                    )
                    .join(";\n");
                if (type.name) {
                    typeDefinitions.push(`export type ${type.name.replaceAll(' ', '')} = {\n${properties}\n};`);
                    return type.name.replaceAll(' ', '');
                } else {
                    return `{\n${properties}\n}`;
                }
            case "List":
                if (type.name) {
                    typeDefinitions.push(`export type ${type.name.replaceAll(' ', '')} = ${generateType(type.elements)}[]`);
                    return type.name.replaceAll(' ', '');
                } else {
                    return `${generateType(type.elements)}[]`;
                }
            case "Dynamic String":
                return "string";
            case "Dynamic Number":
                return "number";
            case "Dynamic Boolean":
                return "boolean";
            case "Fixed Number":
                return `${type.value}`;
            case "Fixed String":
                return `"${type.value}"`;
            case "Fixed Boolean":
                return `${type.value}`;
            case "One Of":
                return type.types
                    .map((t, i) => generateType(t))
                    .join(" | ");
        }
    }

    types.forEach((type, index) => {
        generateType(type);
    });

    return typeDefinitions.join("\n\n");
}

const INITIAL = {
    kind: 'Object',
    name: 'Program Design',
    properties: [{"key":"Operating Model","optional":false,"value":{"kind":"One Of","types":[{"kind":"Object","name":"Lottery","properties":[{"key":"Treatment Size","optional":false,"value":{"kind":"Dynamic Number"}},{"key":"Control Size","optional":false,"value":{"kind":"Dynamic Number"}}]},{"kind":"Fixed String","value":"First Come First Serve"}]}},{"key":"Payment Types","optional":false,"value":{"kind":"Object","name":"","properties":[{"key":"ACH","optional":false,"value":{"kind":"Dynamic Boolean"}},{"key":"Debit Card","optional":false,"value":{"kind":"Dynamic Boolean"}}]}}] 
}

const Distro = React.lazy(() => import("@aidkitorg/typesheets/lib/distroeditor"));

export function DistroTest(props: { value: Type }) {
    const Root: Type = {
        kind: 'Object',
        name: 'Root',
        properties: [{
            key: 'test',
            optional: true,
            value: props.value
        }]
    }

    const [state, setState] = useState<any>({ foo: 'bar'});

    return <div className="p-4 container-sm">
        <Suspense>
            <Distro 
                key={generateTypeScriptTypes([Root as Type])}
                value={state}
                types={generateTypeScriptTypes([Root as Type])} name='Root' onChange={setState}/>
        </Suspense>
    </div>;
}


export function Distro4DistroPage() {
    const [state, setState] = useState<Type>(INITIAL as Type);

    return <div className="p-4 container-sm">
        <Suspense>
            <Distro 
                value={state}
                types='Distro4Distro.tsx' name='Type' onChange={setState}/>
            <DistroTest value={state}/>
            <pre>
                {generateTypeScriptTypes([state])}
            </pre>
        </Suspense>
    </div>;
}
