import * as types from '@aidkitorg/airtable/src/types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAPIPost } from '../API';
import { ClickableButton } from '../Components/Button';
import InterfaceContext, { ConfigurationContext } from '../Context';
import { AidKitLogo, languageContent, SpacedSpinner, safeParse } from '../Util';
import { QuestionProps } from './Props';
import { useModularMarkdown } from '../Hooks/ModularMarkdown';

export function Assignee(props: QuestionProps) { 
    const assignee_id = props.info[props["Target Field"]!];

    const getAssignee = useAPIPost('/user/' + assignee_id + '/whois');
    const context = useContext(InterfaceContext);
    const content = useModularMarkdown({
        content: props[languageContent(context.lang)] || '',
        info: props.info,
        replacer: (str) => str.replace(/<p>/g, '').replace(/<\/p>/g, '')
    });

    const metadata = safeParse(props.Metadata || '{}') as types.Assignee['Metadata'];
    const config = useContext(ConfigurationContext);

    const [assignee, setAssignee] = useState({} as Record<string, string>);

    // If the user has admin role, they can unassign.. unless 
    // the can_unassign_unless metadata key is set.
    let canUnassign = config.user && assignee_id && (config.roles || '').indexOf('admin') !== -1
    if (canUnassign && metadata?.can_unassign_unless && props.info[metadata.can_unassign_unless]) {
        // If this applicant has the can_unassign_unless metadata key set, they can't be unassigned.
        canUnassign = false;
    }

    useEffect(() => {
        if (!assignee_id) return;

        (async () => {
            setAssignee(await getAssignee({}));
        })();
    }, [assignee_id]);

    if ((props["Additional Options"] || []).indexOf("Hidden") !== -1) {
        return <></>
    }

    return (
        <div className="relative">
            <div className="px-2">
                <div className="flex h-8 w-full rounded-t-lg border-b-2 border-blue-800 bg-blue-800 shadow-lg">
                    <div className="mx-auto my-auto font-medium py-auto items-center text-sm tracking-tight">
                        <span style={{ color: '#eee', textShadow: '1px 1px #000' }}>
                            {content}
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex h-12 w-full py-auto rounded-lg bg-white shadow-xl">
                <div className="mx-auto my-auto text-lg font-medium text-gray-700">{
                    assignee_id ?
                    assignee && assignee['name'] ? assignee['name'] : <SpacedSpinner />
                    : "Unassigned"
                }</div>
            </div>
            {canUnassign && <div className="mt-2 text-right"><ClickableButton color="gray" onClick={() => {
                    if (!canUnassign) return;
                    props.setInfoKey(props["Target Field"]!, '', true, false);
                    // we don't go back because 
                    // if we go back set info will not work correctly.
            }}>Unassign</ClickableButton></div>}
            {false && <div className="absolute top-2 left-6 h-16 w-16 rounded-full border-2 border-white shadow-md">
                {false && <AidKitLogo height={16} width={16} />}
            </div>}
        </div>
    );
}

export function CompleteAssignment(props: QuestionProps) {
    const assignment = props["Target Field"]!;
    const assignee_id = props.info[assignment];

    const context = useContext(InterfaceContext);
    const [done, setDone] = useState(false);
    const content = useModularMarkdown({
        content: props[languageContent(context.lang)] || '',
        info: props.info
    })
    
    // Automatically move the reviewer back to their dashboard if they 
    // click I'm done and are no longer assigned to this applicant.
    useEffect(() => {
        if (done && !assignee_id) {
            window.location.pathname = '/';
        }
    }, [done, assignee_id]);

    if (!assignee_id) return <></>;

    return (
        <fieldset>
            <ClickableButton onClick={async () => { 
                props.setInfoKey(assignment, '', true, false);
                setDone(true);  
            }} color="blue">
                {content}
            </ClickableButton>
        </fieldset>
    );
}