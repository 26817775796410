"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetectFaces = void 0;
var neverthrow_1 = require("neverthrow");
var face_library_1 = require("../../util/face_library");
exports.DetectFaces = {
    getSlugs: function (params) {
        return (0, neverthrow_1.ok)({ 'slugs': [
                '_face',
                '_duplicates',
                '_related',
                '_unindexed_reason',
                '_conversion'
            ] });
    },
    compute: function (params) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var key, value, uid, ids, updates, config, collectionId_1, id0lower, prev_face, hashFaceResponse, indexedFaceId, allMatchesResponse, allMatches_1, matches, relatedMatches, match, _b, matchUid, matchKey, matchDeployment, metadata, related;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        console.debug("Detecting faces for field ".concat(params.targetField));
                        if (!params.targetField.endsWith('_face')) {
                            // only compute once.
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        if (!params.conn) {
                            console.log("Cannot detect faces without a connection.");
                            // This is for optimistic compute, so don't return an error.
                            // Just don't compute this field. (It will be computed when called via the API)
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        key = params.targetField.replace(/\_face$/, '').replace(/\_duplicates$/, '').replace(/\_related$/, '').replace(/\_unindexed\_reason$/, '');
                        value = params.newInfoKeys[key];
                        uid = params.newInfoKeys['uid'];
                        if (!uid) {
                            console.log("Cannot detect faces without a uid.");
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        ids = (',' + (value || '')).split(",https:").filter(function (url) { return url && url.startsWith('//'); }).map(function (url) { return "https:".concat(url); });
                        updates = {};
                        console.log("Face IDs: ", ids);
                        if (!(ids.length > 0 && ids[0] !== '')) return [3 /*break*/, 6];
                        return [4 /*yield*/, params.configPromise];
                    case 1:
                        config = (_d.sent()) || {};
                        collectionId_1 = JSON.parse(config['fraud'] || '{}').useSpecificFaceCollection || params.deploymentKey;
                        id0lower = ids[0].toLowerCase();
                        if (((_a = params.computeOptions) === null || _a === void 0 ? void 0 : _a.skipHeics) && (id0lower.endsWith('heic') || id0lower.endsWith('pdf'))) {
                            console.log("Skipping HEIC/PDF image for face detection: ", ids[0]);
                            return [2 /*return*/, (0, neverthrow_1.ok)({})];
                        }
                        prev_face = params.newInfoKeys[key + '_face'];
                        if (!(prev_face && prev_face.indexOf('https') === -1)) return [3 /*break*/, 3];
                        // delete the face
                        return [4 /*yield*/, (0, face_library_1.deleteFace)(prev_face, collectionId_1, params.deploymentKey)];
                    case 2:
                        // delete the face
                        _d.sent();
                        _d.label = 3;
                    case 3: return [4 /*yield*/, (0, face_library_1.hashFaceAtUrl)({
                            config: config,
                            deploymentKey: params.deploymentKey,
                            url: ids[0],
                            uid: uid,
                            key: key,
                            aidkitCrypto: params.aidkitCrypto
                        })];
                    case 4:
                        hashFaceResponse = _d.sent();
                        if (hashFaceResponse.isErr()) {
                            console.debug("Error hashing face at URL: ".concat(ids[0], ". Error:"), hashFaceResponse.error);
                            return [2 /*return*/, (0, neverthrow_1.ok)((_c = {},
                                    _c[key + '_face'] = '',
                                    _c[key + "_unindexed_reason"] = hashFaceResponse.error,
                                    _c[key + "_conversion"] = '',
                                    _c))];
                        }
                        // If there was a conversion, save that to _conversion
                        if (hashFaceResponse.value.conversion) {
                            updates[key + '_conversion'] = hashFaceResponse.value.conversion;
                        }
                        console.debug("Hashed face at URL successfully: ", hashFaceResponse.value);
                        if (hashFaceResponse.value.unindexedReasons && hashFaceResponse.value.unindexedReasons.length > 0) {
                            updates[key + '_unindexed_reason'] = hashFaceResponse.value.unindexedReasons.map(function (r) { return r.replace(/[0-9]/g, '*'); }).join(',');
                            updates[key + '_face'] = '';
                            return [2 /*return*/, (0, neverthrow_1.ok)(updates)];
                        }
                        indexedFaceId = hashFaceResponse.value.indexedFaceId;
                        updates[key + '_face'] = indexedFaceId;
                        updates[key + '_unindexed_reason'] = '';
                        console.log("Searching face in collection: ", indexedFaceId, collectionId_1);
                        return [4 /*yield*/, (0, face_library_1.searchFaceInCollection)(indexedFaceId, collectionId_1)];
                    case 5:
                        allMatchesResponse = _d.sent();
                        allMatches_1 = {};
                        if (allMatchesResponse.isOk()) {
                            Object.assign(allMatches_1, allMatchesResponse.value);
                        }
                        matches = [];
                        relatedMatches = [];
                        for (match in allMatches_1) {
                            _b = __read(match.split(':'), 3), matchUid = _b[0], matchKey = _b[1], matchDeployment = _b[2];
                            if (matchUid !== uid) {
                                matches.push(match);
                            }
                            if (matchUid === uid && matchKey && matchKey !== key) {
                                relatedMatches.push(match);
                            }
                        }
                        if (matches.length > 0) {
                            metadata = {
                                // 'related' is of the form collectionId:uid:targetField:deployment
                                "related": matches.map(function (match) { return collectionId_1 + ":" + match; }),
                                "flag": "duplicate_face"
                            };
                            updates[key + '_duplicates'] = JSON.stringify(metadata);
                        }
                        else {
                            updates[key + '_duplicates'] = '';
                        }
                        if (relatedMatches.length > 0) {
                            related = relatedMatches.map(function (match) { return match.split(':')[1] + ":" + allMatches_1[match]['FaceId']; }).join(',');
                            updates[key + '_related'] = related;
                        }
                        else {
                            updates[key + '_related'] = '';
                        }
                        return [3 /*break*/, 7];
                    case 6:
                        updates[key + '_face'] = '';
                        updates[key + '_duplicates'] = '';
                        updates[key + '_related'] = '';
                        updates[key + '_unindexed_reason'] = '';
                        updates[key + '_conversion'] = '';
                        _d.label = 7;
                    case 7:
                        console.log("Returning updates for detect faces:", updates);
                        return [2 /*return*/, (0, neverthrow_1.ok)(updates)];
                }
            });
        });
    }
};
