import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Default action here just redirects to /apply
 * Launch page might further be configured to show a splash screen or something in the future
 */
export default function LaunchPage() {
    const history = useHistory();
    useEffect(() => {
        history.push('/apply');
    }, [history]);

    return <></>
}