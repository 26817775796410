// Helper functions for conditional content used in both:
// * Frontend useModularMarkdown and
// * AidKitTS Speak getWhatToSay

import { evalDistroConditional } from '../eval';
import * as v0 from '../survey';

export default function buildConditionalContent(props: {
    uid?: string,
    info: Record<string, string | undefined>,
    content: string | v0.Content,
    lang: keyof v0.Text
}) {
    const { content, info, lang } = props;

    const markedPieces: string[] = [];
    if (Array.isArray(content)) {
        type T = v0.RichText | v0.ConditionalContent;
        type C = v0.ConditionalContent;
        let addContent = (content: T) => {
            if ((content as C).conditional) {
                // Map components
                let passing = evalDistroConditional((content as C).conditional, info);
                if (passing) {
                    let components = (content as C).components;
                    if (Array.isArray(components)) {
                        for (const component of components) {
                            addContent(component);
                        }
                    } else {
                        addContent(components);
                    }
                } else {
                    let otherwise = (content as C).otherwise;
                    if (otherwise) {
                        if (Array.isArray(otherwise)) {
                            for (const component of otherwise) {
                                addContent(component);
                            }
                        } else {
                            addContent(otherwise);
                        }
                    }
                }
            } else {
                let text = content as v0.RichText;
                markedPieces.push(text[lang] || text.en || '');
            }
        }
        content.forEach(addContent);
    } else {
        markedPieces.push(typeof content === 'string' ? content : (content[lang] || content.en || ''));
    }

    return markedPieces;

}
