import type * as v0 from "@aidkitorg/types/lib/survey";
import { useLocalizedStrings } from "../Localization";
import { useModularMarkdown } from "../Hooks/ModularMarkdown";
import { useContext, useState, useEffect, useRef, useMemo, useCallback } from "react";
import InterfaceContext, { AuthContext } from "../Context";
import { ClickableButton } from "../Components/Button";
import { usePost } from "../API";
import { toast } from "react-toastify";
import { PaperAirplaneIcon, ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { SpacedSpinner, prettyPhone, safeParse } from "../Util";
import { SupportedLanguage } from "@aidkitorg/i18n/lib";

export const LIVENESS_REQUEST_EVENT_TYPE = 'LIVENESS_REQUEST';

export function LivenessDetectionQuestion(props: { 
  component: v0.LivenessDetection,
  uid: string,
  info: Record<string, string | undefined>,
  setInfo: (key: string, value: string) => void,
  refreshInfo?: () => void
}) {
  const L = useLocalizedStrings();
  const context = useContext(InterfaceContext);

  const content = useModularMarkdown({
      content: props.component.content,
      info: props.info
  });

  const doneContent = useModularMarkdown({
    content: props.component.completionContent || "&#127881; **" + L.questions.identity.identity_verification_passed + "** &#127881;",
    info: props.info
  })

  /** 
   * Liveness Session Mode Configuration
   * - options: Open in new tab, Send link, or Scan QR Code (TBD)  
   **/
  const [linkSent, setLinkSent] = useState(null as null | "email" | "sms");
  const [linkSentTo, setLinkSentTo] = useState('');
  const [mode, setMode] = useState<"link" | "new_window">((window.innerHeight > window.innerWidth) ? "new_window" : "link");
  const [modeDecided, setModeDecided] = useState<null | "link" | "new_window">(null);
  const [windowOpened, setWindowOpened] = useState<Window | null>(null);
  const [allowDesktopWebcam, setAllowDesktopWebcam] = useState(false);
  useEffect(() => {
    (async () => {
      // only allow desktop webcam if they actually have one
      if (props.component.allowDesktopWebcam) {
        const devices = await navigator?.mediaDevices?.enumerateDevices();
        setAllowDesktopWebcam(devices?.some(d => d.kind === 'videoinput'));
      }
    })();
  }, [props.component.allowDesktopWebcam]);

  const sendLivenessLink = usePost("/applicant/send_liveness_link");
  const getLivenessData = usePost("/applicant/get_liveness_data");
  const initiateLivenessSession = usePost("/applicant/initiate_liveness_session");

  const initiatingSession = useRef(false);

  useEffect(() => {
    const cb = async () => {
      if (!(props.info[props.component.targetField] || '').startsWith('pending')) return;
      if (document.visibilityState === 'hidden') return;
      const resp = await getLivenessData({ applicantUid: props.uid });
      
      if (resp?.stage === 'done') {
        props.refreshInfo?.();
      }
    }

    if (allowDesktopWebcam) {
      setMode("new_window");
    }

    const onResizeSetMode = () => {
      if (allowDesktopWebcam) return;
      if (window.innerHeight > window.innerWidth) {
        setMode("new_window");
      } else {
        setMode("link");
      }
    }

    window.addEventListener('resize', onResizeSetMode);
    const interval = setInterval(cb, 3000);
    return () => { 
      window.removeEventListener('resize', onResizeSetMode);
      clearInterval(interval);
    }
  }, [props.uid, allowDesktopWebcam, document.visibilityState, props.info?.[props.component.targetField]]);

  useEffect(() => {
    if (!windowOpened) return;

    const checkData = async () => {
      if (!(props.info[props.component.targetField] || '').startsWith('pending')) return;
      if (document.visibilityState === 'hidden') return;
      const resp = await getLivenessData({ applicantUid: props.uid });
      
      if (resp?.stage === 'done') {
        props.refreshInfo?.();
      }
    }

    const startLivenessSession = async () => {
      initiatingSession.current = true;
      const resp = await initiateLivenessSession({ surveyNodeId: props.component.id, lang: props.info.language as SupportedLanguage || context.lang, applicantUid: props.uid });
      if((resp as any)?.error){
        windowOpened.close();
      }
      else if (resp?.link && windowOpened) {
        windowOpened.location = resp.link;
      }
      props.setInfo(props.component.targetField, 'pending:window');
      initiatingSession.current = false;  
    }
    startLivenessSession();

    function onRequestNewLivenessURL(e:MessageEvent<string>){
      // ensure this event is coming from us
      if (e.origin !== window.location.origin) {
        return;
      }
      const { type } = typeof e.data === 'object' ? e.data : safeParse(e.data);
      // restart the liveness session
      if (type === LIVENESS_REQUEST_EVENT_TYPE) {
        startLivenessSession();
      }
    }

    let interval = setInterval(async () => { 
      if (windowOpened.closed) {
        await checkData();
        setWindowOpened(null);
      }
    }, 1000);
    // listen for postMessage events from liveness page requesting a new URL
    window.addEventListener('message', onRequestNewLivenessURL);
    return () => {
      clearInterval(interval);
      window.removeEventListener('message', onRequestNewLivenessURL);
    }
  }, [windowOpened, props.component.id, props.component.targetField, props.uid, props.info?.[props.component.targetField], props.info.language, props.uid]);

  return <>
      <fieldset>
      {props.info[props.component.targetField] && !props.info[props.component.targetField]!.includes('pending') ? 
        <>
          <legend>{doneContent}</legend>
        </> : 
        <>
          <legend>
              {content}
          </legend>
          
          {(linkSent || windowOpened) ? 
            <div className="rounded-md bg-blue-50 p-2 items-center">
              <div className="flex">
                <div className="flex-shrink-0">
                  {linkSent 
                    ? <PaperAirplaneIcon className="h-8 text-blue-300" />
                    : <ArrowsRightLeftIcon className="h-8 text-blue-300" />}
                </div>
                <div className="ml-2 items-center">
                  <h3 className="text-sm font-medium text-blue-800">
                    {(linkSent && linkSentTo)
                      ? (linkSent === 'email' 
                          ? <span>{L.applicant.link_sent_check_your_email.replace('{}',linkSentTo)}</span> 
                          : <span>{L.applicant.link_sent_check_your_phone.replace('{}', prettyPhone(linkSentTo))}</span>)
                      : <span>{L.selfie.we_opened_a_new_tab}</span>}
                  </h3>
                </div>
              </div>
            </div> : null}

          {!windowOpened && !linkSent && mode === 'new_window' && (
            <div className="mb-2">
              <label htmlFor="modedecider" className="block text-sm font-medium leading-6">
                {allowDesktopWebcam
                  ? <span>{L.selfie.how_would_you_like_to_complete}</span> 
                  : <span>{L.selfie.identity_verification_requires_a_phone}</span>}
              </label>
              <select 
                className="mr-2 mt-1 shadow-sm block w-full overflow-hidden pl-3 bg-white pr-2 py-2 text-black text-base border-2 border-gray-200 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" 
                onChange={(e) => {
                  setModeDecided(e.target.value as "link" | "new_window");
                }}
                defaultValue={modeDecided ?? ""} >
                <option value="">{L.selfie.click_here_to_choose}</option>
                <option value="link">{allowDesktopWebcam 
                  ? <span>{L.selfie.send_me_a_link_option}</span>
                  : <span>{L.selfie.no_send_a_link}</span>}</option>
                <option value="new_window">{allowDesktopWebcam
                  ? <span>{L.selfie.open_a_new_tab_option}</span>
                  : <span>{L.selfie.i_am_on_mobile}</span>}</option>
                {/** placeholder for Scanning a QR Code option */}
              </select>
            </div>)}

          {!linkSent && (mode === "link" || modeDecided === "link") &&
            <div className="flex-col">
              <ClickableButton color="indigo" onClick={async (e) => {
                e.preventDefault();
                initiatingSession.current = true;
                const resp = await sendLivenessLink({ 
                  surveyNodeId: props.component.id, 
                  lang: props.info?.language as SupportedLanguage || context.lang,
                  applicantUid: props.uid
                });
                if (resp?.ok) {
                  toast.success((resp.ok === 'email' ? L.applicant.link_sent_check_your_email : L.applicant.link_sent_check_your_phone).replace('{}',resp.contact));
                  setLinkSent(resp.ok);
                  setLinkSentTo(resp.contact);
                  setWindowOpened(null);
                }
                initiatingSession.current = false;
                props.setInfo(props.component.targetField, 'pending:' + resp.ok);
              }}>
                {initiatingSession.current && <SpacedSpinner />}<span>{allowDesktopWebcam ? <span>{L.selfie.begin}</span> : <span>{L.selfie.send_me_a_link}</span>}</span>
              </ClickableButton>
            </div>}
           
          {!windowOpened && modeDecided === 'new_window' && <div className="flex-col">
            <ClickableButton color="indigo" onClick={async (e) => {
                e.preventDefault();
                setWindowOpened(window.open("", "_blank"));
            }}>
              {initiatingSession.current && <SpacedSpinner />}<span>
                {allowDesktopWebcam ? <span>{L.selfie.begin}</span> : <span>{L.selfie.open_a_new_tab}</span>}
              </span>
            </ClickableButton>
          </div>
          }
          
      </>}
      </fieldset>
  </>
}
