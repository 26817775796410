// import { createLogger, DefaultColors, DefaultStyles, LogLevel } from "./pretty-logger";

const {
    info,
    warn,
    error,
    debug,
    assert
} = console;


// const DevColors: Record<LogLevel, string> = {
//     info: 'lightgreen',
//     warn: 'lightorange',
//     error: 'lightred',
//     debug: 'lightgray',
//     assert: 'gray'
// };

// const dev = process.env.NODE_ENV === 'development' ? createLogger(
//     'DEV Offline Sync',
//     level => [
//         `background: ${DevColors[level]}`,
//         `border-radius: 0.5em`,
//         `color: black`,
//         `font-weight: bold`,
//         `padding: 2px 0.5em`,
//     ]
// ) : {
//     info: () => {},
//     warn: () => {},
//     error: () => {},
//     debug: () => {},
//     assert: () => {}
// };

const dev = console;

export {
    info,
    warn,
    error,
    debug,
    assert,
    dev
};
