import React, { useState } from "react";
import { QuestionProps } from "./Props";
import { usePost } from "../API";
import { safeParse } from "@aidkitorg/roboscreener/lib/util/json";
import { toast } from "react-toastify";

export function InlineNotification(props: QuestionProps) {
	const issueNotif = usePost('/program/admin/issue_notification');
	const [sending, setSending] = useState(false);
	const { name } = safeParse(props.Metadata || '{}');

	async function sendNotification() {
    // Using a callback here guarantees that multiple clicks will not cause multiple API calls
    // because React automatically queues the set requests and executes them sequentially.
		setSending((currentSending) => {
			if (currentSending) {
				console.log("Already clicked");
				return currentSending;
			}

			if (!props['Target Field']) {
				toast.error('Inline notification missing target field');
				return currentSending;
			}

			if (!props.uid) {
				toast.error('Inline notification missing applicant uid');
				return currentSending;
			}

			(async () => {
				await issueNotif({
					target_field: props['Target Field']!,
					applicant: props.uid!,
				});
				setSending(false);
			})();

			return true;
		});
	}

	return (
		<div className='w-full'>
			<h3>{name}</h3>
			{(props.info[props['Target Field']!] || sending)
				? <div className='my-1.5'>{sending ? 'Sending notification...' : 'Notification sent' }</div>
				: (
						<button
							onClick={sendNotification}
							type='button'
							disabled={sending}
						className='inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 my-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
						>
							{'Send ' + (props['Target Field']?.endsWith('_sms') ? 'SMS' : 'Email')}
						</button>
					)
			}
    </div>
  );
}
