// Write a React component to query the AI using usePost with /program/admin/magic

import { useState } from "react"
import { toast } from "react-toastify";
import { usePost } from "../API"
import { ClickableButton } from "../Components/Button";
import { copyToClipboard, SpacedSpinner } from "../Util";

export const AIQueries = () => {
    const [fields, setFields] = useState('');
    const [filters, setFilters] = useState('');
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('')
    const [loading, setLoading] = useState(false)
    
    const post = usePost('/program/admin/magicsql');
    
    return (
        <div>
        <div className="flex flex-col space-y-2 justify-start my-4 bg-gray-50 rounded-md p-4">
            <label>Enter the fields you want to return separated by commas.</label>
            <input
                type="text"
                className="bg-white focus:outline focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                value={fields}
                onChange={(e) => setFields(e.target.value)}
            />
        </div>
        <div className="flex flex-col space-y-2 justify-start my-4 bg-gray-50 rounded-md p-4">
            <label>{`Enter any filters you want to filter on using plain english with any relevant keys. e.g. submitted >= '2022-01-01'`}</label>
            <input
                type="text"
                className="bg-white focus:outline focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                value={filters}
                onChange={(e) => setFilters(e.target.value)}
            />
        </div>
        <div className="flex flex-start justify-start my-4">
            <ClickableButton disabled={loading} color="green" onClick={async () => {
                setLoading(true)
                const data = await post({ fields, filters });
                if (!data) { toast.error('Error querying AI'); }
                setResponse(data.text || '');
                setPrompt(data.prompt);
                setLoading(false)
            }}>{loading && <SpacedSpinner />}Query</ClickableButton>
        </div>
        <h3>Response will show below</h3>
        
        {response && <div className="py-4">
            <p>Note this is essentially GPT3 and is not as good as GPT4, if this is not sufficient please see below.</p>
            <div className="bg-gray-800 rounded-md mb-4">
                <div className="flex items-center relative text-gray-200 bg-aidkit-blue px-4 py-2 text-xs font-sans justify-between rounded-t-md">
                    <span>SQL</span>
                    <button onClick={() => copyToClipboard(response, "Copied!")} className="flex ml-auto gap-2">
                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                        Copy
                    </button>
                </div>
                <div className="p-4 overflow-y-auto">
                    <code className="!whitespace-pre text-blue-50">{response}</code> 
                </div>
            </div>
            <p>Try the prompt with GPT4 to see if it works:</p>
            <div className="bg-gray-800 rounded-md mb-4">
                <div className="flex items-center relative text-gray-200 bg-aidkit-blue px-4 py-2 text-xs font-sans justify-between rounded-t-md">
                    <span>Prompt</span>
                    <button onClick={() => copyToClipboard(prompt, "Copied!")} className="flex ml-auto gap-2">
                        <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                        Copy
                    </button>
                </div>
                <div className="p-4 overflow-y-auto">
                    <code className="!whitespace-pre text-blue-50">{prompt}</code> 
                </div>
            </div>
        </div>}
        </div>
    )
}