import React, { useState, useEffect, useContext } from "react";
import { useMarkdown } from "./Util";
import { useAPIPost } from "./API";
import InterfaceContext, { ConfigurationContext } from "./Context";
import { RCPaymentTimeline } from "./RCPayments";
import { DashboardPage } from "./Apply";

function getAPICall() {
  return "/applicants/check_status";
}

function StatusPage() {
  const config = useContext(ConfigurationContext);
  const [page, setPage] = useState(<></>);

  useEffect(() => {
    if (!config || Object.keys(config).length === 0) return;
    if (config.tailwind_dashboard === 'true') {
      setPage(<DashboardPage />);
    } else {
      setPage(<LegacyStatusPage />);
    }
  }, [config]);

  return page;
}

function LegacyStatusPage() {
  const params = new URLSearchParams(window.location.search);
  const key = params.get("key");
  const check_status = useAPIPost(getAPICall());
  const [resp, setResp] = useState({} as any);
  const context = useContext(InterfaceContext);
  const config = useContext(ConfigurationContext);

  const pairs = context.lang === 'es' ? resp.pairs_es : resp.pairs;
  const info = context.lang === 'es' ? resp.info_es : resp.info;

  const content = useMarkdown(info);

  useEffect(() => {
    (async () => {
      const resp = await check_status({
        status_token: key,
      });
      setResp(resp || {});
    })();
  }, [check_status, key]);

  return (
    <div>
      <br />
      <br />
      <h1>{context.lang === 'es' ? resp.header_es : resp.header }</h1>
      {config.enable_robocontroller && resp.payment_info && resp.payment_info.map((p: any) => <RCPaymentTimeline actions={p}/>)}
      {pairs &&
        pairs.map((k: any) => {
          return (
            <div>
              <b>{k[0]}: </b>
              {k[1]}
            </div>
          );
        })}
        <br />
        <p>{info && content}</p>
    </div>
  );
}

export default StatusPage;
