import { useContext, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { usePost } from "../API";
import { LanguageDropdown } from "../Components/LanguageDropdown";
import InterfaceContext, { ConfigurationContext } from "../Context";
import { useLocalizedStrings } from "../Localization";
import { AidKitLogo } from "../Util";

export function ForbiddenPage(props: any) {
    let { forbiddenType } = useParams() as { forbiddenType: 'expired' | 'inaccessible' };

    const configuration = useContext(ConfigurationContext);
    const context = useContext(InterfaceContext);

    const params = new URLSearchParams(window.location.search);
    const path = params.get('path');
    const [copy, setCopy] = useState(context.forbiddenCopy?.[path || '']);

    const getCopy = usePost('/subsurvey/forbidden_copy');
    useEffect(() => {
        console.log(context.forbiddenCopy);
        if (!path) return;
        (async () => {
            const copy = await getCopy({ form_name: path });
            if (copy[forbiddenType]) {
                setCopy(copy[forbiddenType] as any);
            } else if (copy['expired']) {
                setCopy(copy['expired'] as any);
            }
        })();
    }, [forbiddenType, path]);


    const L = useLocalizedStrings();
    return (
        <>
        <div className="min-h-full flex flex-col bg-indigo-100" style={{minHeight: '100vh'}}>
            <Navbar className="flex justify-content-between bg-white">
                <Navbar.Brand>
                    <AidKitLogo width={100} height={50}/>
                </Navbar.Brand>
                <Nav className="flex flex-end">
                    <LanguageDropdown languages={(configuration.languages || 'en,es').split(',')}/>
                </Nav>
            </Navbar>
            <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 flex justify-center">
                {forbiddenType === 'expired' ? 
                    <svg width="150" height="150" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
                        <g fill="#2a2c60">
                            <path d="m370.65 225.36h-28.414v118.21h-77.523v28.414h105.94z"/>
                            <path d="m553.83 391.02c7.832-42.562 1.8477-86.516-17.074-125.43-18.926-38.918-49.797-70.766-88.109-90.891s-82.055-27.473-124.84-20.969-82.367 26.516-112.97 57.117c-30.602 30.602-50.613 70.184-57.117 112.97s0.84375 86.527 20.969 124.84 51.973 69.184 90.891 88.109c38.918 18.922 82.871 24.906 125.43 17.074 20.184 27.117 51.164 44.113 84.879 46.57 33.715 2.457 66.832-9.8672 90.738-33.77 23.902-23.906 36.227-57.023 33.77-90.738-2.457-33.715-19.453-64.695-46.57-84.879zm-199.52 137.76c-46.273 0-90.648-18.379-123.37-51.098s-51.102-77.098-51.102-123.37c0-46.273 18.383-90.648 51.102-123.37s77.094-51.102 123.37-51.102c46.27 0 90.648 18.383 123.37 51.102s51.098 77.094 51.098 123.37c0.035156 7.2461-0.375 14.492-1.2305 21.688-28.41-11.547-60.234-11.406-88.543 0.39453s-50.809 34.301-62.609 62.609-11.941 60.133-0.39453 88.543c-7.1953 0.85547-14.441 1.2656-21.688 1.2305zm129.86 43.379v0.003906c-23.34 0-45.723-9.2734-62.227-25.777-16.5-16.508-25.77-38.891-25.766-62.23 0.003907-23.34 9.2812-45.723 25.789-62.223 16.508-16.496 38.895-25.762 62.234-25.754 23.34 0.007813 45.719 9.2891 62.215 25.801 16.496 16.512 25.758 38.898 25.746 62.238-0.039062 23.312-9.3164 45.66-25.801 62.145s-38.832 25.762-62.145 25.801z"/>
                            <path d="m470.01 423.22h28.414v88.797h-28.414z"/>
                            <path d="m470.01 522.67h28.414v27.23h-28.414z"/>
                        </g>
                    </svg> : <></>}
                {forbiddenType === 'inaccessible' ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="none" viewBox="0 0 24 24" stroke="#2a2c60" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg> : <></>}
            </div>
            <div className="py-2">
                <div className="text-center">
                    <p className="text-md font-semibold text-indigo-600 uppercase tracking-wide">
                        {L.ahh.sorry_about_this}
                    </p>
                    <p className="mt-2 text-base font-medium text-gray-900">
                        {forbiddenType === 'inaccessible' ? 
                            copy?.[context.lang] || L.ahh.inaccessible
                         : <></>}
                        {forbiddenType === 'expired' ? 
                            copy?.[context.lang] || L.ahh.expired : <></>}
                    </p>
                </div>
            </div>
            </main>
        </div>
        </>
        )
}