export function cropCanvasToDataURL(canvas: HTMLCanvasElement, ctx?: CanvasRenderingContext2D) {
    if (!ctx) ctx = canvas.getContext('2d')!;
    let w = canvas.width, h = canvas.height,
        pix = {x: [] as number[], y: [] as number[]},
        imageData = ctx.getImageData(0,0,canvas.width,canvas.height),
        x, y, index;
    
    for (y = 0; y < h; y++) {
        for (x = 0; x < w; x++) {
            index = (y * w + x) * 4;
            if (imageData.data[index] != 255 && imageData.data[index+1] != 255 && imageData.data[index+2] != 255) {
                pix.x.push(x);
                pix.y.push(y);
            } 
        }
    }
    pix.x.sort(function(a,b){return a-b});
    pix.y.sort(function(a,b){return a-b});
    var n = pix.x.length-1;
    
    w = 1 + pix.x[n] - pix.x[0];
    h = 1 + pix.y[n] - pix.y[0];
    var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);
    
    canvas.width = w;
    canvas.height = h;
    ctx.putImageData(cut, 0, 0);

    return canvas.toDataURL();
}