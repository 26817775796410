import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CheckIcon, ArrowPathIcon, EnvelopeIcon, HashtagIcon, ExclamationCircleIcon, ExclamationTriangleIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import { useAPIPost, usePost } from "./API";
import { useLocalizedStrings } from "./Localization";
import { snakeToEnglish, useInterval } from "./Util";
import { toast } from "react-toastify";
import InterfaceContext, { ConfigurationContext } from "./Context";
import moment from "moment";
import type { ActionDetails, RCActions, Unarray, Event, RCEntry, Action } from "aidkit/lib/payments/rctypes";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

function isCancellable(action: Action) {
    if (action.dwolla_float_ach_grant_extra) {
        // Can cancel ACH transactions when they have yet to be approved OR they still do not have a dwolla transaction
        return action.state === 'PROPOSED' || !action.dwolla_float_ach_grant_extra.dwolla_transaction;
    } else {
        return ['PENDING', 'PROPOSED'].includes(action.state);
    }
}

function CardLoader(props: { action: ActionDetails }) {
    const params = new URLSearchParams(window.location.search);
    const key = params.get("key");

    const [cardNumber, setCardNumber] = React.useState('');
    function sanitizeCardNumber(num: string) {
        setCardNumber(num.replace(/[^0-9]/g, '').substring(0, 4));
    }
    const associateCard = useAPIPost('/applicants/rc_associate_mailed_card');
    async function saveCard() {
        const result = await associateCard({
            token: key || 'not_applicable',
            action_id: props.action.id,
            last4: cardNumber
        });
        if (result.error === 'card_mismatch') {
            alert("I'm sorry, that's not the correct 4 digits");
        }
        if (result.associated === true) {
            window.location.reload();
        }
    }

    const checkStatus = useAPIPost('/applicants/rc_card_status');
    const [resp, setResp] = useState({} as any);
    const L = useLocalizedStrings();

    useEffect(() => {
        (async () => {
        const resp = await checkStatus({
            token: key || 'not_applicable',
            action_id: props.action.id
        });
        setResp(resp);
        })();
    }, [checkStatus, key]);

    if (props.action.state === 'FAILED') {
        return <div className="text-sm">
            <p>{L.status_page.payment_cancelled}</p>
        </div>
    }

    return (
        <div className="text-sm">
            {resp && (resp.status === 'Pre-Active' || (!props.action.usio_mailed_grant_extra?.grant_source_received_card_id && !props.action.load_usio_card_extra?.card_id)) &&
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-2 mb-1">
                    <div className="flex">
                        <div className="flex-shrink-0">
                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                        {resp.status === 'Pre-Active' && <p className="text-sm text-yellow-700 mb-0">{L.status_page.please_activate}</p>}
                        {(!props.action.usio_mailed_grant_extra?.grant_source_received_card_id && !props.action.load_usio_card_extra?.card_id) &&
                        <>
                        <div className="max-w-xs pb-1">
                            <p>{L.status_page.enter_last4}</p>
                            <p>{L.status_page.we_will_load}{(props.action.usio_mailed_grant_extra?.grant_amount!/100).toFixed(2)}</p>
                        </div>
                        <div className="w-40">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {L.status_page.last4}
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm border-0">
                            <div className="border-0 relative flex items-stretch flex-grow focus-within:z-10">
                                <input
                                type="email"
                                name="email"
                                id="email"
                                value={cardNumber}
                                onChange={(e) => sanitizeCardNumber(e.target.value)}
                                className="border-solid border focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-2 sm:text-sm border-gray-300"
                                placeholder="0000"
                                />
                            </div>
                            <button
                                type="button"
                                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                onClick={saveCard}
                            >
                                <span>{L.status_page.submit}</span>
                            </button>
                            </div>
                            </div>
                        </>}
                        </div>
                    </div>
                </div>}
    </div>
    )
}

function Timeline(props: { timeline: Event[] }) {
    const L = useLocalizedStrings();
    const cancelPayment = usePost(`/payments/cancel_rc_action`);
    const config = useContext(ConfigurationContext); 

    if (props.timeline.length === 0) return <></>;

    return (
        <div className="px-3 pt-2 flex">
            <div className="w-full">
                {/* References and dates are always the same in a mini Timeline. (see RCPaymentTimeline, where it is created.) So we only show date / refrence once. */}
                <time className="mb-1 text-sm font-normal leading-none text-gray-400">{props.timeline[0].date}</time>
                <h3 className="text-lg font-semibold text-gray-900">{props.timeline[0].reference ? transformRefToEnglish(props.timeline[0].reference) : ''}{' '}</h3>
                <ul className="relative border-s border-gray-200">
                    {props.timeline.map((event, eventIdx) => (
                    <li key={event.id} className="mb-2 ms-4">
                        <div 
                            className={classNames(
                                event.iconBackground,
                                'absolute w-5 h-5 bg-gray-200 rounded-full mt-1.5 -start-2.5 flex justify-center items-center'
                                )}>
                            <event.icon className="h-4 w-4 text-white" aria-hidden="true" />
                        </div>
                        <div className="flex flex-row items-baseline justify-between">
                            <p className="text-base font-normal text-gray-500 ">{event.content}{' '}</p>
                            {(config.roles || '').includes('admin') && !!event.cancellable &&
                                <button 
                                    className="bg-gray-50 text-gray-500 text-sm py-1 px-2 my-2 ml-3 border border-gray-400 rounded hover:bg-gray-200"
                                    onClick={async () => {
                                        let res: any = await cancelPayment({
                                            kind: event.kind,
                                            actionID: event.action_id });
                                        if (res && !res?.error) {
                                            toast.success('Cancelled payment successfully');
                                        }
                                    }}>
                                    { L.status_page.cancel }
                                </button>
                            }
                        </div>
                        <div className="mb-2">{event.other}</div>
                    </li>
                    ))}
                </ul>
            </div>
        </div>
       
    )
}


function RCPayment(props: { entry: Unarray<NonNullable<RCActions['ledger_by_unique_name']>['entries']>}) {
    const L = useLocalizedStrings();
    const { lang } = useContext(InterfaceContext);
    
    if (props.entry.action.western_union_grant_extra) {
        let timeline: Event[] = [];
        for (const tx of props.entry.action.western_union_grant_extra.wu_transaction || []) {
            timeline.push({
                id: 0,
                reference: props.entry.action.western_union_grant_extra.reference,
                content: L.payment_timeline.wu_sent,
                date: tx.scraped_data["Order Date"],
                datetime: tx.scraped_data["Order Date"],
                icon: EnvelopeIcon,
                iconBackground: 'bg-green-400',
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind,
                cancellable: isCancellable(props.entry.action)

            })
            if (tx.scraped_data["WU MTCN"]) {
                timeline.push({
                    id: 1,
                    reference: props.entry.action.western_union_grant_extra.reference,
                    content: L.payment_timeline.mtcn_issued + (props.entry.action.state === 'FAILED' ? L.payment_timeline.expired : tx.scraped_data['WU MTCN']),
                    date: '',
                    datetime: '',
                    icon: HashtagIcon,
                    iconBackground: 'bg-green-400',
                    state: props.entry.action.state,
                    action_id: props.entry.action.id,
                    kind: props.entry.action.kind
                })
                if (tx.scraped_data["WU Pick-up Status"] === 'Paid') {
                    timeline.push({
                        id: 13,
                        reference: props.entry.action.western_union_grant_extra.reference,
                        content: L.payment_timeline.picked_up + tx.scraped_data["WU Pick-up Location City"],
                        date: tx.scraped_data['WU Pick-up Date and Time'] ?? '',
                        datetime: tx.scraped_data['WU Pick-up Date and Time'] ?? '',
                        icon: CheckIcon,
                        iconBackground: 'bg-green-400',
                        state: props.entry.action.state,
                        action_id: props.entry.action.id,
                        kind: props.entry.action.kind
                    });
                } else {
                    if (props.entry.action.state === 'FAILED') {
                        timeline.push({
                            id: 14,
                            reference: props.entry.action.western_union_grant_extra.reference,
                            content: L.payment_timeline.wu_returned,
                            date: '',
                            datetime: '',
                            icon: ExclamationCircleIcon,
                            iconBackground: 'bg-red-400',
                            state: props.entry.action.state,
                            action_id: props.entry.action.id,
                            kind: props.entry.action.kind
                        });
                    } else {
                        timeline.push({
                            id: 15,
                            reference: props.entry.action.western_union_grant_extra.reference,
                            content: L.payment_timeline.not_picked_up,
                            date: '',
                            datetime: '',
                            icon: props.entry.action.state === 'CANCELLED' ? ExclamationCircleIcon : ArrowPathIcon,
                            iconBackground: props.entry.action.state === 'CANCELLED' ? 'bg-red-400' : 'bg-yellow-400',
                            state: props.entry.action.state,
                            action_id: props.entry.action.id,
                            kind: props.entry.action.kind,
                            cancellable: isCancellable(props.entry.action)
                        });
                    }
                }
            } else {
                timeline.push({
                    id: 2,
                    reference: props.entry.action.western_union_grant_extra.reference,
                    content: L.payment_timeline.mtcn_waiting,
                    date: '',
                    datetime: '',
                    icon: props.entry.action.state === 'CANCELLED' ? ExclamationCircleIcon : ArrowPathIcon,
                    iconBackground: props.entry.action.state === 'CANCELLED' ? 'bg-red-400' : 'bg-yellow-400',
                    state: props.entry.action.state,
                    action_id: props.entry.action.id,
                    kind: props.entry.action.kind,
                    cancellable: isCancellable(props.entry.action)
                })
            }
            break;
        }
        return <><Timeline timeline={timeline} /></>
    } else if (props.entry.action.usio_mailed_grant_extra) {
        let timeline: Event[] = [];
        timeline.push({
            id: 100,
            reference: props.entry.action.usio_mailed_grant_extra.reference,
            date: new Date(props.entry.action.created as unknown as string).toLocaleString(),
            content: props.entry.action.usio_mailed_grant_extra.grant_source_sent_card_id
                ? L.payment_timeline.usio_mailed + ' for $' + (props.entry.action.usio_mailed_grant_extra.grant_amount/100.0).toFixed()
                : L.payment_timeline.usio_mail_pending_load + ' for $' + (props.entry.action.usio_mailed_grant_extra.grant_amount/100.0).toFixed(),
            datetime: props.entry.action.created as unknown as string,
            icon: props.entry.action.state === 'CANCELLED' ? ExclamationCircleIcon : EnvelopeIcon,
            iconBackground: props.entry.action.state === 'CANCELLED' ? 'bg-red-400' : 'bg-green-400',
            state: props.entry.action.state,
            action_id: props.entry.action.id,
            kind: props.entry.action.kind,
            cancellable: isCancellable(props.entry.action)
        })
        return <><Timeline timeline={timeline} /></>
    } else if (props.entry.action.load_usio_card_extra) {
        let timeline: Event[] = [];
        if (props.entry.action.load_usio_card_extra.load_confirmation) {
            timeline.push({
                id: 101,
                reference: props.entry.action.load_usio_card_extra.reference,
                content: L.payment_timeline.usio_loaded.replace('$amount', '$' + (props.entry.action.load_usio_card_extra.grant_amount/100.0).toFixed()),
                date: new Date(props.entry.action.created as unknown as string).toLocaleString(),
                datetime: props.entry.action.created as unknown as string,
                icon: CheckIcon,
                iconBackground: 'bg-green-400',
                other: <CardLoader action={props.entry.action} />,
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind,
                cancellable: isCancellable(props.entry.action)
            })
        } else {
            timeline.push({
                id: 101,
                reference: props.entry.action.load_usio_card_extra.reference,
                content: L.payment_timeline.usio_pending_load.replace('$amount', props.entry.action.load_usio_card_extra.grant_amount.toString()),
                date: new Date(props.entry.action.created as unknown as string).toLocaleString(),
                datetime: props.entry.action.created as unknown as string,
                icon: props.entry.action.state === 'CANCELLED' ? ExclamationCircleIcon : ArrowPathIcon,
                iconBackground: props.entry.action.state === 'CANCELLED' ? 'bg-red-400' : 'bg-yellow-400',
                other: <CardLoader action={props.entry.action} />,
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind,
                cancellable: isCancellable(props.entry.action)
            })
        }
        return <><Timeline timeline={timeline} /></>
    } else if (props.entry.action.dwolla_float_ach_grant_extra) {
        let timeline: Event[] = [];
        if (props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction) {
            timeline.push({
                id: 7,
                reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                date: new Date(props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction.dwolla_data.created).toLocaleString(),
                content: L.payment_timeline.ach_sent + ' for $' + (parseInt(props.entry.action.dwolla_float_ach_grant_extra.grant_amount)/100.0).toFixed(),
                datetime: '',
                icon: EnvelopeIcon,
                iconBackground: 'bg-green-400',
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind,
                cancellable: isCancellable(props.entry.action)
            });
            if (props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction.dwolla_data.achDetails?.destination.traceId) {
                timeline.push({
                    id: 8,
                    reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                    content: L.payment_timeline.trace_id_issued +
                        props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction.dwolla_data.achDetails.destination.traceId,
                    date: '',
                    datetime: '',
                    icon: HashtagIcon,
                    iconBackground: 'bg-green-400',
                    state: props.entry.action.state,
                    action_id: props.entry.action.id,
                    kind: props.entry.action.kind
                });
            }
            if (props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction.dwolla_data.status === 'pending') {
                timeline.push({
                    id: 9,
                    reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                    content: L.payment_timeline.ach_pending,
                    date: '',
                    datetime: '',
                    icon: ArrowPathIcon,
                    iconBackground: 'bg-yellow-400',
                    state: props.entry.action.state,
                    action_id: props.entry.action.id,
                    kind: props.entry.action.kind
                });
            }
            if (props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction.dwolla_data.status === 'failed') {
                timeline.push({
                    id: 10,
                    reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                    content: L.payment_timeline.ach_failed,
                    date: '',
                    datetime: '',
                    icon: ExclamationCircleIcon,
                    iconBackground: 'bg-red-400',
                    state: props.entry.action.state,
                    action_id: props.entry.action.id,
                    kind: props.entry.action.kind
                });
            }
            if (props.entry.action.dwolla_float_ach_grant_extra.dwolla_transaction.dwolla_data.status === 'processed') {
                timeline.push({
                    id: 11,
                    reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                    content: L.payment_timeline.ach_complete,
                    date: '',
                    datetime: '',
                    icon: CheckIcon,
                    iconBackground: 'bg-green-400',
                    state: props.entry.action.state,
                    action_id: props.entry.action.id,
                    kind: props.entry.action.kind
                });
            }
        } else if (props.entry.action.state === 'FAILED') {
            timeline.push({
                id: 12,
                reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                content: L.payment_timeline.ach_failed_duplicate,
                date: '',
                datetime: '',
                icon: ExclamationCircleIcon,
                iconBackground: 'bg-red-400',
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind
            });
        } else if (props.entry.action.state === 'CANCELLED') {
            timeline.push({
                id: 16,
                reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                content: L.payment_timeline.ach_cancelled,
                date: '',
                datetime: '',
                icon: ExclamationCircleIcon,
                iconBackground: 'bg-red-400',
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind
            });
        } else {
            timeline.push({
                id: 6,
                reference: props.entry.action.dwolla_float_ach_grant_extra.reference,
                content: L.payment_timeline.ach_waiting,
                date: '',
                datetime: '',
                icon: ArrowPathIcon,
                iconBackground: 'bg-yellow-400',
                state: props.entry.action.state,
                action_id: props.entry.action.id,
                kind: props.entry.action.kind,
                cancellable: isCancellable(props.entry.action)
            });
        }
        return <><Timeline timeline={timeline} /></>
    } else if(props.entry.action.kind === 'dynamic' && props.entry.action.dynamic_kind.startsWith('givecard')) {
        let timeline: Event[] = [];
        const kind = props.entry.action.dynamic_kind;
        if(kind === 'givecard_load') {
            switch(props.entry.action.state.toUpperCase()) {
                case 'COMPLETE': 
                    timeline.push({
                        id: 401,
                        reference: props.entry.action.dynamic_reference,
                        content: L.payment_timeline.givecard_load.replaceAll('$amount', '$' + (parseFloat(props.entry.amount) / 100) as any),
                        date: moment(props.entry.action.created).lang(lang).toLocaleString(),
                        datetime: moment(props.entry.action.created).lang(lang).toLocaleString(),
                        icon: CheckIcon,
                        iconBackground: 'bg-green-400',
                        state: props.entry.action.state,
                        action_id: props.entry.action.id,
                        kind: props.entry.action.kind
                    });
                    break;
                default: 
                    timeline.push({
                        id: 401,
                        reference: props.entry.action.dynamic_reference,
                        content: L.payment_timeline.givecard_load.replaceAll('$amount', '$' + (parseFloat(props.entry.amount) / 100) as any),
                        date: moment(props.entry.action.created).lang(lang).toLocaleString(),
                        datetime: moment(props.entry.action.created).lang(lang).toLocaleString(),
                        icon: ArrowPathIcon,
                        iconBackground: 'bg-yellow-400',
                        state: props.entry.action.state,
                        action_id: props.entry.action.id,
                        kind: props.entry.action.kind,
                        cancellable: isCancellable(props.entry.action)
                    });
                    break;
            }
        }

        return <><Timeline timeline={timeline} /></>
    } else {
        return <></>
    }
}

export const transformRefToEnglish = (reference: string) => {
    const parts = reference.split(':')
    const newName = snakeToEnglish(parts[2] ?? reference);
    return newName;
};

export function RCPaymentTimeline(props: { actions: RCActions }) {
    const L = useLocalizedStrings();
    const [paymentsToDisplay, setPaymentsToDisplay] = useState<RCEntry[][]>([]);
    const [expandedMap, setExpandedMap] = useState<boolean[]>([]);
    const [numberToDisplay, setNumberToDisplay] = useState(1);

    useEffect(() => {
        if (props.actions.ledger_by_unique_name?.entries) {
            let sortedEntries = props.actions.ledger_by_unique_name.entries.sort((a, b) => {
                if (a.action.created > b.action.created) {
                    return -1;
                } else if (a.action.created < b.action.created) {
                    return 1;
                } else {
                    return 0;
                }
            });
    
            const hashMap: Map<string, typeof sortedEntries> = new Map();
    
            sortedEntries.forEach((entry) => {
                // if entry is for a negative amount, that is us returning money to our account. 
                // so we don't need to log this as an attempt to pay the applicant.
                if (entry.amount && parseInt(entry.amount) < 0) {
                    return;
                }
                const grantExtra = entry.action?.western_union_grant_extra 
                    || entry.action?.dwolla_float_ach_grant_extra 
                    || entry.action?.usio_mailed_grant_extra 
                    || entry.action?.load_usio_card_extra;

                const reference = grantExtra?.reference || entry.action?.dynamic_reference;
    
                if (!reference) {
                    return;
                }
    
                const key = transformRefToEnglish(reference);
                const newEntry = { ...entry };
                hashMap.set(key, [...hashMap.get(key) || [], newEntry]);
            });
            
            hashMap.forEach((entries, key) => {
                entries.forEach((entry, index) => {
                    const copiedEntry = JSON.parse(JSON.stringify(entry)) as RCEntry;
                    const attemptNum = entries.length - index;
                    const newReference = (entries.length === 1 || index === entries.length - 1)
                        ? key
                        : `${key} (Attempt ${attemptNum})`;

                    // givecard reference is stored in dynamic_reference, NOT in the metadata.
                    if (copiedEntry.action?.dynamic_metadata) {
                        copiedEntry.action.dynamic_reference = newReference;
                    } else {
                        const grantExtra = copiedEntry.action?.western_union_grant_extra
                        || copiedEntry.action?.dwolla_float_ach_grant_extra
                        || copiedEntry.action?.usio_mailed_grant_extra
                        || copiedEntry.action?.load_usio_card_extra
                        || copiedEntry.action?.dynamic_metadata;

                        grantExtra.reference = newReference;
                    }

                    entries[index] = copiedEntry;
                });
            });
    
            const hashMapValues = Array.from(hashMap.values()).map(entries => entries as RCEntry[]);
            setPaymentsToDisplay(hashMapValues);
        }
    }, [props.actions]);

    useEffect(() => {
        setExpandedMap(paymentsToDisplay.map(e => false));
    }, [paymentsToDisplay]);

    if (paymentsToDisplay.length > 0) {
        return (
            <>
                {paymentsToDisplay.slice(0, numberToDisplay).map((e, i) => {
                    return (
                        <ul key={i} className="min-h-0 mb-3 rounded border border-gray-100 bg-gray-50 flex flex-col justify-between">
                            {e.map((entry, idx) => {
                                return <li className={idx !== 0 ? 'mt-1' :''} key={entry.id}>
                                    {(expandedMap?.[i] || idx === 0) && <RCPayment entry={entry} />}
                                </li>
                            })}
                            {e.length > 1 && 
                                <span className="flex flex-row text-sm font-normal text-gray-500 ml-3 mb-3">
                                    {expandedMap?.[i] ? 'Collapse payment history' : 'Expand payment history'}
                                    <ChevronDownIcon onClick={() => {
                                        setExpandedMap(prev => {
                                            const newLst = [...prev];
                                            newLst[i] = !expandedMap?.[i];
                                            return newLst;
                                        })
                                    }} className={`ml-1 w-5 h-5 cursor-pointer ${expandedMap?.[i] && 'transform rotate-180'}`} />
                                </span>
                            }
                        </ul>
                    )
                })}
                {paymentsToDisplay.length > numberToDisplay ? <div className="flex items-start space-x-2 mb-3"> 
                    {paymentsToDisplay.length - numberToDisplay >= 1 && <button
                        type="button"
                        className="inline-flex items-center bg-gradient-to-r from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 text-white font-bold py-2 px-4 rounded shadow-md hover:shadow-lg transition-all ease-in-out duration-300"
                        onClick={() => setNumberToDisplay(paymentsToDisplay.length)}
                    ><ChevronDownIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        <span>{L.applicant.show_more_payments}</span>
                        <span className="ml-2 inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-700">
                            {paymentsToDisplay.length - numberToDisplay}
                        </span>
                    </button>}
                </div>: null}
            </>
        );
    }
    return <></>
}

export default function RCPayments(props: {applicant_uid: string, legacy?: boolean}) {
    const [actions, setActions] = useState<RCActions>({});
    const getActions = usePost('/payments/rc_actions');

    const config = useContext(ConfigurationContext);
    const [editingSettings, setEditingSettings] = useState(false);
    const [paymentsDisabled, setPaymentsDisabled] = useState<boolean>(false);
    const fetchCurrentSettings = usePost('/payments/blocked_payments');
    const saveSettings = usePost('/payments/update_blocked_payments');

    const refreshActions = useCallback(() => {
        (async () => {
            const res = await getActions({
                applicant: props.applicant_uid
            });
            let rcActions = (res as any).value;
            if (rcActions) {
                setActions(prev => {
                    let sameActions = true;
                    if (prev.ledger_by_unique_name?.id !== rcActions.ledger_by_unique_name?.id) {
                        sameActions = false;
                    }
                    if (prev.ledger_by_unique_name?.kind !== rcActions.ledger_by_unique_name?.kind) {
                        sameActions = false;
                    }
                    if (prev.ledger_by_unique_name?.unique_name !== rcActions.ledger_by_unique_name?.unique_name) {
                        sameActions = false;
                    }
            
                    let sortedEntries1 = prev.ledger_by_unique_name?.entries.sort((a, b) => {
                        if (a.action.created > b.action.created) {
                            return -1;
                        } else if (a.action.created < b.action.created) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    let sortedEntries2 = rcActions.ledger_by_unique_name?.entries.sort((a : RCEntry, b: RCEntry) => {
                        if (a.action.created > b.action.created) {
                            return -1;
                        } else if (a.action.created < b.action.created) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
            
                    if (JSON.stringify(sortedEntries1) !== JSON.stringify(sortedEntries2)) {
                        sameActions = false;
                    }
                    
                    if (sameActions) {
                        return prev;
                    }
                    return rcActions;
                });
                //setActions(rcActions as RCActions);
            }
        })();
    }, [props.applicant_uid]);

    // Refresh actions every 5 seconds
    const cb = useCallback(() => {
        if (document.hidden) return;
        refreshActions();
    }, [refreshActions]);
    useInterval(cb, 5000);
    
    useEffect(() => {
        refreshActions();
    }, [refreshActions]);

    // Fetch current settings from DB on page load
    useEffect(() => {
        if ((config.roles || '').includes('admin')) {
            (async () => {
                const settings : any = await fetchCurrentSettings({
                    applicant: props.applicant_uid
                });
                if (settings?.value && settings.value.length > 0) {
                    setPaymentsDisabled(settings.value[0].value === 'true');
                }
            })();
        }
    }, [config]);

    const handleCheckboxClick = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const checkboxValue = event.target.checked;
        setPaymentsDisabled(checkboxValue);
    }, [paymentsDisabled]);

    const handleEditOrSaveSettings = useCallback(() => {
        if (editingSettings) {
            saveSettings({
                applicant: props.applicant_uid,
                updatedSettings: {
                    blockedPayments: paymentsDisabled.toString()
                }
            });
        }
        
        setEditingSettings(!editingSettings);
    }, [editingSettings, paymentsDisabled]);

    return <div className={`flex flex-col justify-between ${props.legacy ? 'mb-8' : ''}`}>
        <RCPaymentTimeline actions={actions} />
        {(config.roles || '').includes('admin') && 
            <div className={`p-3 border border-gray-100 bg-gray-50 rounded items-center ${props.legacy ? 'flex flex-row justify-between' : ''}`}>
                <div className="text-gray-500">
                    <div>
                        <input
                            type="checkbox"
                            id={'disable-payment'}
                            className="mr-2 form-checkbox h-3 w-3 cursor-pointer"
                            disabled={!editingSettings}
                            onChange={handleCheckboxClick}
                            checked={paymentsDisabled}
                        />
                        <label htmlFor={'disable-payment'} className="ml-1 text-sm mb-0 cursor-pointer">
                            Disable future payments for applicant
                        </label>
                    </div>
                </div>
                <button
                    onClick={handleEditOrSaveSettings}
                    className={`border-0 flex justify-center btn-primary rounded-md px-1 py-1 text-xs font-semibold leading-6 text-white shadow-sm ${!props.legacy && 'mt-2'}`}
                >
                    { editingSettings ? 'Save Settings' : 'Edit Settings' }
                </button>
            </div>
        }
    </div>
}
